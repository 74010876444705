import {
  downloadPdf,
  openPdf,
  getStyles,
  getHeader,
  //getLogoHeader,
  getLogo,
  verticalFooter,
  landscapeFooter,
  lineVerticalPage,
  lineLandscapePage,
} from "../../../../utils/pdfManager";
import {
  formatDateCalendar,
  formatDateHour,
} from "../../../../utils/formatDate";
// import { getInscriptionsByStudentApi } from "../../../../api/inscription";
// import { getAccessToken } from "../../../../api/auth";

// const accessToken = getAccessToken();

//Busco los estudiantes por el ciclo seleccionado
const findByYear = (adviser, data, year) => {
  //year debe ser un int
  let arrayfinal = [];
  data.map((i) => {
    const states = i.finalConditions.filter(
      (i) => i.schoolYear.year === year && i.adviser === adviser._id
    );
    if (states.length > 0)
      states.map((state) => {
        arrayfinal.push({ student: i, state: state });
        return true;
      });
    return true;
  });
  return arrayfinal;
};

//Busco el estado del estudiantes por el ciclo seleccionado
const findStudentConditionByYear = (data, year) => {
  const result = data.cycles.findIndex((i) => i.schoolYear.year === year);
  if (result !== -1) {
    return data.cycles[result].studentCondition
      ? data.cycles[result].studentCondition.description
      : null;
  } else {
    return null;
  }
};

//Busco el estado de los estudiantes por el ciclo seleccionado
const findByYear2 = (adviser, data, year) => {
  //year debe ser un int
  let arrayfinal = [];
  data.map((student) => {
    // let yearsCoursed= await getInscriptionsByStudentApi(accessToken,student._id);
    // console.log(yearsCoursed)
    let listStates = [];
    //Busco los estados dentro de las fechas de inicio y final de cada item
    const states = student.finalConditions.filter(
      (i) =>
        new Date(i.initDate).getFullYear() === year && i.adviser === adviser._id
        //verifico si la condicion esta "En curso" y se encuentra dentro del rango
        //  || ((i.adviser === adviser._id && new Date(i.initDate).getFullYear() < year && i.finalCondition.description ==="En curso" )
        //   && (!i.finalDate || (i.finalDate && new Date(i.finalDate).getFullYear()>= year))
        //&& (yearsCoursed.inscriptions && yearsCoursed.inscriptions.length > 0 && yearsCoursed.inscriptions.findIndex((i)=>{return i.schoolYear.year === year})!==-1))
        //)
    );
    if (states.length > 0)
      states.map((state) => {
        // arrayfinal.push({student:student,state:state});
        listStates.push(state);
        return true;
      });
    // else{
    //   //busco ultimo estado del estudiante anterior alcanzado
    //   //cuya finalizacion sucedio el año consultado ó se encuentra actualmente vigente (sin fecha final)
    //   let lastState=null;
    //   let dataRanges=student.advisers.filter(i=>i.adviser ===adviser._id);
    //   student.finalConditions.map((data)=>{
    //     if(data.adviser && data.adviser !== null && data.adviser === adviser._id){
    //       //verifico que el año consultado este entre dentro del rango de initDate y finalDate
    //       let verifyInitYear=data.initDate? new Date(data.initDate).getFullYear() < year: false;
    //       let verifyFinalYear=data.finalDate? new Date(data.finalDate).getFullYear() >= year : true;
    //       //verifico que las fechas esten en el rango de su años/dias como consejero del estudiante
    //       let verify=dataRanges && dataRanges.length >0?
    //         dataRanges.filter(i=>checkInRange(i.initDate, i.finalDate, data.initDate)===true &&
    //         checkInRange(i.initDate, i.finalDate, data.finalDate? data.finalDate : new Date())===true) : false;
    //       // console.log(verify , verify.length > 0 , verifyInitYear ,verifyFinalYear)
    //         if(verify && verify.length > 0 && verifyInitYear && verifyFinalYear){
    //         if(lastState!==null && Date.parse(lastState.initDate) < Date.parse(data.initDate)){
    //           lastState=data;
    //         }
    //         if(lastState===null){
    //           lastState=data;
    //         }
    //       }
    //     }
    //     return true;
    //   });
    //   if(lastState !== null){
    //     arrayfinal.push({student:student ,state:lastState});
    //   }

    // }
    //console.log(listStates)
    if (listStates.length > 0) {
      //Busco la condicion del estudiante en base al ciclo lectivo
      let result = findStudentConditionByYear(student, year);
      arrayfinal.push({
        student: student,
        state: listStates,
        studentCondition: result,
      });
    }
    return true;
  });
  return arrayfinal;
};

//Chequear si una fecha y hora se encuentra dentro del rango.
function checkInRange(init, final, date) {
  if (init && final !== undefined) {
    if (
      Date.parse(date) >= Date.parse(init) &&
      Date.parse(final) >= Date.parse(date)
    ) {
      return true;
    }
    return false;
  } else if (init && (!final || final === undefined)) {
    if (
      Date.parse(date) >= Date.parse(init) &&
      Date.parse(new Date()) >= Date.parse(date)
    ) {
      return true;
    }
    return false;
  }
  return false;
}

export function reportAdviserHistory(adviser, dataStudents, isDownload) {
  const _formatStudent = (data) => {
    return data.map((item, index) => {
      return [
        {
          text:
            item.student.name &&
            (index === 0 ||
              (index !== 0 && data[index - 1].student._id !== item.student._id))
              ? item.student.lastname + " " + item.student.name
              : "",
          margin: [0, 5, 0, 0],
          fontSize: 10,
        },
        {
          text: item.state
            ? item.state
                .map((i) => {
                  return i.finalCondition ? i.finalCondition.description : "";
                })
                .join(", \n")
            : "",
          margin: [0, 5, 0, 0],
          fontSize: 10,
        },
        {
          text: item.studentCondition ? item.studentCondition : "-",
          margin: [10, 5, 0, 0],
          // alignment:"center",
          fontSize: 10,
        },
        {
          text: item.student.email ? item.student.email : "",
          margin: [0, 5, 0, 0],
          fontSize: 10,
        }, 
        {
          text: item.student.phone ? item.student.phone : "",
          margin: [0, 5, 0, 0],
          fontSize: 9.5,
        },
      ];
    });
  };

  const _formatYear = (adviser, data, years) => {
    years
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      })
      .reverse();

    return years.map((item) => {
      const dataByYear = findByYear2(adviser, data, item);

      dataByYear.sort(function (a, b) {
        return a.student.lastname.localeCompare(b.student.lastname);
      });

      const finalData = _formatStudent(dataByYear);

      if (finalData && finalData.length > 0) {
        return [
          "\n",
          { text: `Ciclo ${item}`, style: "titleLeft" },
          "\n",
          {
            style: "table",
            table: {
              headerRow: 1,
              widths: [130, 50, 60, 150, 80],
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
              body: [
                [
                  {
                    text: "Estudiante",
                    style: "tableHeaderWithoutColor",
                  },
                  {
                    text: "Estado",
                    style: "tableHeaderWithoutColor",
                  },
                  {
                    text: "Condición",
                    style: "tableHeaderWithoutColor",
                    alignment: "center",
                  },
                  {
                    text: "E-mail",
                    style: "tableHeaderWithoutColor",
                  },
                  {
                    text: "Telefono",
                    style: "tableHeaderWithoutColor",
                  },
                ],
                ...finalData,
              ],
            },
            layout: "noBorders",
          },
          // "\n",
        ];
      }
      return [];
    });
  };

  //Obtengo los ciclos lectivos (incluido el año actual)
  const years = [];

  dataStudents.map((item) => {
    item.advisers.map((b) => {
      if (b.adviser === adviser._id) {
        let date1 = b.initDate ? new Date(b.initDate).getFullYear() : null;
        let date2 = b.finalDate
          ? new Date(b.finalDate).getFullYear()
          : new Date().getFullYear();
        if (date1 !== null && years.indexOf(date1) === -1) {
          years.push(date1);
        }
        if (date2 !== null && years.indexOf(date2) === -1) {
          years.push(date2);
        }
      }
      return true;
    });
    return true;
  });

  let rangeYears = [];
  for (let i = Math.min(...years); i < Math.max(...years) + 1; i++) {
    rangeYears.push(i);
  }

  const formattedData = _formatYear(adviser, dataStudents, rangeYears);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [20, 40, 20, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      [
        lineVerticalPage(),
        { text: "REPORTE CONSEJERO HISTORICO", style: "title" },
      ],
      "\n",
      [
        {
          text: `Consejero: ${
            adviser.lastname ? adviser.lastname + ", " + adviser.name : ""
          }`,
        },
      ],
      "\n",
      "\n",
      {
        // style: "table",
        table: {
          headerRow: 1,
          widths: ["*"],
          margin: [0, 0, 0, 0],
          body: [[{ text: "CICLOS", style: "tableHeaderWithoutColor" }]],
        },
      },
      "\n",
      ...formattedData,
    ],
    footer: verticalFooter,
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteConsejero");
  } else {
    openPdf(docDefinition);
  }
}

export function reportAdviserYear(adviser, dataStudents, year, isDownload) {
  const yearInt = parseInt(year);

  const _formatStudent = (data) => {
    return data.map((item) => {
      return [
        {
          text: item.student.name
            ? item.student.lastname + " " + item.student.name
            : "",
          margin: [0, 4, 0, 0],
          fontSize: 11,
        },
        {
          text: item.state
            ? item.state
                .map((i) => {
                  return i.finalCondition ? i.finalCondition.description : "";
                })
                .join(", \n")
            : "",
          margin: [0, 5, 0, 0],
          fontSize: 11,
        },
        {
          text:
            item.studentCondition && item.studentCondition != null
              ? item.studentCondition
              : "-",
          margin: [10, 5, 0, 0],
          //  alignment:"center",
          fontSize: 11,
        },
        {
          text:
            item.student.coursedHours === null
              ? "-"
              : item.student.coursedHours,
          margin: [0, 5, 0, 0],
          alignment: "center",
          fontSize: 11,
        },
        {
          text: item.student.email ? item.student.email : "",
          margin: [0, 5, 0, 0],
          fontSize: 11,
        },
        {
          text: item.student.phone ? item.student.phone : "",
          margin: [0, 5, 0, 0],
          fontSize: 11,
        },
      ];
    });
  };

  const _formatYear = (adviser, data, year) => {
    const dataByYear = findByYear2(adviser, data, year);

    dataByYear.sort(function (a, b) {
      return a.student.lastname.localeCompare(b.student.lastname);
    });

    const finalData = _formatStudent(dataByYear);
    return [
      "\n",
      { text: `Ciclo ${year}`, style: "titleLeft" },
      "\n",
      {
        style: "table",
        table: {
          headerRow: 1,
          widths: [160, 70, 75, 90, 200, "auto"],
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          body: [
            [
              {
                text: "Estudiante",
                style: "tableHeaderWithoutColor",
                // alignment: "center",
              },
              {
                text: "Estado",
                style: "tableHeaderWithoutColor",
              },
              {
                text: "Condición",
                style: "tableHeaderWithoutColor",
                // alignment: "center",
              },
              {
                text: "Hs Cursadas",
                style: "tableHeaderWithoutColor",
                alignment: "center",
              },
              {
                text: "E-mail",
                style: "tableHeaderWithoutColor",
              },
              {
                text: "Telefono",
                style: "tableHeaderWithoutColor",
              },
            ],
            ...finalData,
          ],
        },
        layout: "noBorders",
      },

      // "\n",
    ];
  };

  const formattedData = _formatYear(adviser, dataStudents, yearInt);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [30, 40, 30, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      [lineLandscapePage(), { text: "REPORTE CONSEJERO", style: "title" }],
      "\n",
      [
        {
          text: `Consejero: ${
            adviser.name ? adviser.lastname + " " + adviser.name : ""
          }`,
        },
      ],
      "\n",
      ...formattedData,
    ],
    footer: landscapeFooter,
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteConsejero");
  } else {
    openPdf(docDefinition);
  }
}

export function reportAdviserHistoryExcel(adviser, dataStudents) {
  const _formatYear = (adviser, data, years) => {
    let dataFinal = [];
    years
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      })
      .reverse();
    years.map((item2) => {
      const dataByYear = findByYear2(adviser, data, item2);

      dataByYear.sort(function (a, b) {
        return a.student.lastname.localeCompare(b.student.lastname);
      });

      return dataByYear.map((student) => {
        // console.log(student)
        let info = [
          item2,
          student.student.lastname
            ? student.student.lastname + " " + student.student.name
            : "",
          student.state
            ? student.state
                .map((i) => {
                  return i.finalCondition ? i.finalCondition.description : "";
                })
                .join(", ")
            : "",
          student.studentCondition ? student.studentCondition : "",
          student.student.email ? student.student.email : "",
          student.student.phone ? student.student.phone : "",
        ];
        dataFinal.push(info);
      });
    });
    return dataFinal;
  };

  //Obtengo los ciclos lectivos (incluido el año actual)
  const years = [];

  dataStudents.map((item) => {
    item.advisers.map((b) => {
      if (b.adviser === adviser._id) {
        let date1 = b.initDate ? new Date(b.initDate).getFullYear() : null;
        let date2 = b.finalDate
          ? new Date(b.finalDate).getFullYear()
          : new Date().getFullYear();
        if (date1 !== null && years.indexOf(date1) === -1) {
          years.push(date1);
        }
        if (date2 !== null && years.indexOf(date2) === -1) {
          years.push(date2);
        }
      }
      return true;
    });
    return true;
  });

  let rangeYears = [];
  for (let i = Math.min(...years); i < Math.max(...years) + 1; i++) {
    rangeYears.push(i);
  }

  const data = _formatYear(adviser, dataStudents, rangeYears);
  // console.log(data);
  return data;
}

export function reportAdviserYearExcel(adviser, dataStudents, year) {
  const yearInt = parseInt(year);

  const _formatYear = (adviser, data, year) => {
    let dataFinal = [];
    const dataByYear = findByYear2(adviser, data, year);

    dataByYear.sort(function (a, b) {
      return a.student.lastname.localeCompare(b.student.lastname);
    });

    dataByYear.map((student) => {
      let info = [
        // year:year,
        // name: student.student.name? student.student.lastname + " " + student.student.name : "",
        // state: student.state? student.state.finalCondition.description : "",
        // email: student.student.email ? student.student.email : "",
        // phone: student.student.phone? student.student.phone : ""
        year,
        student.student.lastname
          ? student.student.lastname + " " + student.student.name
          : "",
        student.state
          ? student.state
              .map((i) => {
                return i.finalCondition ? i.finalCondition.description : "";
              })
              .join(", ")
          : "",
        student.studentCondition && student.studentCondition !== null
          ? student.studentCondition
          : "",
        student.student.coursedHours
          ? student.student.coursedHours === null
            ? 0
            : student.student.coursedHours
          : "",
        student.student.email ? student.student.email : "",
        student.student.phone ? student.student.phone : "",
      ];
      dataFinal.push(info);
      return true;
    });
    return dataFinal;
  };

  const data = _formatYear(adviser, dataStudents, yearInt);

  return data;
}

export function reportAdvisersYear(advisers, dataStudents, year, isDownload) {
  const yearInt = parseInt(year);

  // const _formatStudent = (data) => {
  //     return data.map((item)=>{
  //         return [
  //           { text: item.student.name? item.student.lastname + " " + item.student.name : "",
  //               margin:[0,4,0,0], fontSize:11
  //           },
  //           {
  //             text: item.state? item.state.map(i=> {return i.finalCondition? i.finalCondition.description : ""}).join(", \n")  : "", margin:[0,5,0,0],
  //              fontSize:11
  //           },
  //           {
  //             text: item.studentCondition && item.studentCondition!=null? item.studentCondition  : "-", margin:[10,5,0,0],
  //             //  alignment:"center",
  //              fontSize:11
  //           },
  //           {
  //             text: item.student.coursedHours===null? "-" : item.student.coursedHours, margin:[0,5,0,0],
  //             alignment:"center",
  //              fontSize:11
  //           },
  //           {
  //             text: item.student.email ? item.student.email : "", margin:[0,5,0,0],
  //             fontSize:11
  //           },
  //           { text: item.student.phone? item.student.phone : "", margin:[0,5,0,0],
  //             fontSize:11 },
  //         ];
  //       })

  // };

  const _formatYear = (advisers, data, year) => {
    let dataFinal = [];

    advisers.sort(function (a, b) {
      return a.lastname.localeCompare(b.lastname);
    });

    advisers.map((adviser) => {
      let dataStudents = data.findIndex((i) => i.adviser === adviser._id);
      if (
        data[dataStudents].students &&
        data[dataStudents].students.length > 0
      ) {
        const dataByYear = findByYear2(
          adviser,
          data[dataStudents].students,
          year
        );

        dataByYear.sort(function (a, b) {
          return a.student.lastname.localeCompare(b.student.lastname);
        });

        // const finalData=_formatStudent(dataByYear);
        dataFinal.push([
          {
            text: `${adviser.lastname} ${adviser.name}`,
            margin: [0, 0, 8, 0],
            fontSize: 12,
          },
          {
            text: `${data[dataStudents].students.length}`,
            alignment: "center",
            margin: [0, 0, 8, 0],
            fontSize: 12,
          },

          // "\n",
        ]);
      }
    });
    return dataFinal;
  };

  const formattedData = _formatYear(advisers, dataStudents, yearInt);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [30, 40, 30, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      [lineVerticalPage(), { text: `REPORTE CONSEJEROS CICLO ${year}`, style: "title" }],
      "\n",
      "\n",
      [
        {
          text: `Estado alumnos: "En Curso"`,
          style: "titleLeft"
        },
      ],
      "\n",
      {
        style: "table",
        table: {
          headerRow: 1,
          widths: [160, 150],
          border: [false, false, false, false],
          margin: [0, 0, 0, 0],
          body: [
            [
              {
                text: "Consejero",
                style: "tableHeaderWithoutColor",
                // alignment: "center",
              },
              {
                text: "Cant. alumnos",
                style: "tableHeaderWithoutColor",
                alignment: "center",
              },
            ],
            ...formattedData,
          ],
        },
        layout: "noBorders",
      },
    ],
    footer: verticalFooter,
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteConsejero");
  } else {
    openPdf(docDefinition);
  }
}

export function reportAdvisersYearCompleted(
  advisers,
  dataStudents,
  year,
  isDownload
) {
  const yearInt = parseInt(year);

  const _formatStudent = (data) => {
    return data.map((item) => {
      return [
        {
          text: item.student.name
            ? item.student.lastname + " " + item.student.name
            : "",
          margin: [0, 4, 0, 0],
          fontSize: 11,
        },
        {
          text:
            item.studentCondition && item.studentCondition != null
              ? item.studentCondition
              : "-",
          margin: [10, 5, 0, 0],
          //  alignment:"center",
          fontSize: 11,
        },
        {
          text: item.student.email ? item.student.email : "",
          margin: [0, 5, 0, 0],
          fontSize: 11,
        },
        {
          text: item.student.phone ? item.student.phone : "",
          margin: [0, 5, 0, 0],
          fontSize: 11,
        },
      ];
    });
  };

  const _formatYear = (advisers, data, year) => {
    advisers.sort(function (a, b) {
      return a.lastname.localeCompare(b.lastname);
    });

    return advisers.map((adviser) => {
      let dataStudents = data.findIndex((i) => i.adviser === adviser._id);

      if (
        data[dataStudents].students &&
        data[dataStudents].students.length > 0
      ) {
        const dataByYear = findByYear2(
          adviser,
          data[dataStudents].students,
          year
        );

        dataByYear.sort(function (a, b) {
          return a.student.lastname.localeCompare(b.student.lastname);
        });

        const finalData = _formatStudent(dataByYear);
        return [
          "\n",
          { text: `${adviser.lastname} ${adviser.name}`, style: "titleLeft", fontSize:12},
          {
            table : { 
                headerRows : 1,
                alignment: "center",
                widths: [510],
                body : [
                        [''],
                        ['']
                        ]
            },
            layout : 'headerLineOnly'
          },
          {
            style: "table",
            table: {
              headerRow: 1,
              widths: [150, 80, 200, "auto"],
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
              body: [
                [
                  {
                    text: "Estudiante",
                    style: "tableHeaderWithoutColor",
                    // alignment: "center",
                  },
                  {
                    text: "Condición",
                    style: "tableHeaderWithoutColor",
                    // alignment: "center",
                  },
                  {
                    text: "E-mail",
                    style: "tableHeaderWithoutColor",
                  },
                  {
                    text: "Telefono",
                    style: "tableHeaderWithoutColor",
                  },
                ],
                ...finalData,
              ],
            },
            layout: "noBorders",
          },
          "\n",
          // "\n",
        ];
      }
    });
  };

  const formattedData = _formatYear(advisers, dataStudents, yearInt);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [30, 40, 30, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      [lineVerticalPage(), { text: `REPORTE CONSEJEROS CICLO ${year}`, style: "title" }],
      "\n",
      [
        {
          text: `Ciclo: ${year}`,
          style: "titleLeft",
        },
      ],
      "\n",
      ...formattedData,
    ],
    //footer: landscapeFooter,
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteConsejero");
  } else {
    openPdf(docDefinition);
  }
}

export function reportAdvisersYearCompleted2(
  advisers,
  year,
  isDownload
) {
  const yearInt = parseInt(year);

  const _formatStudent = (data) => {
    return data.map((item) => {
      return [
        {
          text: item.student.name
            ? item.student.lastname + " " + item.student.name
            : "",
          margin: [0, 4, 0, 0],
          fontSize: 11,
        },
        {
          text: item.state
            ? item.state
                .map((i) => {
                  return i.finalCondition ? i.finalCondition.description : "";
                })
                .join(", \n")
            : "",
          margin: [0, 5, 0, 0],
          fontSize: 11,
        },
        {
          text: item.student.email ? item.student.email : "",
          margin: [0, 5, 0, 0],
          fontSize: 11,
        },
        {
          text: item.student.phone ? item.student.phone : "",
          margin: [0, 5, 0, 0],
          fontSize: 11,
        },
      ];
    });
  };

  const _formatYear = (advisers, year) => {
    advisers.sort(function (a, b) {
      return a.lastname.localeCompare(b.lastname);
    });

    return advisers.map((adviser) => {
      let dataStudents = adviser.students;

      if (
        dataStudents&&
        dataStudents.length > 0
      ) {

        const dataByYear = findByYear2(
          adviser,
          dataStudents,
          year
        );

        dataByYear.sort(function (a, b) {
          return a.student.lastname.localeCompare(b.student.lastname);
        });

        const finalData = _formatStudent(dataByYear);
        return [
          "\n",
          { text: `${adviser.lastname} ${adviser.name}`, style: "titleLeft", fontSize:12},
          {
            table : { 
                headerRows : 1,
                alignment: "center",
                widths: [510],
                body : [
                        [''],
                        ['']
                        ]
            },
            layout : 'headerLineOnly'
          },
          {
            style: "table",
            table: {
              headerRow: 1,
              widths: [150, 100, 190, "auto"],
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
              body: [
                [
                  {
                    text: "Estudiante",
                    style: "tableHeaderWithoutColor",
                    // alignment: "center",
                  },
                  {
                    text: "Estado",
                    style: "tableHeaderWithoutColor",
                    // alignment: "center",
                  },
                  {
                    text: "E-mail",
                    style: "tableHeaderWithoutColor",
                  },
                  {
                    text: "Telefono",
                    style: "tableHeaderWithoutColor",
                  },
                ],
                ...finalData,
              ],
            },
            layout: "noBorders",
          },
          "\n",
          // "\n",
        ];
      }
    });
  };

  const formattedData = _formatYear(advisers, yearInt);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [30, 40, 30, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      [lineVerticalPage(), { text: `REPORTE CONSEJEROS CICLO ${year}`, style: "title" }],
      "\n",
      [
        {
          text: `Ciclo: ${year}`,
          style: "titleLeft",
        },
      ],
      "\n",
      ...formattedData,
    ],
    //footer: landscapeFooter,
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteConsejero");
  } else {
    openPdf(docDefinition);
  }
}

export function reportAdvisersYearExcel(advisers, dataStudents, year) {
  const yearInt = parseInt(year);

  const _formatYear = (advisers, data, year) => {
    let dataFinal = [];

    advisers.sort(function (a, b) {
      return a.lastname.localeCompare(b.lastname);
    });

    advisers.map((adviser) => {
      let dataStudents = data.findIndex((i) => i.adviser === adviser._id);
      if (
        data[dataStudents].students &&
        data[dataStudents].students.length > 0
      ) {
        // const dataByYear=(findByYear2(adviser, data[dataStudents].students, year))

        // const finalData=_formatStudent(dataByYear);
        dataFinal.push(
          [
            adviser.lastname + " " + adviser.name,
            data[dataStudents].students.length,
          ]
          // "\n",
        );
      }
    });
    return dataFinal;
  };

  const data = _formatYear(advisers, dataStudents, yearInt);

  return data;
}

export function reportAdvisersYearCompletedExcel(advisers, year) {
  const yearInt = parseInt(year);

  const _formatYear = (advisers, year) => {
    let dataFinal = [];

    advisers.sort(function (a, b) {
      return a.lastname.localeCompare(b.lastname);
    });

    advisers.map((adviser) => {
      let dataStudents = adviser.students;
      if (
        dataStudents &&
        dataStudents.length > 0
      ) {
        // const dataByYear=(findByYear2(adviser, data[dataStudents].students, year))

        // const finalData=_formatStudent(dataByYear);
        const dataByYear = findByYear2(
          adviser,
          dataStudents,
          year
        );
        
        dataByYear.map(result=>{
        const finalConditionData=result.state? 
        (result.state.map((i) => {
                  return i.finalCondition ? i.finalCondition.description : "";
                })
                .join(", \n")): "";
          dataFinal.push(
            {
              adviser: adviser.lastname + " " + adviser.name,
              lastname: result.student.lastname,
              name:result.student.name,
              state: finalConditionData,
              phone:result.student.phone?result.student.phone:"",
              email:result.student.email?result.student.email:"",

            }
            // "\n",
          );
        })

      }
    });
    return dataFinal;
  };

  const data = _formatYear(advisers, yearInt);

  //console.log(data)
  return data;
}
