import { switchPath, apiVersion } from './config';

/**FUNCIONES PARA MANEJO DEL MODULO COLABORADOR DE DOCENTE QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getTeacherCollabExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/teachercollab-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'teachercollab.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postTeacherCollabImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/teachercollab-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addTeacherCollabApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-teachercollab`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getTeacherCollabsApi(token) {
    const url = `${switchPath()}/${apiVersion}/teachercollabs`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getTeacherCollabsPaginateApi(token) {
    const url = `${switchPath()}/${apiVersion}/teachercollabs-paginate`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function getTeacherCollabsActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/teachercollabs-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateTeacherCollabApi(token, teacherCollabData, teacherCollabId) {
    const url = `${switchPath()}/${apiVersion}/update-teachercollab/${teacherCollabId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(teacherCollabData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateTeacherCollabApi(token, teacherCollabId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-teachercollab/${teacherCollabId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteTeacherCollabApi(token, teacherCollabId) {
    const url = `${switchPath()}/${apiVersion}/delete-teachercollab/${teacherCollabId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}
