import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { updateEntryConditionApi } from "../../../../api/entryCondition";
import { getAccessToken } from "../../../../api/auth";

import "./EditEntryConditionForm.scss";

export default function EditEntryConditionForm(props) {
  const { entryCondition, setIsVisibleModal, setReloadEntryConditions } = props;
  const [entryConditionData, setEntryConditionData] = useState({});

  //trae los datos de condicion de ingreso
  useEffect(() => {
    setEntryConditionData({
      entryCondition_code: entryCondition.entryCondition_code,
      description: entryCondition.description,
    });
  }, [entryCondition]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!entryConditionData.description) {
      notification["error"]({
        message: "Obligatorio: descripción.",
      });
      errorExists = true;
    } else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateEntryCondition = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let entryConditionUpdate = entryConditionData; //es let porque se va actualizando

      updateEntryConditionApi(
        token,
        entryConditionUpdate,
        entryCondition._id
      ).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadEntryConditions(true);
        }
      });
    }
  };

  return (
    <div className="edit-entrycondition-form">
      <EditForm
        entryConditionData={entryConditionData}
        setEntryConditionData={setEntryConditionData}
        updateEntryCondition={updateEntryCondition}
      />
    </div>
  );
}

function EditForm(props) {
  const { entryConditionData, setEntryConditionData, updateEntryCondition } =
    props;

  return (
    <Form className="form-edit" onSubmit={updateEntryCondition}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={entryConditionData.entryCondition_code}
              onChange={(e) =>
                setEntryConditionData({
                  ...entryConditionData,
                  entryCondition_code: e,
                })
              }
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              //   placeholder="descripción"
              value={entryConditionData.description}
              onChange={(e) =>
                setEntryConditionData({
                  ...entryConditionData,
                  description: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateEntryCondition}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
