import { downloadPdf, openPdf , getStyles, getHeader, getLogoHeader, getLogo, lineVerticalPage, lineLandscapePage, verticalFooter, landscapeFooter} from "../../../../utils/pdfManager"; //docDefinitionTable
//var currentPage = 1;
//var pageCount = 1;

 // Cantidad de horas totales acumuladas
 const countTotalHours = (arrayInscriptions, student) => {
  let hours=0;
  const infoKeys = Object.keys(arrayInscriptions);
  infoKeys.map((item) => {
    arrayInscriptions[item].map((subitem)=>{
      let studentData = subitem.students.filter(i=>i.student === student._id);
      if(studentData && studentData.length > 0){
        if(studentData[0].stateStudentCourse && studentData[0].stateStudentCourse.description.toLowerCase() === "aprobado"){
          //TO DO: Verificar cuando esten cargadas todas las inscripciones. 
          //hours+= studentData[0].ownHours ? studentData[0].ownHours : subitem.course.workload ? subitem.course.workload.hours : 0 ;
           hours+= studentData[0].ownHours ? studentData[0].ownHours : 0  ;
        }
      }
      return true;
    });
     return true;
  });
  return hours;
};


//REPORTE ESTUDIANTE
export function reportCyclesStudent(data, isDownload) {

  const _format = (data) => {
    return [
      { text: `Nombre: ${data.name ? data.name : ""}`, style:'list' },
      { text: `Apellido: ${data.lastname ? data.lastname : ""}` , style:'list'},
      { text: `Telefono : ${data.phone ? data.phone : ""}`, style:'list' },
      { text: `E-mail: ${data.email ? data.email : ""}`, style:'list' },
      { text: `Consejero: ${data.adviser ? data.adviser.name + " " + data.adviser.lastname : ""}` , style:'list'},
      // { text: `Observaciones: ${data.person.observation ? data.person.observation : ""}`, style:'list' },
    ];
  };

  const _formatCycles = (cyclesArray) => {
    // console.log(data.cycles)
    return cyclesArray.map((item) => {
      return [
        { text: item.schoolYear? item.schoolYear.year : "", alignment: "left", margin:[0,2,0,0] },
        {
          text: item.studentCondition ? item.studentCondition.description : "",
          alignment: "left", margin:[0,2,0,0]},
      ];
    });
  };

  const formattedData = _format(data);
  const formattedDataCycles = data.cycles? _formatCycles(data.cycles) : [];

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [40, 40, 40, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      //getLogo(),
      //'\n',
      [
        lineVerticalPage(),
        { text:'REPORTE ESTUDIANTE', style:'title'},
      ],
      '\n',
      '\n',
      {
        type: "none",
        ol: [...formattedData],
      },
      '\n',
      '\n',
      formattedDataCycles.length > 0? 
      {
        style: 'table',
        table: {
          headerRow: 1,
          widths:['*','*'],
          border: [false, false, false,false],
          body: [
            [
              { text: "Ciclo", style: "tableHeaderWithoutColor" },
              { text: "Condición", style: "tableHeaderWithoutColor" },
            ],
            ...formattedDataCycles,
          ],
        },
        layout: "noBorders",
      } : null,
    ],

    footer: verticalFooter,
    /*
    footer: [
      {
        text: `Instituto Clínico de Buenos Aires - Callao 1033 5° Piso - C.P(1023) - Tel: 4816-7292`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
      {
        text: `e-mail: icba@eol.org.ar`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
    ],*/
    styles:getStyles()
  };
  let title = `${data.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}

//INFORME DE HORAS CURSADAS
export function reportHoursStudent(student, data, departments, isDownload) {
  var totalHours = 0;

  //Filtro listado de departamentos para sacar los no requeridos
  const departmentsFilter=departments.filter(i=>i.description.toLowerCase() !== "sin departamento");

  //Contar la cantidad de horas del estudiante con estado aprobado por departamento
  const countHoursStudentByDepartment = (array) =>{
    let count = 0;
    if(array && array.length > 0){
      array.map((item)=>{
        let dataStudent = item.students.filter(i=>i.student === student._id);
        if(dataStudent && dataStudent.length > 0){
          if(dataStudent[0].stateStudentCourse && dataStudent[0].stateStudentCourse.description.toLowerCase() === "aprobado"){
            if(dataStudent[0].ownHours){
              count += dataStudent[0].ownHours;
            }
          }
        }
      });
    }
    totalHours += count;
    return count;
  }

  const _formatDepartment = (array) => {
    // const info=Object.keys(array);

    return departmentsFilter.map((item) => {
        let dataInscriptions = array[item._id];
        let hours = countHoursStudentByDepartment(dataInscriptions);
        return [
          { text:`${item.description}`, style: 'titleLeft', alignment:"center", margin:[0,5,0,0], fontSize:12},
          { text:`${hours}`, style: 'titleLeft', alignment:"center", margin:[0,5,0,20], fontSize:12},         
        ]
    });
  };

  //Agrupa por departamento
  const result = data.reduce(function (a, b) {
    if(b.course.department){
      a[b.course.department._id] = a[b.course.department._id] || [];
      a[b.course.department._id].push(b);
    }
     return a;
  }, Object.create(null));

  const formattedDataDepartment = result? _formatDepartment(result) : [];

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [40, 40, 40, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header:getHeader(),
    content: [
      //getLogoHeader(),
      //getLogo(),
      //'\n',
      [
        lineVerticalPage(),
        { text:'INFORME DE HORAS CURSADAS', bold:true, alignment:"center", decoration: "underline", fontSize:20},
      ],
      '\n',
      '\n',
      {
        style: "table",
        table: {
          headerRow: 1,
          widths: ["*", "*"],
          margin: [0, 10, 0, 10],
          border:[false, false, false, false],
          body: [
            [
              { text: `Estudiante: ${student.lastname}, ${student.name}`, style: "tableHeaderWithoutColor",margin: [10, 15, 10, 15] , fillColor:"#dcdcdc", fontSize:12 },
              { text: student.adviser? `Consejero: ${student.adviser.lastname}, ${student.adviser.name}` : "", style: "tableHeaderWithoutColor",margin: [10, 15, 10, 15], fillColor:"#dcdcdc", fontSize:12 }
            ]
          ],
        },
        layout: "noBorders",
      },
      '\n',
      '\n',
      [
        { text:'Total de Horas' , style:'title'},
        { text: totalHours , style:'title'},
    ],
      '\n',
      '\n',
    {
       table: {
        widths:['*'],
        body: [
          [
        {
          style: 'table',
          table: {
            headerRow: 1,
            widths:['*', '*','*'],
            // border: [false, false, false,false],
            body: [
              [
                formattedDataDepartment[0], formattedDataDepartment[1], formattedDataDepartment[2]
              ],
              [
                formattedDataDepartment[3], formattedDataDepartment[4], formattedDataDepartment[5]
              ],
              [
                 formattedDataDepartment[6], formattedDataDepartment[7], formattedDataDepartment[8]
              ],
            ],
          },
          layout: "noBorders",
        },
        ]
      ],
      }, 
    }
    ],
    footer: verticalFooter,
    styles:getStyles()
  };
  let title = `${data.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}

//INFORME DEL CONSEJERO
export function reportAdviserStudent(student, data, absences, isDownload) {

  //Cantidad de inasistencias del estudiante en el curso
  const countAbsences = (courseId) =>{
    let a=absences.filter(i=>i.course === courseId);
    if(a.length > 0){
      
       return a.length ;
    }
    return 0;
  };

  // // Cantidad de horas totales acumuladas
  // const countTotalHours = (arrayInscriptions) => {
  //   let hours=0;
  //   const infoKeys = Object.keys(arrayInscriptions);
  //   infoKeys.map((item) => {
  //     arrayInscriptions[item].map((subitem)=>{
  //       let studentData = subitem.students.filter(i=>i.student === student._id);
  //       if(studentData && studentData.length > 0){
  //         if(studentData[0].stateStudentCourse && studentData[0].stateStudentCourse.description.toLowerCase() === "aprobado"){
  //           //TO DO: Verificar cuando esten cargadas todas las inscripciones. 
  //           //hours+= studentData[0].ownHours ? studentData[0].ownHours : subitem.course.workload ? subitem.course.workload.hours : 0 ;
  //            hours+= studentData[0].ownHours ? studentData[0].ownHours : 0  ;
  //         }
  //       }
  //       return true;
  //     });
  //      return true;
  //   });
  //   return hours;
  // };

  //Estado del estudiante en la inscripcion del curso
  const stateStudent = (arrayStudents) =>{
    let dataStudent=arrayStudents.filter(i=>i.student === student._id);
    if(dataStudent.length > 0){
      if(dataStudent[0].stateStudentCourse){
        return dataStudent[0].stateStudentCourse.description;
      }
       //Si no tiene stateStudentCourse es porque esta en estado de inscripto
       return "Inscripto"
    }
    return "-";
  };


  const _format = (data) => {
    return [
      //{ text: `${data.lastname ? data.lastname : ""}, ${data.name ? data.name : ""}`, fontSize:10 , bold:true, margin:[8,0,0,0]},
      {
        table:{
          body:[
            [
            [ 
              { text: `${data.lastname ? data.lastname : ""}, ${data.name ? data.name : ""}`, fontSize:10 , bold:true, margin:[8,0,0,0]},
              { rowSpan:2, text: `\n \n ${data.city && data.province ? data.city + ", " + data.province : ""}`, fontSize:10, margin:[8,0,0,5]},
              { text: `${data.phone ? data.phone : ""}`,fontSize:10, margin:[8,0,0,5]},
              { text: `${data.email ? data.email : ""}`,fontSize:10, margin:[8,0,0,5]}
            ],
            [
              { text: `(${countTotalHours(result, student)} Horas)`, fontSize:10 , bold:true, margin:[8,0,0,0]},
              { text: `Consejero: ${data.adviser ? data.adviser.name + " " + data.adviser.lastname : ""}`,fontSize:10, margin:[8,8,0,5]},
              { text: `${data.yearAdmission ? "Año de Ingreso : " + data.yearAdmission : ""}`,fontSize:10, margin:[8,0,0,5]},
              {},{}
            ],
          ],
          ]
        },
        layout: "noBorders",
      }
    ];
  };

  const _formatCycles = (cyclesArray) => {
    return cyclesArray.map((item) => {
      return [
        { text: item.schoolYear? item.schoolYear.year : "", alignment: "left", fontSize: 10 },
        {
          text: item.studentCondition ? item.studentCondition.description : "",
          alignment: "left", fontSize: 10
        },
      ];
    });
  };
  
  const _formatCourses = (array) => {
    return array.map((item) => {
      return [
        { text: item.course.name? item.course.name : "", alignment: "left",fontSize: 9, margin:[0,4,0,0] },
        { text: item.course.department ? item.course.department.description : "", alignment: "left",fontSize: 9, margin:[0,4,0,0] },
        { text: item.course.teachers? item.course.teachers.map(teacher=> teacher.description) : "",fontSize: 9 , margin:[0,4,0,0] },
        { text: item.course.workload ? item.course.workload.hours : "", alignment: "left" ,fontSize: 9 , margin:[0,4,0,0] },
        { text: item.course.workload ? item.course.workload.absencesAllowed : "", alignment: "left" ,fontSize: 9, margin:[0,4,0,0] },
        { text: countAbsences(item.course._id), alignment: "left" ,fontSize: 9, margin:[0,4,0,0] },
        { text: stateStudent(item.students), alignment: "left" ,fontSize: 9, margin:[0,4,0,0] }

      ];
    });
  };

  const _formatYear = (array) => {
    const info = Object.keys(array);
    info
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      })
      .reverse();

    return info.map((item) => {
      return [
        "\n",
        { text: `Ciclo: ${item}`, style: "titleLeft" ,margin: [ 5, 0, 0, 0 ] },
        "\n",
        {
          style: "table",
          table: {
            headerRow: 1,
            widths: [200, 80 , 100, "*", "auto","*","*"],
            border: [false, false, false, false],
            margin: [8, 0, 3, 0],
            body: [
              [
                // { text: "Código", style: "tableHeader" },
                { text: "Nombre del curso/seminario", style: "tableHeaderWithoutColor", alignment: "left", fontSize: 10 },
                { text: "Sector", style: "tableHeaderWithoutColor", alignment: "left", fontSize: 10 },
                { text: "Responsable", style: "tableHeaderWithoutColor", alignment: "left", fontSize: 10 },
                { text: "Carga Horaria", style: "tableHeaderWithoutColor", alignment: "left", fontSize: 10 },
                { text: "Faltas permitidas", style: "tableHeaderWithoutColor", alignment: "left",fontSize: 10 },
                { text: "Faltas", style: "tableHeaderWithoutColor", alignment: "left" , fontSize: 10 },
                { text: "Condición", style: "tableHeaderWithoutColor", alignment: "left", fontSize: 10 }
              ],
              ..._formatCourses(array[item]),
            ],
          },
          layout: "noBorders",
        },
        "\n",
      ];
    });
    // return print;
  };

  //Agrupar por schoolYear
  const result = data.reduce(function (a, b) {
    if(b.course.schoolYear){
      a[b.course.schoolYear.year] = a[b.course.schoolYear.year] || [];
      a[b.course.schoolYear.year].push(b);
    }
    return a;
  }, Object.create(null));

  //console.log(result);
  const formattedDataStudent = _format(student);
  const formattedData = _formatYear(result);
  const formattedDataCycles = student.cycles? _formatCycles(student.cycles) : [];

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "landscape",
    pageMargins: [40, 40, 40, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      //getLogo(),
      [
        lineLandscapePage(),
        { text: "INFORME DEL CONSEJERO", style: "title", decoration: "underline"}
      ],
      "\n",
      [
        ...formattedDataStudent
      ],
      "\n",
      {
        // style: "table",
        table: {
          headerRow: 1,
          widths: ["*"],
          margin: [0, 0, 0, 0],
          body: [
            [
              { text: "CURSOS", style: "tableHeaderWithoutColor" }
            ]
          ],
        },
      },
      "\n",
      ...formattedData,

      '\n',
      '\n',
      {
        // style: "table",
        table: {
          headerRow: 1,
          widths: ["*"],
          margin: [0, 0, 0, 0],
          body: [
            [
              { text: "CICLOS", style: "tableHeaderWithoutColor" }
            ]
          ],
        },
      },
      {
        style: 'table',
        table: {
          headerRow: 1,
          widths:['*','*'],
          margin: [ 8, 0, 5, 0 ],
          border: [false, false, false,false],
          body: [
            [
              { text: "Ciclo", style: "tableHeaderWithoutColor", fontSize: 10 },
              { text: "Condición", style: "tableHeaderWithoutColor", fontSize: 10 },
            ],
            ...formattedDataCycles,
          ],
        },
        layout: "noBorders",
      },
      '\n',
      '\n',
      {
        // style: "table",
        table: {
          headerRow: 1,
          widths: ["*"],
          margin: [0, 0, 0, 0],
          body: [
            [
              { text: "Ensayos", style: "tableHeaderWithoutColor" }
            ]
          ],
        },
      },
      // {
        
      // },
    ],
    footer: landscapeFooter,
    /*
    footer: [
      {
        text: `Instituto Clínico de Buenos Aires - Callao 1033 5° Piso - C.P(1023) - Tel: 4816-7292`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
      {
        text: `e-mail: icba@eol.org.ar`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
    ],*/
    //currentPage.toString() + ' de ' + pageCount
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteConsejero");
  } else {
    openPdf(docDefinition);
  }
}

//ACTIVIDADES CURSADAS Y HORAS SEGMENTADAS POR AÑO
export function reportActivitiesAndHoursStudentByYear(student, data, isDownload) {

    //Cantidad de horas aprobadas por departamento
    const countSubTotalHours = (arrayInscriptions) => {
      let hours=0;
      arrayInscriptions.map((item) => {
        let studentData = item.students.filter(i=>i.student === student._id);
        if(studentData && studentData.length > 0){
          //if(studentData[0].stateStudentCourse && studentData[0].stateStudentCourse.description.toLowerCase() === "aprobado"){
            //hours+= studentData[0].ownHours ? studentData[0].ownHours : item.course.workload ? item.course.workload.hours : 0  ;
            hours+= studentData[0].ownHours ? studentData[0].ownHours : 0  ;
          //}
        }
        return true;
      });
      return hours;
    };

    // Cantidad de horas totales acumuladas de los cursos aprobados
    // const countTotalHours = (arrayInscriptions) => {
    //   let hours=0;
    //   const infoKeys = Object.keys(arrayInscriptions);
    //   infoKeys.map((item) => {
    //     arrayInscriptions[item].map((subitem)=>{
    //       let studentData = subitem.students.filter(i=>i.student === student._id);
    //       if(studentData && studentData.length > 0){
    //         //TO DO: Verificar cuando esten cargadas todas las incripciones. 
    //         //if(studentData[0].stateStudentCourse && studentData[0].stateStudentCourse.description.toLowerCase() === "aprobado"){
    //           //hours+= studentData[0].ownHours ? studentData[0].ownHours : subitem.course.workload ? subitem.course.workload.hours : 0 ;
    //           hours+= studentData[0].ownHours ? studentData[0].ownHours : 0  ;
    //         //}
    //       }
    //       return true;
    //     });
    //      return true;
    //   });
    //   return hours;
    // };
  

  const _formatCourses = (arrayCourses) => {
    return arrayCourses.map((item) => {
      return [
        { text: item.course.name? item.course.name : "", alignment: "left", margin:[4,0,0,8] },
        { text: item.course.teachers? item.course.teachers.map(teacher=> teacher.description) : "sin datos", margin:[0,0,0,8] },
        { text: item.course.workload ? item.course.workload.hours : "", alignment: "left", margin:[0,0,0,8] }

      ];
    });
  };

  const _formatDepartment = (array) =>{
    //Agrupo por departamento
    const result = array.reduce(function (a, b) {
      a[b.course.department.description] = a[b.course.department.description] || [];
      a[b.course.department.description].push(b);
    //}
    return a;
    }, Object.create(null))

    const infoKeys = Object.keys(result);

    return infoKeys.map((item) => {
      // console.log(item)
      return [
        "\n",
        { text: `${item} (${countSubTotalHours(result[item],student)} horas)`, margin: [5, 0, 0, 0], decoration: "underline" },
        {
          style: "table",
          table: {
            headerRow: 1,
            widths: [250, 150, 100],
            border: [false, false, false, false],
            body: [
              [
                 { text: "", style: "tableHeaderWithoutColor", alignment: "center"},
                 { text: "", style: "tableHeaderWithoutColor", alignment: "center"},
                 { text: "", style: "tableHeaderWithoutColor", alignment: "center"}
              ],
              ..._formatCourses(result[item]),
            ],
          },
          
          layout: "noBorders",
        },
        "\n",
      ];
    });
  }


  const _formatYear = (array) => {
    const infoKeys = Object.keys(array);

    //Ordeno las claves por año
    infoKeys
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      })
      .reverse();

    return infoKeys.map((item) => {
      return [
        "\n",
        { text: `Año ${item} (${countSubTotalHours(array[item])} horas)`, bold:true, fontSize:14 },
        ..._formatDepartment(array[item]),
        "\n",
      ];
    });
    // return print;
  };

  //Agrupar por schoolYear
  const result = data.reduce(function (a, b) {
    //filtrar por cursos aprobados
    let infoCoursed=b.students.filter(i=>i.student === student._id && i.stateStudentCourse && i.stateStudentCourse.description.toLowerCase() === "aprobado");
    if(infoCoursed && infoCoursed.length > 0){
      a[b.course.schoolYear.year] = a[b.course.schoolYear.year] || [];
      a[b.course.schoolYear.year].push(b);
    }
    return a;
  }, Object.create(null));

  //const formattedDataStudent = _format(student);
  const formattedData = _formatYear(result);
  const total = countTotalHours(result, student);

  const docDefinition = {
    pageSize: "A4",
    pageMargins: [40, 40, 40, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    pageOrientation: "vertical",
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      //getLogo(),
      //"\n",
      [
        lineVerticalPage(),
        { text: "Actividades cursadas por: " + student.lastname + ", " + student.name + " ( "+ total +" horas )", bold:true, fontSize:18 }
      ],
      "\n",
      ...formattedData,
      '\n',
      //{canvas: [ { type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 } ]},
      //line
    ],

    footer: verticalFooter,
    /*
    //FOOTER PERSONALIZADO
    footer: function(currentPage, pageCount) { 
      return(
        [
          { canvas: [ { type: 'line', x1: 35, y1: 0, x2: 550, y2: 0, lineWidth: 2, alignment: "center" } ]},
          //"\n",
          {
            text: `Instituto Clínico de Buenos Aires - Callao 1033 5° Piso - C.P(1023) - Tel: 4816-7292`,
            style: "footer",
            alignment: "center",
            // opacity: 0.5,
            fontSize: 9,
            margin: [0, 5, 0, 0]
          },
          {
            text: `e-mail: icba@eol.org.ar`,
            style: "footer",
            alignment: "center",
            // opacity: 0.5,
            fontSize: 9,
          },
          { 
            text: `${currentPage} de ${pageCount}`, 
            style:'footer', 
            alignment: 'right', 
            opacity: 0.5, fontSize: 9, 
            margin: [0, 0, 30, 10] 
          },
        ]
        //currentPage.toString() + ' de ' + pageCount 
      ) 
    },
    
    
    footer: [
      "\n",
      [
        line,
        {
          text: `Instituto Clínico de Buenos Aires - Callao 1033 5° Piso - C.P(1023) - Tel: 4816-7292`,
          style: "footer",
          alignment: "center",
          // opacity: 0.5,
          fontSize: 9,
        },
        {
          text: `e-mail: icba@eol.org.ar`,
          style: "footer",
          alignment: "center",
          // opacity: 0.5,
          fontSize: 9,
        },
        function(currentPage, pageCount) { 
          return(
            [
              {text: `${currentPage} de ${pageCount}`, style:'footer', alignment: 'center', opacity: 0.5, fontSize: 9},
            ]
            //currentPage.toString() + ' de ' + pageCount 
          ) 
        },
      ]
    ],*/
    //currentPage.toString() + ' de ' + pageCount
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteConsejero");
  } else {
    openPdf(docDefinition);
  }
}

//HISTORIAL ESTUDIANTE (HORAS CURSADAS, AÑOS CURSADOS, CONDICION FINAL)
export function reportAcademicRecordStudentExcel(student, inscriptions){
  
  const _formatStudent = (student, years) => {
    years
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      })
      .reverse();

    let stringYears=years.map(function(elem){
          return elem;
      }).join(", ");

    let yearsAbandonment = student.yearsAbandonment && student.yearsAbandonment.length > 0?  
    student.yearsAbandonment.map(function(elem){
      return elem.schoolYear.year;
    }).join(", ") : null;

    let info={
        lastname: student.lastname ,
        name:student.name,
        specialty: student.specialty? student.specialty.description : "",
        yearAdmission: student.yearAdmission,
        yearGraduation: student.yearGraduation,
        yearAbandonment: student.yearsAbandonment,
        finalCondition: student.finalCondition? student.finalCondition.description : "",
        ownHours: student.ownHours,
        yearsCoursed: stringYears,
        yearsAbandonment: yearsAbandonment
    }  
    return info;
  };

  //Obtengo los ciclos cursados
  const years=[ ];

  for (const i in inscriptions){
    let year=inscriptions[i].course.schoolYear? inscriptions[i].course.schoolYear.year : false;
    if(year && years.indexOf(year)===-1)
     years.push(year)
  }

  return _formatStudent(student,years);

}