import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, Row, Col, notification } from "antd";
import { UserOutlined, MailOutlined, LockOutlined } from "@ant-design/icons";
import { signUpAdminApi , getUsersApi} from "../../../../api/user";
import { getAccessToken } from "../../../../api/auth";
// import { getPersonsActiveApi } from "../../../../api/person";
import { getRolesActiveApi } from "../../../../api/role";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import {
  emailValidation,
  passwordValidation,
} from "../../../../utils/formValidation";
import { administradores } from '../../../../utils/constants';
import jwtDecode from "jwt-decode";

import "./AddUserForm.scss";

export default function AddUserForm(props) {
  const { setIsVisibleModal, setReloadUsers } = props;
  const [activeRoles, setActiveRoles] = useState([]);
  const [permissionsActive, setPermissionsActive] = useState([]);
  // const [listPersonsActive, setListPersonsActive] = useState([]);
  //const [userData, setUserData] = useState({});
  const [listUsers, setListUsers] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken);

  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    role: "",
    password: "",
    repeatPassword: "",
  });

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, [permissionsActive]);


  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, [roleById]);

  // useEffect(() => {
  //   const accessToken = getAccessToken();
  //   getPersonsActiveApi(accessToken, true).then((response) => {
  //     setListPersonsActive(response.persons);
  //   });
  // }, []);

  useEffect(() => {
    getRolesActiveApi(accessToken, true).then((response) => {
      setActiveRoles(response.roles);
    });
  }, []);

  useEffect(() => {
    getUsersApi(accessToken).then((response) => {
      setListUsers(response);
    });
  }, []);

  const [formValid, setFormValid] = useState({
    email: false,
    password: false,
  });

  const inputValidation = (e) => {
    const { type, name } = e.target;

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });

    if (type === "email") {
      setFormValid({
        ...formValid,
        [name]: emailValidation(e.target),
      });
    }
    if (type === "password") {
      setFormValid({
        ...formValid,
        [name]: passwordValidation(e.target),
      });
    }
  };

  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !inputs.username ||
      !inputs.role ||
      !inputs.email ||
      !inputs.password ||
      !inputs.repeatPassword
    ) {
      notification["error"]({
        message: "Todos los campos son obligatorios",
      });
      errorExists = true;
    }
    if (inputs.password && inputs.repeatPassword) {
      if (inputs.password !== inputs.repeatPassword) {
        notification["error"]({
          message: "Las contraseñas tienen que ser iguales",
        });
        errorExists = true;
      }
    }
    if (!formValid.email && inputs.email !== "") {
      notification["error"]({
        message: "El email es inválido",
      });
      errorExists = true;
    }
    if (!formValid.password && inputs.password !== "") {
      notification["error"]({
        message:
          "Las contraseñas deben tener al menos 8 carácteres y debe contener mayúsculas, minúsculas y dígitos",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addUser = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await signUpAdminApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response === "El usuario ya existe") {
            notification["error"]({
              message: "El usuario ya existe.",
            });
          } else {
            notification["success"]({
              message: response,
            });
            setIsVisibleModal(false);
          }
          setReloadUsers(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-user-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        inputValidation={inputValidation}
        addUser={addUser}
        activeRoles={activeRoles}
        // listPersonsActive={listPersonsActive}
        listUsers={listUsers}
        roleById={roleById}
        administradores={administradores}
        permissionsActive={permissionsActive}
        userToken={userToken}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, inputValidation, addUser, activeRoles, roleById, userToken, permissionsActive } = props; //form
  const { Option } = Select;
  //const [listPerson, setListPerson] = useState([]);
  //console.log(administradores)

  // useEffect(() => {
  //   if(listUsers.users){
  //     let list=[];
  //     listPersonsActive.map(person=>{
  //       let personExist=listUsers.users.find(e=> e.person._id === person._id);
  //       if(!personExist){
  //         list.push(person);
  //       }
  //       return true;
  //     })
  //     setListPerson(list);
  //   }
  // },[listUsers, listPersonsActive]);


  return (
    <Form className="form-add" onSubmit={addUser}>
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span>Persona
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una persona"
              value={inputs.person}
              onChange={(e) => setInputs({ ...inputs, person: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listPerson
                ? listPerson.map((person) => {
                    return (
                      <Option key={person._id}>
                        {person.name + " " + person.lastname}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <label className="control-label">
              <span className="control-required">*</span> Nombre de usuario
            </label>
            <Input
              prefix={<UserOutlined />}
              placeholder="Nombre de usuario"
              value={inputs.username}
              onChange={(e) => setInputs({ ...inputs, username: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <label className="control-label">
              <span className="control-required">*</span> e-mail
            </label>
            <Input
              prefix={<MailOutlined />}
              type="email"
              name="email"
              placeholder="Correo electrónico"
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
        {checkAction(permissionsActive, userToken.role, "user-role", "add") ||
        isAdmin(roleById) ? (
          <Form.Item>
            <label className="control-label">
              <span className="control-required">*</span> Rol
            </label>
            <Select
              className="select-form"
              placeholder="Selecciona el rol"
              onChange={(e) => setInputs({ ...inputs, role: e })}
              value={inputs.role}
              //style={{textAlign: "left"}}
            >
            {activeRoles
              ? activeRoles.map((i) => {
                  return <Option key={i._id}>{i.name}</Option>;
                })
              : "No hay roles disponibles"}
            </Select>
          </Form.Item>
          ) :
          <Form.Item>
            <label>
              Rol
            </label>
            <Select
              className="select-form"
              placeholder="Selecciona un rol"
              onChange={(e) => setInputs({ ...inputs, role: e })}
              value={inputs.role}
              //disabled={true}
            >
            {/* DEBERIA SER ASI PERO NO RETORNA LOS ROLES
            {
              activeRoles ? activeRoles.map((i) => {
                administradores.map((r) => {
                  //console.log(r);
                  if(i.name !== r){
                    return <Option key={i._id}>{i.name}</Option>;
                  }
                })

              })
              : "No hay roles disponibles"
            }
            */}
            {
            activeRoles ? activeRoles.map((i) => {
              //if ((isAdmin(i.name)))
              if ((i.name !== 'administrador') &&
                (i.name !== 'Administrador') &&
                (i.name !== 'ADMINISTRADOR') &&
                (i.name !== 'admin') &&
                (i.name !== 'Admin') &&
                (i.name !== 'ADMIN')
                )
                {
                  return <Option key={i._id}>{i.name}</Option>;
                } else {
                  return null; //retorno algo por el warning
                }
              })
              : "No hay roles disponibles"
            }

            </Select>
          </Form.Item>
        }
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <label className="control-label">
              <span className="control-required">*</span> Contraseña
            </label>
            <Input
              prefix={<LockOutlined />}
              type="password"
              name="password"
              placeholder="Contraseña"
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <label className="control-label">
              <span className="control-required">*</span> Repetir contraseña
            </label>
            <Input
              prefix={<LockOutlined />}
              type="password"
              name="repeatPassword"
              placeholder="Repetir contraseña"
              onChange={(e) =>
                setInputs({ ...inputs, repeatPassword: e.target.value })
              }
              value={inputs.repeatPassword}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addUser}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
