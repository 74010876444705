import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  //Radio,
  Button,
  notification,
  //Modal as ModalAntd,
  Table,
  BackTop,
} from "antd";
import { 
  //DownloadOutlined, 
  FilePdfOutlined 
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import { getCoursesByTeacherCollabApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { reportTeacherCollabHistory } from "../../TeacherCollabs/ReportsTeacherCollabs/pdfTeacherCollabsReport";
import jwtDecode from "jwt-decode";

import "./ListTeacherCollabsReports.scss";

//const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListTeacherCollabsReports(props) {
  const {
    setPaginationActive,
    setPaginationInactive,
    teacherCollabsActive,
    teacherCollabsInactive,
    setReloadTeacherCollabs,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewTeachersActives, setViewTeachersActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [teacherCollabsActiveFilter, setTeacherCollabsActiveFilter] = useState(
    []
  );
  const [teacherCollabsInactiveFilter, setTeacherCollabsInactiveFilter] =
    useState([]);

  const [propsPaginationActive, setPropsPaginationActive] = useState({});
  const [propsPaginationInactive, setPropsPaginationInactive] = useState({});

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //Reporte Colaborador
  const report_teachercollab = (
    <div style={{"alignItems": "center", "textAlign": "center"}}>
      {" "}
      <p>Descargar PDF</p>{" "}
    </div>
  );

  //Utilizar para paginate
  // useEffect(() => {
  //   if(teacherCollabsActive){
  //     setPropsPaginationActive({
  //       total: teacherCollabsActive.totalDocs,
  //       pageSize: teacherCollabsActive.limit,
  //       defaultCurrent: teacherCollabsActive.page,
  //       current: teacherCollabsActive.page
  //     })
  //   }
  // }, [teacherCollabsActive]);

  // useEffect(() => {
  //   if(teacherCollabsInactive){
  //     setPropsPaginationInactive({
  //       total: teacherCollabsInactive.totalDocs,
  //       pageSize:teacherCollabsInactive.limit,
  //       defaultCurrent:teacherCollabsInactive.page,
  //       current: teacherCollabsActive.page
  //     })
  //   }
  // }, [teacherCollabsInactive]);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    // Utilizar para paginate
    // if (teacherCollabsActive && teacherCollabsActive.docs) {
    //   setTeacherCollabsActiveFilter(teacherCollabsActive.docs);
    // }
    const listActive = [];
    if (teacherCollabsActive) {
      teacherCollabsActive.forEach((element) => {
        if (
          element.description
            .toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          (element.teacherCollab_code &&
            element.teacherCollab_code
              .toString()
              .toUpperCase()
              .includes(filterText.toUpperCase())) ||
          filterText === ""
        ) {
          listActive.push(element);
        }
      });
    }
    setTeacherCollabsActiveFilter(listActive);
    // setTeachersActiveFilter(listActive);
  }, [teacherCollabsActive]);

  //Utilizar para paginate
  //useEffect(() => {
  //   // const listActive = [];
  //   if (filterText) {
  //     setPaginationActive({page:1, state:1, filter:filterText});
  //     setPaginationInactive({page:1, state:2, filter:filterText});
  //   }
  //   else if (filterText==="") {
  //     setPaginationActive({page:1, state:1});
  //     setPaginationInactive({page:1, state:2});
  //   }
  //   // setTeachersActiveFilter(listActive);
  // }, [filterText]);

  useEffect(() => {
    // Utilizar para paginate
    // if (teacherCollabsInactive && teacherCollabsInactive.docs) {
    //   setTeacherCollabsInactiveFilter(teacherCollabsInactive.docs);
    // }
    const listInactive = [];
    if (teacherCollabsInactive) {
      teacherCollabsInactive.forEach((element) => {
        if (
          element.description
            .toUpperCase()
            .indexOf(filterText.toUpperCase()) !== -1 ||
          filterText === ""
        ) {
          listInactive.push(element);
        }
      });
    }
    setTeacherCollabsInactiveFilter(listInactive);
  }, [filterText, teacherCollabsInactive]);

  // const addTeacherModal = () => {
  //   setIsVisibleModal(true);
  //   setModalTitle("Crear Docente");
  //   setModalContent(
  //     <AddTeacherForm
  //       setIsVisibleModal={setIsVisibleModal}
  //       setReloadTeachers={setReloadTeachers}
  //     />
  //   );
  // };

  return (
    <div className="list-teachercollabs">
      <div className="list-teachercollabs__header">
        {checkAction(
          permissionsActive,
          userToken.role,
          "teacherollabs",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "teachercollabs",
          "enable"
        ) ||
        isAdmin(roleById) ? (
          <>
            <div className="list-teachercollabs__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewTeachersActives(!viewTeachersActives)}
              />
              <span>{viewTeachersActives ? "Activos" : "Inactivos"}</span>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      {checkAction(
        permissionsActive,
        userToken.role,
        "teachercollabs",
        "all"
      ) ||
      checkAction(
        permissionsActive,
        userToken.role,
        "teachercollabs",
        "view"
      ) ||
      isAdmin(roleById) ? (
        <>
          <div className="list-teachercollabs__search">
            <Search
              className="search"
              allowClear
              placeholder=""
              onSearch={(e) => setFilterText(e)}
              enterButton="Buscar"
              style={{ width: "100%" }}
            />
            {/*
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> colaboradores.
            </h3>
            */}
          </div>
        </>
      ) : (
        <></>
      )}

      {viewTeachersActives ? (
        <>
          {checkAction(
            permissionsActive,
            userToken.role,
            "teachercollabs",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "teachercollabs",
            "view"
          ) ||
          isAdmin(roleById) ? (
            <>
              <TeacherCollabsActive
                teacherCollabsActive={teacherCollabsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadTeacherCollabs={setReloadTeacherCollabs}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationActive}
                propsPagination={propsPaginationActive}
                filterText={filterText}
                report_teachercollab={report_teachercollab}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {checkAction(
            permissionsActive,
            userToken.role,
            "teachercollabs",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "teachercollabs",
            "view"
          ) ||
          isAdmin(roleById) ? (
            <>
              <TeachersInactive
                teacherCollabsInactive={teacherCollabsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadTeacherCollabs={setReloadTeacherCollabs}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationInactive}
                propsPagination={propsPaginationInactive}
                filterText={filterText}
                report_teachercollab={report_teachercollab}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop />
    </div>
  );
}

function TeacherCollabsActive(props) {
  const {
    teacherCollabsActive,
    userToken,
    permissionsActive,
    roleById,
    report_teachercollab
    //propsPagination, setPagination, filterText,
  } = props;

  const teacherCollabHistoryPdf = (teacherCollab) => {
    const accessToken = getAccessToken();

    getCoursesByTeacherCollabApi(accessToken, teacherCollab._id, false)
      .then((response) => {
        if (response.courses) {
          reportTeacherCollabHistory(teacherCollab, response.courses);
          // console.log(teacherCollab, response.courses);
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const columns = [
    {
      title: "Código",
      //dataIndex: "teacherCollab_code",
      render: (teacherCollab) =>
        teacherCollab.id_old
          ? teacherCollab.id_old
          : teacherCollab.teachercollab_code,
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, teacherCollab) => (
        <div>
          {checkAction(
            permissionsActive,
            userToken.role,
            "teachercollabs",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "teachercollabs",
            "pdf"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_teachercollab}>
                <Button
                  className="button-cycle"
                  onClick={() => teacherCollabHistoryPdf(teacherCollab)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={() => {
        return teacherCollabsActive.length
          ? `Total de registros: ${teacherCollabsActive.length}`
          : false;
      }}
      // pagination={{
      //   ...propsPagination,
      //   showSizeChanger:false,
      //   onChange:(newPage) => {
      //     setPagination({page:newPage, state: 1, filter:filterText})}
      // }}
      dataSource={teacherCollabsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function TeachersInactive(props) {
  const {
    teacherCollabsInactive,
    userToken,
    permissionsActive,
    roleById,
    //propsPagination, setPagination, filterText,
    report_teachercollab
  } = props;

  // console.log(teachersInactive);

  const teacherCollabHistoryPdf = (teacherCollab) => {
    const accessToken = getAccessToken();

    getCoursesByTeacherCollabApi(accessToken, teacherCollab._id, false)
      .then((response) => {
        if (response.courses) {
          reportTeacherCollabHistory(teacherCollab, response.courses);
          // console.log(teacher, response.courses);
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const columns = [
    {
      title: "Código",
      //dataIndex: "teacher_code",
      render: (teacherCollab) =>
        teacherCollab.id_old
          ? teacherCollab.id_old
          : teacherCollab.teachercollab_code,
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, teacherCollab) => (
        <div>
          {checkAction(
            permissionsActive,
            userToken.role,
            "teachercollab",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "teachercollab",
            "pdf"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_teachercollab}>
                <Button
                  className="button-cycle"
                  onClick={() => teacherCollabHistoryPdf(teacherCollab)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={() => {
        return teacherCollabsInactive.length
          ? `Total de registros: ${teacherCollabsInactive.length}`
          : false;
      }}
      // pagination={{
      //   ...propsPagination,
      //   showSizeChanger:false,
      //   onChange:(newPage) => {
      //     setPagination({page:newPage, state: 1, filter:filterText})}
      // }}
      dataSource={teacherCollabsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
