import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { addDayApi } from "../../../../api/day";
import { getAccessToken } from "../../../../api/auth";

import "./AddDayForm.scss";

export default function AddDayForm(props) {
  const { setIsVisibleModal, setReloadDays } = props;

  const [inputs, setInputs] = useState({
    //day_code: "",
    description: "",
  });

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorio: descripcion.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addDay = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      if(!disabledSubmit){
        setDisabledSubmit(true);
        const accessToken = getAccessToken();

        await addDayApi(accessToken, inputs)
          .then((response) => {
            if (
              response === "ERR_CONNECTION_REFUSED" ||
              (response.message && response.message === "Failed to fetch") ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setDisabledSubmit(false);
            } else if (response.code !== 200) {
              notification["error"]({
                message: response.message,
              });
              setDisabledSubmit(false);
              setReloadDays(true);
            } else {
              notification["success"]({
                message: "Día creado",
              });
              setIsVisibleModal(false);
              setReloadDays(true);
            }
            //setReloadDays(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      }
    }
  };

  return (
    <div className="add-day-form">
      <AddForm inputs={inputs} setInputs={setInputs} addDay={addDay} />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addDay } = props;

  return (
    <Form className="form-add" onSubmit={addDay}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.day_code}
              onChange={(e) => setInputs({ ...inputs, day_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              //   placeholder="descripcion"
              value={inputs.description}
              onChange={(e) =>
                setInputs({ ...inputs, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addDay}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
