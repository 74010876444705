import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditSchoolYearForm from "../EditSchoolYearForm";
import AddSchoolYearForm from "../AddSchoolYearForm";
import { activateSchoolYearApi, deleteSchoolYearApi } from "../../../../api/schoolYear";
import { getCoursesBySchoolYearApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListSchoolYears.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListSchoolYears(props) {
  const { schoolYearsActive, schoolYearsInactive, setReloadSchoolYears } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewSchoolYearsActives, setViewSchoolYearsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [schoolYearsActiveFilter, setSchoolYearsActiveFilter] = useState(
    []
  );
  const [ schoolYearsInactiveFilter, setSchoolYearsInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (schoolYearsActive) {
      schoolYearsActive.forEach((element) => {
        /*
        if (
        element.schoolyear_code.toString()
        .toUpperCase()
        .includes(filterText.toUpperCase()) ||
        */
          if (
          (element.year && element.year.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()))||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setSchoolYearsActiveFilter(listActive);
  }, [filterText, schoolYearsActive]);

  useEffect(() => {
    const listInactive = [];
    if (schoolYearsInactive) {
      schoolYearsInactive.forEach((element) => {
        /*
        if (
        element.schoolyear_code.toString()
        .toUpperCase()
        .includes(filterText.toUpperCase()) ||
        */
          if (
          (element.year && element.year.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()))||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setSchoolYearsInactiveFilter(listInactive);
  }, [filterText, schoolYearsInactive]);

  const addSchoolYearModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Año lectivo");
    setModalContent(
      <AddSchoolYearForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadSchoolYears={setReloadSchoolYears}
      />
    );
  };

  return (
    <div className="list-schoolyears">
      <div className="list-schoolyears__header">
        {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
        checkAction(permissionsActive, userToken.role, "schoolyears", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-schoolyears__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewSchoolYearsActives(!viewSchoolYearsActives)
                }
              />
              <span>
                {viewSchoolYearsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
        checkAction(permissionsActive, userToken.role, "schoolyears", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addSchoolYearModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
      checkAction(permissionsActive, userToken.role, "schoolyears", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-schoolyears__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> debe crear primero el año lectivo y luego el curso.
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewSchoolYearsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "schoolyears", "view") || isAdmin(roleById) ? (
            <>
              <SchoolYearsActive
                schoolYearsActive={schoolYearsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadSchoolYears={setReloadSchoolYears}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "schoolyears", "view") || isAdmin(roleById) ? (
            <>
              <SchoolYearsInactive
                schoolYearsInactive={schoolYearsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadSchoolYears={setReloadSchoolYears}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function SchoolYearsActive(props) {
  const {
    schoolYearsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadSchoolYears,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editSchoolYear = (schoolYear) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${schoolYear.year ? schoolYear.year : "no hay"}`
    );
    setModalContent(
      <EditSchoolYearForm
        schoolYear={schoolYear}
        setIsVisibleModal={setIsVisibleModal}
        setReloadSchoolYears={setReloadSchoolYears}
      />
    );
  };

  const desactivateSchoolYear = (schoolYear) => {
    activateSchoolYearApi(accessToken, schoolYear._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message? response.message : "El año lectivo ha sido desactivado",
        });
        setReloadSchoolYears(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (schoolYear) => {
    let response = await getCoursesBySchoolYearApi(accessToken, schoolYear._id);
    if(response.courses.length > 0){
      confirm({
        title: "Eliminando -> Año lectivo",
        content: `¿Estás seguro que quieres eliminar a ${schoolYear.year}? porque existen cursos asociados a él,¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateSchoolYear(schoolYear);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Año lectivo",
        content: `¿Estás seguro que quieres eliminar a ${schoolYear.year}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteSchoolYearApi(accessToken, schoolYear._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadSchoolYears(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
    
  };

  const edit_schoolyear = (
    <div>
      {" "}
      <p>Editar Año lectivo</p>{" "}
    </div>
  );

  const desactivate_schoolyear = (
    <div>
      {" "}
      <p>Pasar a Años lectivos inactivos</p>{" "}
    </div>
  );

  const delete_schoolyear = (
    <div>
      {" "}
      <p>Eliminar Año lectivo</p>{" "}
    </div>
  );

  const columns = [
    //{
    //  title: "Código",
    //  dataIndex: "schoolyear_code",
    //},
    {
      title: "Año",
      dataIndex: "year",
    },
    {
        title: "Estado",
        dataIndex: "stateSchoolYear",
        render: (stateSchoolYear) => stateSchoolYear ? stateSchoolYear.description : null,
    },
    {
      title: "Acción",
      key: "action",
      render: (text, schoolYear) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "schoolyears", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_schoolyear}>
                <Button
                  className="button-edit"
                  onClick={() => editSchoolYear(schoolYear)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "schoolyears", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_schoolyear}>
                <Button
                  className="button-schoolyear"
                  type="primary"
                  onClick={() => desactivateSchoolYear(schoolYear)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "schoolyears", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_schoolyear}>
                <Button
                  className="button-schoolyear"
                  type="danger"
                  onClick={() => showDeleteConfirm(schoolYear)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return schoolYearsActive.length > 0? `Total de registros: ${schoolYearsActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={schoolYearsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function SchoolYearsInactive(props) {
  const { schoolYearsInactive,
          setReloadSchoolYears,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateSchoolYear = (schoolYear) => {
    activateSchoolYearApi(accessToken, schoolYear._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message? response.message : "El año lectivo ha sido activado",
        });
        setReloadSchoolYears(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (schoolYear) => {
    let response = await getCoursesBySchoolYearApi(accessToken, schoolYear._id);
    if(response.courses.length > 0){
      ModalAntd.info({
        title: "Eliminando -> Año Lectivo",
        content:`No se puede eliminar '${schoolYear.year}' porque existen cursos asociados a él`,
        onOk() {
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Año lectivo",
        content: `¿Estás seguro que quieres eliminar a ${schoolYear.year}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteSchoolYearApi(accessToken, schoolYear._id)
            .then((res) => {
              if (res.code === 200) {
                notification["success"]({
                  message: res.message,
                });
                setReloadSchoolYears(true);
              } else if (res.code === 404 || res.code === 500) {
                notification["error"]({
                  message: res.message,
                });
              } else if (
                res === "ERR_CONNECTION_REFUSED" ||
                res === "Failed to fetch" ||
                res === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
    
  };

  const activate_schoolyear = (
    <div>
      {" "}
      <p>Pasar a Años lectivos activos</p>{" "}
    </div>
  );

  const delete_schoolyear = (
    <div>
      {" "}
      <p>Eliminar Año lectivo</p>
    </div>
  );

  const columns = [
    //{
    //  title: "Código",
    //  dataIndex: "schoolyear_code",
    //},
    {
      title: "Año",
      dataIndex: "year",
    },
    {
        title: "Estado",
        dataIndex: "stateSchoolYear",
        render: (stateSchoolYear) => stateSchoolYear ? stateSchoolYear.description : null,
    },
    {
      title: "Acción",
      key: "action",
      render: (text, schoolYear) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "schoolyears", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_schoolyear}>
                <Button
                  className="button-activate"
                  onClick={() => activateSchoolYear(schoolYear)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "schoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "schoolyears", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_schoolyear}>
                <Button
                  className="button-schoolyear"
                  type="danger"
                  onClick={() => showDeleteConfirm(schoolYear)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return schoolYearsInactive.length > 0? `Total de registros: ${schoolYearsInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={schoolYearsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
