import React, { useState, useEffect } from "react";
import difference from "lodash/difference";
import {
  Form,
  //Input,
  Transfer,
  Modal as ModalAntd,
  Button,
  Table,
  Row,
  Col,
  Tag,
  notification,
  Select,
  Switch,
  //Tooltip,
} from "antd";
import {
  getInscriptionsActiveApi,
  insertStudentInscriptionByCourseIdApi,
  deleteStudentInscriptionByCourseIdApi,
  getInscriptionsFullBySchoolYearApi,
} from "../../../../api/inscription";
import { hasCoursesStudentApi } from "../../../../api/student";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import { getCourseApi } from "../../../../api/course";
import { updateOwnHoursByStudentIdApi } from "../../../../api/student";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import { getTypeCoursesApi } from "../../../../api/typeCourse";
import { getStudentConditionsActiveApi } from "../../../../api/studentCondition";
//import { getCoursesActiveApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
//import { checkInscriptions } from "../../../../utils/checkUserPermission";
//import { formatDateHourView } from "../../../../utils/formatDate";
import { updateStateStudentCourse } from "../../../../utils/stateStudent";
import moment from "moment";

import "./AddCoursesInscriptionForm.scss";

const { confirm } = ModalAntd;
const { Option } = Select;
//const { TextArea, Search } = Input;

export default function AddCoursesInscriptionForm(props) {
  //faltaria traer los datos del alumno o el id
  const {
    studentData,
    inscriptions,
    setIsVisibleModal,
    setReloadInscriptionCourses,
    //inscriptionsActive
  } = props;
  //const [inscriptionData, setInscriptionData] = useState({});
  //const [courseData, setCourseData] = useState({});
  const [coursesStudentEnrolled, setCoursesStudentEnrolled] = useState([]);
  const [coursesInscriptions, setCoursesInscriptions] = useState([]);
  const [listInscriptionsActive, setListInscriptionsActive] = useState([]);
  const [listCoursesActive, setListCoursesActive] = useState([]);
  const [listSchoolYearsActive, setListSchoolYearsActive] = useState([]);
  // const [schoolYears, setSchoolYears] = useState([]);
  const [schoolYearFilter, setSchoolYearFilter] = useState(null);
  const [listStateCoursesActive, setListStateCoursesActive] = useState([]);
  const [listTypeCoursesActive, setListTypeCoursesActive] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const accessToken = getAccessToken();

  //trae los años lectivos
  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      if (response.schoolYears && response.schoolYears.length > 0) {
        // response.schoolYears.sort((a,b) =>{
        //   return Number.parseInt(b.value) - Number.parseInt(a.value)
        // });
        setSchoolYearFilter(response.schoolYears[0]._id);
        setListSchoolYearsActive(response.schoolYears);
      }
    });
  }, []);

  useEffect(() => {
    getStateCoursesActiveApi(accessToken, true).then((response) => {
      setListStateCoursesActive(response.stateCourses);
    });
  }, []);

  useEffect(() => {
    getTypeCoursesApi(accessToken, true).then((response) => {
      setListTypeCoursesActive(response.typeCourses);
    });
  }, []);

  // useEffect(() => {
  //     // let result= listSchoolYearsActive.filter(i=>i.stateSchoolYear &&
  //     //   i.stateSchoolYear.description.toUpperCase() !== "INACTIVO" &&  i.stateSchoolYear.description.toUpperCase() !== "FINALIZADO");
  //     // result.sort((a,b) =>{
  //     //    return Number.parseInt(b.value) - Number.parseInt(a.value)
  //     // });
  //     setSchoolYears(listSchoolYearsActive);
  // }, [listSchoolYearsActive]);

  // useEffect(() => {
  //   if(schoolYears.length > 0){
  //     setSchoolYearFilter(schoolYears[0]);
  //   }
  // }, [schoolYears]);

  useEffect(() => {
    if (schoolYearFilter !== null) {
      getInscriptionsFullBySchoolYearApi(accessToken, schoolYearFilter).then(
        (response) => {
          if (response.inscriptions) {
            setListInscriptionsActive(response.inscriptions);
          } else {
            setListInscriptionsActive([]);
            if (response.code === 404) {
              notification["error"]({
                message: response.message,
              });
            }
          }
        }
      );
    }
  }, [schoolYearFilter]);

  useEffect(() => {
    setCoursesInscriptions([...coursesStudentEnrolled]);
  }, [schoolYearFilter]);
  //console.log(listSchoolYearsActive);

  // const sortSchoolYears = listSchoolYearsActive.sort((a,b) =>{
  //   return Number.parseInt(b.value) - Number.parseInt(a.value)
  // });

  //console.log('Array Ordenado: ', sortSchoolYears);
  //console.log('Mayor: ', sortSchoolYears[0]); //muestro el mayor

  //trae los datos de la inscripciones junto a los cursos
  // useEffect(() => {
  //   getInscriptionsActiveApi(accessToken, true).then((response) => {
  //     setListInscriptionsActive(response.inscriptions);
  //   });
  // }, []);

  useEffect(() => {
    if (listInscriptionsActive.length >= 0) {
      //let courses = [];
      let lastyear_courses = [];
      //Verifico disponibilidad
      listInscriptionsActive.map((i) => {
        //FILTRO POR ULTIMO AÑO
        if (i.course.schoolYear) {
          //console.log(i.course.schoolYear);
          // let i_schoolyear = i.course.schoolYear;
          // if (i_schoolyear.year === sortSchoolYears[0].year){
          //   console.log(i_schoolyear.year);
          let available = i.course.quota
            ? i.course.quota - (i.students ? i.students.length : 0)
            : "sin datos";
          i.course.available = available;
          lastyear_courses.push(i.course);
          //}
          //console.log(sortSchoolYears[0].year);
        }
        /*
        let available = i.course.quota
          ? i.course.quota - (i.students ? i.students.length : 0)
          : "sin datos";
        i.course.available = available;
        courses.push(i.course);
        */
      });
      //setListCoursesActive(courses);
      setListCoursesActive(lastyear_courses);
      // console.log("lastyear_courses", lastyear_courses.length); //cursos ultimo año
    }
  }, [listInscriptionsActive]);

  // console.log("1", listCoursesActive.length); //cursos ultimo año
  //console.log("2", listInscriptionsActive.length); //todos los cursos

  /*
  useEffect(() => {
    // getCoursesActiveApi(accessToken, true).then((response) => {
    //   setListCoursesActive(response.courses);
    // });
    if (listInscriptionsActive.length > 0) {
      let lastcourses = [];
      let nuevo = listInscriptionsActive.filter((i) => i.course.schoolYear === sortSchoolYears[0]);
      console.log(nuevo);
      //Verifico disponibilidad
      listInscriptionsActive.map((i) => {
        //console.log(i.course.schoolYear);
        //console.log(sortSchoolYears[0]);
          if(i.course.schoolYear === sortSchoolYears[0]) {
            console.log("si");
            lastcourses.push(i.course);
          }
      });
      console.log(lastcourses);
      setListCoursesActive(lastcourses);
    }
  }, [listInscriptionsActive]);

  console.log("2", listCoursesActive);
  */

  //INSCRIPCIONES DEL ESTUDIANTE
  useEffect(() => {
    if (inscriptions) {
      let newList = [];
      inscriptions.map((i) => {
        newList.push(i.course._id);
        return true;
      });
      setCoursesStudentEnrolled(newList);
    }
  }, [inscriptions]);

  useEffect(() => {
    setCoursesInscriptions([...coursesStudentEnrolled]);
  }, [coursesStudentEnrolled]);

  //console.log(coursesStudentEnrolled);
  // console.log(coursesStudentEnrolled.length);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    return errorExists;
  };

  const setHasCoursesStudent = (student) => {
    //const accessToken = getAccessToken();

    hasCoursesStudentApi(accessToken, student._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        //setReloadStudents(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const insertCoursesStudentInscription = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      if (!disabledSubmit) {
        setDisabledSubmit(true); //Bloqueo el submit para evitar duplicado
        //Verifico los cursos nuevos a los que se inscribio
        coursesInscriptions.map(async (course) => {
          if (!coursesStudentEnrolled.includes(course)) {
            //TODO: DICE QUE LO AGREGA PERO NO
            let newCourse = listCoursesActive.filter((i) => i._id === course);
            // if (await verifyCicle(newCourse[0])) {
            if (newCourse && newCourse.length > 0) {
              if (newCourse[0].available <= 4 && newCourse[0].available > 1) {
                confirm({
                  title: "Agregando Curso",
                  // content: `Esta por asignar el último cupo del ${newCourse[0].name} ¿Deseas continuar?`,
                  content: `Quedan (${
                    newCourse[0].available ? newCourse[0].available : ""
                  }) vacantes de '${
                    newCourse[0].name
                  }' para completar el cupo. ¿Deseas continuar?`,
                  okText: "Agregar",
                  okType: "warning",
                  cancelText: "Cancelar",
                  onOk() {
                    _insert(course);
                  },
                });
              } else if (newCourse[0].available === 1) {
                confirm({
                  title: "Agregando Curso",
                  // content: `Esta por asignar el último cupo del ${newCourse[0].name} ¿Deseas continuar?`,
                  content: `Queda (${
                    newCourse[0].available ? newCourse[0].available : ""
                  }) vacante de '${
                    newCourse[0].name
                  }' para completar el cupo. ¿Deseas continuar?`,
                  okText: "Agregar",
                  okType: "warning",
                  cancelText: "Cancelar",
                  onOk() {
                    _insert(course);
                  },
                });
              } else if (newCourse[0].available <= 0) {
                confirm({
                  title: "Agregando Curso",
                  content: `'${newCourse[0].name}' no tiene cupo disponible ¿Deseas continuar?`,
                  okText: "Agregar",
                  okType: "danger",
                  cancelText: "Cancelar",
                  onOk() {
                    _insert(course);
                  },
                });
              } else {
                _insert(course);
              }
            }
            // }
          }
        });
        //Verifico los cursos a los que se desinscribio
        coursesStudentEnrolled.map(async (course) => {
          const token = getAccessToken();
          if (!coursesInscriptions.includes(course)) {
            return await deleteStudentInscriptionByCourseIdApi(
              token,
              studentData,
              course
            ).then((result) => {
              if (
                result.message === "ERR_CONNECTION_REFUSED" ||
                result.message === "Failed to fetch" ||
                result.message === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
                //setIsVisibleModal(true);
              } else if (result.code !== 200) {
                notification["error"]({
                  message: result.message,
                });
                //setIsVisibleModal(true);
              } else {
                notification["success"]({
                  message: result.message, //el mensaje que viene del server
                });
                //_updateStateAndHoursStudent(course)
                _updateHoursStudent(course);
                setReloadInscriptionCourses(true);
                // setIsVisibleModal(false);
              }
            });
          }
        });
        setIsVisibleModal(false);
      }
    }
  };

  /*Funcion para verificar si algun ciclo del estudiante corresponde con el del curso*/
  // const verifyCicle = async (course) => {
  //   let filter = studentData.cycles.filter(
  //     (i) => i.schoolYear._id === course.schoolYear._id
  //   );
  //   if (filter.length === 0) {
  //     notification.error({
  //       message: "No se puede inscribir al curso",
  //       description: `El estudiante no puede incribirse al curso ${
  //         course.name
  //       } ${course.schoolYear ? `(${course.schoolYear.year})` : ""},
  //          por no tener cargada una condición en dicho ciclo.`,
  //     });
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  const _insert = async (course) => {
    const token = getAccessToken();
    return await insertStudentInscriptionByCourseIdApi(
      token,
      studentData,
      course
    ).then((result) => {
      if (
        result.message === "ERR_CONNECTION_REFUSED" ||
        result.message === "Failed to fetch" ||
        result.message === undefined ||
        result.code === 500
      ) {
        notification["error"]({
          message: "Servidor caido",
        });
        //setIsVisibleModal(true);
      } else if (result.code === 404) {
        notification["warning"]({
          message: result.message,
        });
        //setIsVisibleModal(true);
      } else {
        notification["success"]({
          message: result.message, //el mensaje que viene del server
        });
        _updateStateAndHoursStudent(course);
        setReloadInscriptionCourses(true);
        //console.log(studentData);
        setHasCoursesStudent(studentData);
      }
    });
  };

  const _updateStateAndHoursStudent = async (courseId) => {
    //verifico si el curso esta finalizado para actualizar el estado del estudiante en el curso
    const token = getAccessToken();
    let responseCourse = await getCourseApi(token, courseId);
    let course = responseCourse.course ? responseCourse.course : null;
    let stateCourseFinish = listStateCoursesActive.filter(
      (i) => i.description.toLowerCase() === "finalizado"
    );
    if (
      stateCourseFinish !== undefined &&
      stateCourseFinish.length > 0 &&
      course !== null &&
      course.stateCourse &&
      course.stateCourse === stateCourseFinish[0]._id
    ) {
      notification["info"]({
        message:
          "El curso al que se quiere inscribir esta finalizado, se actualizará el estado del estudiante",
        duration: 10,
      });
      //actualizo el estado del estudiante en el curso
      let result = await updateStateStudentCourse(token, course, [
        { student: studentData._id },
      ]);
      if (result && result.state === true) {
        notification["info"]({
          message: "Se ha actualizado el estado del estudiante",
          duration: 5,
        });
        setIsVisibleModal(false);
      } else {
        notification["warning"]({
          message: result.message
            ? result.message
            : "Error al actualizar estado del estudiante",
          duration: 5,
        });
      }
    }
  };

  //Actualizo horas en el caso de que quiera eliminar curso y el mismo este en estado finalizado
  const _updateHoursStudent = async (courseId) => {
    //verifico si el curso esta finalizado para actualizar el estado del estudiante en el curso
    const token = getAccessToken();
    let responseCourse = await getCourseApi(token, courseId);
    let course = responseCourse.course ? responseCourse.course : null;
    let stateCourseFinish = listStateCoursesActive.filter(
      (i) => i.description.toLowerCase() === "finalizado"
    );
    if (
      stateCourseFinish !== undefined &&
      stateCourseFinish.length > 0 &&
      course !== null &&
      course.stateCourse &&
      course.stateCourse === stateCourseFinish[0]._id
    ) {
      notification["info"]({
        message:
          "El curso al que se quiere desinscribir esta finalizado, se actualizará las horas del estudiante",
        duration: 10,
      });
      //actualizo las horas del estudiante en el curso
      let result = await updateOwnHoursByStudentIdApi(token, studentData._id);
      if (result.code === 200) {
        notification["success"]({
          message: "Actualización de las horas del estudiante correcta",
          duration: 5,
        });
        setIsVisibleModal(false);
      } else {
        notification["warning"]({
          message: result.message
            ? result.message
            : "Error al actualizar las horas del estudiante",
          duration: 5,
        });
      }
    }
  };

  return (
    <div className="edit-inscription-form">
      <EditForm
        student={studentData}
        insertCoursesStudentInscription={insertCoursesStudentInscription}
        listCoursesActive={listCoursesActive}
        coursesInscriptions={coursesInscriptions}
        setCoursesInscriptions={setCoursesInscriptions}
        schoolYears={listSchoolYearsActive}
        setSchoolYearFilter={setSchoolYearFilter}
        schoolYearFilter={schoolYearFilter}
        listTypeCoursesActive={listTypeCoursesActive}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    student,
    insertCoursesStudentInscription,
    listCoursesActive,
    coursesInscriptions,
    setCoursesInscriptions,
    schoolYears,
    setSchoolYearFilter,
    schoolYearFilter,
    listTypeCoursesActive,
  } = props;

  const [listCourses, setListCourses] = useState([]);
  const [studentConditionByCycle, setStudentConditionByCycle] = useState(null);
  const [filterByCondition, setFilterByCondition] = useState(true);

  useEffect(() => {
    if (schoolYearFilter) {
      let filter = student.cycles.findIndex(
        (i) => i.schoolYear._id === schoolYearFilter
      );
      if (filter !== -1) {
        let dataStudentCondition = student.cycles[filter].studentCondition
          ? student.cycles[filter].studentCondition.description
            ? student.cycles[filter].studentCondition.description
            : null
          : null;
        setStudentConditionByCycle(dataStudentCondition);
      } else {
        setStudentConditionByCycle(null);
      }
    }
  }, [schoolYearFilter]);

  useEffect(() => {
    let list = listCoursesActive.map((course) => {
      if (course.schoolYear) {
        let filter = student.cycles.filter(
          (i) => i.schoolYear._id === course.schoolYear._id
        );
        if (filter.length === 0) {
          course.disabled = true;
        } else {
          if (course.typeCourse && filterByCondition) {
            //verifico que el tipo de curso concuerde con la condicion del estudiante en el ciclo
            let indexType = listTypeCoursesActive.findIndex(
              (i) => i._id === course.typeCourse
            );
            let dataStudentCondition = filter[0].studentCondition
              ? filter[0].studentCondition.description
              : "";
            if (
              indexType !== -1 &&
              dataStudentCondition.includes(
                listTypeCoursesActive[indexType].description
              )
            ) {
              course.disabled = false;
            } else {
              course.disabled = true;
            }
          } else {
            course.disabled = false; //ya que el curso no esta restringido en ningun tipo
          }
        }
      } else {
        course.disabled = true;
      }
      //if (course.schoolYear === sortSchoolYears[0]) {
      //  listb.push(course);;
      //}
      return course;
    });
    list.sort((a, b) => {
      return a.disabled - b.disabled;
    });
    setListCourses(list);
    //console.log(list);
    // console.log(list.length);
    // setListCourses(listCoursesActive);
  }, [listCoursesActive, filterByCondition]);

  // console.log(listCoursesActive.length); //cursos ultimo año

  const onChangeStudents = (nextTargetKeys) => {
    setCoursesInscriptions(nextTargetKeys);
  };

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === "left" ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            rowClassName={(record) => record.disabled && "disabled-row"}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? "none" : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
            pagination={{
              size: "small",
              showSizeChanger: false,
              pageSize: 8,
            }}
          />
        );
      }}
    </Transfer>
  );

  const leftTableColumns = [
    {
      dataIndex: "name",
      title: "Nombre",
      width: "40%",
      ellipsis: true
    },
    {
      dataIndex: "quota",
      title: "Cupo",
      width: "10%",
    },
    {
      dataIndex: "available",
      title: "Disponible",
      width: "10%",
      ellipsis: true,
      render: (disponible) =>
        disponible > 0 ? (
          <Tag color="green">{disponible}</Tag>
        ) : (
          <Tag color="red">{0}</Tag>
        ),
    },
    {
      dataIndex: "days",
      title: "Días cursada",
      render: (days) =>
        days
          ? days.map((day) =>
              day.description ? (
                <Tag key={day._id}>{day.description}</Tag>
              ) : null
            )
          : null,
      width: "25%",
    },
    {
      dataIndex: "hour_start",
      title: "Hora Inicio",
      render: (hour_start) =>
        hour_start ? moment(hour_start).format("HH:mm") : null,
      width: "10%",
    },
  ];
  const rightTableColumns = [
    {
      dataIndex: "name",
      title: "Nombre",
      width: "40%",
      ellipsis: true,
    },
    {
      dataIndex: "quota",
      title: "Cupo",
      width: "10%",
    },
    {
      dataIndex: "available",
      title: "Disponible",
      width: "10%",
      ellipsis: true,
      render: (disponible) =>
        disponible > 0 ? (
          <Tag color="green">{disponible}</Tag>
        ) : (
          <Tag color="red">{0}</Tag>
        ),
    },
    {
      dataIndex: "days",
      title: "Días cursada",
      render: (days) =>
        days
          ? days.map((day) => <Tag key={day._id}>{day.description}</Tag>)
          : null,
      width: "25%",
    },
    {
      dataIndex: "hour_start",
      title: "Hora Inicio",
      render: (hour_start) =>
        hour_start ? moment(hour_start).format("HH:mm") : null,
      width: "10%",
    },
  ];

  return (
    <Form className="form-edit" onSubmit={insertCoursesStudentInscription}>
      {/* //onFinish={updateUser} */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={10}>
          <label>
            <b style={{ fontSize: 14 }}>Estudiante: </b>
          </label>
          {student.name} {student.lastname}
          {/* <Descriptions
            title="Estudiante"
            size={"small"}
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Nombre">
              {student.name}{" "}
            </Descriptions.Item> */}
          {/* <Descriptions.Item label="Apellido">
              {student.lastname}{" "}
            </Descriptions.Item> 
          </Descriptions>*/}
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <Form.Item label="Ciclo">
            <Select
              showSearch
              value={schoolYearFilter}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "80%" }}
              onChange={(e) => setSchoolYearFilter(e)}
              loading={schoolYears.length === 0 ? true : false}
              // notFoundContent={schoolYears.length ===0? <Spin size="small"/> : null}
            >
              {schoolYears
                ? schoolYears.map((item) => {
                    return <Option key={item._id}>{item.year}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6} style={{ marginTop: 8 }}>
          <div>
            <Switch
              defaultChecked
              disabled={studentConditionByCycle !== null ? false : true}
              onChange={() => setFilterByCondition(!filterByCondition)}
            />
            <span>
              {studentConditionByCycle !== null
                ? ` Filtrar por "${studentConditionByCycle}"`
                : ""}
            </span>
          </div>
        </Col>
        <Col span={7} offset={5}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              // className="btn-submit"
              onClick={insertCoursesStudentInscription}
              style={{ width: "100%" }}
            >
              Actualizar
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <TableTransfer
            dataSource={listCourses}
            targetKeys={coursesInscriptions}
            disabled={false}
            showSearch={true}
            titles={["Cursos", "Cursos del Estudiante Inscripto"]}
            onChange={onChangeStudents}
            filterOption={(inputValue, item) =>
              item.name &&
              item.name.toUpperCase().includes(inputValue.toUpperCase())
            }
            leftColumns={leftTableColumns}
            rightColumns={rightTableColumns}
            rowKey={(item) => item._id}
            // oneWay
            size="small"
            listStyle={{ width: 100, minHeight: 400 }}
          />
        </Col>
      </Row>
      {/* <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={insertCoursesStudentInscription}
        >
          Actualizar
        </Button>
      </Form.Item> */}
    </Form>
  );
}
