import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop,
  Row,
  Col,
  Select
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import AddAbsenceForm from "../AddAbsenceForm";
import EditAbsenceForm from "../EditAbsenceForm";
import { activateAbsenceApi, deleteAbsenceApi } from "../../../../api/absence";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getCourseApi } from "../../../../api/course";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import jwtDecode from "jwt-decode";
// import useRouteProps from "../../../../hooks/UseRouteProps"
// import { PluralizeEsp } from "../../../../utils/convert";
import { formatDateView } from "../../../../utils/formatDate";

import "./ListAbsences.scss";

const { confirm } = ModalAntd;
const { Search } = Input;
const { Option } = Select;

export default function ListAbsences(props) {
  const { absencesActive, absencesInactive, setReloadAbsences, setPaginationActive, setPaginationInactive } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewAbsencesActives, setViewAbsencesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [absencesActiveFilter, setAbsencesActiveFilter] = useState([]);
  const [absencesInactiveFilter, setAbsencesInactiveFilter] = useState([]);
  const [propsPaginationActive, setPropsPaginationActive]=useState({});
  const [propsPaginationInactive, setPropsPaginationInactive]=useState({});
  // const [listStateCoursesActive, setListStateCoursesActive] = useState([]);
  const [schoolYear, setSchoolYear]= useState(null);
  const [listSchoolYears, setListSchoolYears] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  //console.log("userToken", userToken);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  // useEffect(() => {
  //   getStateCoursesActiveApi(accessToken, true).then((response) => {
  //     setListStateCoursesActive(response.stateCourses);
  //   });
  // }, []);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then(response => {
      setListSchoolYears(response.schoolYears);
      if(response.schoolYears && response.schoolYears.length > 0){
        // setSchoolYear(response.schoolYears[0].year);
        //seteo el año seleccionado con el año en curso/actual
        let year=new Date().getFullYear();
        setSchoolYear(year);
      }
    });
  }, []);

  // useEffect(() => {
  //   const listActive = [];
  //   if (absencesActive) {
  //     absencesActive.forEach((element) => {
  //         if (
  //           (element.course && element.course.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
  //             -1 ) ||
  //           filterText === ""
  //         ) {
  //           listActive.push(element);
  //         }
  //     });
  //   }
  //   setAbsencesActiveFilter(listActive);
  // }, [filterText, absencesActive]);

  // useEffect(() => {
  //   const listInactive = [];
  //   if (absencesInactive) {
  //     absencesInactive.forEach((element) => {
  //         if (
  //           (element.course && element.course.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
  //             -1 )||
  //           filterText === ""
  //         ) {
  //           listInactive.push(element);
  //         }
  //     });
  //   }
  //   setAbsencesInactiveFilter(listInactive);
  // }, [filterText, absencesInactive]);

  useEffect(() => {
    if (filterText) {
      setPaginationActive({page:1, state:1, filter:filterText, schoolYear:schoolYear});
      setPaginationInactive({page:1, state:2, filter:filterText, schoolYear:schoolYear});
    }
    else if (filterText==="") {
      setPaginationActive({page:1, state:1, schoolYear:schoolYear});
      setPaginationInactive({page:1, state:2, schoolYear:schoolYear});
    }
  }, [filterText, schoolYear]);

  useEffect(() => {
    if (absencesActive  && absencesActive.docs) {
      setAbsencesActiveFilter(absencesActive.docs);
    }
  }, [absencesActive]);

  useEffect(() => {
    if (absencesInactive && absencesInactive.docs) {
      setAbsencesInactiveFilter(absencesInactive.docs);
    }
  }, [absencesInactive]);
   
  useEffect(() => {
    if(absencesActive){
      setPropsPaginationActive({
        //total: absencesActive.totalDocs,
        total: absencesActive.totalDocs? absencesActive.totalDocs : null,
        pageSize: absencesActive.limit? absencesActive.limit : null,
        defaultCurrent: absencesActive.page? absencesActive.page : null,
        current: absencesActive.page? absencesActive.page : null,
      });
    }
  }, [absencesActive]);

  useEffect(() => {
    if(absencesInactive){
      setPropsPaginationInactive({
        //total: absencesInactive.totalDocs,
        total: absencesInactive.totalDocs,
        pageSize:absencesInactive.limit,
        defaultCurrent:absencesInactive.page,
        current:absencesInactive.page
      });
    }
  }, [absencesInactive]);

  const addAbsenceModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear -> Inasistencias");
    setModalWidth(1000);
    setModalContent(
      <AddAbsenceForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadAbsences={setReloadAbsences}
      />
    );
  };

  return (
    <div className="list-absences">
      <div className="list-absences__header">
        {checkAction(permissionsActive, userToken.role, "absences", "all") ||
        checkAction(permissionsActive, userToken.role, "absences", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-absences__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewAbsencesActives(!viewAbsencesActives)}
              />
              <span>
                {viewAbsencesActives ? "Activos" : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "absences", "all") ||
        checkAction(permissionsActive, userToken.role, "absences", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addAbsenceModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "absences", "all") ||
      checkAction(permissionsActive, userToken.role, "absences", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-absences__search">
            <Row gutter={24}>
            <Col span={4}>
          <Select 
            showSearch
            value={schoolYear}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }} 
            onChange={e=>setSchoolYear(e)}>
            {listSchoolYears? listSchoolYears.map(item=>{
                    return (
                  <Option key={item.year}>{item.year}</Option>)
                }) : null}
                <Option key={"all"}>{"Todos"}</Option>)
          </Select>
            </Col>
      <Col span={20}>
            <Search
              className="Buscar"
              allowClear
              placeholder="Buscar"
              onSearch={(e) => setFilterText(e)}
              enterButton
            />
            </Col>
            </Row>
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong>
            </h3>
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewAbsencesActives ? (
        <>

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "view") || isAdmin(roleById) ? (
            <>
              <AbsencesActive
                absencesActive={absencesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadAbsences={setReloadAbsences}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                filterText={filterText}
                setPagination={setPaginationActive}
                propsPagination={propsPaginationActive}
                // listStateCoursesActive={listStateCoursesActive}
                schoolYear={schoolYear}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "view") || isAdmin(roleById) ? (
            <>
              <AbsencesInactive
                absencesInactive={absencesInactiveFilter}
                setReloadAbsences={setReloadAbsences}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                filterText={filterText}
                setPagination={setPaginationInactive}
                propsPagination={propsPaginationInactive}
                schoolYear={schoolYear}
                // listStateCoursesActive={listStateCoursesActive}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function AbsencesActive(props) {
  const { absencesActive, setReloadAbsences, setIsVisibleModal, setModalTitle, setModalWidth, 
    setModalContent,accessToken, userToken, permissionsActive, roleById,
    setPagination, propsPagination, filterText, schoolYear} = props; //showDeleteConfirm

  const editAbsence= (absence) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar Inasistencia: ${absence.course ? absence.course.name : "curso"}`);
    setModalWidth(1000);
    setModalContent(
      <EditAbsenceForm
        absence={absence}
        setIsVisibleModal={setIsVisibleModal}
        setReloadAbsences={setReloadAbsences}
      />
    );
  };

  const desactivateAbsence = (absence) => {
    //const accessToken = getAccessToken();

    activateAbsenceApi(accessToken, absence._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadAbsences(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = (absence) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Inasistencia",
      content: `¿Estás seguro que quieres eliminar a ${absence.absence_code ? absence.absence_code : "inasistencia"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteAbsenceApi(accessToken, absence._id)
          .then((response) => {
            notification["success"]({
              message: response.message,
            });
            if(response.code === 200){
            }
            setReloadAbsences(true);
            setIsVisibleModal(false);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const infoResult=(err, message)=>{
    ModalAntd.info({
      title:'Curso Finalizado',
      content:(
        <div>
          <p>{message}</p>
          {/* <p> Cantidad de errores: {err} </p> */}
        </div>
      )
    })
  }

  const edit_absence = (
    <div>
      {" "}
      <p>Editar inasistencia</p>{" "}
    </div>
  );

  const desactivate_absence = (
    <div>
      {" "}
      <p>Pasar a inasistencia inactivas</p>{" "}
    </div>
  );

  const delete_absence = (
    <div>
      {" "}
      <p>Eliminar inasistencia</p>{" "}
    </div>
  );
  // const download_absence = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle inasistencia</p>{" "}
  //   </div>
  // );
  const columns = [
    {
      title: "Código",
      dataIndex: "absence_code",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      render: date =>formatDateView(date)
    },
    {
      title: "Curso",
      dataIndex: "course",
      render: course =>course.name,
    },
    {
      title: "Acción",
      key: "action",
      render: (text, absence) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_absence}>
                <Button
                  className="button-edit"
                  onClick={() => editAbsence(absence)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_absence}>
                <Button
                  className="button-absence"
                  type="primary"
                  onClick={() => desactivateAbsence(absence)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_absence}>
                <Button
                  className="button-absence"
                  type="danger"
                  onClick={() => showDeleteConfirm(absence)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      pagination={{
        ...propsPagination,
        showSizeChanger:false,
        onChange:(newPage) => {
          setPagination({page:newPage,
            state:1,
            filter:filterText,
            schoolYear: schoolYear
          })
          }
        }}
      dataSource={absencesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function AbsencesInactive(props) {
  const { absencesInactive,
          setReloadAbsences,
          accessToken,
          userToken,
          permissionsActive,
          roleById,
          propsPagination,
          setPagination,
          filterText,
          // listStateCoursesActive,
          schoolYear
        } = props; //showDeleteConfirm

  const activateAbsence= (absence) => {
    activateAbsenceApi(accessToken, absence._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadAbsences(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = (absence) => {
    confirm({
      title: "Eliminando -> Inasistencia",
      content: `¿Estás seguro que quieres eliminar a ${absence.course ? absence.course.name : "inasistencia"}?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteAbsenceApi(accessToken, absence._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            // Verifico el estado del curso, si el mismo es finalizado 
            // actualizo el estado de los estudiantes inscriptos 
            if(response.code === 200){
            }
            
            setReloadAbsences(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_absence= (
    <div>
      {" "}
      <p>Pasar a inasistencia activas</p>{" "}
    </div>
  );

  const delete_absence = (
    <div>
      {" "}
      <p>Eliminar inasistencia</p>{" "}
    </div>
  );
  // const download_absence = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle inasistencia</p>{" "}
  //   </div>
  // );

  
  const infoResult=(err, message)=>{
    ModalAntd.info({
      title:'Curso Finalizado',
      content:(
        <div>
          <p>{message}</p>
          {/* <p> Cantidad de errores: {err} </p> */}
        </div>
      )
    })
  }

  const columns = [
    {
      title: "Código",
      dataIndex: "absence_code",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      render: date =>formatDateView(date)
    },
    {
      title: "Curso",
      dataIndex: "course",
      render: course =>course.name,
    },
    {
      title: "Acción",
      key: "action",
      render: (text, absence) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_absence}>
                <Button
                  className="button-activate"
                  onClick={() => activateAbsence(absence)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_absence}>
                <Button
                  className="button-absence"
                  type="danger"
                  onClick={() => showDeleteConfirm(absence)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      pagination={{
      ...propsPagination,
      showSizeChanger:false,
      onChange:(newPage) => {
        setPagination({page:newPage,
          state:2,
          filter:filterText,
          schoolYear: schoolYear
        })
        }
      }}
      dataSource={absencesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
