import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  Space,
  BackTop,
  Popover,
  Divider,
  Row,
  Col,
  Progress
} from "antd";

import { Link } from "react-router-dom";

import {
  EditOutlined,
  StopOutlined,
  UpOutlined,
  DownOutlined,
  DeleteOutlined,
  CheckOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  //FileExcelOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined,
  ProfileOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

import Modal from "../../../Modal";
import EditStudentForm from "../EditStudentForm";
import AddStudentForm from "../AddStudentForm";
import AdvancedSearchCourseForm from "./AdvancedSearchForm";
import AddEditStudentCyclesForm from "../../StudentCycles/AddEditStudentCyclesForm";
import {
  activateStudentApi,
  deleteStudentApi,
  getExportStudentsApi,
  //getStudentsHasCoursesApi,
  //hasCoursesStudentApi,
} from "../../../../api/student";
import { getInscriptionsByStudentReportApi } from "../../../../api/inscription";
import {
  listStudentsPdf,
  //studentDetailPdf
} from "./pdfStudents";
import { reportAcademicRecordStudentExcel } from "../ReportsStudents/pdfStudentsReports";
import { getAccessToken } from "../../../../api/auth";
import {
  checkAction,
  isAdmin,
  checkStudentHasCourses,
} from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
//import useRouteProps from "../../../../hooks/UseRouteProps"
//import { PluralizeEsp } from "../../../../utils/convert";
import ReactExport from "@ibrahimrahmani/react-export-excel";

import "./ListStudents.scss";
import "./ProgressBar.scss";
import ProgressBar from "../../../../utils/progressBar";

const { confirm } = ModalAntd;
const { Search } = Input;
//const {  Progress  } = antd;
//var inicio = Date.now();
//var fin = "";

export default function ListStudents(props) {
  const {
    setPaginationActive,
    setPaginationInactive,
    studentsActive,
    studentsInactive,
    setReloadStudents,
    //inscriptionActiveCourses,
    //inscriptionInactiveCourses,
    studentsHasCourses,
    studentsHasNoCourses,
    studentsActiveExport,
    studentsInactiveExport,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewStudentsActives, setViewStudentsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  //filtros para busqueda avanzada
  const [filters, setFilters] = useState([]);
  const [studentsActiveFilter, setStudentsActiveFilter] = useState([]);
  const [studentsInactiveFilter, setStudentsInactiveFilter] = useState([]);
  const [exportActivePdf, setExportActivePdf] = useState([]);
  const [exportActiveExcel, setExportActiveExcel] = useState([]);
  const [exportInactivePdf, setExportInactivePdf] = useState([]);
  const [exportInactiveExcel, setExportInactiveExcel] = useState([]);

  const [propsPaginationActive, setPropsPaginationActive] = useState({});
  const [propsPaginationInactive, setPropsPaginationInactive] = useState({});

  const [expand, setExpand] = useState(false);
  const [expandDownload, setExpandDownload] = useState(false);
  const [nuevaLista, setNuevaLista] = useState([]);

  //const [state, setState] = useState({});

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //const { id } = props.location.state ? props.location.state : false;
  //console.log("userToken", userToken);
  // console.log(useRouteProps());

  //EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  //const ButtonGroup = Button.Group;

  /*
  let estado = {
    percent: 0,
  }

  var increase = () => {
    let percent = estado.percent + 1;
    //console.log("percent1", percent);
    if (percent > 0 && !isNaN(state.percent)) {
      percent = state.percent + 10;
    }
    if (percent > 100) {
      percent = 100;
    }
    //console.log("percent2", percent);
    setState({ percent });
  }

  var decline = () => {
    let percent = estado.percent - 1;
    if (percent < 0) {
      percent = 0;
    }

    setState({ percent });
  }
  */

  //console.log("state", state.percent);
  //console.log("estado", estado.percent);

  const pdf_download = <div>Descargar PDF</div>;

  const xls_download = <div>Descargar Excel</div>;

  useEffect(() => {
    if (studentsActive) {
      setPropsPaginationActive({
        total: studentsActive.totalDocs,
        //sorter:(a,b) => a.lastname.localeCompare(b.lastname),
        pageSize: studentsActive.limit,
        defaultCurrent: studentsActive.page,
        current: studentsActive.page,
      });
    }
  }, [studentsActive]);

  useEffect(() => {
    if (studentsInactive) {
      setPropsPaginationInactive({
        total: studentsInactive.totalDocs,
        pageSize: studentsInactive.limit,
        defaultCurrent: studentsInactive.page,
        current: studentsInactive.page,
      });
    }
  }, [studentsInactive]);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    if (studentsActive && studentsActive.docs) {
      setStudentsActiveFilter(studentsActive.docs);
    }
  }, [studentsActive]);

  useEffect(() => {
    if (studentsInactive && studentsInactive.docs) {
      setStudentsInactiveFilter(studentsInactive.docs);
    }
  }, [studentsInactive]);

  useEffect(() => {
    let isMounted = true;
    if (studentsActiveExport) {
      if (isMounted) {
        setExportActivePdf(studentsActiveExport);
        setExportActiveExcel(formatDataExportExcel(studentsActiveExport));
      }
    }
    return () => {
      isMounted = false;
    };
  }, [studentsActiveExport]);

  useEffect(() => {
    let isMounted = true;
    if (studentsInactiveExport) {
      if (isMounted) {
        setExportInactivePdf(studentsInactiveExport);
        setExportInactiveExcel(formatDataExportExcel(studentsInactiveExport));
      }
    }
    return () => {
      isMounted = false;
    };
  }, [studentsInactiveExport]);

  useEffect(() => {
    if (filterText) {
      setPaginationActive({
        page: 1,
        state: 1,
        filter: filterText,
        filters: filters,
      });
      setPaginationInactive({
        page: 1,
        state: 2,
        filter: filterText,
        filters: filters,
      });
    } else if (filterText === "") {
      setPaginationActive({ page: 1, state: 1, filters: filters });
      setPaginationInactive({ page: 1, state: 2, filters: filters });
    }
    setExportActiveExcel([]);
    setExportInactiveExcel([]);
    setExportActivePdf([]);
    setExportInactivePdf([]);
  }, [filterText, filters]);

  //This mimics a delay from the api
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let isMounted = true;
    setTimeout(() => {
      if (isMounted) {
        setIsLoading(false);
        setIsVisibleModal(false);
      }
    }, 10000);
    return () => {
      isMounted = false;
    };
  }, []);

  const formatDataExportExcel = (students) => {
    let exportData = [];
    students.map((student) => {
      let studentData = {
        name: student.name,
        lastname: student.lastname ? student.lastname : "",
        docType: student.docType ? student.docType.description : "",
        documentNumber: student.documentNumber ? student.documentNumber : "",
        phone: student.phone ? student.phone : "",
        email: student.email ? student.email : "",
        specialty: student.specialty ? student.specialty.description : "",
        address: student.address ? student.address : "",
        city: student.city ? student.city : "",
        country: student.country ? student.country : "",
        ownHours: student.ownHours ? student.ownHours : "",
        finalCondition: student.finalCondition
          ? student.finalCondition.description
          : "",
        adviser: student.adviser
          ? student.adviser.lastname + " " + student.adviser.name
          : "",
        yearAdmission: student.yearAdmission ? student.yearAdmission : "",
        yearGraduation: student.yearGraduation ? student.yearGraduation : "",
        entryCondition: student.entryCondition
          ? student.entryCondition.description
          : "",
        observation: student.observation ? student.observation : "",
      };
      exportData.push(studentData);
      return null; //retorno algo por warnings
    });
    return exportData;
  };

  const addStudentModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear -> Estudiante");
    setModalWidth(1000);
    setModalContent(
      <AddStudentForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadStudents={setReloadStudents}
      />
    );
  };

  const selectedAcademicRecord = (students) => {
    setIsVisibleModal(true);
    setModalTitle(`Reporte Historial Académico`);
    setModalContent(
      <StudentAcademicRecord
        students={students}
        setIsVisibleModal={setIsVisibleModal}
      />
    );
  };

  //const [open, setOpen] = useState(false);

  /*
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  */

  const progressBarModal = (e) => {
    e.preventDefault();
    setIsVisibleModal(true);
    setModalTitle("Cargando...");
    setModalWidth(500);
    setModalContent(
      <div>
        <h2>Cargando</h2>
        <ProgressBar
          //isLoading={true}
          isLoading={isLoading}
          //component={StudentsActive}
          percent={100}
          type="line"
          strokeColor="green"
          status="normal"
          size={"medium"}
          showInfo={false}
          setIsVisibleModal={setIsVisibleModal}
          //shouldCloseOnOverlayClick={false}
          //cancelButtonProps={{ style: { display: 'none' } }}
          //onClick={false}
          //onRequestClose={false}
          //shouldCloseOnEsc={false}
          //footer={null}
        />
      </div>
    );
  };

  const addEditCycleStudent = (student) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Ciclo del Estudiante ${
        student.name ? student.name + " " + student.lastname : "sin nombre"
      }`
    );
    setModalWidth(1000);
    setModalContent(
      <AddEditStudentCyclesForm
        student={student}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStudents={setReloadStudents}
      />
    );
  };

  // const exportStudentsPdf = (active) => {
  //   const token = accessToken;
  //   getExportStudentsApi(token, active, filterText, filters).then(
  //     (response) => {
  //       if (response.students) {
  //         if (response.students.length > 0) {
  //           listStudentsPdf(response.students, false);
  //         }
  //       }
  //     }
  //   );
  // };

  //let studentsSearch = [];

  const exportStudentsExcel = (active) => {
    const token = accessToken;
    //let studentsSearch = [];
    /*
    let boton =
      <>
        <ExcelFile filename={'ListadoEstudiantes'} element={
          <Popover content={xls_download}>
            <Button
              type="default"
              shape="round"
              >
              <FileExcelOutlined />
            </Button>
          </Popover>
          }>
          <ExcelSheet data={studentsSearch ? studentsSearch: null} name="Estudiantes">
            <ExcelColumn label="Apellido" value="lastname" />
            <ExcelColumn label="Nombre" value="name" />
            <ExcelColumn label="Teléfono" value="phone" />
            <ExcelColumn label="Correo" value="email" />
            <ExcelColumn label="Especialidad" value="specialty" />
          </ExcelSheet>
        </ExcelFile>
      </>
    */

    getExportStudentsApi(token, active, filterText, filters).then(
      (response) => {
        let studentsSearch = [];
        //let nuevaLista = response.students;
        if (response.students) {
          //let data = response.students;
          if (response.students.length > 0) {
            response.students.map((item) => {
              studentsSearch.push(item);
            });
            setNuevaLista(studentsSearch);
            //console.log(nuevaLista);
            //iteroLista(studentsSearch, Popover, xls_download, Button, FileExcelOutlined, ExcelFile, ExcelSheet, ExcelColumn);

            //return [studentsSearch];
            /*
          const _format = (data) => {
            return data.map((item) => {
              studentsSearch.push(item);
            });
          };

          const formattedData = _format(data);
          console.log(formattedData);

          */
            //console.log(studentsSearch);
            //return studentsSearch;
            /*
          studentsSearch.sort((a,b)=>{
            //console.log(a.lastname.localeCompare(b.lastname) ||
            //a.name.localeCompare(b.name));
            return a.lastname.localeCompare(b.lastname) ||
            a.name.localeCompare(b.name);
          });
          */
          } else {
            return null;
          }

          //console.log(nuevaLista);
        }

        //console.log(studentsSearch);
        //iteroLista(studentsSearch, Popover, xls_download, Button, FileExcelOutlined, ExcelFile, ExcelSheet, ExcelColumn);
      }
    );

    //console.log(formattedData);

    /*
    return(
      <>
        <ExcelFile filename={'ListadoEstudiantes'} element={
          <Popover content={xls_download}>
            <Button
              type="default"
              shape="round"
              >
              <FileExcelOutlined />
            </Button>
          </Popover>
          }>
          <ExcelSheet data={lpm ? lpm: null} name="Estudiantes">
            <ExcelColumn label="Apellido" value="lastname" />
            <ExcelColumn label="Nombre" value="name" />
            <ExcelColumn label="Teléfono" value="phone" />
            <ExcelColumn label="Correo" value="email" />
            <ExcelColumn label="Especialidad" value="specialty" />
          </ExcelSheet>
        </ExcelFile>
      </>
    );
    */
  };

  //EXCEL
  //let inscripStudents = [];
  //let courseName = "";

  /*
    //if (nuevaLista != null){
      //nuevaLista.map((item) => {
        //console.log(item.student);
        //inscripStudents.push(item);
      //});

      //console.log(nuevaLista);

      //inscripStudents.sort((a,b)=>{
        //return a.lastname.localeCompare(b.lastname) ||
        //a.name.localeCompare(b.name);
      //});

      return(
        <>
          <ExcelFile filename={'ListadoEstudiantes'} element={
            <Popover content={xls_download}>
              <Button
                type="default"
                shape="round"
                >
                <FileExcelOutlined />
              </Button>
            </Popover>
            }>
            <ExcelSheet data={nuevaLista ? nuevaLista: null} name="Estudiantes">
              <ExcelColumn label="Apellido" value="lastname" />
              <ExcelColumn label="Nombre" value="name" />
              <ExcelColumn label="Teléfono" value="phone" />
              <ExcelColumn label="Correo" value="email" />
              <ExcelColumn label="Especialidad" value="specialty" />
            </ExcelSheet>
          </ExcelFile>
        </>
      );
    }
    */

  const getLastCondition = (cycles) => {
    //Obtener la ultima condicion de acuerdo al ultimo año u año mas proximo
    if (cycles.length > 0) {
      let result = cycles.sort((a, b) => b.schoolYear.year - a.schoolYear.year);
      if (result && result.length > 0) {
        return result[0].studentCondition.description;
      } else {
        return null;
      }
    }
    return null;
  };

  //EXCEL
  //let studentsSearch = [];

  //exportStudentsExcel(true);
  //console.log(studentsSearch);

  return (
    <div>
      {/*
      <Button type="primary" onClick={progressBarModal}>
        cargar
      </Button>
      */}

      <div className="list-students">
        <div className="list-students__header">
          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "students",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <div className="list-students__header-switch">
                <Switch
                  defaultChecked
                  onChange={() => setViewStudentsActives(!viewStudentsActives)}
                />
                <span>{viewStudentsActives ? "Activos" : "Inactivos"}</span>
              </div>
            </>
          ) : (
            <></>
          )}
          {/*
          <div>
            <Progress type="circle" percent={state.percent} />
            <ButtonGroup>
              <Button onClick={() => decline()} icon="minus" />
              <Button onClick={() => increase()} icon="plus" />
            </ButtonGroup>
          </div>

          <div>
            <Button type="primary" icon={<PlusOutlined/>} onClick={progressBarModal}>
              Progreso
            </Button>
          </div>
          */}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "add") ||
          isAdmin(roleById) ? (
            <>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={addStudentModal}
              >
                Nuevo
              </Button>
            </>
          ) : (
            <></>
          )}
        </div>
        {checkAction(permissionsActive, userToken.role, "students", "all") ||
        checkAction(permissionsActive, userToken.role, "students", "view") ||
        isAdmin(roleById) ? (
          <>
            <div className="list-students__search">
              <Search
                className="Buscar"
                allowClear
                placeholder="Buscar"
                onSearch={(e) => setFilterText(e)}
                enterButton
              />
              <h1>
                <Button
                  type="link"
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    setExpand(!expand);
                  }}
                >
                  {expand ? <UpOutlined /> : <DownOutlined />} Búsqueda Avanzada
                  (filtros)
                </Button>
                {expand ? (
                  <div>
                    {/* busqueda avanzada */}
                    <AdvancedSearchCourseForm
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </div>
                ) : null}
              </h1>
            </div>
          </>
        ) : (
          <></>
        )}

        {viewStudentsActives ? (
          <>
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(permissionsActive, userToken.role, "students", "pdf") ||
            isAdmin(roleById) ? (
              <>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginRight: 10, marginBottom: 10 }}
                  shape="round"
                  size={"small"}
                  onClick={() => {
                    setExpandDownload(!expandDownload);
                  }}
                >
                  {<DownloadOutlined />} Descargas{" "}
                  {!expandDownload ? <RightOutlined /> : <LeftOutlined />}
                </Button>
                {expandDownload ? (
                  <>
                    <Space
                      split={<Divider type="vertical" />}
                      style={{ marginBottom: 10 }}
                    >
                      <Popover content={pdf_download}>
                        <Button
                          type="default"
                          shape="round"
                          size={"small"}
                          disabled={
                            exportActivePdf
                              ? exportActivePdf.length > 0
                                ? false
                                : true
                              : true
                          }
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            listStudentsPdf(exportActivePdf, false);
                          }}
                        >
                          <FilePdfOutlined /> Listado Pdf
                        </Button>
                      </Popover>

                      <>
                        <ExcelFile
                          filename={"ListadoEstudiantes"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                size={"small"}
                                shape="round"
                                disabled={
                                  exportActiveExcel
                                    ? exportActiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Listado xls
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportActiveExcel}
                            name="Estudiantes"
                          >
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn label="Teléfono" value="phone" />
                            <ExcelColumn label="Correo" value="email" />
                            <ExcelColumn
                              label="Especialidad"
                              value="specialty"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </>
                      {/* Datos de estudiantes xls*/}
                      <>
                        <ExcelFile
                          filename={"ListadoFichaEstudiantes"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                size={"small"}
                                shape="round"
                                disabled={
                                  exportActiveExcel
                                    ? exportActiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Ficha Estudiantes
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportActiveExcel}
                            name="Estudiantes"
                          >
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn
                              label="Tipo Documento"
                              value="docType"
                            />
                            <ExcelColumn
                              label="Documento"
                              value="documentNumber"
                            />
                            <ExcelColumn label="Teléfono" value="phone" />
                            <ExcelColumn label="Correo" value="email" />
                            {/* <ExcelColumn label="Direccion" value="address" /> */}
                            <ExcelColumn label="Ciudad" value="city" />
                            <ExcelColumn label="Pais" value="country" />
                            <ExcelColumn
                              label="Especialidad"
                              value="specialty"
                            />
                            <ExcelColumn label="Consejero" value="adviser" />
                            <ExcelColumn
                              label="Año ingreso"
                              value="yearAdmission"
                            />
                            <ExcelColumn
                              label="Año egreso"
                              value="yearGraduation"
                            />
                            <ExcelColumn
                              label="Condicion ingreso"
                              value="entryCondition"
                            />
                            <ExcelColumn
                              label="Condicion final"
                              value="finalCondition"
                            />
                            <ExcelColumn
                              label="Observaciones"
                              value="observation"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </>
                      {/* Historial estudiantes xls */}
                      <Popover content={xls_download}>
                        <Button type="default"
                        size={"small"} 
                        shape="round"
                        disabled={exportActivePdf? exportActivePdf.length > 0? false : true : true}
                        onClick={() => selectedAcademicRecord(exportActivePdf)}
                        >
                          <FileExcelOutlined /> Historial Académico
                        </Button>
                      </Popover>
                      {/* <>
                  <ExcelFile
                    filename={"ListadoHistorialEstudiantes"}
                    element={
                      <Popover content={xls_download}>
                        <Button type="default"
                        size={"small"} 
                        shape="round"
                        disabled={exportActiveExcel? exportActiveExcel.length > 0? false : true : true}
                        >
                          <FileExcelOutlined /> Historial Academico
                        </Button>
                      </Popover>
                    }
                  >
                    <ExcelSheet data={exportActiveExcel} name="Estudiantes">
                      <ExcelColumn label="Apellido" value="lastname" />
                      <ExcelColumn label="Nombre" value="name" />
                      <ExcelColumn label="Condicion" value="finalCondition" />
                      <ExcelColumn label="Horas Cursadas" value="ownHours" />
                    </ExcelSheet>
                  </ExcelFile>
                </> */}
                    </Space>
                  </>
                ) : null}
              </>
            ) : (
              <></>
            )}

            <>&nbsp;</>

            {/*
          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
            checkAction(permissionsActive, userToken.role, "courses", "pdf") ||
            isAdmin(roleById) ? (
              exportStudentsExcel(true)
            ) : (
              <>
              </>
          )}
          */}

            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "view"
            ) ||
            isAdmin(roleById) ? (
              <>
                <StudentsActive
                  studentsActive={studentsActiveFilter}
                  propsPagination={propsPaginationActive}
                  setIsVisibleModal={setIsVisibleModal}
                  setModalTitle={setModalTitle}
                  setModalContent={setModalContent}
                  setReloadStudents={setReloadStudents}
                  setModalWidth={setModalWidth}
                  accessToken={accessToken}
                  userToken={userToken}
                  permissionsActive={permissionsActive}
                  roleById={roleById}
                  setPagination={setPaginationActive}
                  filterText={filterText}
                  filters={filters}
                  addEditCycleStudent={addEditCycleStudent}
                  getLastCondition={getLastCondition}
                  //inscriptionActiveCourses={inscriptionActiveCourses}
                  studentsHasCourses={studentsHasCourses}
                  studentsHasNoCourses={studentsHasNoCourses}
                  //state={state}
                  //decline={decline}
                  //increase={increase}
                  progressBarModal={progressBarModal}
                  //showDeleteConfirm={showDeleteConfirm}
                />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(permissionsActive, userToken.role, "students", "pdf") ||
            isAdmin(roleById) ? (
              <>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginRight: 10, marginBottom: 10 }}
                  shape="round"
                  size={"small"}
                  onClick={() => {
                    setExpandDownload(!expandDownload);
                  }}
                >
                  {<DownloadOutlined />} Descargas{" "}
                  {!expandDownload ? <RightOutlined /> : <LeftOutlined />}
                </Button>
                {expandDownload ? (
                  <>
                    <Space
                      split={<Divider type="vertical" />}
                      style={{ marginBottom: 10 }}
                    >
                      <Popover content={pdf_download}>
                        <Button
                          type="default"
                          shape="round"
                          size="small"
                          style={{ marginRight: 10 }}
                          disabled={
                            exportInactivePdf
                              ? exportInactivePdf.length > 0
                                ? false
                                : true
                              : true
                          }
                          onClick={() => {
                            listStudentsPdf(exportInactivePdf, false);
                          }}
                        >
                          <FilePdfOutlined /> Listado Pdf
                        </Button>
                      </Popover>

                      <>
                        <ExcelFile
                          filename={"ListadoEstudiantesInactivos"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                size="small"
                                shape="round"
                                disabled={
                                  exportInactiveExcel
                                    ? exportInactiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Listado xls
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportInactiveExcel}
                            name="Estudiantes"
                          >
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn label="Teléfono" value="phone" />
                            <ExcelColumn label="Correo" value="email" />
                            <ExcelColumn
                              label="Especialidad"
                              value="specialty"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </>

                      {/* Datos de estudiantes xls*/}
                      <>
                        <ExcelFile
                          filename={"ListadoFichaEstudiantes"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                size={"small"}
                                shape="round"
                                disabled={
                                  exportInactiveExcel
                                    ? exportInactiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Ficha Estudiantes
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportInactiveExcel}
                            name="Estudiantes"
                          >
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn
                              label="Tipo Documento"
                              value="docType"
                            />
                            <ExcelColumn
                              label="Documento"
                              value="documentNumber"
                            />
                            <ExcelColumn label="Teléfono" value="phone" />
                            <ExcelColumn label="Correo" value="email" />
                            {/* <ExcelColumn label="Direccion" value="address" /> */}
                            <ExcelColumn label="Ciudad" value="city" />
                            <ExcelColumn label="Pais" value="country" />
                            <ExcelColumn
                              label="Especialidad"
                              value="specialty"
                            />
                            <ExcelColumn label="Consejero" value="adviser" />
                            <ExcelColumn
                              label="Año ingreso"
                              value="yearAdmission"
                            />
                            <ExcelColumn
                              label="Año egreso"
                              value="yearGraduation"
                            />
                            <ExcelColumn
                              label="Condicion ingreso"
                              value="entryCondition"
                            />
                            <ExcelColumn
                              label="Condicion final"
                              value="finalCondition"
                            />
                            <ExcelColumn
                              label="Observaciones"
                              value="observation"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </>
                       {/* Historial estudiantes xls */}
                       <Popover content={xls_download}>
                        <Button type="default"
                        size={"small"} 
                        shape="round"
                        disabled={exportInactivePdf? exportInactivePdf.length > 0? false : true : true}
                        onClick={() => selectedAcademicRecord(exportInactivePdf)}
                        >
                          <FileExcelOutlined /> Historial Académico
                        </Button>
                      </Popover>
                    </Space>
                  </>
                ) : null}
              </>
            ) : (
              <></>
            )}
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "view"
            ) ||
            isAdmin(roleById) ? (
              <>
                <StudentsInactive
                  studentsInactive={studentsInactiveFilter}
                  propsPagination={propsPaginationInactive}
                  setReloadStudents={setReloadStudents}
                  accessToken={accessToken}
                  userToken={userToken}
                  permissionsActive={permissionsActive}
                  roleById={roleById}
                  setPagination={setPaginationInactive}
                  filterText={filterText}
                  filters={filters}
                  addEditCycleStudent={addEditCycleStudent}
                  getLastCondition={getLastCondition}
                  //inscriptionInactiveCourses={inscriptionInactiveCourses}
                  //progressBarModal={progressBarModal}
                  //inscriptionCourses={inscriptionCourses}
                  //showDeleteConfirm={showDeleteConfirm}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )}

        <Modal
          title={modalTitle}
          isVisible={isVisibleModal}
          setIsVisible={setIsVisibleModal}
          modalWidth={modalWidth}
        >
          {modalContent}
        </Modal>

        <BackTop />
      </div>
    </div>
  );
}

function StudentsActive(props) {
  const {
    studentsActive,
    setReloadStudents,
    setIsVisibleModal,
    setModalTitle,
    propsPagination,
    setPagination,
    addEditCycleStudent,
    getLastCondition,
    setModalWidth,
    setModalContent,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    filterText,
    filters,
    //inscriptionActiveCourses,
    studentsHasCourses,
    //studentsHasNoCourses,
  } = props; //showDeleteConfirm

  //var estudiantes = [];
  //const ButtonGroup = Button.Group;

  const editStudent = (student) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar Estudiante ${
        student.name ? student.name + " " + student.lastname : "sin nombre"
      }`
    );
    setModalWidth(1000);
    setModalContent(
      <EditStudentForm
        student={student}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStudents={setReloadStudents}
      />
    );
  };

  const desactivateStudent = (student) => {
    //const accessToken = getAccessToken();

    activateStudentApi(accessToken, student._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadStudents(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = (student) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Estudiante",
      content: `¿Estás seguro que quieres eliminar a ${
        student.name ? student.name + " " + student.lastname : "estudiante"
      }?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteStudentApi(accessToken, student._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadStudents(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const cycle_student = (
    <div>
      {" "}
      <p>Ciclo del estudiante</p>{" "}
    </div>
  );

  const edit_student = (
    <div>
      {" "}
      <p>Editar estudiante</p>{" "}
    </div>
  );

  const desactivate_student = (
    <div>
      {" "}
      <p>Pasar a estudiantes inactivos</p>{" "}
    </div>
  );

  const delete_student = (
    <div>
      {" "}
      <p>Eliminar estudiante</p>{" "}
    </div>
  );
  // const download_student = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle estudiante</p>{" "}
  //   </div>
  // );
  const enroll_students = (
    <div>
      {" "}
      <p>Inscribir</p>{" "}
    </div>
  );
  const courses_student = (
    <div>
      {" "}
      <p>Cursos</p>{" "}
    </div>
  );

  /* PRUEBAS APARICION BOTON CURSOS (SI EL ESTUDIANTE TIENE)
  const pruebaBotonCurso = async (student) => {
    let responseInscriptions = await getInscriptionsByStudentApi(accessToken, student._id);
    //console.log(responseInscriptions.inscriptions[0]);
    if (responseInscriptions.inscriptions.length > 0) {
      console.log("si");
      //console.log(responseInscriptions);
      return true;
    } else {
      return false;
    }
  }

  const pruebaBotonCurso2 = async (student) => {
    //let responseInscriptions = await getInscriptionsByStudentApi(accessToken, student._id).then((inscriptions) => inscriptions);
    let responseInscriptions = await getInscriptionsByStudentApi(accessToken, student._id);
    //console.log(responseInscriptions);
    //if (responseInscriptions.inscriptions.length > 0) {
    if (responseInscriptions.inscriptions[0]) {
      //console.log("si");
      //console.log(responseInscriptions);
      return true;
    } else {
      return false;
    }
  }

  const pruebaEjec = async (student) => {
    let result = await pruebaBotonCurso2(student);
    console.log(result);

    if (result === true){
      console.log("si");
      //return true;
      return (<>
      <Popover content={courses_student}>
        <Link to={{pathname:`/admin/studentCourses/${student.student_code}`, state:{id:student._id}}} >
          <Button
              className="button-courses"
          >
            <ContainerOutlined />
          </Button>
        </Link>
      </Popover>
    </>)
    } else {
      console.log("no");
      //return false;
      return (
        <>
        </>
      )
    }
  };
  */
  /*
  var finishLoading = false;
  function pruebaAlgo (student) {
    if (finishLoading === false) {
      checkInscriptions(inscriptionActiveCourses, student._id);
      finishLoading = true;
    }
  }
  */

  const putButtons = async (student) => {
    var contador = 0;
    var start = Date.now();
    var end = "";
    await (
      <div>
        {checkAction(permissionsActive, userToken.role, "students", "all") ||
        checkAction(permissionsActive, userToken.role, "students", "edit") ||
        isAdmin(roleById) ? (
          ((
            <>
              <Popover content={cycle_student}>
                <Button
                  className="button-cycle"
                  onClick={() => addEditCycleStudent(student)}
                >
                  <ProfileOutlined />
                </Button>
              </Popover>
            </>
          ),
          (end = Date.now()),
          console.log("tiempo: ", end - start))
        ) : (
          <></>
        )}
        {checkAction(permissionsActive, userToken.role, "students", "all") ||
        checkAction(permissionsActive, userToken.role, "students", "edit") ||
        isAdmin(roleById) ? (
          <>
            <Popover content={edit_student}>
              <Button
                className="button-edit"
                onClick={() => editStudent(student)}
              >
                <EditOutlined />
              </Button>
            </Popover>
          </>
        ) : (
          <></>
        )}

        {checkAction(permissionsActive, userToken.role, "students", "all") ||
        checkAction(permissionsActive, userToken.role, "students", "enable") ||
        isAdmin(roleById) ? (
          <>
            <Popover content={desactivate_student}>
              <Button
                className="button-student"
                type="primary"
                onClick={() => desactivateStudent(student)}
              >
                <StopOutlined />
              </Button>
            </Popover>
          </>
        ) : (
          <></>
        )}

        {/* BOTON OCULTADO */}
        {checkAction(permissionsActive, userToken.role, "students", "all") ||
        checkAction(permissionsActive, userToken.role, "students", "delete") ||
        isAdmin(roleById) ? (
          <>
            <Popover content={delete_student}>
              <Button
                className="button-student"
                type="danger"
                hidden={true}
                onClick={() => showDeleteConfirm(student)}
              >
                <DeleteOutlined />
              </Button>
            </Popover>
          </>
        ) : (
          <></>
        )}

        {checkAction(permissionsActive, userToken.role, "students", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
        isAdmin(roleById) ? (
          ((
            <>
              <Popover content={enroll_students}>
                <Link
                  to={{
                    pathname: `/admin/coursesStudent/${student.student_code}`,
                    state: { id: student._id },
                  }}
                >
                  <Button className="button-enroll">
                    <FileDoneOutlined />
                  </Button>
                </Link>
              </Popover>
            </>
          ),
          (contador = contador + 1))
        ) : (
          <></>
        )}

        {(checkAction(permissionsActive, userToken.role, "students", "all") &&
          checkStudentHasCourses(studentsHasCourses, student._id)) ||
        (checkAction(permissionsActive, userToken.role, "courses", "enroll") &&
          checkStudentHasCourses(studentsHasCourses, student._id)) ||
        (isAdmin(roleById) &&
          checkStudentHasCourses(studentsHasCourses, student._id)) ? (
          <>
            <Popover content={courses_student}>
              <Link
                to={{
                  pathname: `/admin/studentCourses/${student.student_code}`,
                  state: { id: student._id },
                }}
              >
                <Button className="button-courses">
                  <ContainerOutlined />
                </Button>
              </Link>
            </Popover>
          </>
        ) : (
          <></>
        )}
      </div>
    );
    return true;
  };

  /*
  const Progress = ({ done }) => {
    const [style, setStyle] = React.useState({});
    setTimeout(() => {
      const newStyle = {
        opacity: 1,
        width: `${done}%`,
      };
      setStyle(newStyle);
    }, 1000);
    return (
      <div class="progress">
        <div class="progress-done" style={style}>
          {done}%
        </div>
      </div>
    );
    //LLAMAR ASI
    //<>
    //  <h1>React Progress Bar</h1>
    //  <Progress done="70"/>
    //</>
  };
  */

  //var contador = 0;
  //var start = Date.now();
  //var end = "";
  const columns = [
    // {
    //   title: "Cód.",
    //   //dataIndex: "student_code",
    //   render: student => student.id_old ? student.id_old: student.student_code,
    // },
    {
      title: "Apellido",
      dataIndex: "lastname",
      width: "15%",
      //sorter:(a,b) => a.lastname.localeCompare(b.lastname),
      //defaultSortOrder:"ascend"
    },
    {
      title: "Nombre",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      width: "15%",
    },

    {
      title: "Condición",
      dataIndex: "cycles",
      render: (cycles) => (cycles ? getLastCondition(cycles) : null),
      width: "10%",
    },

    {
      title: "Acción",
      key: "action",
      width: "30%",
      render: (text, student) => (
        //putButtons
        <div>
          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "edit") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={cycle_student}>
                <Button
                  className="button-cycle"
                  onClick={() => addEditCycleStudent(student)}
                >
                  <ProfileOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "edit") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={edit_student}>
                <Button
                  className="button-edit"
                  onClick={() => editStudent(student)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "students",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_student}>
                <Button
                  className="button-student"
                  type="primary"
                  onClick={() => desactivateStudent(student)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {/* BOTON OCULTADO */}
          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "students",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_student}>
                <Button
                  className="button-student"
                  type="danger"
                  hidden={true}
                  onClick={() => showDeleteConfirm(student)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={enroll_students}>
                <Link
                  to={{
                    pathname: `/admin/coursesStudent/${student.student_code}`,
                    state: { id: student._id },
                  }}
                >
                  <Button className="button-enroll">
                    <FileDoneOutlined />
                  </Button>
                </Link>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {(checkAction(permissionsActive, userToken.role, "students", "all") &&
            checkStudentHasCourses(studentsHasCourses, student._id)) ||
          (checkAction(
            permissionsActive,
            userToken.role,
            "courses",
            "enroll"
          ) &&
            checkStudentHasCourses(studentsHasCourses, student._id)) ||
          (isAdmin(roleById) &&
            checkStudentHasCourses(studentsHasCourses, student._id)) ? (
            //(end = Date.now()),
            //console.log("tiempo botones: ", (end - start)/1000),
            //(end = ""),
            //contador = contador+1
            <>
              <Popover content={courses_student}>
                <Link
                  to={{
                    pathname: `/admin/studentCourses/${student.student_code}`,
                    state: { id: student._id },
                  }}
                >
                  <Button className="button-courses">
                    <ContainerOutlined />
                  </Button>
                </Link>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
        /*
        &&
        (<div>
        <h2>Cargando...</h2>
        <ProgressBar
          isLoading={true}
          //student={student}
          percent={100}
          type='line'
          strokeColor='green'
          //status='success'
          size={"medium"}
          showInfo={false}
        />
      </div>)*/
      ),
    },
  ];

  //cronometro
  //fin = Date.now();
  //console.log("tiempo activos: ", (fin - inicio)/1000);

  return (
    <div>
      {
        /*(finishLoading === false) ?
      (<div>
        <h2>Cargando...</h2>
        <ProgressBar
          isLoading={true}
          //student={student}
          percent={50}
          size={"medium"}
          showInfo={false}
        />
      </div>) :
      (<Table
        footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}`: false}}     pagination={{
          ...propsPagination,
          showSizeChanger:false,
          onChange:(newPage) => {
            setPagination({page:newPage, state: 1, filter:filterText, filters:filters})}
        }}
        dataSource={studentsActive}
        columns={columns}
        finishLoading={finishLoading}
        size="small"
        rowKey="_id"
      />) && esperar
      */
        //(end = Date.now()),
        //console.log("tiempo: ", (end - start)/1000),
        <Table
          footer={() => {
            return propsPagination.total
              ? `Total de registros: ${propsPagination.total}`
              : false;
          }}
          pagination={{
            ...propsPagination,
            showSizeChanger: false,
            onChange: (newPage) => {
              setPagination({
                page: newPage,
                state: 1,
                filter: filterText,
                filters: filters,
              });
            },
          }}
          dataSource={studentsActive}
          columns={columns}
          //finishLoading={finishLoading}
          size="small"
          rowKey="_id"
        />
      }
    </div>
  );
}

function StudentsInactive(props) {
  const {
    studentsInactive,
    setReloadStudents,
    propsPagination,
    setPagination,
    addEditCycleStudent,
    accessToken,
    userToken,
    permissionsActive,
    filterText,
    filters,
    roleById,
    //getLastCondition,
    //inscriptionInactiveCourses,
    studentsHasCourses,
  } = props; //showDeleteConfirm

  const activateStudent = (student) => {
    //const accessToken = getAccessToken();

    activateStudentApi(accessToken, student._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadStudents(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = (student) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Estudiante",
      content: `¿Estás seguro que quieres eliminar a ${
        student.name ? student.name + " " + student.lastname : "estudiante"
      }?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteStudentApi(accessToken, student._id)
          .then((response) => {
            notification["success"]({
              message: response,
            });
            setReloadStudents(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const cycle_student = (
    <div>
      {" "}
      <p>Ciclo del Estudiante</p>{" "}
    </div>
  );

  const activate_student = (
    <div>
      {" "}
      <p>Pasar a estudiantes activos</p>{" "}
    </div>
  );

  const delete_student = (
    <div>
      {" "}
      <p>Eliminar estudiante</p>{" "}
    </div>
  );

  /*
  const download_student = (
    <div>
      {" "}
      <p>Descargar detalle estudiante</p>{" "}
    </div>
  );
  */

  const courses_student = (
    <div>
      {" "}
      <p>Cursos</p>{" "}
    </div>
  );

  const columns = [
    // {
    //   title: "Cód.",
    //   //dataIndex: "student_code",
    //   render: student => student.id_old ? student.id_old: student.student_code,
    // },
    {
      title: "Apellido",
      dataIndex: "lastname",
      width: "15%",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      width: "15%",
    },
    /*
    {
      title: "Condición",
      dataIndex: "cycles",
      render: (cycles) => cycles? getLastCondition(cycles) : null,
      width:"10%"
    },
    */
    {
      title: "Acción",
      key: "action",
      width: "30%",
      render: (text, student) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "edit") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={cycle_student}>
                <Button
                  className="button-edit"
                  onClick={() => addEditCycleStudent(student)}
                >
                  <ProfileOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "students",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={activate_student}>
                <Button
                  className="button-activate"
                  onClick={() => activateStudent(student)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "students",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_student}>
                <Button
                  className="button-student"
                  type="danger"
                  hidden={true}
                  onClick={() => showDeleteConfirm(student)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
          {checkStudentHasCourses(studentsHasCourses, student._id) ? (
            <>
              <Popover content={courses_student}>
                <Link
                  to={{
                    pathname: `/admin/studentCourses/${student.student_code}`,
                    state: { id: student._id },
                  }}
                >
                  <Button className="button-courses">
                    <ContainerOutlined />
                  </Button>
                </Link>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={() => {
        return propsPagination.total
          ? `Total de registros: ${propsPagination.total}`
          : false;
      }}
      pagination={{
        ...propsPagination,
        showSizeChanger: false,
        onChange: (newPage) => {
          setPagination({
            page: newPage,
            state: 2,
            filter: filterText,
            filters: filters,
          });
        },
      }}
      dataSource={studentsInactive}
      columns={columns}
      size="small"
      rowKey="_id"
    />
  );
}

function StudentAcademicRecord(props) {
  const { students, setIsVisibleModal } = props;
  const isMounted = React.useRef(true);
  const [exportHistory, setExportHistory] = useState([]);
  const [count, setCount] = useState(0);
  const [percent, setPercent] = useState(0);

  //EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const increase=()=>{
    if(isMounted.current){
      setPercent((prevPercent)=>{
        const newPercent = prevPercent + 100/students.length
        //Math.ceil(100/students.length);
        if(newPercent > 100){
          return 100
        }
        return newPercent
      })
    }
  }

   const increaseCount=()=>{
    if(isMounted.current){
      setCount((prevCount)=>{
        const newCount = prevCount + 1
        return newCount
      })
    }
  }

  const getHistory = async (accessToken,students)=>{
    let data=[];
    for await (const item of students){
      if (isMounted.current){
        let result= await getInscriptionsByStudentReportApi(accessToken,item._id);
          if(result && result.inscriptions){
            if (isMounted.current){
              data.push(reportAcademicRecordStudentExcel(item, result.inscriptions));
              increaseCount();
              increase();
            }
          }else{
            break;
          }
      }
    }
    return data;
  }

  useEffect(() => {
    const accessToken = getAccessToken();
    if(students && students.length > 0){
      getHistory(accessToken, students).then((response)=>{
        if (isMounted.current){
          setExportHistory(response);
          if(count === students.length){
            increase();
          }
        }
      });
    }
    return ()=>{
      isMounted.current=false;
    }
  }, [students]);

  const closed = () => {
    setIsVisibleModal(false);
  };

  return (
    <div>
       <p> Archivo en proceso, espere por favor.</p>
     
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={14} >
      <Progress percent={percent} format={()=>{}} strokeColor={{'0%':'#108ee9', '99.9%':'#87d068'}}/>
      </Col>
      <Col>{count} / {students.length} Estudiantes</Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={7} ></Col>
        <Col span={8} >
               {/* Historial estudiantes xls */}
               {(exportHistory && exportHistory.length > 0)?
                (<>
                  <ExcelFile
                    filename={"ListadoHistorialEstudiantes"}
                    element={ 
                      <Popover content={"Descargar Excel"}>
                        <Button type="primary"
                        size={"small"} 
                        shape="round"
                        //onClick={() => {historyStudents(studentsActiveExport)}}
                        onClick={()=>{closed()}}
                        disabled={exportHistory? exportHistory.length > 0? false : true : true}
                        loading={exportHistory? exportHistory.length > 0? false : true : true}
                        >
                          <DownloadOutlined /> Historial Academico
                        </Button>
                      </Popover>
                    }
                  >
                     <ExcelSheet data={exportHistory} name="Estudiantes">
                      <ExcelColumn label="Apellido" value="lastname" />
                      <ExcelColumn label="Nombre" value="name" />
                      <ExcelColumn label="Especialidad" value="specialty"/>
                      <ExcelColumn label="Año ingreso" value="yearAdmission" />
                      <ExcelColumn label="Año egreso" value="yearGraduation"/>
                      <ExcelColumn label="Condicion Final" value="finalCondition" />
                      <ExcelColumn label="Horas Cursadas" value="ownHours" />
                      <ExcelColumn label="Años Cursados" value="yearsCoursed" />
                      <ExcelColumn label="Años Abandono" value="yearsAbandonment" />
                    </ExcelSheet>
                  </ExcelFile>
                </>) : null }
        </Col>
      </Row>
      {/* <Row>
        <Col span={24}>
        <Button type="primary" style={{marginTop:25, width:"100%"}} onClick={closed}>
          Salir
        </Button>
        </Col>
      </Row> */}
    </div>
  );
}