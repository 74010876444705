import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { updateStateStudentCourseApi } from "../../../../api/stateStudentCourse";
import { getAccessToken } from "../../../../api/auth";
import { numberValidation } from "../../../../utils/formValidation";

import "./EditStateStudentCourseForm.scss";

export default function EditStateStudentCourseForm(props) {
  const { stateStudentCourse, setIsVisibleModal, setReloadStateStudentCourses } = props;
  const [stateStudentCourseData, setStateStudentCourseData] = useState({});

  const [modifDataToValid, setModifDataToValid] = useState({
    phone: false,
  });
  const [validData, setValidData] = useState({
    email: false,
  });

  //trae los datos de estado estudiante curso
  useEffect(() => {
    setStateStudentCourseData({
      statestudentcourse_code: stateStudentCourse.statestudentcourse_code,
      description: stateStudentCourse.description,
    });
  }, [stateStudentCourse]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!stateStudentCourseData.description) {
      notification["error"]({
        message: "Obligatorio: Descripción.",
      });
      errorExists = true;
    } else {
    /*
    else if (modifDataToValid.phone) {
      if (!validData.phone) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "Ingreso de teléfono inválido"
        });
        errorExists = true;
      }
    }*/
      errorExists = false;
    }
    return errorExists;
  };

  const updateStateStudentCourse = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let stateStudentCourseUpdate = stateStudentCourseData; //es let porque se va actualizando

      updateStateStudentCourseApi(token, stateStudentCourseUpdate, stateStudentCourse._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadStateStudentCourses(true);
          }
        }
      );
    }
  };

  return (
    <div className="edit-statestudentcourse-form">
      <EditForm
        stateStudentCourseData={stateStudentCourseData}
        setStateStudentCourseData={setStateStudentCourseData}
        updateStateStudentCourse={updateStateStudentCourse}
        modifDataToValid={modifDataToValid}
        setModifDataToValid={setModifDataToValid}
        validData={validData}
        setValidData={setValidData}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    stateStudentCourseData,
    setStateStudentCourseData,
    updateStateStudentCourse,
    setModifDataToValid,
    modifDataToValid,
    validData,
    setValidData,
  } = props;

  //validaciones de inputs
  const inputValidation = (e) => {
    const { type, description } = e.target;

    setStateStudentCourseData({
      ...stateStudentCourseData,
      [description]: e.target.value,
    });
    setModifDataToValid({
      ...modifDataToValid,
      [description]: true,
    });
  };

  return (
    <Form className="form-edit" onSubmit={updateStateStudentCourse}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={stateStudentCourseData.statestudentcourse_code}
              onChange={(e) => setStateStudentCourseData({ ...stateStudentCourseData, statestudentcourse_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={stateStudentCourseData.description}
              onChange={(e) =>
                setStateStudentCourseData({ ...stateStudentCourseData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateStateStudentCourse}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
