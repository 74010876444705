import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  //Checkbox,
  Select,
  Button,
  Row,
  Col,
  //DatePicker, TimePicker,
  notification,
} from "antd";
import {
  //UserOutlined, MailOutlined, CalendarOutlined,
  //EnvironmentOutlined, PhoneOutlined, TeamOutlined,
  TagOutlined,
} from "@ant-design/icons";

import { getCoursesActiveApi } from "../../../../api/course";
import { addInscriptionApi, getInscriptionByCourseApi } from "../../../../api/inscription";
import { getAccessToken } from "../../../../api/auth";
//import { emailValidation, numberValidation } from "../../../../utils/formValidation";
//import {formatDateHour} from  "../../../../utils/formatDate";

import "./AddInscriptionForm.scss";

//const { TextArea } = Input;

export default function AddInscriptionForm(props) {
  const { setIsVisibleModal, setReloadInscriptions } = props;
  const [listCoursesActive, setListCoursesActive] = useState([]);
  const accessToken = getAccessToken();

  useEffect(() => {
    getCoursesActiveApi(accessToken, true).then((response) => {
      setListCoursesActive(response.courses);
    });
  }, []);

  const [inputs, setInputs] = useState({
    // name:null,
  });

  const isFormValid = (e) => {
    let errorExists = false;
    // console.log(inputs);
    if (
      !inputs.course ||
      !inputs.date
      ) {
      notification["error"]({
        message: "Obligatorios: curso y fecha.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addInscription = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      const accessToken = getAccessToken();
      //console.log("inputs", inputs);
      if (inputs.course) {
        //chequea que exista alguna inscripcion del curso
        getInscriptionByCourseApi(accessToken, inputs.course).then((response) => {
          if (response.inscription) { //si existe, avisa y no la crea
            // await addInscriptionApi(accessToken, inputs)
            //console.log("response.inscription", response.inscription);
            notification["error"]({
              message: "Ya existe una inscripción para este curso",
            });

          } else { //si no existe, inserto la inscripcion
            addInscriptionApi(accessToken, inputs)
            .then((response) => {
              if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else if (response.code !== 200) {
                notification["error"]({
                  message: response.message,
                });
              } else {
                notification["success"]({
                  message: "Inscripción creada",
                });
                setIsVisibleModal(false);
              }
              setReloadInscriptions(true);
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
          }
          //console.log("response", response);
        });
        //console.log("inscriptionCourse", inscriptionCourse);
      }
    }
  };

  return (
    <div className="add-inscription-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addInscription={addInscription}
        listCoursesActive={listCoursesActive}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    addInscription,
    listCoursesActive,
  } = props;
  const { Option } = Select;
  //console.log("inputs", inputs.course);
  //console.log("listCoursesActive", listCoursesActive);
  let reverseListCoursesActive = listCoursesActive.reverse();
  return (
    <Form className="form-add" onSubmit={addInscription}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={3}>
          <label className="control-label">
            <span className="control-required">*</span>Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.course_code}
              onChange={(e) => setInputs({ ...inputs, inscription_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha
          </label>
          <Form.Item>
            <Input
              // prefix={<CalendarOutlined/>}
              type="date"
              placeholder="Fecha "
              value={inputs.date}
              onChange={(e) =>
                setInputs({ ...inputs, date: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={17}>
          <label className="control-label">
            <span className="control-required">*</span> Curso
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona el curso"
              value={inputs.course}
              onChange={(e) => setInputs({ ...inputs, course: e })}
              optionFilterProp="children"
              // con esto rompe la busqueda con reverseListCoursesActive:
              //filterOption={(input, option) =>
              //  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              //}
            >
              {
              reverseListCoursesActive
                ? reverseListCoursesActive.map((course) => {
                    if (course.schoolYear){
                      let sy = course.schoolYear;
                      //console.log(course.schoolYear);
                      //console.log(sy.year);
                      return (
                        <Option key={course._id}>
                          {course.name} ({sy.year ? sy.year : "sin año"})
                        </Option>
                      );
                    } else {
                      return (
                        <Option key={course._id}>
                          {course.name} ({course.year ? course.year : "sin año"})
                        </Option>
                      );
                    }
                  })
                : 
                null
              }
              {/* 
              {listCoursesActive
                ? listCoursesActive.map((course) => {
                    return (
                      <Option key={course._id}>
                        {course.name} {course.year}
                      </Option>
                    );
                  })
                : 
              null}
              */}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addInscription}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
