import { downloadPdf, openPdf , getStyles, getHeader, getLogo} from "../../../../utils/pdfManager";
import moment from "moment";

export function listStudentCoursesPdf(data, title, schoolYear, isDownload) {

  const yearDescription = schoolYear!=="" ? "Ciclo: " + parseInt(schoolYear): "";

  const _format = (data) => {
    return data.map((item) => {
      return [
        { text: item.course.name },
        // { text: item.course.days? item.course.days.map(function(elem){
        //   return elem.description
        // }).join(" ,") : "" },
        // {text: item.course.hour_start? moment(item.course.hour_start,"HH:mm").isValid()?  moment(new Date(item.course.hour_start),"HH:mm").format("HH:mm") : item.course.hour_start : ""},
        // {text: item.course.hour_end? moment(item.course.hour_end,"HH:mm").isValid()?  moment(new Date(item.course.hour_end),"HH:MM").format("HH:mm") : item.course.hour_end : ""},
        // {text: item.course.teachers? item.course.teachers.map(function(elem){
        //   return elem.description
        // }).join(" ,") : "" },
        { text: item.absences? item.absences : "-" },
        { text: item.course? item.course.workload ? item.course.workload.absencesAllowed : null : "-" },
        { text: item.stateStudentCourse? item.stateStudentCourse : "-" },
        //{ text: item.students[0].stateStudentCourse? item.students[0].stateStudentCourse.description : "sin datos" }
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    //pageOrientation: "landscape",
    header:getHeader(),
    content: [
        getLogo(),
        [
            { text:'Listado de Cursos', style:'title'},
            { text:"Estudiante: " + title, style:'title'},
        ],
      '\n',
      '\n',
      { text: yearDescription },
      '\n',
      {
        style: 'table',
        table: {
          headerRow: 1,
          // widths:['*','auto','auto','*','*','*','*','*'],
          widths:['*','auto','auto','*'],
          body: [
            [
              { text: "Nombre", style: "tableHeader" },
              // { text: "Dia", style: "tableHeader" },
              // { text: "Horario Inicio", style: "tableHeader" },
              // { text: "Horario Fin", style: "tableHeader" },
              // { text: "Docente", style: "tableHeader" },
              { text: "Faltas", style: "tableHeader" },
              { text: "Inasistencias Permitidas", style: "tableHeader" },
              { text: "Estado", style: "tableHeader" }
              // { text: "Duración", style: "tableHeader" },
              // { text: "Cupo", style: "tableHeader" },
            ],
            ...formattedData,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoEstudiantes");
  } else {
    openPdf(docDefinition);
  }
}
