import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditStateCourseForm from "../EditStateCourseForm";
import AddStateCourseForm from "../AddStateCourseForm";
import { activateStateCourseApi, deleteStateCourseApi } from "../../../../api/stateCourse";
import { getCoursesByStateCourseApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListStateCourses.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListStateCourses(props) {
  const { stateCoursesActive, stateCoursesInactive, setReloadStateCourses } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewStateCoursesActives, setViewStateCoursesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [stateCoursesActiveFilter, setStateCoursesActiveFilter] = useState(
    []
  );
  const [ stateCoursesInactiveFilter, setStateCoursesInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (stateCoursesActive) {
      stateCoursesActive.forEach((element) => {
          if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.statecourse_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setStateCoursesActiveFilter(listActive);
  }, [filterText, stateCoursesActive]);

  useEffect(() => {
    const listInactive = [];
    if (stateCoursesInactive) {
      stateCoursesInactive.forEach((element) => {
          if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.statecourse_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setStateCoursesInactiveFilter(listInactive);
  }, [filterText, stateCoursesInactive]);

  const addStateCourseModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Estado de curso");
    setModalContent(
      <AddStateCourseForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateCourses={setReloadStateCourses}
      />
    );
  };

  return (
    <div className="list-statecourses">
      <div className="list-statecourses__header">
        {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
        checkAction(permissionsActive, userToken.role, "statecourses", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-statecourses__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewStateCoursesActives(!viewStateCoursesActives)
                }
              />
              <span>
                {viewStateCoursesActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
        checkAction(permissionsActive, userToken.role, "statecourses", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addStateCourseModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
      checkAction(permissionsActive, userToken.role, "statecourses", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-statecourses__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de estados de cursos 'en curso, vigente, cancelado, rechazado'
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewStateCoursesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statecourses", "view") || isAdmin(roleById) ? (
            <>
              <StateCoursesActive
                stateCoursesActive={stateCoursesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadStateCourses={setReloadStateCourses}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statecourses", "view") || isAdmin(roleById) ? (
            <>
              <StateCoursesInactive
                stateCoursesInactive={stateCoursesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadStateCourses={setReloadStateCourses}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function StateCoursesActive(props) {
  const {
    stateCoursesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadStateCourses,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editStateCourse = (stateCourse) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${stateCourse.description ? stateCourse.description : "sin nombre"}`
    );
    setModalContent(
      <EditStateCourseForm
        stateCourse={stateCourse}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateCourses={setReloadStateCourses}
      />
    );
  };

  const desactivateStateCourse = (stateCourse) => {
    //const accessToken = getAccessToken();

    activateStateCourseApi(accessToken, stateCourse._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateCourses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (stateCourse) => {
    //const accessToken = getAccessToken();
    let response = await getCoursesByStateCourseApi(accessToken, stateCourse._id);
    //console.log(response.courses);
    if (response.courses.length > 0) {
      confirm({
        title: "Eliminando -> Estado de curso",
        content: `No se puede eliminar '${stateCourse.description}' porque existen cursos asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateStateCourse(stateCourse);
        },
      });
    } else {
      //Inscripcion inexistente o hubo error al consultar (VERIFICAR)
      //  ModalAntd.error({
      //   title: 'Eliminando -> Curso',
      //   content: `No se pudo verificar que ${course.name ? course.name : "curso"} no tenga estudiantes inscriptos`,
      // });
      confirm({
        title: "Eliminando -> Estado de curso",
        content: `'${stateCourse.description ? stateCourse.description : "tipo de curso"}' no tiene ningún curso asociado. ¿Estás seguro que quieres eliminarlo?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteStateCourseApi(accessToken, stateCourse._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadStateCourses(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_statecourse = (
    <div>
      {" "}
      <p>Editar Estado</p>{" "}
    </div>
  );

  const desactivate_statecourse = (
    <div>
      {" "}
      <p>Pasar a Estados inactivos</p>{" "}
    </div>
  );

  const delete_statecourse = (
    <div>
      {" "}
      <p>Eliminar Estado</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "statecourse_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, stateCourse) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statecourses", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_statecourse}>
                <Button
                  className="button-edit"
                  onClick={() => editStateCourse(stateCourse)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statecourses", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_statecourse}>
                <Button
                  className="button-statecourse"
                  type="primary"
                  onClick={() => desactivateStateCourse(stateCourse)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statecourses", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_statecourse}>
                <Button
                  className="button-statecourse"
                  type="danger"
                  onClick={() => showDeleteConfirm(stateCourse)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return stateCoursesActive.length > 0? `Total de registros: ${stateCoursesActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={stateCoursesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function StateCoursesInactive(props) {
  const { stateCoursesInactive,
          setReloadStateCourses,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateStateCourse = (stateCourse) => {
    activateStateCourseApi(accessToken, stateCourse._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateCourses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (stateCourse) => {
    let response = await getCoursesByStateCourseApi(accessToken, stateCourse._id);
    if(response.courses.length > 0){
      ModalAntd.info({
        title: "Eliminando -> Estado de curso",
        content:`No se puede eliminar '${stateCourse.description}' porque existen cursos asociados a él`,
        onOk(){},
      });
    }else {
      confirm({
        title: "Eliminando -> Estado de curso",
        content: `'${stateCourse.description ? stateCourse.description : "tipo de curso"}' no tiene ningún curso asociado. ¿Estás seguro que quieres eliminarlo?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteStateCourseApi(accessToken, stateCourse._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadStateCourses(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_statecourse = (
    <div>
      {" "}
      <p>Pasar a Estados activos</p>{" "}
    </div>
  );

  const delete_statecourse = (
    <div>
      {" "}
      <p>Eliminar Estado</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "statecourse_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, stateCourse) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statecourses", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_statecourse}>
                <Button
                  className="button-activate"
                  onClick={() => activateStateCourse(stateCourse)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statecourses", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_statecourse}>
                <Button
                  className="button-statecourse"
                  type="danger"
                  onClick={() => showDeleteConfirm(stateCourse)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return stateCoursesInactive.length > 0? `Total de registros: ${stateCoursesInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={stateCoursesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
