import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getStateCoursesActiveApi } from "../../../api/stateCourse";
import ListStateCourses from "../../../components/Admin/StateCourses/ListStateCourses";


export default function StateCourses() {
    const [stateCoursesActive, setStateCoursesActive] = useState([]);
    const [stateCoursesInactive, setStateCoursesInactive] = useState([]);
    const [reloadStateCourses, setReloadStateCourses ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getStateCoursesActiveApi(token, true).then(response => {
            setStateCoursesActive(response.stateCourses);
        });
        getStateCoursesActiveApi(token, false).then(response => {
            setStateCoursesInactive(response.stateCourses);
        });
        setReloadStateCourses(false);
    }, [token, reloadStateCourses]);

    return (
        <div className="statecourses">
            <h3>Listado de Estados de Curso</h3>
            <ListStateCourses stateCoursesActive={stateCoursesActive} stateCoursesInactive={stateCoursesInactive} setReloadStateCourses={setReloadStateCourses}
                />

        </div>
    );
}