import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { updateStateSchoolYearApi } from "../../../../api/stateSchoolYear";
import { getAccessToken } from "../../../../api/auth";
import { numberValidation } from "../../../../utils/formValidation";

import "./EditStateSchoolYearForm.scss";

export default function EditStateSchoolYearForm(props) {
  const { stateSchoolYear, setIsVisibleModal, setReloadStateSchoolYears } = props;
  const [stateSchoolYearData, setStateSchoolYearData] = useState({});

  const [modifDataToValid, setModifDataToValid] = useState({
  });

  const [validData, setValidData] = useState({
  });

  //trae los datos de estado de año lectivo
  useEffect(() => {
    setStateSchoolYearData({
      stateschoolyear_code: stateSchoolYear.stateschoolyear_code,
      description: stateSchoolYear.description,
    });
  }, [stateSchoolYear]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!stateSchoolYearData.description) {
      notification["error"]({
        message: "Obligatorio: Descripción.",
      });
      errorExists = true;
    } else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateStateSchoolYear = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let stateUpdate = stateSchoolYearData; //es let porque se va actualizando

      updateStateSchoolYearApi(token, stateUpdate, stateSchoolYear._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadStateSchoolYears(true);
          }
        }
      );
    }
  };

  return (
    <div className="edit-stateschoolyear-form">
      <EditForm
        stateSchoolYearData={stateSchoolYearData}
        setStateSchoolYearData={setStateSchoolYearData}
        updateStateSchoolYear={updateStateSchoolYear}
        modifDataToValid={modifDataToValid}
        setModifDataToValid={setModifDataToValid}
        validData={validData}
        setValidData={setValidData}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    stateSchoolYearData,
    setStateSchoolYearData,
    updateStateSchoolYear,
    setModifDataToValid,
    modifDataToValid,
    validData,
    setValidData,
  } = props;

  return (
    <Form className="form-edit" onSubmit={updateStateSchoolYear}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={stateSchoolYearData.stateschoolyear_code}
              onChange={(e) => setStateSchoolYearData({ ...stateSchoolYearData, stateschoolyear_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={stateSchoolYearData.description}
              onChange={(e) =>
                setStateSchoolYearData({ ...stateSchoolYearData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateStateSchoolYear}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
