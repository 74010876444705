import React, { useState, useEffect } from "react";
import {
  //Switch,
  //List,
  //Input,
  //Radio,
  Button,
  //notification,
  Modal as ModalAntd,
  //Table,
  //Space,
  Select,
  Form,
  Col,
  Row,
  //Pagination as PaginationAntd
} from "antd";

/*
import {
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
*/

//import Modal from "../../../Modal";
//import { activateCourseApi, deleteCourseApi } from "../../../../api/course";
import { getTypeCoursesActiveApi } from "../../../../api/typeCourse";
import { getDepartmentsActiveApi } from "../../../../api/department";
import { getTeachersActiveApi } from "../../../../api/teacher";
//import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
//import { getInscriptionsActiveApi, getInscriptionsApi,getInscriptionByCourseApi } from "../../../../api/inscription";
import { getAccessToken } from "../../../../api/auth";

//const { confirm } = ModalAntd;
const { Option } = Select;

export default function AdvancedSearchCourseForm (props){
    const {filters, setFilters} = props;
    const [data, setData]=useState([]);
    const [listTypeCourses, setListTypeCourses] = useState([]);
    const [listDepartments, setListDepartments] = useState([]);
    const [listTeachers, setListTeachers] = useState([]);
    //const [listStateCourses, setListStateCourses]=useState([]);
    const [
      listStateQuota, 
      setListStateQuota
    ] = useState([
      "disponible", "completo"
    ]);

    const [form] = Form.useForm();
  
    const onFinish = (values) => {
      setFilters(data);
    };
    const accessToken = getAccessToken();

    const resetFields=()=>{
      setData([]);
      setFilters([]);
    };

    useEffect(() => {
      setData(filters);
    }, []);

    useEffect(() => {
      getTypeCoursesActiveApi(accessToken, true).then(response => {
        setListTypeCourses(response.typeCourses);
      });
    }, []);

    useEffect(() => {
      getTeachersActiveApi(accessToken, true).then(response => {
        setListTeachers(response.teachers);
      });
    }, []);

    useEffect(() => {
      getTypeCoursesActiveApi(accessToken, true).then(response => {
        setListTypeCourses(response.typeCourses);
      });
    }, []);

    useEffect(() => {
      getDepartmentsActiveApi(accessToken, true).then(response => {
        setListDepartments(response.departments);
      });
    }, []);
  
    return (
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        layout="vertical"
        size="small"
        onFinish={onFinish}
      >
        <Row gutter={24}>
        <Col span={6}>
            <Form.Item label="Tipo Curso">
              <Select 
              showSearch
              value={data.typeCourse}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }} onChange={e=>setData({...data,typeCourse:e})}>
                  {listTypeCourses? listTypeCourses.map(type=>{
                      return (
                    <Option key={type._id}>{type.description}</Option>)
                  }) : null}
              </Select>
            </Form.Item>

          </Col>
          <Col span={6}>
            <Form.Item label="Cupo">
              <Select 
               showSearch
               value={data.stateQuota}
               optionFilterProp="children"
               filterOption={(input, option) =>
                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
              style={{ width: "100%" }} 
              onChange={e=>setData({...data,stateQuota:e})}>
              {listStateQuota? listStateQuota.map(state=>{
                      return (
                    <Option key={state}>{state}</Option>)
                  }) : null}
            </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Departamento">
              <Select 
               showSearch
               value={data.department}
               optionFilterProp="children"
               filterOption={(input, option) =>
                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
              style={{ width: "100%" }} onChange={e=>setData({...data,department:e})}>
              {listDepartments? listDepartments.map(deparment=>{
                      return (
                    <Option key={deparment._id}>{deparment.description}</Option>)
                  }) : null}
            </Select>
            </Form.Item>

          </Col>
          <Col span={6}>
            <Form.Item label="Docente">
              <Select 
               showSearch
               value={data.teacher}
               optionFilterProp="children"
               filterOption={(input, option) =>
                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
              style={{ width: "100%" }} onChange={e=>setData({...data,teacher:e})}>
              {listTeachers? listTeachers.map(teacher=>{
                      return (
                    <Option key={teacher._id}>{teacher.description}</Option>)
                  }) : null}
            </Select>
            </Form.Item>

          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{
              textAlign: 'right',
            }}
          >
            <Button type="primary" htmlType="submit">
              Aplicar
            </Button>
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => {
                resetFields();
              }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };