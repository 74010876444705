import {
  downloadPdf,
  openPdf,
  getStyles,
  getHeader,
  //getLogoHeader,
  getLogo,
  verticalFooter,
  lineVerticalPage
} from "../../../../utils/pdfManager"; //docDefinitionTable
//import { useEffect } from "react";

export function reportTeacherCollabHistory(teacherCollab, data, isDownload) {
  const _formatCourses = (data) => {
    return data.map((item) => {
      return [
        { text: item.name, alignment: "center" },
        {
          text: item.department ? item.department.description : "",
          alignment: "center",
        },
        { text: item.workload.hours, alignment: "center" },
      ];
    });
  };

  const _formatYear = (data) => {
    const info = Object.keys(data);
    info
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      })
      .reverse();

    return info.map((item) => {
      return [
        "\n",
        { text: `Ciclo ${item}`, style: "titleLeft" },
        "\n",
        {
          style: "table",
          table: {
            headerRow: 1,
            widths: ["auto", "*", "*"],
            border: [false, false, false, false],
            margin: [0, 0, 0, 0],
            body: [
              [
                // { text: "Código", style: "tableHeader" },
                {
                  text: "Nombre del curso/seminario",
                  style: "tableHeaderWithoutColor",
                  alignment: "center",
                },
                {
                  text: "Departamento",
                  style: "tableHeaderWithoutColor",
                  alignment: "center",
                },
                {
                  text: "Carga Horaria",
                  style: "tableHeaderWithoutColor",
                  alignment: "center",
                },
              ],
              ..._formatCourses(data[item]),
            ],
          },
          layout: "noBorders",
        },
        "\n",
      ];
    });
    // return print;
  };

  //Agrupa por schoolYear
  const result = data.reduce(function (a, b) {
    a[b.schoolYear.year] = a[b.schoolYear.year] || [];
    a[b.schoolYear.year].push(b);
    return a;
  }, Object.create(null));

  // console.log(result)

  const formattedData = _formatYear(result);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [40, 40, 40, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      [
        lineVerticalPage(),
        { text: "REPORTE COLABORADOR", style: "title" }],
      "\n",
      [
        {
          text: `Colaborador: ${
            teacherCollab.description ? teacherCollab.description : ""
          }`,
        },
      ],
      "\n",
      "\n",
      {
        // style: "table",
        table: {
          headerRow: 1,
          widths: ["*"],
          margin: [0, 0, 0, 0],
          body: [[{ text: "CURSOS", style: "tableHeaderWithoutColor" }]],
        },
      },
      "\n",
      ...formattedData,
    ],
    footer: verticalFooter,
    /*
    footer: [
      {
        text: `Instituto Clínico de Buenos Aires - Callao 1033 5° Piso - C.P(1023) - Tel: 4816-7292`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
      {
        text: `e-mail: icba@eol.org.ar`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
    ],*/
    //currentPage.toString() + ' de ' + pageCount
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteDocente");
  } else {
    openPdf(docDefinition);
  }
}
