import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditStudentConditionForm from "../EditStudentConditionForm";
import AddStudentConditionForm from "../AddStudentConditionForm";
import { activateStudentConditionApi, deleteStudentConditionApi } from "../../../../api/studentCondition";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getStudentsByStudentConditionApi } from "../../../../api/student";
import jwtDecode from "jwt-decode";

import "./ListStudentConditions.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListStudentConditions(props) {
  const {
    studentConditionsActive,
    studentConditionsInactive,
    setReloadStudentConditions,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewStudentConditionsActives, setViewStudentConditionsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [studentConditionsActiveFilter, setStudentConditionsActiveFilter] = useState(
    []
  );
  const [ studentConditionsInactiveFilter, setStudentConditionsInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (studentConditionsActive) {
      studentConditionsActive.forEach((element) => {
        if (
          element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.studentCondition_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setStudentConditionsActiveFilter(listActive);
  }, [filterText, studentConditionsActive]);

  useEffect(() => {
    const listInactive = [];
    if (studentConditionsInactive) {
      studentConditionsInactive.forEach((element) => {
        if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.studentConditions_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setStudentConditionsInactiveFilter(listInactive);
  }, [filterText, studentConditionsInactive]);

  const addStudentConditionModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Condicion");
    setModalContent(
      <AddStudentConditionForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadStudentConditions={setReloadStudentConditions}
      />
    );
  };

  return (
    <div className="list-studentconditions">
      <div className="list-studentconditions__header">
        {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "studentconditions", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-typecourses__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewStudentConditionsActives(!viewStudentConditionsActives)
                }
              />
              <span>
                {viewStudentConditionsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "studentconditions", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addStudentConditionModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
      checkAction(permissionsActive, userToken.role, "studentconditions", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-studentconditions__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de condicion del estudiante
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewStudentConditionsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "studentconditions", "view") || isAdmin(roleById) ? (
            <>
              <StudentConditionsActive
                studentConditionsActive={studentConditionsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadStudentConditions={setReloadStudentConditions}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "studentconditions", "view") || isAdmin(roleById) ? (
            <>
              <StudentConditionsInactive
                studentConditionsInactive={studentConditionsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadStudentConditions={setReloadStudentConditions}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function StudentConditionsActive(props) {
  const {
    studentConditionsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadStudentConditions,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editStudentCondition = (studentCondition) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${studentCondition.description ? studentCondition.description : "sin descripción"}`
    );
    setModalContent(
      <EditStudentConditionForm
        studentCondition={studentCondition}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStudentConditions={setReloadStudentConditions}
      />
    );
  };

  const desactivateStudentCondition = (studentCondition) => {
    activateStudentConditionApi(accessToken, studentCondition._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStudentConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (studentCondition) => {
    let response = await getStudentsByStudentConditionApi(accessToken, studentCondition._id);
    //console.log(studentCondition._id);
    //console.log(response);
    if (response.students.length > 0) {
      confirm({
        title: "Eliminando -> Condición",
        content: `No se puede eliminar '${studentCondition.description}' porque existen estudiantes asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateStudentCondition(studentCondition);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Condición",
        content: `¿Estás seguro que quieres eliminar a ${studentCondition.description? studentCondition.description : null}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteStudentConditionApi(accessToken, studentCondition._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadStudentConditions(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_studentcondition = (
    <div>
      {" "}
      <p>Editar Condicion</p>{" "}
    </div>
  );

  const desactivate_studentcondition = (
    <div>
      {" "}
      <p>Pasar a Condicion inactivos</p>{" "}
    </div>
  );

  const delete_studentcondition = (
    <div>
      {" "}
      <p>Eliminar Condicion</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "studentCondition_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, studentCondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "studentconditions", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_studentcondition}>
                <Button
                  className="button-edit"
                  onClick={() => editStudentCondition(studentCondition)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "studentconditions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_studentcondition}>
                <Button
                  className="button-studentcondition"
                  type="primary"
                  onClick={() => desactivateStudentCondition(studentCondition)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "studentconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_studentcondition}>
                <Button
                  className="button-studentcondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(studentCondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return studentConditionsActive.length > 0? `Total de registros: ${studentConditionsActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={studentConditionsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function StudentConditionsInactive(props) {
  const { studentConditionsInactive,
          setReloadStudentConditions,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateStudentCondition = (studentCondition) => {
    activateStudentConditionApi(accessToken, studentCondition._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStudentConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (studentCondition) => {
    let response = await getStudentsByStudentConditionApi(accessToken, studentCondition._id);
    if (response.students.length > 0) {
      ModalAntd.info({
        title: "Eliminando -> Condición",
        content:`No se puede eliminar '${studentCondition.description}' porque existen estudiantes asociados a él`,
        onOk(){},
      });
    }else {
      confirm({
        title: "Eliminando -> Condición",
        content: `¿Estás seguro que quieres eliminar a ${studentCondition.description? studentCondition.description : null}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteStudentConditionApi(accessToken, studentCondition._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadStudentConditions(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_studentcondition = (
    <div>
      {" "}
      <p>Pasar a Condicion activos</p>{" "}
    </div>
  );

  const delete_studentcondition = (
    <div>
      {" "}
      <p>Eliminar Condicion</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "studentCondition_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, studentCondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "studentconditions", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_studentcondition}>
                <Button
                  className="button-activate"
                  onClick={() => activateStudentCondition(studentCondition)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "studentconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "studentconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_studentcondition}>
                <Button
                  className="button-studentcondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(studentCondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return studentConditionsInactive.length > 0? `Total de registros: ${studentConditionsInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={studentConditionsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
