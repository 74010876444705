import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
    getEntryConditionsActiveApi, 
    //getEntryConditionsApi 
} from "../../../api/entryCondition";
import ListEntryConditions from "../../../components/Admin/EntryConditions/ListEntryConditions";


export default function FinalConditions() {
    const [entryConditionsActive, setEntryConditionsActive] = useState([]);
    const [entryConditionsInactive, setEntryConditionsInactive] = useState([]);
    const [reloadEntryConditions, setReloadEntryConditions ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getEntryConditionsActiveApi(token, true).then(response => {
            setEntryConditionsActive(response.entryConditions);
        });
        getEntryConditionsActiveApi(token, false).then(response => {
            setEntryConditionsInactive(response.entryConditions);
        });
        setReloadEntryConditions(false);
    }, [token, reloadEntryConditions]);

    return (
        <div className="entryconditions">
            <h3>Listado de Condición de Ingreso</h3>
            <ListEntryConditions entryConditionsActive={entryConditionsActive} entryConditionsInactive={entryConditionsInactive} setReloadEntryConditions={setReloadEntryConditions}
                />

        </div>
    );
}