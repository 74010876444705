import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getWeeksActiveApi } from "../../../api/week";
import ListWeeks from "../../../components/Admin/Weeks/ListWeeks";


export default function Weeks() {
    const [weeksActive, setWeeksActive] = useState([]);
    const [weeksInactive, setWeeksInactive] = useState([]);
    const [reloadWeeks, setReloadWeeks ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getWeeksActiveApi(token, true).then(response => {
            setWeeksActive(response.weeks);
        });
        getWeeksActiveApi(token, false).then(response => {
            setWeeksInactive(response.weeks);
        });
        setReloadWeeks(false);
    }, [token, reloadWeeks]);

    return (
        <div className="weeks">
            <h3>Listado de Semanas</h3>
            <ListWeeks weeksActive={weeksActive} weeksInactive={weeksInactive} setReloadWeeks={setReloadWeeks}
                />

        </div>
    );
}