import React, { useState, useEffect } from "react";
import { Form, InputNumber, Select, Button, Row, Col, notification } from "antd";
/*
import {
  UserOutlined,
  MailOutlined,
  EnvironmentOutlined,
  ContactsOutlined,
  DollarCircleOutlined,
  IdcardOutlined,
  TagOutlined,
  ScheduleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
*/
import { addSchoolYearApi } from "../../../../api/schoolYear";
import { getAccessToken } from "../../../../api/auth";
import { getStateSchoolYearsActiveApi } from "../../../../api/stateSchoolYear";

import "./AddSchoolYearForm.scss";

export default function AddSchoolYearForm(props) {
  const { setIsVisibleModal, setReloadSchoolYears } = props;
  const [listStateSchoolYearsActive, setListStateSchoolYearsActive] = useState([]);

  useEffect(() => {
    const accessToken = getAccessToken();
    getStateSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListStateSchoolYearsActive(response.stateSchoolYears);
    });
  }, []);

  const [inputs, setInputs] = useState({
    //schoolyear_code: "",
    year: "",
    stateSchoolYear: "",
  });

  const isFormValid = (e) => {
    let errorExists = false;
    if (!inputs.stateSchoolYear || !inputs.year) {
      notification["error"]({
        message: "Obligatorios: año y estado",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addSchoolYear = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addSchoolYearApi(accessToken, inputs)
        .then((response) => {
          // if(response.message === "ERR_CONNECTION_REFUSED" || response === "Failed to fetch" || response === undefined) {
          //     notification["error"]({
          //         message: "Servidor caido"
          //     });
          // } else if (response.message === "El proveedor ya existe") {
          //     notification["error"]({
          //         message: "El proveedor ya existe."
          //     });
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (!response.schoolYear) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              //message: response
              message: "Año lectivo creado",
            });
            setIsVisibleModal(false);
          }
          //console.log(response);
          setReloadSchoolYears(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-schoolyear-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addSchoolYear={addSchoolYear}
        listStateSchoolYearsActive={listStateSchoolYearsActive}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    addSchoolYear,
    listStateSchoolYearsActive,
  } = props;
  const { Option } = Select;

  return (
    <Form className="form-add" onSubmit={addSchoolYear}>
      {/*
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.statecourse_code}
              onChange={(e) => setInputs({ ...inputs, statecourse_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      */}

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span> Año
          </label>
          <Form.Item>
            <InputNumber
              // prefix={<EnvironmentOutlined />}
              placeholder="Año"
              value={inputs.year}
              onChange={(e) => setInputs({ ...inputs, year: e })}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span>Estado
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona un estado"
              value={inputs.stateSchoolYear}
              onChange={(e) => setInputs({ ...inputs, stateSchoolYear: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStateSchoolYearsActive
                ? listStateSchoolYearsActive.map((state) => {
                    return (
                      <Option key={state._id}>
                        {state.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addSchoolYear}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
