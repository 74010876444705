import React, { useState, useEffect } from "react";
import difference from "lodash/difference";
import {
  Form,
  Input,
  Divider,
  Transfer,
  Space,
  Switch,
  //Select,
  Button,
  Table,
  Tag,
  Row,
  Col,
  notification,
  Modal as ModalAntd,
  //Descriptions,
} from "antd";
/*
import {
  UserOutlined,
  MailOutlined,
  CarOutlined,
  ScheduleOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  DollarCircleOutlined,
  TeamOutlined,
  TagOutlined,
} from "@ant-design/icons";
*/
import {
  //insertStudentsByInscriptionIdApi,
  insertStudentByInscriptionIdApi,
} from "../../../../api/inscription";
import {
  getStudentsActiveApi,
  hasCoursesStudentApi,
  getStudentsBySchoolYearCycleApi,
} from "../../../../api/student";
import { getAccessToken } from "../../../../api/auth";
import { getCourseApi } from "../../../../api/course";
import { getTypeCoursesApi } from "../../../../api/typeCourse";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import { updateStateStudentCourse } from "../../../../utils/stateStudent";
import "./AddStudentsInscriptionForm.scss";

//const { TextArea, Search } = Input;
const { confirm } = ModalAntd;

export default function AddStudentInscriptionForm(props) {
  const { inscription, setIsVisibleModal, setReloadInscriptionCourses } = props;
  const [inscriptionData, setInscriptionData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [studentsAlreadyEnrolled, setStudentsAlreadyEnrolled] = useState([]);
  const [studentsInscriptions, setStudentsInscriptions] = useState([]);
  const [listStudentsActive, setListStudentsActive] = useState([]);
  const [listStateCoursesActive, setListStateCoursesActive] = useState([]);
  const [listTypeCoursesActive, setListTypeCoursesActive] = useState([]);
  const accessToken = getAccessToken();

  //trae los datos de la inscripcion
  useEffect(() => {
    setInscriptionData({
      inscription_code: inscription.inscription_code,
      date: inscription.date,
      course: inscription.course._id,
      students: inscription.students,
    });
  }, [inscription]);

  useEffect(() => {
    setCourseData({
      name: inscription.course.name,
      quota: inscription.course.quota,
      schoolYear: inscription.course.schoolYear,
      typeCourse: inscription.course.typeCourse,
    });
  }, [inscription]);

  //Para el caso que se requiera toda la lista de estudiantes activos
  // useEffect(() => {
  //   getStudentsActiveApi(accessToken, true).then((response) => {
  //     setListStudentsActive(response.students);
  //   });
  // }, []);

  useEffect(() => {
    getTypeCoursesApi(accessToken, true).then((response) => {
      setListTypeCoursesActive(response.typeCourses);
    });
  }, []);

  //Para el caso de solo estudiantes del ciclo del estudiante de acuerdo al ciclo lectivo del curso
  useEffect(() => {
    if (courseData.schoolYear) {
      getStudentsBySchoolYearCycleApi(accessToken, courseData.schoolYear).then(
        (response) => {
          let list = [...response.students];
          //Verifico los estudiantes inscriptos para saber si estan en la lista sino
          //los agrego para que aparezcan en la tabla
          inscriptionData.students.map((item) => {
            let result = list.findIndex((i) => i._id === item.student._id);
            if (result === -1) {
              list.push(item.student);
            }
            return true;
          });
          list.sort((a, b) => {
            return a.lastname.localeCompare(b.lastname);
          });
          setListStudentsActive(list);
        }
      );
    }
  }, [courseData]);

  useEffect(() => {
    if (inscriptionData.students) {
      let list = [];
      inscriptionData.students.map((i) => {
        if (i.student !== null) {
          list.push(i.student._id);
        }
      });
      setStudentsAlreadyEnrolled(list);
    }
  }, [inscriptionData.students]);

  useEffect(() => {
    setStudentsInscriptions([...studentsAlreadyEnrolled]);
  }, [studentsAlreadyEnrolled]);

  useEffect(() => {
    getStateCoursesActiveApi(accessToken, true).then((response) => {
      setListStateCoursesActive(response.stateCourses);
    });
  }, []);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    return errorExists;
  };

  const updateInscription = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const studentsInscriptionFilter = studentsInscriptions.filter(
        (student) => !studentsAlreadyEnrolled.includes(student)
      );

      let newStudents = [];
      studentsInscriptionFilter.map((obj) =>
        newStudents.push({ student: obj })
      );
      //consulto disponibilidad del curso
      const available = courseData.quota - inscription.students.length;
      if (
        available <= 4 &&
        available > 1 &&
        available - newStudents.length >= 0
      ) {
        confirm({
          title: "Agregando Estudiantes",
          // content: `Esta por asignar el último cupo del ${newCourse[0].name} ¿Deseas continuar?`,
          content: (
            <>
              <p>
                Quedan ({available ? available : ""}) vacantes de{" "}
                {courseData.name} para completar el cupo.
              </p>
              <p>Estudiantes seleccionados: {newStudents.length}</p>
              <p>¿Deseas continuar?</p>
              {/* <br /> */}
            </>
          ),
          okText: "Agregar",
          okType: "warning",
          cancelText: "Cancelar",
          onOk() {
            _insert(newStudents);
          },
        });
      } else if (available === 1 && available - newStudents.length >= 0) {
        confirm({
          title: "Agregando Estudiantes",
          // content: `Esta por asignar el último cupo del ${newCourse[0].name} ¿Deseas continuar?`,
          content: (
            <>
              <p>
                Quedan ({available ? available : ""}) vacantes de{" "}
                {courseData.name} para completar el cupo.
              </p>
              <p>Estudiantes seleccionados: {newStudents.length}</p>
              <p>¿Deseas continuar?</p>
              {/* <br /> */}
            </>
          ),
          okText: "Agregar",
          okType: "warning",
          cancelText: "Cancelar",
          onOk() {
            _insert(newStudents);
          },
        });
      } else if (available <= 0) {
        confirm({
          title: "Agregando Estudiantes",
          content: (
            <>
              <p>'{courseData.name}' no tiene cupo disponible.</p>
              <p>Estudiantes seleccionados: {newStudents.length}</p>
              <p>¿Deseas continuar?</p>
              {/* <br /> */}
            </>
          ),
          okText: "Agregar",
          okType: "danger",
          cancelText: "Cancelar",
          onOk() {
            _insert(newStudents);
          },
        });
      } else {
        if (available - newStudents.length < 0) {
          confirm({
            title: "Agregando Estudiantes",
            content: (
              <>
                <p>
                  '{courseData.name}' no tiene cupo disponible para la totalidad
                  de estudiantes seleccionados.
                </p>
                <p>Cupo disponible: {available ? available : ""}</p>
                <p>Estudiantes seleccionados: {newStudents.length}</p>
                <p>¿Deseas continuar?</p>
                {/* <br /> */}
              </>
            ),
            okText: "Agregar",
            okType: "danger",
            cancelText: "Cancelar",
            onOk() {
              _insert(newStudents);
            },
          });
        } else {
          confirm({
            title: "Agregando Estudiantes",
            content: (
              <>
                <p>
                  Quedan ({available ? available - newStudents.length : ""})
                  vacantes de '{courseData.name}' para completar el cupo.
                </p>
                <p>Cupo disponible: {available ? available : ""}</p>
                <p>Estudiantes seleccionados: {newStudents.length}</p>
                <p>¿Deseas continuar?</p>
                {/* <br /> */}
              </>
            ),
            okText: "Agregar",
            okType: "success",
            cancelText: "Cancelar",
            onOk() {
              _insert(newStudents);
            },
          });
        }
      }
    }
  };

  const setHasCoursesStudent = (student) => {
    //const accessToken = getAccessToken();

    hasCoursesStudentApi(accessToken, student.student, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        //setReloadStudents(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const _insert = async (newStudents) => {
    const token = getAccessToken();
    for await (let student of newStudents) {
      let result = await insertStudentByInscriptionIdApi(
        token,
        student,
        inscription._id
      );
      if (
        result.message === "ERR_CONNECTION_REFUSED" ||
        result.message === "Failed to fetch" ||
        result.message === undefined
      ) {
        notification["error"]({
          message: "Servidor caido",
        });
        setIsVisibleModal(true);
      } else if (result.code !== 200) {
        notification["error"]({
          message: result.message,
        });
        setIsVisibleModal(true);
      } else {
        notification["success"]({
          message: result.message, //el mensaje que viene del server
        });
        // setIsVisibleModal(false);
        //console.log(student.student);
        setHasCoursesStudent(student);
      }
    }
    _updateStateAndHoursStudent(inscription, newStudents);
    setReloadInscriptionCourses(true);
  };

  //si el curso esta finalizado, actualiza el estado del estudiante y sus horas.
  const _updateStateAndHoursStudent = async (inscription, students) => {
    //verifico si el curso esta finalizado para actualizar
    const token = getAccessToken();
    let responseCourse = await getCourseApi(token, inscription.course._id);
    let course = responseCourse.course ? responseCourse.course : null;
    let stateCourseFinish = listStateCoursesActive.filter(
      (i) => i.description.toLowerCase() === "finalizado"
    );
    if (
      stateCourseFinish !== undefined &&
      stateCourseFinish.length > 0 &&
      course !== null &&
      course.stateCourse &&
      course.stateCourse === stateCourseFinish[0]._id
    ) {
      notification["info"]({
        message:
          "El curso al que se quiere inscribir esta finalizado, se actualizará el estado de los estudiantes",
        duration: 10,
      });
      //actualizo el estado de todos los estudiantes agregados en el curso
      let result = await updateStateStudentCourse(token, course, students);
      if (result && result.state === true) {
        let messageCount = result.count
          ? " Cantidad de Estudiantes actualizados: " + result.count
          : null;
        notification["info"]({
          message: result.message + messageCount,
          duration: 5,
        });
        setIsVisibleModal(false);
        setReloadInscriptionCourses(true);
      } else {
        notification["err"]({
          message: result.message
            ? result.message
            : "Error al actualizar estado de el/los estudiante",
          duration: 5,
        });
      }
    } else {
      setIsVisibleModal(false);
    }
  };

  return (
    <div className="edit-inscription-form">
      <EditForm
        inscriptionData={inscriptionData}
        courseData={courseData}
        updateInscription={updateInscription}
        listStudentsActive={listStudentsActive}
        studentsInscriptions={studentsInscriptions}
        setStudentsInscriptions={setStudentsInscriptions}
        listTypeCoursesActive={listTypeCoursesActive}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    courseData,
    updateInscription,
    listStudentsActive,
    studentsInscriptions,
    setStudentsInscriptions,
    listTypeCoursesActive,
  } = props;

  const [listStudents, setListStudents] = useState([]);
  const [typeCourse, setTypeCourse] = useState(null);
  const [filterByCondition, setFilterByCondition] = useState(true);

  useEffect(() => {
    if (courseData && courseData.typeCourse) {
      let indexType = listTypeCoursesActive.findIndex(
        (i) => i._id === courseData.typeCourse
      );
      if (indexType !== -1) {
        setTypeCourse(listTypeCoursesActive[indexType].description);
      } else {
        setTypeCourse(null);
      }
    }
  }, [courseData, listStudentsActive]);

  useEffect(() => {
    let list = listStudentsActive.map((student) => {
      let filter = student.cycles
        ? student.cycles.filter(
            (i) => i.schoolYear._id === courseData.schoolYear
          )
        : [];
      if (filter.length === 0) {
        student.disabled = true;
      } else {
        //student.disabled=false;
        //verifico que el tipo de curso concuerde con la condicion del estudiante en el ciclo
        if (courseData.typeCourse && filterByCondition) {
          let indexType = listTypeCoursesActive.findIndex(
            (i) => i._id === courseData.typeCourse
          );
          let dataStudentCondition = filter[0].studentCondition
            ? filter[0].studentCondition.description
            : "";
          if (
            indexType !== -1 &&
            dataStudentCondition.includes(
              listTypeCoursesActive[indexType].description
            )
          ) {
            student.disabled = false;
          } else {
            student.disabled = true;
          }
        } else {
          student.disabled = false; //ya que el curso no esta restringido a ningun tipo
        }
      }
      return student;
    });
    list.sort((a, b) => {
      return a.disabled - b.disabled;
    });
    setListStudents(list);
  }, [listStudentsActive, filterByCondition]);

  const onChangeStudents = (nextTargetKeys) => {
    setStudentsInscriptions(nextTargetKeys);
  };

  const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
    <Transfer {...restProps}>
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === "left" ? leftColumns : rightColumns;

        const rowSelection = {
          getCheckboxProps: (item) => ({
            disabled: listDisabled || item.disabled,
          }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter((item) => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys,
        };

        return (
          <Table
            rowSelection={rowSelection}
            rowClassName={(record) => record.disabled && "disabled-row"}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? "none" : null }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) return;
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
            pagination={{
              size: "small",
              showSizeChanger: false,
              pageSize: 8,
            }}
          />
        );
      }}
    </Transfer>
  );

  const leftTableColumns = [
    {
      dataIndex: "lastname",
      title: "Apellido",
      // sorter:(a,b)=>a.lastname.localeCompare(b.lastname),
      // defaultSortOrder:"ascend",
    },
    {
      dataIndex: "name",
      title: "Nombre",
    },
    {
      dataIndex: "email",
      title: "E-mail",
    },
  ];
  const rightTableColumns = [
    // {
    //   dataIndex: 'student_code',
    //   title: 'Código',
    // },
    {
      dataIndex: "lastname",
      title: "Apellido",
      // sorter:(a,b)=>a.lastname.localeCompare(b.lastname),
      // defaultSortOrder:"ascend"
    },
    {
      dataIndex: "name",
      title: "Nombre",
    },
    {
      dataIndex: "email",
      title: "E-mail",
    },
  ];

  return (
    <Form className="form-edit" onSubmit={updateInscription}>
      {/* //onFinish={updateUser} */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          {/* <Descriptions
            title="Curso"
            size={"small"}
            // column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
          > */}
          <h3>Curso</h3>
          {/* <Descriptions.Item label="Nombre" size={"small"} span={4} > */}
          <p>
            {" "}
            Nombre: {courseData.name ? courseData.name : "sin datos"}
            {"  "}
            <br />
          </p>
          {/* </Descriptions.Item>
            <Descriptions.Item label="Cupos" size={"small"}> */}
          <Space split={<Divider type="vertical" />}>
            Cupo:{courseData.quota ? courseData.quota : "sin datos"}
            {/* </Descriptions.Item>
            <Descriptions.Item label="Disponibles" size={"small"}> */}
            Disponibles:{" "}
            {courseData.quota ? (
              courseData.quota - studentsInscriptions.length > 0 ? (
                <Tag color="green">
                  {courseData.quota - studentsInscriptions.length}
                </Tag>
              ) : (
                <Tag color="red">{0}</Tag>
              )
            ) : (
              "sin datos"
            )}{" "}
          </Space>

          {/* </Descriptions.Item>
          </Descriptions> */}
        </Col>
        <Col span={10} style={{ marginTop: 40 }}>
          <div>
            <Switch
              defaultChecked
              disabled={typeCourse !== null ? false : true}
              onChange={() => setFilterByCondition(!filterByCondition)}
            />
            <span>
              {typeCourse !== null ? ` Filtrar por "${typeCourse}"` : ""}
            </span>
          </div>
        </Col>
        <Col span={6} style={{ marginTop: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-submit"
            onClick={updateInscription}
          >
            Actualizar
          </Button>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <TableTransfer
            dataSource={listStudents}
            targetKeys={studentsInscriptions}
            disabled={false}
            showSearch={true}
            titles={["Estudiantes", "Estudiantes Inscriptos al curso"]}
            onChange={onChangeStudents}
            filterOption={(inputValue, item) =>
              (item.name &&
                item.name.toUpperCase().includes(inputValue.toUpperCase())) ||
              (item.name &&
                item.name.toUpperCase().includes(inputValue.toUpperCase())) ||
              (item.email &&
                item.email.toUpperCase().includes(inputValue.toUpperCase()))
            }
            leftColumns={leftTableColumns}
            rightColumns={rightTableColumns}
            rowKey={(item) => item._id}
            oneWay
            scroll={{ y: 250 }}
            size="small"
            listStyle={{ width: 100, minHeight: 400 }}
          />
        </Col>
      </Row>
      {/* <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateInscription}
        >
          Actualizar
        </Button>
      </Form.Item> */}
    </Form>
  );
}
