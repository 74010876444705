import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { addWorkloadApi } from "../../../../api/workload";
import { getAccessToken } from "../../../../api/auth";

import "./AddWorkloadForm.scss";

export default function AddWorkloadForm(props) {
  const { setIsVisibleModal, setReloadWorkloads } = props;

  const [inputs, setInputs] = useState({
    //workload_code: "",
    description: "",
    // absencesAllowed: 0,
  });

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorio: descripcion.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addWorkload = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      if(!disabledSubmit){
        setDisabledSubmit(true);
        const accessToken = getAccessToken();

        await addWorkloadApi(accessToken, inputs)
          .then((response) => {
            if (
              response === "ERR_CONNECTION_REFUSED" ||
              (response.message && response.message === "Failed to fetch") ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setDisabledSubmit(false);
            } else if (response.code !== 200) {
              notification["error"]({
                message: response.message,
              });
              setDisabledSubmit(false);
              setReloadWorkloads(true);
            } else {
              notification["success"]({
                message: "Carga horaria creada",
              });
              setIsVisibleModal(false);
              setReloadWorkloads(true);
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      }
    }
  };

  return (
    <div className="add-workload-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addWorkload={addWorkload}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addWorkload } = props;

  return (
    <Form className="form-add" onSubmit={addWorkload}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.workload_code}
              onChange={(e) => setInputs({ ...inputs, workload_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              //   placeholder="descripcion"
              value={inputs.description}
              onChange={(e) =>
                setInputs({ ...inputs, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Inasistencias permitidas</label>
          <Form.Item>
            <InputNumber
              value={inputs.absencesAllowed}
              onChange={(e) => setInputs({ ...inputs, absencesAllowed: e })}
              min={0}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addWorkload}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
