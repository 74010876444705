import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getTypeCoursesActiveApi } from "../../../api/typeCourse";
import ListTypeCourses from "../../../components/Admin/TypeCourses/ListTypeCourses";


export default function TypeCourses() {
    const [typeCoursesActive, setTypeCoursesActive] = useState([]);
    const [typeCoursesInactive, setTypeCoursesInactive] = useState([]);
    const [reloadTypeCourses, setReloadTypeCourses ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getTypeCoursesActiveApi(token, true).then(response => {
            setTypeCoursesActive(response.typeCourses);
        });
        getTypeCoursesActiveApi(token, false).then(response => {
            setTypeCoursesInactive(response.typeCourses);
        });
        setReloadTypeCourses(false);
    }, [token, reloadTypeCourses]);

    return (
        <div className="typecourses">
            <h3>Listado de Tipo de cursos</h3>
            <ListTypeCourses typeCoursesActive={typeCoursesActive} typeCoursesInactive={typeCoursesInactive} setReloadTypeCourses={setReloadTypeCourses}
                />

        </div>
    );
}