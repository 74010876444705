import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getDocTypesActiveApi } from "../../../api/docType";
import ListDocTypes from "../../../components/Admin/DocTypes/ListDocTypes";


export default function DocTypes() {
    const [docTypesActive, setDocTypesActive] = useState([]);
    const [docTypesInactive, setDocTypesInactive] = useState([]);
    const [reloadDocTypes, setReloadDocTypes ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getDocTypesActiveApi(token, true).then(response => {
            setDocTypesActive(response.docTypes);
        });
        getDocTypesActiveApi(token, false).then(response => {
            setDocTypesInactive(response.docTypes);
        });
        setReloadDocTypes(false);
    }, [token, reloadDocTypes]);

    return (
        <div className="doctypes">
             <h3>Listado de Tipos de documentos</h3>
            <ListDocTypes docTypesActive={docTypesActive} docTypesInactive={docTypesInactive} setReloadDocTypes={setReloadDocTypes}
                />
        </div>
    );
}