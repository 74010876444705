import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditTypeCourseForm from "../EditTypeCourseForm";
import AddTypeCourseForm from "../AddTypeCourseForm";
import { activateTypeCourseApi, deleteTypeCourseApi } from "../../../../api/typeCourse";
import { getCoursesByTypeCourseApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListTypeCourses.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListTypeCourses(props) {
  const {
    typeCoursesActive,
    typeCoursesInactive,
    setReloadTypeCourses,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewTypeCoursesActives, setViewTypeCoursesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [typeCoursesActiveFilter, setTypeCoursesActiveFilter] = useState(
    []
  );
  const [ typeCoursesInactiveFilter, setTypeCoursesInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (typeCoursesActive) {
      typeCoursesActive.forEach((element) => {
        if (
          element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.typecourse_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setTypeCoursesActiveFilter(listActive);
  }, [filterText, typeCoursesActive]);

  useEffect(() => {
    const listInactive = [];
    if (typeCoursesInactive) {
      typeCoursesInactive.forEach((element) => {
        if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.typecourse_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setTypeCoursesInactiveFilter(listInactive);
  }, [filterText, typeCoursesInactive]);

  const addTypeCourseModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Tipo de curso");
    setModalContent(
      <AddTypeCourseForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadTypeCourses={setReloadTypeCourses}
      />
    );
  };

  return (
    <div className="list-typecourses">
      <div className="list-typecourses__header">
        {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
        checkAction(permissionsActive, userToken.role, "typecourses", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-typecourses__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewTypeCoursesActives(!viewTypeCoursesActives)
                }
              />
              <span>
                {viewTypeCoursesActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
        checkAction(permissionsActive, userToken.role, "typecourses", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addTypeCourseModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
      checkAction(permissionsActive, userToken.role, "typecourses", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-typecourses__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de tipos de cursos
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewTypeCoursesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "typecourses", "view") || isAdmin(roleById) ? (
            <>
              <SpecialtiesActive
                typeCoursesActive={typeCoursesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadTypeCourses={setReloadTypeCourses}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "typecourses", "view") || isAdmin(roleById) ? (
            <>
              <TypeCoursesInactive
                typeCoursesInactive={typeCoursesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadTypeCourses={setReloadTypeCourses}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function SpecialtiesActive(props) {
  const {
    typeCoursesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadTypeCourses,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editTypeCourse = (typeCourse) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${typeCourse.description ? typeCourse.description : "sin descripción"}`
    );
    setModalContent(
      <EditTypeCourseForm
        typeCourse={typeCourse}
        setIsVisibleModal={setIsVisibleModal}
        setReloadTypeCourses={setReloadTypeCourses}
      />
    );
  };

  const desactivateTypeCourse = (typeCourse) => {
    activateTypeCourseApi(accessToken, typeCourse._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadTypeCourses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (typeCourse) => {
    let response = await getCoursesByTypeCourseApi(accessToken, typeCourse._id);
    if (response.courses.length > 0) {
      confirm({
        title: "Eliminando -> Tipo de curso",
        content: `No se puede eliminar '${typeCourse.description}' porque existen cursos asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateTypeCourse(typeCourse);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Tipo de curso",
        content: `¿Estás seguro que quieres eliminar a ${typeCourse.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteTypeCourseApi(accessToken, typeCourse._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadTypeCourses(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_typecourse = (
    <div>
      {" "}
      <p>Editar Tipo</p>{" "}
    </div>
  );

  const desactivate_typecourse = (
    <div>
      {" "}
      <p>Pasar a Tipos inactivos</p>{" "}
    </div>
  );

  const delete_typecourse = (
    <div>
      {" "}
      <p>Eliminar Tipo</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "typecourse_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, typeCourse) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "typecourses", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_typecourse}>
                <Button
                  className="button-edit"
                  onClick={() => editTypeCourse(typeCourse)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "typecourses", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_typecourse}>
                <Button
                  className="button-typecourse"
                  type="primary"
                  onClick={() => desactivateTypeCourse(typeCourse)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "typecourses", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_typecourse}>
                <Button
                  className="button-typecourse"
                  type="danger"
                  onClick={() => showDeleteConfirm(typeCourse)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={typeCoursesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function TypeCoursesInactive(props) {
  const { typeCoursesInactive,
          setReloadTypeCourses,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateTypeCourse = (typeCourse) => {
    activateTypeCourseApi(accessToken, typeCourse._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadTypeCourses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (typeCourse) => {
    let response = await getCoursesByTypeCourseApi(accessToken, typeCourse._id);
    if(response.courses.length > 0){
      ModalAntd.info({
        title: "Eliminando -> Estado de curso",
        content:`No se puede eliminar '${typeCourse.description}' porque existen cursos asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Tipo de curso",
        content: `¿Estás seguro que quieres eliminar a ${typeCourse.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteTypeCourseApi(accessToken, typeCourse._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadTypeCourses(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_typecourse = (
    <div>
      {" "}
      <p>Pasar a Tipos activos</p>{" "}
    </div>
  );

  const delete_typecourse = (
    <div>
      {" "}
      <p>Eliminar Tipo</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "typecourse_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, typeCourse) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "typecourses", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_typecourse}>
                <Button
                  className="button-activate"
                  onClick={() => activateTypeCourse(typeCourse)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "typecourses", "all") ||
          checkAction(permissionsActive, userToken.role, "typecourses", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_typecourse}>
                <Button
                  className="button-typecourse"
                  type="danger"
                  onClick={() => showDeleteConfirm(typeCourse)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return typeCoursesInactive.length > 0? `Total de registros: ${typeCoursesInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={typeCoursesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
