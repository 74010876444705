import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  Space,
  BackTop
} from "antd";

import {
  DeleteOutlined,
  //DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ArrowLeftOutlined

} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import AddCoursesInscriptionForm from "../AddCoursesInscriptionForm";
import {
  deleteStudentInscriptionByCourseIdApi,
  // getInscriptionsActiveApi
} from "../../../../api/inscription";
import { getCourseApi } from "../../../../api/course";
import { updateOwnHoursByStudentIdApi } from "../../../../api/student";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin, checkInscriptions } from "../../../../utils/checkUserPermission";
import { hasCoursesStudentApi } from "../../../../api/student";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import { listCoursesStudentPdf } from "./pdfCoursesStudent";
import { getInscriptionsByStudentApi } from "../../../../api/inscription";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from "moment";

import "./ListCoursesStudent.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

//Listado de los Cursos a los que esta inscripto el Estudiante
export default function ListStudentsCourse(props) {
  const { studentData, inscriptions, setReloadInscriptionCourses } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  // const [inscriptionData, setInscriptionData] = useState([]);
  const [listCourses, setListCourses] = useState([]);
  // const [viewStudentsCourseActives, setViewStudentsCourseActives] =useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [coursesFilter, setCoursesFilter] = useState([]);
  // const [inscriptionActiveCourses, setInscriptionActiveCourses] = useState([]);
  const [listStateCoursesActive, setListStateCoursesActive] = useState([]);
  const [listCourseExcel, setListCourseExcel]=useState([]);

  //para VOLVER HACIA ATRAS
  const history = useHistory();

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  //console.log("userToken", userToken);
  // console.log(useRouteProps());

  //EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const pdf_download = (
    <div>
      Descargar PDF
    </div>
  );

  const xls_download = (
    <div>
      Descargar Excel
    </div>
  );

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    getStateCoursesActiveApi(accessToken, true).then((response) => {
      setListStateCoursesActive(response.stateCourses);
    });
  }, []);

  useEffect(() => {
    // getInscriptionsActiveApi(accessToken, true).then(response => {
    //   setInscriptionActiveCourses(response.inscriptions);
    // });
    if(inscriptions.length > 0 && studentData.hasCourses ===false){
      setHasCoursesStudent(studentData);
    }
  }, [inscriptions]);



  useEffect(() => {
    if (inscriptions) {
      let newList = [];
      inscriptions.map((i) => {
        newList.push(i.course);
        //console.log(i.course);
        return true;
      });
      setListCourses(sortListBySchoolYears(newList));
      setListCourseExcel(sortListBySchoolYears(exportExcel(newList)));
    }
  }, [inscriptions]);

  useEffect(() => {
    const list = [];
    if (listCourses.length > 0) {
      listCourses.forEach((element) => {
        if (
          element.name.toUpperCase().includes(filterText.toUpperCase()) ||
          (element.course_code && element.course_code
            .toString()
            .toUpperCase()
            .includes(filterText.toUpperCase())) ||
          (element.schoolYear && element.schoolYear.year &&
            element.schoolYear.year.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase())) ||
          filterText === ""
        ) {
          list.push(element);
        }
      });
    }
    setCoursesFilter(list);
  }, [filterText, listCourses]);

  const addCoursesStudentModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar -> cursos");
    setModalWidth(1600);
    setModalContent(
      <AddCoursesInscriptionForm
        studentData={studentData}
        inscriptions={inscriptions}
        //inscriptionActiveCourses={inscriptionActiveCourses}
        setIsVisibleModal={setIsVisibleModal}
        setReloadInscriptionCourses={setReloadInscriptionCourses}
      />
    );
  };

  const setHasNoCoursesStudent = (student) => {
    //const accessToken = getAccessToken();

    hasCoursesStudentApi(accessToken, student._id, false)
      .then((response) => {
        // notification["success"]({
        //   message: response,
        // });
        //setReloadStudents(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const setHasCoursesStudent = (student) => {
    //const accessToken = getAccessToken();

    hasCoursesStudentApi(accessToken, student._id, true)
      .then((response) => {
        // notification["success"]({
        //   message: response,
        // });
        //setReloadStudents(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };


  const deleteCourseStudent = (record, updateOwnHourStudent) => {
    const token = getAccessToken();
    deleteStudentInscriptionByCourseIdApi(token, studentData, record._id).then(
      async (result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //verifico si hay que actualizar las horas del estudiante (True)
          if(updateOwnHourStudent)
          {
            notification["info"]({
              message: "El curso al que se elimino se encuentra finalizado, se actualizará la cantidad de horas del estudiante",
              duration:10
            });
            let result = await updateOwnHoursByStudentIdApi(token, studentData._id);
              if(result.code===200){
                notification["success"]({
                  message: "Actualización de las horas del estudiante correcta",
                  duration:5
                });
              }
          }
          //si no tiene mas inscripciones
          // console.log(inscriptionActiveCourses,studentData._id)
          let inscriptionsActive=await getInscriptionsByStudentApi(token,studentData._id);
          // if (checkInscriptions(inscriptionsActiveCourses, studentData._id) === false){
          if (inscriptionsActive && inscriptionsActive.inscriptions && inscriptionsActive.inscriptions.length === 0){
            // console.log("no tiene cursos, pasa a false", studentData);
            setHasNoCoursesStudent(studentData); //seteo hasCourses a false
          } else {
            // console.log("tiene cursos", studentData);
          }
           setReloadInscriptionCourses(true);
           setIsVisibleModal(false);
          //console.log("studentData", studentData);
        }
      }
    );
  };

  const sortListBySchoolYears=(list)=>{
    let newList = list.sort(function (a,b){
      if(a && a.schoolYear && a.schoolYear.year && b&& b.schoolYear && b.schoolYear.year){
        return a.schoolYear.year - b.schoolYear.year
      }
      else if(a && a.schoolYear && a.schoolYear.year){
        return -1;
      }else if(b && b.schoolYear && b.schoolYear.year ){
        return 1;
      }
      return 0;
    });
    return newList;
  }

  const exportExcel=(list)=>{
    let newList=[];
    if (list.length > 0) {
      list.map((i) => {
        let nameCourse = i.name ? i.name : "";
        // let days=i.days.map(function(elem){
        //     return elem.description;
        //   }).join(" ,");
        // let teachers=i.teachers.map(function(elem){
        //     return elem.description;
        // }).join(" -");
        newList.push({
          // schoolYear:i.schoolYear? i.schoolYear.year? i.schoolYear.year : "" : "", 
          name: nameCourse,
          // teachers:teachers,
          // days: days,
          // hour_start: i.hour_start? moment(i.hour_start,"HH:mm").isValid()?  moment(new Date(i.hour_start),"HH:mm").format("HH:mm") : i.hour_start : "",
          // hour_end: i.hour_end? moment(i.hour_end,"HH:mm").isValid()?  moment(new Date(i.hour_end),"HH:MM").format("HH:mm") : i.hour_end : "",
        });
        return null; //retorno algo por el warning
      });
    }
    return newList;
  }
  // const infoResult=(message)=>{
  //   Modal.info({
  //     title:'Actualizar Horas Estudiante',
  //     content:(
  //       <div>
  //         <p> {message} </p>
  //       </div>
  //     )
  //   })
  // }

  /*
  inscriptions.map((i) => {
    console.log(i);
  });
  */

  return (
    <div className="list-inscriptions">
      <div className="list-inscriptions__header">
        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
        isAdmin(roleById) ? (
          <>

            {/*VOLVER HACIA ATRAS*/}
            <Button type="primary" icon={<ArrowLeftOutlined/>} onClick={() => history.goBack()}>Atrás</Button>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={addCoursesStudentModal}
            >
              Agregar Cursos
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "courses", "all") ||
      checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
      isAdmin(roleById) ? (
        <>
          <div className="list-inscriptions__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
          </div>
        </>
      ) : (
        <></>
      )}

      {checkAction(permissionsActive, userToken.role, "courses", "all") ||
      checkAction(permissionsActive, userToken.role, "courses", "pdf") ||
      isAdmin(roleById) ? (
        <>
          <Popover content={pdf_download}>
            <Space align="center" style={{ marginBottom: 16 }}>
              <Button
                type="default"
                shape="round"
                onClick={() => {
                  listCoursesStudentPdf(
                    listCourses,
                    studentData.name + " " + studentData.lastname,
                    false
                  );
                }}
              >
                <FilePdfOutlined />
              </Button>
            </Space>
          </Popover>
        </>
      ) : (
        <></>
      )}

      <>
        &nbsp;
      </>

      {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "pdf") ||
        isAdmin(roleById) ? (
          <>
            <ExcelFile filename={'ListadoCursos-'+studentData.name+studentData.lastname} element={
              <Popover content={xls_download}>
                <Button
                  type="default"
                  shape="round"
                  >
                  <FileExcelOutlined />
                </Button>
              </Popover>
              }>
              <ExcelSheet data={listCourseExcel ? listCourseExcel: null} name="Cursos">
                {/* <ExcelColumn label="Ciclo Lectivo" value="schoolYear" /> */}
                <ExcelColumn label="Nombre" value="name" />
                {/* <ExcelColumn label="Dia" value="days" />
                <ExcelColumn label="Horario Inicio" value="hour_start" />
                <ExcelColumn label="Horario Fin" value="hour_end" />
                <ExcelColumn label="Docente" value="teachers" /> */}
              </ExcelSheet>
            </ExcelFile>
          </>
        ) : (
          <>
          </>
      )}

      <>
        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
        isAdmin(roleById) ? (
          <>
            <Courses
              courses={coursesFilter}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
              setReloadInscriptionCourse={setReloadInscriptionCourses}
              setModalWidth={setModalWidth}
              accessToken={accessToken}
              userToken={userToken}
              permissionsActive={permissionsActive}
              roleById={roleById}
              deleteCourseStudent={deleteCourseStudent}
              listStateCoursesActive={listStateCoursesActive}
              //showDeleteConfirm={showDeleteConfirm}
            />
          </>
        ) : (
          <></>
        )}
      </>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function Courses(props) {
  const {
    courses,
    deleteCourseStudent,
    listStateCoursesActive,
    // setReloadInscriptions,
    // setIsVisibleModal,
    // setModalTitle,
    // setModalWidth,
    // setModalContent,
    // accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props; //showDeleteConfirm

  const showDeleteConfirm = async (course) => {
     const token = getAccessToken();
     //verifico si el curso esta finalizado para actualizar
     let responseCourse= await getCourseApi(token, course._id);
     let resultCourse=responseCourse.course? responseCourse.course : null;
     let stateCourseFinish=listStateCoursesActive.filter(i=>i.description.toLowerCase() === "finalizado");
     if(stateCourseFinish!== undefined && stateCourseFinish.length > 0 && resultCourse!== null 
         && course.stateCourse && course.stateCourse === stateCourseFinish[0]._id){
          confirm({
            title: "Eliminando -> Estudiante del Curso",
            content: `El curso se encuentra finalizado. ¿Estás seguro que quieres eliminar la inscripción del estudiante al ${
              course ? course.name : "curso"
            }?`,
            okText: "Eliminar",
            okType: "danger",
            cancelText: "Cancelar",
            onOk() {
              deleteCourseStudent(course, true);
            },
          });
     }else{
      confirm({
        title: "Eliminando -> Estudiante del Curso",
        content: `¿Estás seguro que quieres eliminar la inscripción del estudiante al ${
          course ? course.name : "curso"
        }?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteCourseStudent(course, false);
        },
      });
     }
  };

  const delete_course = (
    <div>
      {" "}
      <p>Eliminar inscripción al curso</p>{" "}
    </div>
  );

  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "course_code",
    // },
    {
      title: "Ciclo",
      dataIndex: "schoolYear",
      render: (schoolYear) => (schoolYear ? schoolYear.year : null),
      sorter:(a,b)=>{
        if(a && a.schoolYear && a.schoolYear.year && b&& b.schoolYear && b.schoolYear.year){
          return a.schoolYear.year - b.schoolYear.year
        }
        else if(a && a.schoolYear && a.schoolYear.year){
          return -1;
        }else if(b && b.schoolYear && b.schoolYear.year ){
          return 1;
        }
        return 0;
      }
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, course) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_course}>
                <Button
                  className="button-inscription"
                  type="danger"
                  onClick={() => showDeleteConfirm(course)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return courses.length > 0? `Total de registros: ${courses.length}` : false}}
      pagination={false}
      dataSource={courses}
      columns={columns}
      rowKey="_id"
    />
  );
}
