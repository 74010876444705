import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getStudentConditionsActiveApi } from "../../../api/studentCondition";
import ListStudentConditions from "../../../components/Admin/StudentConditions/ListStudentConditions";


export default function StudentConditions() {
    const [studentConditionsActive, setStudentConditionsActive] = useState([]);
    const [studentConditionsInactive, setStudentConditionsInactive] = useState([]);
    const [reloadStudentConditions, setReloadStudentConditions ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getStudentConditionsActiveApi(token, true).then(response => {
            setStudentConditionsActive(response.studentConditions);
        });
        getStudentConditionsActiveApi(token, false).then(response => {
            setStudentConditionsInactive(response.studentConditions);
        });
        setReloadStudentConditions(false);
    }, [token, reloadStudentConditions]);

    return (
        <div className="studentconditions">
            <h3>Listado de Condicion del estudiante</h3>
            <ListStudentConditions studentConditionsActive={studentConditionsActive} studentConditionsInactive={studentConditionsInactive} setReloadStudentConditions={setReloadStudentConditions}
                />

        </div>
    );
}