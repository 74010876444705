import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  // Checkbox,
  TimePicker,
  Select,
  Button,
  Row,
  Col,
  notification,
  Modal,
  Spin,
  Alert
} from "antd";
import {
  UserOutlined,
  // MailOutlined,
  // CarOutlined,
  // ScheduleOutlined,
  // EnvironmentOutlined,
  // PhoneOutlined,
  // DollarCircleOutlined,
  TeamOutlined,
  TagOutlined
} from "@ant-design/icons";
import { updateCourseApi } from "../../../../api/course";
import { getTeachersActiveApi } from "../../../../api/teacher";
import { getTeacherCollabsActiveApi } from "../../../../api/teacherCollab";
import { getDurationsActiveApi } from "../../../../api/duration";
import { getDepartmentsActiveApi } from "../../../../api/department";
import { getPlacesActiveApi } from "../../../../api/place";
import { getWorkloadsActiveApi } from "../../../../api/workload";
import { getDaysActiveApi } from "../../../../api/day";
import { getWeeksActiveApi } from "../../../../api/week";
import { getTypeCoursesActiveApi } from "../../../../api/typeCourse";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import { getAccessToken } from "../../../../api/auth";
import {
  //emailValidation,
  //numberValidation,
  stringValidation
} from "../../../../utils/formValidation";
import { formatDateCalendar2, formatDateCalendar, formatDateHour, formatDateHourView, convertToDate } from "../../../../utils/formatDate";
import {updateStateStudentCourse} from "../../../../utils/stateStudent";

import moment from "moment";

import "./EditCourseForm.scss";

const { TextArea } = Input;

export default function EditCourseForm(props) {
  const { course, setIsVisibleModal, setReloadCourses } = props;
  const [courseData, setCourseData] = useState({});
  const [listTeachersActive, setListTeachersActive] = useState([]);
  const [listTeacherCollabsActive, setListTeacherCollabsActive] = useState([]);
  const [listDurationsActive, setListDurationsActive] = useState([]);
  const [listDepartmentsActive, setListDepartmentsActive] = useState([]);
  const [listPlacesActive, setListPlacesActive] = useState([]);
  const [listWorkloadsActive, setListWorkloadsActive] = useState([]);
  const [listDaysActive, setListDaysActive] = useState([]);
  const [listWeeksActive, setListWeeksActive] = useState([]);
  const [listTypeCoursesActive, setListTypeCoursesActive] = useState([]);
  const [listStateCoursesActive, setListStateCoursesActive] = useState([]);
  const [listSchoolYearsActive, setListSchoolYearsActive] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stateCourseActual, setStateCourseActual] = useState(null);
  const accessToken = getAccessToken();

  //trae los datos del curso
  useEffect(() => {
    let dataDays = [];
    let dataWeeks = [];
    let dataTeachers = [];
    let dataTeacherCollabs = [];

    if(course.days){
      course.days.map((i) => {dataDays.push(i._id)})
    }
    if(course.weeks){
      course.weeks.map((i) => {
      dataWeeks.push(i._id)});
    }
    if(course.teachers){
      course.teachers.map((i) => {
        dataTeachers.push(i._id)});
    }
    if(course.teacherCollabs){
      course.teacherCollabs.map((i) => {
      dataTeacherCollabs.push(i._id)})
    }

    setCourseData({
      course_code: course.course_code,
      name: course.name,
      quota: course.quota,
      typeCourse: course.typeCourse? course.typeCourse._id : null,
      schoolYear: course.schoolYear? course.schoolYear._id : null,
      stateCourse: course.stateCourse? course.stateCourse._id : null,
      hour_start: course.hour_start? moment(new Date(course.hour_start)).isValid()? formatDateHourView(course.hour_start): formatDateHourView(convertToDate(course.hour_start,"h:m")): null,
      hour_end: course.hour_end? moment(new Date(course.hour_end)).isValid()? formatDateHourView(course.hour_end): formatDateHourView(convertToDate(course.hour_end,"h:m")) : null,
      date_start: course.date_start? moment(course.date_start,"DD/MM/yyyy",true).isValid()? formatDateCalendar2(course.date_start): formatDateCalendar(course.date_start): null,
      date_end: course.date_end? moment(course.date_end,"DD/MM/yyyy",true).isValid()? formatDateCalendar2(course.date_end): formatDateCalendar(course.date_end): null,
      place: course.place ? course.place._id : null,
      duration: course.duration ? course.duration._id : null,
      department: course.department ? course.department._id : null,
      workload: course.workload ? course.workload._id : null,
      absencesAllowed: course.workload ? course.workload.absencesAllowed : null,
      teachers: dataTeachers,
      teacherCollabs: dataTeacherCollabs,
      days: dataDays,
      weeks: dataWeeks
    });
  }, [course]);

  
  useEffect(() => {
    setStateCourseActual(course.stateCourse? course.stateCourse._id : null);
  }, [course]);

  useEffect(() => {
    getTeachersActiveApi(accessToken, true).then((response) => {
      setListTeachersActive(response.teachers);
    });
  }, []);

  useEffect(() => {
    getTeacherCollabsActiveApi(accessToken, true).then((response) => {
      setListTeacherCollabsActive(response.teacherCollabs);
    });
  }, []);

  useEffect(() => {
    getDurationsActiveApi(accessToken, true).then((response) => {
      setListDurationsActive(response.durations);
    });
  }, []);

  useEffect(() => {
    getDepartmentsActiveApi(accessToken, true).then((response) => {
      setListDepartmentsActive(response.departments);
    });
  }, []);

  useEffect(() => {
    getPlacesActiveApi(accessToken, true).then((response) => {
      setListPlacesActive(response.places);
    });
  }, []);

  useEffect(() => {
    getWorkloadsActiveApi(accessToken, true).then((response) => {
      setListWorkloadsActive(response.workloads);
    });
  }, []);

  useEffect(() => {
    getDaysActiveApi(accessToken, true).then((response) => {
      setListDaysActive(response.days);
    });
  }, []);

  useEffect(() => {
    getWeeksActiveApi(accessToken, true).then((response) => {
      setListWeeksActive(response.weeks);
    });
  }, []);

  useEffect(() => {
    getTypeCoursesActiveApi(accessToken, true).then((response) => {
      setListTypeCoursesActive(response.typeCourses);
    });
  }, []);

  useEffect(() => {
    getStateCoursesActiveApi(accessToken, true).then((response) => {
      setListStateCoursesActive(response.stateCourses);
    });
  }, []);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListSchoolYearsActive(response.schoolYears);
    });
  }, []);


  const [formValid, setFormValid] = useState({
    // email: false,
  });

  const inputValidation = (e) => {
    const { type, name } = e.target;

    setCourseData({
      ...courseData,
      [name]: e.target.value,
    });

    // if (name === "documentNumber") {
    //   setFormValid({
    //     ...formValid,
    //     [name]: numberValidation(e.target),
    //   });
    // }

    if (name === "name") {
      setFormValid({
        ...formValid,
        [name]: stringValidation(e.target),
      });
    }
  };

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !courseData.name ||
      (course.hour_start && !courseData.hour_start) ||
      (course.hour_end && !courseData.hour_end) ||
      !courseData.workload ||
      !courseData.schoolYear
      // !courseData.duration ||
      // !courseData.place
    ) {
      //BUG
      notification["error"]({
        message: "Complete los campos obligatorios (*)",
      });
      errorExists = true;
    }
    if(new Date(courseData.hour_start) > new Date(courseData.hour_end)){
      notification["error"]({
        message: "La hora de comienzo debe ser menor a la de finalización",
      });
      errorExists = true;
    }
    else if (formValid.name === false) {
      //BUG
      notification["error"]({
        message: "Nombre inválido, verifique los carácteres ingresados",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateCourse = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      //console.log(courseData);
      const token = getAccessToken();
      // courseData.hour_end=formatDateHour(courseData.hour_end);
      // courseData.hour_start=formatDateHour(courseData.hour_start);
      let courseUpdate = courseData; //es let porque se va actualizando

      updateCourseApi(token, courseUpdate, course._id).then(async (result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          // Verifico el estado del curso, si el mismo es finalizado 
          // actualizo el estado de los estudiantes inscriptos 
          // let newUpdate=result.course? result.course : null;
          let newUpdate=courseUpdate;
          let stateCourseFinish=listStateCoursesActive.filter(i=>i.description.toLowerCase() === "finalizado");
          if(stateCourseFinish!== undefined && stateCourseFinish.length > 0 && newUpdate!== null 
            && newUpdate.stateCourse && newUpdate.stateCourse === stateCourseFinish[0]._id){
              //VERIFICO QUE EL CURSO NO ESTE YA FINALIZADO ANTERIORMENTE 
              //POR AHORA VERIFICO QUE SEA MAYOR A 2021 xq no hay inasistencias todavia cargadas
              if(course.stateCourse._id !== newUpdate.stateCourse && 
                course.schoolYear && course.schoolYear.year > 2021 ){
                setLoading(true);
                //actualizo el estados de los estudiantes del curso
                await updateStateStudentCourse(token, course).then(result=>{
                  if(result && result.state){
                    infoResult(result.err, result.message, result.count);
                  }
                  setIsVisibleModal(false);
                  setLoading(false);
                });
                setReloadCourses(true);
              }else{
                setIsVisibleModal(false);
                setReloadCourses(true);
              }
            // notification["success"]({
            //   message: "Estado de los Estudiantes Actualizados", //el mensaje que viene del server
            // });
          }else{
            setIsVisibleModal(false);
            setReloadCourses(true);
          }
          //console.log("respuesta: " + result.message);
        }
      });
    }
  };

  const infoResult=(err, message, count)=>{
    Modal.info({
      title:'Curso Finalizado',
      content:(
        <div>
          <p> {message} </p>
          <p> Cantidad de Estudiantes actualizados: {count}</p>
          <p> Cantidad de errores: {err} </p>
        </div>
      )
    })
  }

  return (
    <div className="edit-course-form">
      {loading? 
      <div style={{"textAlign":"center"}}> 
      <Alert 
        message="Curso Finalizado"
        description="Actualización del estado de los estudiantes."
        type="info"
      />
      <div style={{"margin":"auto", "paddingTop":'5%', "paddingBottom":'5%'}}><Spin style={{height:'50%'}} tip="en progreso"/> </div></div>
        : null}
      <EditForm
        courseData={courseData}
        setCourseData={setCourseData}
        updateCourse={updateCourse}
        listTeachersActive={listTeachersActive}
        listDurationsActive={listDurationsActive}
        listDepartmentsActive={listDepartmentsActive}
        listPlacesActive={listPlacesActive}
        listWorkloadsActive={listWorkloadsActive}
        listDaysActive={listDaysActive}
        listWeeksActive={listWeeksActive}
        listTypeCoursesActive={listTypeCoursesActive}
        listStateCoursesActive={listStateCoursesActive}
        listTeacherCollabsActive={listTeacherCollabsActive}
        listSchoolYearsActive={listSchoolYearsActive}
        inputValidation={inputValidation}
        stateCourseActual={stateCourseActual}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    courseData,
    setCourseData,
    updateCourse,
    listTeachersActive,
    listTeacherCollabsActive,
    listDurationsActive,
    listDepartmentsActive,
    listPlacesActive,
    listWorkloadsActive,
    listDaysActive,
    listWeeksActive,
    listTypeCoursesActive,
    listStateCoursesActive,
    listSchoolYearsActive,
    inputValidation,
    stateCourseActual
  } = props;
  const { Option } = Select;

  const [stateDisabled, setStateDisabled]=useState(false);

  useEffect(() => {
    if(courseData.workload){
      let absences=listWorkloadsActive.filter((i) => i._id === courseData.workload);
      if(absences.length > 0){
        setCourseData({...courseData, absencesAllowed:absences[0].absencesAllowed} );
      }
    }
  }, [courseData.workload]);

  useEffect(() => {
    if(listStateCoursesActive && listStateCoursesActive.length > 0 && stateCourseActual){
      let state=listStateCoursesActive.filter(i=>i._id===stateCourseActual);
      if(state&& state.length > 0 &&
        state[0].description.toLowerCase() === "finalizado"){
        setStateDisabled(true);
      } else{
        setStateDisabled(false);
      }
    }
  }, [stateCourseActual, listStateCoursesActive]);

  return (
    <Form className="form-edit" onSubmit={updateCourse}>
      {/* //onFinish={updateUser} */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span>Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={courseData.course_code}
              onChange={(e) =>
                setCourseData({ ...courseData, course_code: e })
              }
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Por favor ingrese un nombre",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="nombre"
              name="name"
              value={courseData.name}
              //onChange={(e) => setCourseData({ ...courseData, name: e.target.value }) }
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Tipo</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona tipo de curso"
              value={courseData.typeCourse}
              onChange={(e) => setCourseData({ ...courseData, typeCourse: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listTypeCoursesActive
                ? listTypeCoursesActive.map((type) => {
                    return (
                      <Option key={type._id}>
                        {type.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Estado</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              disabled={stateDisabled}
              showSearch
              placeholder="Selecciona el estado del curso"
              value={courseData.stateCourse}
              onChange={(e) => setCourseData({ ...courseData, stateCourse: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStateCoursesActive
                ? listStateCoursesActive.map((state) => {
                    return (
                      <Option key={state._id} >
                        {state.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha comienzo
          </label>
          <Form.Item>
            <Input
              type="date"
              placeholder="Fecha comienzo"
              value={courseData.date_start}
              onChange={(e) =>
                setCourseData({ ...courseData, date_start: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
        <label className="control-label">Fecha fin</label>
          <Form.Item>
            <Input
              type="date"
              placeholder="Fecha fin"
              value={courseData.date_end}
              onChange={(e) =>
                setCourseData({ ...courseData, date_end: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Hora desde
          </label>
          <Form.Item>
          <TimePicker
              prefix={<UserOutlined />}
              placeholder="Hora desde"
              value={courseData.hour_start}
              format="HH:mm"
              minuteStep={10}
              onChange={(e) =>
                setCourseData({ ...courseData, hour_start: e})
              }
              style={{width:"100%"}}
              showNow={false}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Hora hasta
          </label>
          <Form.Item>
          <TimePicker
              prefix={<UserOutlined />}
              placeholder="Hora hasta"
              value={courseData.hour_end}
              format="HH:mm"
              minuteStep={10}
              onChange={(e) =>
                setCourseData({ ...courseData, hour_end: e})
              }
              style={{width:"100%"}}
              showNow={false}
            />
          </Form.Item>
        </Col>
        </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Cupos</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad de cupos"
              name="quota"
              value={courseData.quota}
              onChange={(e) => setCourseData({ ...courseData, quota: e })}
              style={{ width: "90%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Duración</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona la duración"
              value={courseData.duration}
              onChange={(e) => setCourseData({ ...courseData, duration: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDurationsActive
                ? listDurationsActive.map((duration) => {
                    return (
                      <Option key={duration._id}>
                        {duration.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Docente</label>
          <Form.Item>
            <Select
              showSearch
              mode="multiple"
              placeholder="Seleccione docentes"
              value={courseData.teachers}
              onChange={(e) => setCourseData({ ...courseData, teachers: e })}
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listTeachersActive
                ? listTeachersActive.map((teacher) => {
                    return (
                      <Option key={teacher._id}>{teacher.description}</Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Colaboradores</label>
          <Form.Item>
            <Select
              showSearch
              mode="multiple"
              placeholder="Selecciona colaborador"
              value={courseData.teacherCollabs}
              onChange={(e) => setCourseData({ ...courseData, teacherCollabs: e })}
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listTeacherCollabsActive
                ? listTeacherCollabsActive.map((collab) => {
                    return (
                      <Option key={collab._id}>{collab.description}</Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={6}>
          <label className="control-label">Departamento</label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona el departamento"
              value={courseData.department}
              onChange={(e) => setCourseData({ ...courseData, department: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDepartmentsActive
                ? listDepartmentsActive.map((department) => {
                    return (
                      <Option key={department._id}>{department.description}</Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Lugar</label>
          <Form.Item>
            <Select
              prefix={<TeamOutlined />}
              showSearch
              placeholder="Selecciona un lugar"
              value={courseData.place}
              onChange={(e) => setCourseData({ ...courseData, place: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listPlacesActive
                ? listPlacesActive.map((place) => {
                    return (
                      <Option key={place._id}>
                        {place.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span>Carga horaria
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona la carga de trabajo"
              value={courseData.workload}
              onChange={(e) => setCourseData({ ...courseData, workload: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listWorkloadsActive
                ? listWorkloadsActive.map((workload) => {
                    return (
                      <Option key={workload._id}>
                        {workload.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>

        {courseData.workload?
        <Col span={6}>
          <label className="control-label">Inasistencias Permitidas</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              value={courseData.absencesAllowed}
              onChange={(e) => setCourseData({ ...courseData, absencesAllowed: e })}
              style={{ width: "100%", color:"black",
              backgroundColor: "white"}}
              disabled={true}
            />
          </Form.Item>
        </Col>
      :null}
        </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Semanas</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              mode="multiple"
              placeholder="Selecciona las semanas en que se dicta el curso"
              value={courseData.weeks}
              onChange={(e) => setCourseData({ ...courseData, weeks: e })}
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listWeeksActive
                ? listWeeksActive.map((week) => {
                    return (
                      <Option key={week._id}>
                        {week.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Días</label>
          <Form.Item>
            <Select
              showSearch
              mode="multiple"
              placeholder="Selecciona los dias en que se dicta el curso"
              value={courseData.days}
              onChange={(e) => setCourseData({ ...courseData, days: e })}
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDaysActive
                ? listDaysActive.map((day) => {
                    return (
                      <Option key={day._id}>
                        {day.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <span className="control-required">*</span>
          <label className="control-label">Año lectivo</label>
          <Form.Item>
          <Select
              showSearch
              placeholder="Selecciona el año en que se dicta el curso"
              name="schoolYear"
              value={courseData.schoolYear}
              onChange={(e) =>
                setCourseData({ ...courseData, schoolYear: e })
              }
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSchoolYearsActive
                ? listSchoolYearsActive.map((schoolYear) => {
                    return (
                      <Option key={schoolYear._id}>
                        {schoolYear.year}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateCourse}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
