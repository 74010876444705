import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  //Modal as ModalAntd,
  Table,
  Space,
  BackTop,
  Popover,
  Divider,
  Progress,
  Row,
  Col
} from "antd";

//import { Link } from "react-router-dom";
import {
  UpOutlined,
  DownOutlined,
  //CheckOutlined, FileDoneOutlined,
  FileExcelOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  RightOutlined,
  LeftOutlined,
  //FileExcelOutlined,
  //PlusOutlined, ProfileOutlined, ContainerOutlined,
} from "@ant-design/icons";

import Modal from "../../../Modal";
// import EditStudentForm from "../EditStudentForm";
// import AddStudentForm from "../AddStudentForm";
import AdvancedSearchCourseForm from "./AdvancedSearchForm";
import { listStudentsPdf } from "../ListStudents/pdfStudents";
import {
  reportCyclesStudent,
  reportHoursStudent,
  reportAdviserStudent,
  reportActivitiesAndHoursStudentByYear,
  reportAcademicRecordStudentExcel
} from "./pdfStudentsReports";
import { getInscriptionsByStudentReportApi } from "../../../../api/inscription";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getDepartmentsApi } from "../../../../api/department";
import { getAbsencesByStudentApi } from "../../../../api/absence";
import jwtDecode from "jwt-decode";
//import useRouteProps from "../../../../hooks/UseRouteProps"
//import { PluralizeEsp } from "../../../../utils/convert";
import ReactExport from "@ibrahimrahmani/react-export-excel";

import "./ListStudentsReports.scss";
import "./ProgressBar.scss";
import ProgressBar from "../../../../utils/progressBar";

//const { confirm } = ModalAntd;
const { Search } = Input;
//const {  Progress  } = antd;
//var inicio = Date.now();
//var fin = "";

export default function ListStudentsReports(props) {
  const {
    setPaginationActive,
    setPaginationInactive,
    studentsActive,
    studentsInactive,
    setReloadStudents,
    //inscriptionActiveCourses, inscriptionInactiveCourses,
    studentsHasCourses,
    studentsHasNoCourses,
    studentsActiveExport,
    studentsInactiveExport,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewStudentsActives, setViewStudentsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  //filtros para busqueda avanzada
  const [filters, setFilters] = useState([]);
  const [studentsActiveFilter, setStudentsActiveFilter] = useState([]);
  const [studentsInactiveFilter, setStudentsInactiveFilter] = useState([]);
  const [exportActivePdf, setExportActivePdf] = useState([]);
  const [exportActiveExcel, setExportActiveExcel] = useState([]);
  const [exportInactivePdf, setExportInactivePdf] = useState([]);
  const [exportInactiveExcel, setExportInactiveExcel] = useState([]);

  const [propsPaginationActive, setPropsPaginationActive] = useState({});
  const [propsPaginationInactive, setPropsPaginationInactive] = useState({});

  const [expand, setExpand] = useState(false);
  const [expandDownload, setExpandDownload] = useState(false);
  // const [loadingHistory, setLoadingHistory] = useState (false);
  // const [exportHistory, setExportHistory]= useState([]);
  //const [nuevaLista, setNuevaLista] = useState([]);

  //const [state, setState] = useState({});

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //const { id } = props.location.state ? props.location.state : false;
  //console.log("userToken", userToken);
  // console.log(useRouteProps());

  //EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const pdf_download = <div>Descargar lista en PDF</div>;

  const xls_download = <div>Descargar lista en Excel</div>;

  const report_student = (
    <div style={{ "alignItems": "center", "textAlign": "center" }}>
      {" "}
      <p>Reporte del estudiante</p>
      {"Descarga PDF"}
    </div>
  );

  const report_hours = (
    <div style={{ "alignItems": "center", "textAlign": "center" }}>
      {" "}
      <p>Informe Horas Cursadas</p>
      {"Descarga PDF"}
    </div>
  );

  const report_adviser = (
    <div style={{ "alignItems": "center", "textAlign": "center" }}>
      {" "}
      <p>Informe del Consejero</p>
      {"Descarga PDF"}
    </div>
  );

  const report_activitiesandhours = (
    <div style={{ "alignItems": "center", "textAlign": "center" }}>
      {" "}
      <p>Actividades Cursadas y horas segmentadas por año</p>
      {"Descarga PDF"}
    </div>
  );

  useEffect(() => {
    if (studentsActive) {
      setPropsPaginationActive({
        total: studentsActive.totalDocs,
        //sorter:(a,b) => a.lastname.localeCompare(b.lastname),
        pageSize: studentsActive.limit,
        defaultCurrent: studentsActive.page,
        current: studentsActive.page,
      });
    }
  }, [studentsActive]);

  useEffect(() => {
    if (studentsInactive) {
      setPropsPaginationInactive({
        total: studentsInactive.totalDocs,
        pageSize: studentsInactive.limit,
        defaultCurrent: studentsInactive.page,
        current: studentsInactive.page,
      });
    }
  }, [studentsInactive]);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    if (studentsActive && studentsActive.docs) {
      setStudentsActiveFilter(studentsActive.docs);
    }
  }, [studentsActive]);

  useEffect(() => {
    if (studentsInactive && studentsInactive.docs) {
      setStudentsInactiveFilter(studentsInactive.docs);
    }
  }, [studentsInactive]);

  useEffect(() => {
    if (studentsActiveExport) {
      setExportActivePdf(studentsActiveExport);
      setExportActiveExcel(formatDataExportExcel(studentsActiveExport));
    }
  }, [studentsActiveExport]);

  useEffect(() => {
    if (studentsInactiveExport) {
      setExportInactivePdf(studentsInactiveExport);
      setExportInactiveExcel(formatDataExportExcel(studentsInactiveExport));
    }
  }, [studentsInactiveExport]);

  useEffect(() => {
    if (filterText) {
      setPaginationActive({
        page: 1,
        state: 1,
        filter: filterText,
        filters: filters,
      });
      setPaginationInactive({
        page: 1,
        state: 2,
        filter: filterText,
        filters: filters,
      });
    } else if (filterText === "") {
      setPaginationActive({ page: 1, state: 1, filters: filters });
      setPaginationInactive({ page: 1, state: 2, filters: filters });
    }
    setExportActiveExcel([]);
    setExportInactiveExcel([]);
    setExportActivePdf([]);
    setExportInactivePdf([]);
  }, [filterText, filters]);

  //This mimics a delay from the api
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      setIsVisibleModal(false);
    }, 10000);
  }, []);

  const formatDataExportExcel = (students) => {
    let exportData = [];
    students.map((student) => {
      let studentData = {
        name: student.name,
        lastname: student.lastname ? student.lastname : "",
        phone: student.phone ? student.phone : "",
        email: student.email ? student.email : "",
        specialty: student.specialty ? student.specialty.description : "",
        address: student.address ? student.address : "",
        city: student.city ? student.city : "",
        country: student.country ? student.country : "",
        ownHours: student.ownHours ? student.ownHours : "",
        finalCondition: student.finalCondition
          ? student.finalCondition.description
          : "",
        adviser: student.adviser
          ? student.adviser.lastname + " " + student.adviser.name
          : "",
        yearAdmission: student.yearAdmission ? student.yearAdmission : "",
        yearGraduation: student.yearGraduation ? student.yearGraduation : "",
        entryCondition: student.entryCondition
          ? student.entryCondition.description
          : "",
        observation: student.observation ? student.observation : "",
      };
      exportData.push(studentData);
      return null; //retorno algo por warnings
    });
    return exportData;
  };

  const progressBarModal = (e) => {
    e.preventDefault();
    setIsVisibleModal(true);
    setModalTitle("Cargando...");
    setModalWidth(500);
    setModalContent(
      <div>
        <h2>Cargando</h2>
        <ProgressBar
          //isLoading={true}
          isLoading={isLoading}
          //component={StudentsActive}
          percent={100}
          type="line"
          strokeColor="green"
          status="normal"
          size={"medium"}
          showInfo={false}
          setIsVisibleModal={setIsVisibleModal}
        />
      </div>
    );
  };

  const getLastCondition = (cycles) => {
    //Obtener la ultima condicion de acuerdo al ultimo año u año mas proximo
    if (cycles.length > 0) {
      let result = cycles.sort((a, b) => b.schoolYear.year - a.schoolYear.year);
      if (result && result.length > 0) {
        return result[0].studentCondition.description;
      } else {
        return null;
      }
    }
    return null;
  };

  const hoursStudentPdf = (student) => {
    const accessToken = getAccessToken();

    getInscriptionsByStudentReportApi(accessToken, student._id, false)
      .then((response) => {
        if (response.inscriptions) {
          getDepartmentsApi(accessToken)
            .then((result) => {
              reportHoursStudent(
                student,
                response.inscriptions,
                result.departments,
                false
              );
              // console.log(teacher, response.courses);
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const selectedAcademicRecord = (students) => {
    setIsVisibleModal(true);
    setModalTitle(`Reporte Historial Académico`);
    setModalContent(
      <StudentAcademicRecord
        students={students}
        setIsVisibleModal={setIsVisibleModal}
      />
    );
  };

  const adviserStudentPdf = (student) => {
    const accessToken = getAccessToken();
    getInscriptionsByStudentReportApi(accessToken, student._id)
      .then((response) => {
        if (response.inscriptions) {
          getAbsencesByStudentApi(accessToken, student._id).then((result) => {
            reportAdviserStudent(
              student,
              response.inscriptions,
              result.absences,
              false
            );
          });
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const activitiesAndHoursStudentPdf = (student) => {
    const accessToken = getAccessToken();
    getInscriptionsByStudentReportApi(accessToken, student._id)
      .then((response) => {
        if (response.inscriptions) {
          reportActivitiesAndHoursStudentByYear(
            student,
            response.inscriptions,
            false
          );
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  // const historyStudents = async(studentsData) => {
  //   setLoadingHistory(true);
  //   let data=[];
  //   const accessToken = getAccessToken();

  //   for await (const item of studentsData){
  //     let result = await getInscriptionsByStudentReportApi(accessToken,item._id);
  //     if(result && result.inscriptions){
  //       data.push(reportHistoryStudentExcel(item, result.inscriptions));
  //       console.log(data);
  //     }
  //   }
  //   setLoadingHistory(false);
  //   setExportHistory(data);
  // };

  return (
    <div>
      {/*
      <Button type="primary" onClick={progressBarModal}>
        cargar
      </Button>
      */}

      <div className="list-students">
        <div className="list-students__header">
          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "students",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <div className="list-students__header-switch">
                <Switch
                  defaultChecked
                  onChange={() => setViewStudentsActives(!viewStudentsActives)}
                />
                <span>{viewStudentsActives ? "Activos" : "Inactivos"}</span>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        {checkAction(permissionsActive, userToken.role, "students", "all") ||
        checkAction(permissionsActive, userToken.role, "students", "view") ||
        isAdmin(roleById) ? (
          <>
            <div className="list-students__search">
              <Search
                className="Buscar"
                allowClear
                placeholder="Buscar"
                onSearch={(e) => setFilterText(e)}
                enterButton
              />
              <h2>
                <Button
                  type="link"
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    setExpand(!expand);
                  }}
                >
                  {expand ? <UpOutlined /> : <DownOutlined />} Búsqueda Avanzada
                  (filtros)
                </Button>
                {expand ? (
                  <div>
                    {/* busqueda avanzada */}
                    <AdvancedSearchCourseForm
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </div>
                ) : null}
              </h2>
            </div>
          </>
        ) : (
          <></>
        )}

        {viewStudentsActives ? (
          <>
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(permissionsActive, userToken.role, "students", "pdf") ||
            isAdmin(roleById) ? (
              <>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginRight: 10, marginBottom: 10 }}
                  shape="round"
                  size={"small"}
                  onClick={() => {
                    setExpandDownload(!expandDownload);
                  }}
                >
                  {<DownloadOutlined />} Descargas{" "}
                  {!expandDownload ? <RightOutlined /> : <LeftOutlined />}
                </Button>
                {expandDownload ? (
                  <>
                    <Space
                      split={<Divider type="vertical" />}
                      style={{ marginBottom: 10 }}
                    >
                      <Popover content={pdf_download}>
                        <Button
                          type="default"
                          shape="round"
                          size="small"
                          disabled={
                            exportActivePdf
                              ? exportActivePdf.length > 0
                                ? false
                                : true
                              : true
                          }
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            listStudentsPdf(exportActivePdf, false);
                          }}
                        >
                          <FilePdfOutlined /> Listado Pdf
                        </Button>
                      </Popover>

                      <>
                        <ExcelFile
                          filename={"ListadoEstudiantes"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                shape="round"
                                size="small"
                                disabled={
                                  exportActiveExcel
                                    ? exportActiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Listado xls
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportActiveExcel}
                            name="Estudiantes"
                          >
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn label="Teléfono" value="phone" />
                            <ExcelColumn label="Correo" value="email" />
                            <ExcelColumn
                              label="Especialidad"
                              value="specialty"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </>
                      {/* Datos de estudiantes xls*/}
                      <>
                        <ExcelFile
                          filename={"ListadoFichaEstudiantes"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                size={"small"}
                                shape="round"
                                disabled={
                                  exportActiveExcel
                                    ? exportActiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Ficha Estudiantes
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportActiveExcel}
                            name="Estudiantes"
                          >
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn
                              label="Tipo Documento"
                              value="docType"
                            />
                            <ExcelColumn
                              label="Documento"
                              value="documentNumber"
                            />
                            <ExcelColumn label="Teléfono" value="phone" />
                            <ExcelColumn label="Correo" value="email" />
                            {/* <ExcelColumn label="Direccion" value="address" /> */}
                            <ExcelColumn label="Ciudad" value="city" />
                            <ExcelColumn label="Pais" value="country" />
                            <ExcelColumn
                              label="Especialidad"
                              value="specialty"
                            />
                            <ExcelColumn label="Consejero" value="adviser" />
                            <ExcelColumn
                              label="Año ingreso"
                              value="yearAdmission"
                            />
                            <ExcelColumn
                              label="Año egreso"
                              value="yearGraduation"
                            />
                            <ExcelColumn
                              label="Condicion ingreso"
                              value="entryCondition"
                            />
                            <ExcelColumn
                              label="Condicion final"
                              value="finalCondition"
                            />
                            <ExcelColumn
                              label="Observaciones"
                              value="observation"
                            />
                            {/* <ExcelColumn label="Pais" value="country" /> */}
                          </ExcelSheet>
                        </ExcelFile>
                      </>
                      {/* Historial estudiantes xls */}
                      <Popover content={xls_download}>
                        <Button type="default"
                        size={"small"} 
                        shape="round"
                        disabled={exportActivePdf? exportActivePdf.length > 0? false : true : true}
                        onClick={() => selectedAcademicRecord(exportActivePdf)}
                        >
                          <FileExcelOutlined /> Historial Académico
                        </Button>
                      </Popover>
                      {/* {(exportHistory && exportHistory.length > 0)?
                (<>
                  <ExcelFile
                    filename={"ListadoHistorialEstudiantes"}
                    element={ 
                      <Popover content={xls_download}>
                        <Button type="default"
                        size={"small"} 
                        shape="round"
                        // onClick={() => {historyStudents(studentsActiveExport)}}
                        disabled={exportActiveExcel? exportActiveExcel.length > 0? false : true : true}
                        >
                          <DownloadOutlined /> Historial Academico
                        </Button>
                      </Popover>
                    }
                  > 
                     <ExcelSheet data={exportHistory} name="Estudiantes">
                      <ExcelColumn label="Apellido" value="lastname" />
                      <ExcelColumn label="Nombre" value="name" />
                      <ExcelColumn label="Condicion" value="finalCondition" />
                      <ExcelColumn label="Horas Cursadas" value="ownHours" />
                      <ExcelColumn label="Años Cursadas" value="yearsCoursed" />
                    </ExcelSheet>
                  </ExcelFile>
                </>) : (<Popover content={xls_download}>
                        <Button type="default"
                        size={"small"} 
                        shape="round"
                        onClick={() => {historyStudents(studentsActiveExport)}}
                        loading={loadingHistory}
                        >
                          <FileExcelOutlined /> Historial Academico
                        </Button>
                      </Popover>)} */}
                    </Space>
                  </>
                ) : null}
              </>
            ) : (
              <></>
            )}

            <>&nbsp;</>

            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "view"
            ) ||
            isAdmin(roleById) ? (
              <>
                <StudentsActive
                  studentsActive={studentsActiveFilter}
                  propsPagination={propsPaginationActive}
                  setIsVisibleModal={setIsVisibleModal}
                  setModalTitle={setModalTitle}
                  setModalContent={setModalContent}
                  setReloadStudents={setReloadStudents}
                  setModalWidth={setModalWidth}
                  accessToken={accessToken}
                  userToken={userToken}
                  permissionsActive={permissionsActive}
                  roleById={roleById}
                  setPagination={setPaginationActive}
                  filterText={filterText}
                  filters={filters}
                  getLastCondition={getLastCondition}
                  //inscriptionActiveCourses={inscriptionActiveCourses}
                  studentsHasCourses={studentsHasCourses}
                  studentsHasNoCourses={studentsHasNoCourses}
                  progressBarModal={progressBarModal}
                  report_student={report_student}
                  report_hours={report_hours}
                  report_adviser={report_adviser}
                  report_activitiesandhours={report_activitiesandhours}
                  hoursStudentPdf={hoursStudentPdf}
                  adviserStudentPdf={adviserStudentPdf}
                  activitiesAndHoursStudentPdf={activitiesAndHoursStudentPdf}
                />
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(permissionsActive, userToken.role, "students", "pdf") ||
            isAdmin(roleById) ? (
              <>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginRight: 10, marginBottom: 10 }}
                  shape="round"
                  size={"small"}
                  onClick={() => {
                    setExpandDownload(!expandDownload);
                  }}
                >
                  {<DownloadOutlined />} Descargas{" "}
                  {!expandDownload ? <RightOutlined /> : <LeftOutlined />}
                </Button>
                {expandDownload ? (
                  <>
                    <Space
                      split={<Divider type="vertical" />}
                      style={{ marginBottom: 10 }}
                    >
                      <Popover content={pdf_download}>
                        <Button
                          type="default"
                          shape="round"
                          size="small"
                          style={{ marginRight: 10 }}
                          disabled={
                            exportInactivePdf
                              ? exportInactivePdf.length > 0
                                ? false
                                : true
                              : true
                          }
                          onClick={() => {
                            listStudentsPdf(exportInactivePdf, false);
                          }}
                        >
                          <FilePdfOutlined /> Listado Pdf
                        </Button>
                      </Popover>

                      <>
                        <ExcelFile
                          filename={"ListadoEstudiantesInactivos"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                size="small"
                                disabled={
                                  exportInactiveExcel
                                    ? exportInactiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                                shape="round"
                              >
                                <FileExcelOutlined /> Listado xls
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportInactiveExcel}
                            name="Estudiantes"
                          >
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn label="Teléfono" value="phone" />
                            <ExcelColumn label="Correo" value="email" />
                            <ExcelColumn
                              label="Especialidad"
                              value="specialty"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </>

                      {/* Datos de estudiantes xls*/}
                      <>
                        <ExcelFile
                          filename={"ListadoFichaEstudiantes"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                size={"small"}
                                shape="round"
                                disabled={
                                  exportInactiveExcel
                                    ? exportInactiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Ficha Estudiantes
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportInactiveExcel}
                            name="Estudiantes"
                          >
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn
                              label="Tipo Documento"
                              value="docType"
                            />
                            <ExcelColumn
                              label="Documento"
                              value="documentNumber"
                            />
                            <ExcelColumn label="Teléfono" value="phone" />
                            <ExcelColumn label="Correo" value="email" />
                            {/* <ExcelColumn label="Direccion" value="address" /> */}
                            <ExcelColumn label="Ciudad" value="city" />
                            <ExcelColumn label="Pais" value="country" />
                            <ExcelColumn
                              label="Especialidad"
                              value="specialty"
                            />
                            <ExcelColumn label="Consejero" value="adviser" />
                            <ExcelColumn
                              label="Año ingreso"
                              value="yearAdmission"
                            />
                            <ExcelColumn
                              label="Año egreso"
                              value="yearGraduation"
                            />
                            <ExcelColumn
                              label="Condicion ingreso"
                              value="entryCondition"
                            />
                            <ExcelColumn
                              label="Condicion final"
                              value="finalCondition"
                            />
                            <ExcelColumn
                              label="Observaciones"
                              value="observation"
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      </>

                       {/* Historial estudiantes xls */}
                       <Popover content={xls_download}>
                        <Button type="default"
                        size={"small"} 
                        shape="round"
                        disabled={exportInactivePdf? exportInactivePdf.length > 0? false : true : true}
                        onClick={() => selectedAcademicRecord(exportInactivePdf)}
                        >
                          <FileExcelOutlined /> Historial Académico
                        </Button>
                      </Popover>
                    </Space>
                  </>
                ) : null}
              </>
            ) : (
              <></>
            )}
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "view"
            ) ||
            isAdmin(roleById) ? (
              <>
                <StudentsInactive
                  studentsInactive={studentsInactiveFilter}
                  propsPagination={propsPaginationInactive}
                  setReloadStudents={setReloadStudents}
                  accessToken={accessToken}
                  userToken={userToken}
                  permissionsActive={permissionsActive}
                  roleById={roleById}
                  setPagination={setPaginationInactive}
                  filterText={filterText}
                  filters={filters}
                  getLastCondition={getLastCondition}
                  report_student={report_student}
                  report_hours={report_hours}
                  report_adviser={report_adviser}
                  report_activitiesandhours={report_activitiesandhours}
                  hoursStudentPdf={hoursStudentPdf}
                  adviserStudentPdf={adviserStudentPdf}
                  activitiesAndHoursStudentPdf={activitiesAndHoursStudentPdf}
                  //inscriptionInactiveCourses={inscriptionInactiveCourses}
                  //progressBarModal={progressBarModal}
                  //inscriptionCourses={inscriptionCourses}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )}

        <Modal
          title={modalTitle}
          isVisible={isVisibleModal}
          setIsVisible={setIsVisibleModal}
          modalWidth={modalWidth}
        >
          {modalContent}
        </Modal>

        <BackTop />
      </div>
    </div>
  );
}

function StudentsActive(props) {
  const {
    studentsActive,
    //setReloadStudents, setIsVisibleModal, setModalTitle,
    propsPagination,
    setPagination,
    getLastCondition,
    //setModalWidth, setModalContent, accessToken,
    userToken,
    permissionsActive,
    roleById,
    filterText,
    filters,
    //inscriptionActiveCourses, studentsHasCourses,
    report_student,
    report_hours,
    report_adviser,
    report_activitiesandhours,
    //studentsHasNoCourses,
    hoursStudentPdf,
    adviserStudentPdf,
    activitiesAndHoursStudentPdf,
  } = props;

  const columns = [
    // {
    //   title: "Cód.",
    //   //dataIndex: "student_code",
    //   render: student => student.id_old ? student.id_old: student.student_code,
    // },
    {
      title: "Apellido",
      dataIndex: "lastname",
      width: "15%",
      //sorter:(a,b) => a.lastname.localeCompare(b.lastname),
      //defaultSortOrder:"ascend"
    },
    {
      title: "Nombre",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      width: "15%",
    },

    {
      title: "Condición",
      dataIndex: "cycles",
      render: (cycles) => (cycles ? getLastCondition(cycles) : null),
      width: "10%",
    },

    {
      title: "Acción",
      key: "action",
      width: "30%",
      render: (text, student) => (
        //putButtons
        <div>
          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_student}>
                <Button
                  className="button-cycle"
                  onClick={() => reportCyclesStudent(student)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_hours}>
                <Button
                  className="button-hours"
                  onClick={() => hoursStudentPdf(student)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_adviser}>
                <Button
                  className="button-adviser"
                  onClick={() => adviserStudentPdf(student)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_activitiesandhours}>
                <Button
                  className="button-activitiesandhours"
                  onClick={() => activitiesAndHoursStudentPdf(student)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  //cronometro
  //fin = Date.now();
  //console.log("tiempo activos: ", (fin - inicio)/1000);

  return (
    <div>
      {
        //(end = Date.now()),
        //console.log("tiempo: ", (end - start)/1000),
        <Table
          footer={() => {
            return propsPagination.total
              ? `Total de registros: ${propsPagination.total}`
              : false;
          }}
          pagination={{
            ...propsPagination,
            showSizeChanger: false,
            onChange: (newPage) => {
              setPagination({
                page: newPage,
                state: 1,
                filter: filterText,
                filters: filters,
              });
            },
          }}
          dataSource={studentsActive}
          columns={columns}
          //finishLoading={finishLoading}
          size="small"
          rowKey="_id"
        />
      }
    </div>
  );
}

function StudentsInactive(props) {
  const {
    studentsInactive,
    propsPagination,
    setPagination,
    userToken,
    permissionsActive,
    filterText,
    filters,
    roleById,
    report_student,
    report_hours,
    report_adviser,
    report_activitiesandhours,
    hoursStudentPdf,
    adviserStudentPdf,
    activitiesAndHoursStudentPdf,
  } = props;

  const columns = [
    // {
    //   title: "Cód.",
    //   //dataIndex: "student_code",
    //   render: student => student.id_old ? student.id_old: student.student_code,
    // },
    {
      title: "Apellido",
      dataIndex: "lastname",
      width: "15%",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      width: "15%",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      width: "15%",
    },
    /*
    {
      title: "Condición",
      dataIndex: "cycles",
      render: (cycles) => cycles? getLastCondition(cycles) : null,
      width:"10%"
    },
    */
    {
      title: "Acción",
      key: "action",
      width: "30%",
      render: (text, student) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_student}>
                <Button
                  className="button-cycle"
                  onClick={() => reportCyclesStudent(student)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_hours}>
                <Button
                  className="button-hours"
                  onClick={() => hoursStudentPdf(student)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_adviser}>
                <Button
                  className="button-adviser"
                  onClick={() => adviserStudentPdf(student)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "students", "all") ||
          checkAction(permissionsActive, userToken.role, "students", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={report_activitiesandhours}>
                <Button
                  className="button-activitiesandhours"
                  onClick={() => activitiesAndHoursStudentPdf(student)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={() => {
        return propsPagination.total
          ? `Total de registros: ${propsPagination.total}`
          : false;
      }}
      pagination={{
        ...propsPagination,
        showSizeChanger: false,
        onChange: (newPage) => {
          setPagination({
            page: newPage,
            state: 2,
            filter: filterText,
            filters: filters,
          });
        },
      }}
      dataSource={studentsInactive}
      columns={columns}
      size="small"
      rowKey="_id"
    />
  );
}


function StudentAcademicRecord(props) {
  const { students, setIsVisibleModal } = props;
  const isMounted = React.useRef(true);
  const [exportHistory, setExportHistory] = useState([]);
  const [count, setCount] = useState(0);
  const [percent, setPercent] = useState(0);

  //EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const increase=()=>{
    if(isMounted.current){
      setPercent((prevPercent)=>{
        const newPercent = prevPercent + 100/students.length
        //Math.ceil(100/students.length);
        if(newPercent > 100){
          return 100
        }
        return newPercent
      })
    }
  }

   const increaseCount=()=>{
    if(isMounted.current){
      setCount((prevCount)=>{
        const newCount = prevCount + 1
        return newCount
      })
    }
  }

  const getHistory = async (accessToken,students)=>{
    let data=[];
    for await (const item of students){
      if (isMounted.current){
        let result= await getInscriptionsByStudentReportApi(accessToken,item._id);
          if(result && result.inscriptions){
            if (isMounted.current){
              data.push(reportAcademicRecordStudentExcel(item, result.inscriptions));
              increaseCount();
              increase();
            }
          }else{
            break;
          }
      }
    }
    return data;
  }

  useEffect(() => {
    const accessToken = getAccessToken();
    if(students && students.length > 0){
      getHistory(accessToken, students).then((response)=>{
        if (isMounted.current){
          setExportHistory(response);
          if(count === students.length){
            increase();
          }
        }
      });
    }
    return ()=>{
      isMounted.current=false;
    }
  }, [students]);

  const closed = () => {
    setIsVisibleModal(false);
  };

  return (
    <div>
      <p> Archivo en proceso, espere por favor.</p>
     
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={14} >
      <Progress percent={percent} format={()=>{}} strokeColor={{'0%':'#108ee9', '99.9%':'#87d068'}}/>
      </Col>
      <Col>{count} / {students.length} Estudiantes</Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={7} ></Col>
        <Col span={8} >
               {/* Historial estudiantes xls */}
               {(exportHistory && exportHistory.length > 0)?
                (<>
                  <ExcelFile
                    filename={"ListadoHistorialEstudiantes"}
                    element={ 
                      <Popover content={"Descargar Excel"}>
                        <Button type="primary"
                        size={"small"} 
                        shape="round"
                        //onClick={() => {historyStudents(studentsActiveExport)}}
                        onClick={()=>{closed()}}
                        disabled={exportHistory? exportHistory.length > 0? false : true : true}
                        loading={exportHistory? exportHistory.length > 0? false : true : true}
                        >
                          <DownloadOutlined /> Historial Academico
                        </Button>
                      </Popover>
                    }
                  >
                     <ExcelSheet data={exportHistory} name="Estudiantes">
                      <ExcelColumn label="Apellido" value="lastname" />
                      <ExcelColumn label="Nombre" value="name" />
                      <ExcelColumn label="Especialidad" value="specialty"/>
                      <ExcelColumn label="Año ingreso" value="yearAdmission" />
                      <ExcelColumn label="Año egreso" value="yearGraduation"/>
                      <ExcelColumn label="Condicion Final" value="finalCondition" />
                      <ExcelColumn label="Horas Cursadas" value="ownHours" />
                      <ExcelColumn label="Años Cursados" value="yearsCoursed" />
                      <ExcelColumn label="Años Abandono" value="yearsAbandonment" />
                    </ExcelSheet>
                  </ExcelFile>
                </>) : null }
        </Col>
      </Row>
      {/* <Row>
        <Col span={24}>
        <Button type="primary" style={{marginTop:25, width:"100%"}} onClick={closed}>
          Salir
        </Button>
        </Col>
      </Row> */}
    </div>
  );
}