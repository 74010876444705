import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
    //getAbsencesActiveApi, 
    getAbsencesPaginateApi 
} from "../../../api/absence";
import ListAbsences from "../../../components/Admin/Absences/ListAbsences";


export default function Absences() {
    const [absencesActive, setAbsencesActive] = useState([]);
    const [absencesInactive, setAbsencesInactive] = useState([]);
    const [reloadAbsences, setReloadAbsences ] = useState(false);
    const [paginationActive, setPaginationActive]= useState({page:1, schoolYear: null});
    const [paginationInactive, setPaginationInactive]= useState({page:1, schoolYear: null});
    const token = getAccessToken();

    useEffect(() => {
        if(paginationActive.schoolYear !== null && paginationActive.schoolYear !==  undefined){
            getAbsencesPaginateApi(token, true, 50, paginationActive.page, paginationActive.filter, paginationActive.schoolYear).then(response => {
                setAbsencesActive(response.absences);
            });
            setReloadAbsences(false);
        }
    }, [token, paginationActive, reloadAbsences]);

    useEffect(() => {
        if(paginationInactive.schoolYear !== null && paginationInactive.schoolYear !==  undefined){
            getAbsencesPaginateApi(token, false, 50, paginationInactive.page, paginationInactive.filter, paginationActive.schoolYear).then(response => {
                setAbsencesInactive(response.absences);
            });
            setReloadAbsences(false);
        }
    }, [token, paginationInactive, reloadAbsences]);

    // useEffect(() => {
    //     getAbsencesActiveApi(token, true).then(response => {
    //         setAbsencesActive(response.absences);
    //     });
    //     getAbsencesActiveApi(token, false).then(response => {
    //         setAbsencesInactive(response.absences);
    //     });
    //     setReloadAbsences(false);
    // }, [token, reloadAbsences]);

    return (
        <div className="absences">
            <h3>Listado de Inasistencias</h3>
            <ListAbsences absencesActive={absencesActive} absencesInactive={absencesInactive} 
            setReloadAbsences={setReloadAbsences}
            setPaginationActive={setPaginationActive} setPaginationInactive={setPaginationInactive}
                />

        </div>
    );
}