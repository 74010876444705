import { downloadPdf, openPdf , getStyles, getHeader, getLogo} from "../../../../utils/pdfManager";

export function listStudentsCoursePdf(data,title, isDownload) {
  console.log(data);

  data.sort((a,b)=>{
    return a.student.lastname.localeCompare(b.student.lastname) ||
    a.student.name.localeCompare(b.student.name);
  });

  const _format = (data) => {
    return data.map((item) => {
      return [
        { text: item.student.lastname? item.student.lastname : ""},
        { text: item.student.name? item.student.name : "" },
        { text: item.student.email? item.student.email : "" },
        { text: item.student.absences? item.student.absences : 0 , alignment:"center"},
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
        getLogo(),
        [
            { text:'Listado de Estudiantes', style:'title'},
            { text:"Curso: " + title, style:'title'},
        ],
      '\n',
      '\n',
      {
        style: 'table',
        table: {
          headerRow: 1,
          widths:['*','*','auto', 'auto'],
          body: [
            [
              { text: "Apellido", style: "tableHeader" },
              { text: "Nombre", style: "tableHeader" },
              { text: "Email", style: "tableHeader" },
              { text: "Faltas", style: "tableHeader"}
            ],
            ...formattedData,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoEstudiantes");
  } else {
    openPdf(docDefinition);
  }
}
