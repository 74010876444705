import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getTeacherCollabsActiveApi } from "../../../api/teacherCollab";
import ListTeacherCollabs from "../../../components/Admin/TeacherCollabs/ListTeacherCollabs";


export default function TeacherCollabs() {
    const [teacherCollabsActive, setTeacherCollabsActive] = useState([]);
    const [teacherCollabsInactive, setTeacherCollabsInactive] = useState([]);
    const [reloadTeacherCollabs, setReloadTeacherCollabs ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getTeacherCollabsActiveApi(token, true).then(response => {
            setTeacherCollabsActive(response.teacherCollabs);
        });
        getTeacherCollabsActiveApi(token, false).then(response => {
            setTeacherCollabsInactive(response.teacherCollabs);
        });
        setReloadTeacherCollabs(false);
    }, [token, reloadTeacherCollabs]);

    return (
        <div className="teachercollabs">
            <h3>Listado de Colaboradores</h3>
            <ListTeacherCollabs teacherCollabsActive={teacherCollabsActive} teacherCollabsInactive={teacherCollabsInactive} setReloadTeacherCollabs={setReloadTeacherCollabs}
                />

        </div>
    );
}