import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { addDepartmentApi } from "../../../../api/department";
import { getAccessToken } from "../../../../api/auth";
//import { numberValidation } from "../../../../utils/formValidation";

import "./AddDepartmentForm.scss";

export default function AddDepartmentForm(props) {
  const { setIsVisibleModal, setReloadDepartments } = props;

  const [inputs, setInputs] = useState({
    department_code: "",
    description: "",
  });

  const [formValid, setFormValid] = useState({
    phone: false,
  });

  const inputValidation = (e) => {
    const { type, description } = e.target;

    setInputs({
      ...inputs,
      [description]: e.target.value,
    });
  };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorio: descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addDepartment = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addDepartmentApi(accessToken, inputs)
        .then((response) => {
          if (
            response.message === "ERR_CONNECTION_REFUSED" ||
            response.message === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (!response.department) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Departamento creado",
            });
            setIsVisibleModal(false);
          }
          //console.log(response);
          setReloadDepartments(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-department-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        inputValidation={inputValidation}
        addDepartment={addDepartment}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addDepartment, inputValidation } = props;

  return (
    <Form className="form-add" onSubmit={addDepartment}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.department_code}
              onChange={(e) => setInputs({ ...inputs, department_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={inputs.description}
              onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addDepartment}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
