import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  TimePicker,
  notification,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  CalendarOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  TeamOutlined,
  TagOutlined,
} from "@ant-design/icons";

import { addCourseApi } from "../../../../api/course";
import { getTeachersActiveApi } from "../../../../api/teacher";
import { getTeacherCollabsActiveApi } from "../../../../api/teacherCollab";
import { getDurationsActiveApi } from "../../../../api/duration";
import { getDepartmentsActiveApi } from "../../../../api/department";
import { getPlacesActiveApi } from "../../../../api/place";
import { getWorkloadsActiveApi } from "../../../../api/workload";
import { getDaysActiveApi } from "../../../../api/day";
import { getWeeksActiveApi } from "../../../../api/week";
import { getTypeCoursesActiveApi } from "../../../../api/typeCourse";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import { getAccessToken } from "../../../../api/auth";
import {
  emailValidation,
  numberValidation,
  stringValidation
} from "../../../../utils/formValidation";
import {formatDateHour} from  "../../../../utils/formatDate";

import "./AddCourseForm.scss";

const { TextArea } = Input;

export default function AddCourseForm(props) {
  const { setIsVisibleModal, setReloadCourses } = props;
  const [listTeachersActive, setListTeachersActive] = useState([]);
  const [listTeacherCollabsActive, setListTeacherCollabsActive] = useState([]);
  const [listDurationsActive, setListDurationsActive] = useState([]);
  const [listDepartmentsActive, setListDepartmentsActive] = useState([]);
  const [listPlacesActive, setListPlacesActive] = useState([]);
  const [listWorkloadsActive, setListWorkloadsActive] = useState([]);
  const [listDaysActive, setListDaysActive] = useState([]);
  const [listWeeksActive, setListWeeksActive] = useState([]);
  const [listTypeCoursesActive, setListTypeCoursesActive] = useState([]);
  const [listStateCoursesActive, setListStateCoursesActive] = useState([]);
  const [listSchoolYearsActive, setListSchoolYearsActive] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const accessToken = getAccessToken();

  useEffect(() => {
    getTeachersActiveApi(accessToken, true).then((response) => {
      setListTeachersActive(response.teachers);
    });
  }, []);

  useEffect(() => {
    getTeacherCollabsActiveApi(accessToken, true).then((response) => {
      setListTeacherCollabsActive(response.teacherCollabs);
    });
  }, []);

  useEffect(() => {
    getDurationsActiveApi(accessToken, true).then((response) => {
      setListDurationsActive(response.durations);
    });
  }, []);

  useEffect(() => {
    getDepartmentsActiveApi(accessToken, true).then((response) => {
      setListDepartmentsActive(response.departments);
    });
  }, []);

  useEffect(() => {
    getPlacesActiveApi(accessToken, true).then((response) => {
      setListPlacesActive(response.places);
    });
  }, []);

  useEffect(() => {
    getWorkloadsActiveApi(accessToken, true).then((response) => {
      setListWorkloadsActive(response.workloads);
    });
  }, []);

  useEffect(() => {
    getDaysActiveApi(accessToken, true).then((response) => {
      setListDaysActive(response.days);
    });
  }, []);

  useEffect(() => {
    getWeeksActiveApi(accessToken, true).then((response) => {
      setListWeeksActive(response.weeks);
    });
  }, []);

  useEffect(() => {
    getTypeCoursesActiveApi(accessToken, true).then((response) => {
      setListTypeCoursesActive(response.typeCourses);
    });
  }, []);

  useEffect(() => {
    getStateCoursesActiveApi(accessToken, true).then((response) => {
      setListStateCoursesActive(response.stateCourses);
    });
  }, []);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListSchoolYearsActive(response.schoolYears);
    });
  }, []);

  const [inputs, setInputs] = useState({
    // name:null,
  });

  const [formValid, setFormValid] = useState({
    email: false,
    phone: false,
    documentNumber: false,
  });

  const inputValidation = (e) => {
    const { type, name } = e.target;

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });
    /*
    if (type === "email") {
      setFormValid({
        ...formValid,
        [name]: emailValidation(e.target),
      });
    }
    if (name === "phone") {
      setFormValid({
        ...formValid,
        [name]: numberValidation(e.target),
      });
    }
    if (name === "documentNumber") {
      setFormValid({
        ...formValid,
        [name]: numberValidation(e.target),
      });
    }
    */
    if (name === "name") {
      setFormValid({
        ...formValid,
        [name]: stringValidation(e.target),
      });
    }
  };

  const isFormValid = (e) => {
    let errorExists = false;
    //console.log(inputs);
    if (
      !inputs.name ||
      !inputs.hour_start||
      !inputs.hour_end ||
      !inputs.workload ||
      !inputs.schoolYear
      // !inputs.teacher ||
      // !inputs.place ||
      // !inputs.duration ||
      // !inputs.workload ||
      // !inputs.day ||
      // !inputs.week
      ) {
      notification["error"]({
        message: "Complete los campos obligatorios (*)",
      });
      errorExists = true;
    }
    if(new Date(inputs.hour_start) > new Date(inputs.hour_end)){
      notification["error"]({
        message: "La hora de comienzo debe ser menor a la de finalización",
      });
      errorExists = true;
    }
    else if (formValid.name === false) {
      //BUG
      notification["error"]({
        message: "Nombre inválido, verifique los carácteres ingresados",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addCourse = async (e) => {
    e.preventDefault();
    // console.log(formatDateHour(inputs.hour_end));
    if (!isFormValid()) {
      if(!disabledSubmit){
      const accessToken = getAccessToken();
      setDisabledSubmit(true);

      let inputsFinal ={...inputs};//copio para evitar error al cambiar el formato a los campos horas 
      inputsFinal.hour_end=formatDateHour(inputsFinal.hour_end);
      inputsFinal.hour_start=formatDateHour(inputsFinal.hour_start);
      await addCourseApi(accessToken, inputsFinal)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setDisabledSubmit(false);
          } else if (response.code === 500) {
            notification["error"]({
              message:response.message,
            });
            setDisabledSubmit(false);
          } else if (response.code === 400) {
            notification["error"]({
              message:response.message,
            });
            setDisabledSubmit(false);
            setReloadCourses(true);
          } else if (response.code === 402) { //Error al cargar la inscripcion, debera cargar manualmente
            notification["warning"]({
              duration:20,
              message:response.message + ". Verifique que el curso se haya creado. Luego verifique la inscripción sino existe agregue la inscripción correspondiente.",
            });
            setIsVisibleModal(false);
            setReloadCourses(true);
          } else if(response.code === 200){
            notification["success"]({
              message: "El curso y su correpondiente inscripción se han creado correctamente",
            });
            setIsVisibleModal(false);
            setReloadCourses(true);
          }
          //setReloadCourses(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
      }
    }
  };

  return (
    <div className="add-course-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addCourse={addCourse}
        listTeachersActive={listTeachersActive}
        listDurationsActive={listDurationsActive}
        listDepartmentsActive={listDepartmentsActive}
        listPlacesActive={listPlacesActive}
        listWorkloadsActive={listWorkloadsActive}
        listDaysActive={listDaysActive}
        listWeeksActive={listWeeksActive}
        listTypeCoursesActive={listTypeCoursesActive}
        listStateCoursesActive={listStateCoursesActive}
        listTeacherCollabsActive={listTeacherCollabsActive}
        listSchoolYearsActive={listSchoolYearsActive}
        inputValidation={inputValidation}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    addCourse,
    listTeachersActive,
    listDurationsActive,
    listDepartmentsActive,
    listPlacesActive,
    listWorkloadsActive,
    listDaysActive,
    listWeeksActive,
    listTypeCoursesActive,
    listStateCoursesActive,
    listTeacherCollabsActive,
    listSchoolYearsActive,
    inputValidation,
  } = props;
  const { Option } = Select;

  useEffect(() => {
    if(inputs.workload){
      let absences=listWorkloadsActive.filter((i) => i._id === inputs.workload);
      if(absences.length > 0){
        setInputs({...inputs, absencesAllowed:absences[0].absencesAllowed} );
      }
    }
  }, [inputs.workload]);

  return (
    <Form className="form-add" onSubmit={addCourse}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span>Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.course_code}
              onChange={(e) => setInputs({ ...inputs, course_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="nombre"
              name="name"
              value={inputs.name}
              //onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Tipo</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona tipo de curso"
              value={inputs.typeCourse}
              onChange={(e) => setInputs({ ...inputs, typeCourse: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listTypeCoursesActive
                ? listTypeCoursesActive.map((type) => {
                    return (
                      <Option key={type._id}>
                        {type.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Estado</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona el estado del curso"
              value={inputs.stateCourse}
              onChange={(e) => setInputs({ ...inputs, stateCourse: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStateCoursesActive
                ? listStateCoursesActive.map((state) => {
                    return (
                      <Option key={state._id}>
                        {state.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha comienzo
          </label>
          <Form.Item>
            <Input
              // prefix={<CalendarOutlined/>}
              type="date"
              placeholder="Fecha comienzo"
              value={inputs.date_start}
              onChange={(e) =>
                setInputs({ ...inputs, date_start: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Fecha fin</label>
          <Form.Item>
            <Input
              type="date"
              // prefix={<CalendarOutlined />}
              placeholder="Fecha fin"
              value={inputs.date_end}
              onChange={(e) =>
                setInputs({ ...inputs, date_end: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Hora desde
          </label>
          <Form.Item>
            <TimePicker
              prefix={<UserOutlined />}
              placeholder="Hora desde"
              format="HH:mm"
              value={inputs.hour_start}
              onChange={(e) =>
                setInputs({ ...inputs, hour_start: e })
              }
              minuteStep={10}
              style={{width:"100%"}}
              showNow={false}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Hora hasta
          </label>
          <Form.Item>
            <TimePicker
              prefix={<UserOutlined />}
              placeholder="Hora hasta"
              value={inputs.hour_end}
              format="HH:mm"
              minuteStep={10}
              onChange={(e) =>
                setInputs({ ...inputs, hour_end: e})
              }
              style={{width:"100%"}}
              showNow={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

        <Col span={6}>
          <label className="control-label">Cupos</label>
          <Form.Item>
            <InputNumber
              placeholder="Cantidad de cupos"
              name="quota"
              value={inputs.quota}
              onChange={(e) => setInputs({ ...inputs, quota: e })}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Duración</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona la duración"
              value={inputs.duration}
              onChange={(e) => setInputs({ ...inputs, duration: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDurationsActive
                ? listDurationsActive.map((duration) => {
                    return (
                      <Option key={duration._id}>
                        {duration.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Docentes</label>
          <Form.Item>
            <Select
              showSearch
              mode="multiple"
              placeholder="Selecciona docentes"
              value={inputs.teachers}
              onChange={(e) => setInputs({ ...inputs, teachers: e })}
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listTeachersActive
                ? listTeachersActive.map((teacher) => {
                    return (
                      <Option key={teacher._id}>{teacher.description}</Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Colaboradores</label>
          <Form.Item>
            <Select
              showSearch
              mode="multiple"
              placeholder="Selecciona colaboradores"
              value={inputs.teacherCollabs}
              onChange={(e) => setInputs({ ...inputs, teacherCollabs: e })}
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listTeacherCollabsActive
                ? listTeacherCollabsActive.map((collab) => {
                    return (
                      <Option key={collab._id}>{collab.description}</Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={6}>
          <label className="control-label">Departamento</label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona el departamento"
              value={inputs.department}
              onChange={(e) => setInputs({ ...inputs, department: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDepartmentsActive
                ? listDepartmentsActive.map((department) => {
                    return (
                      <Option key={department._id}>{department.description}</Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Lugar</label>
          <Form.Item>
            <Select
              prefix={<TeamOutlined />}
              showSearch
              placeholder="Selecciona un lugar"
              value={inputs.place}
              onChange={(e) => setInputs({ ...inputs, place: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listPlacesActive
                ? listPlacesActive.map((place) => {
                    return (
                      <Option key={place._id}>
                        {place.place_code + " " + place.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span>Carga horaria
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona la carga horaria"
              value={inputs.workload}
              onChange={(e) => setInputs({ ...inputs, workload: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listWorkloadsActive
                ? listWorkloadsActive.map((workload) => {
                    return (
                      <Option key={workload._id}>
                        {workload.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
        <label className="control-label">Inasistencias Permitidas</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              value={inputs.absencesAllowed}
              onChange={(e) => setInputs({ ...inputs, absencesAllowed: e })}
              style={{ width: "100%", color:"black",
              backgroundColor: "white"}}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Semanas</label>
          <Form.Item>
          <Select
              showSearch
              mode="multiple"
              placeholder="Selecciona las semanas en que se dicta el curso"
              name="weeks"
              value={inputs.weeks}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  weeks: e,
                })
              }
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listWeeksActive
                ? listWeeksActive.map((week) => {
                    return (
                      <Option key={week._id}>
                        {week.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Días</label>
          <Form.Item>
          <Select
              showSearch
              mode="multiple"
              placeholder="Selecciona los días en que se dicta el curso"
              name="days"
              value={inputs.days}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  days: e,
                })
              }
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDaysActive
                ? listDaysActive.map((day) => {
                    return (
                      <Option key={day._id}>
                        {day.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <span className="control-required">*</span>Año lectivo
          <Form.Item>
          <Select
              showSearch
              placeholder="Selecciona el año en que se dicta el curso"
              name="schoolYear"
              value={inputs.schoolYear}
              onChange={(e) =>
                setInputs({ ...inputs, schoolYear: e })
              }
              maxTagCount="responsive"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSchoolYearsActive
                ? listSchoolYearsActive.map((schoolYear) => {
                    return (
                      <Option key={schoolYear._id}>
                        {schoolYear.year}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addCourse}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
