import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
    //getTeachersActiveApi, 
    getTeachersApi ,
    getTeachersExportApi
} from "../../../api/teacher";
import ListTeachers from "../../../components/Admin/Teachers/ListTeachers";


export default function Teachers() {
    const [teachersActive, setTeachersActive] = useState([]);
    const [teachersInactive, setTeachersInactive] = useState([]);
    const [teachersExportActive, setTeachersExportActive] = useState([]);
    const [teachersExportInactive, setTeachersExportInactive] = useState([]);
    const [reloadTeachers, setReloadTeachers ] = useState(false);
    const token = getAccessToken();
    const [paginationActive, setPaginationActive]= useState({page:1});
    const [paginationInactive, setPaginationInactive]= useState({page:1});

    useEffect(() => {
        let isMounted = true;
        getTeachersApi(token, true,50,paginationActive.page,paginationActive.filter, paginationActive.filters).then(response => {
            setTeachersActive(response.teachers);
        });
        getTeachersExportApi(token, true, paginationActive.filter, paginationActive.filters).then(response => {
            if(isMounted){
              if(response.teachers){
                setTeachersExportActive(response.teachers);
              }
            }
          });
        setReloadTeachers(false);
        return () => {
            isMounted = false;
          };
    }, [token, paginationActive,reloadTeachers]);

    useEffect(() => {
        let isMounted = true;
        getTeachersApi(token, false, 50,paginationInactive.page,paginationInactive.filter, paginationInactive.filters).then(response => {
            setTeachersInactive(response.teachers);
        });
        getTeachersExportApi(token, false, paginationActive.filter, paginationActive.filters).then(response => {
            if(isMounted){
              if(response.teachers){
                setTeachersExportInactive(response.teachers);
              }
            }
          });
        setReloadTeachers(false);
        return () => {
            isMounted = false;
          };
    }, [token, paginationInactive,reloadTeachers]);

    return (
        <div className="teachers">
            <h3>Listado de Docentes</h3>
            <ListTeachers 
                setPaginationActive={setPaginationActive} 
                setPaginationInactive={setPaginationInactive} 
                teachersActive={teachersActive} 
                teachersInactive={teachersInactive} 
                teachersExportActive={teachersExportActive}
                teachersExportInactive={teachersExportInactive}
                setReloadTeachers={setReloadTeachers}
            />
        </div>
    );
}