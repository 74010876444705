import { downloadPdf, openPdf , getStyles, getHeader, getLogo} from "../../../../utils/pdfManager"; //docDefinitionTable
// import { convertTypeIva } from "../../../../utils/convert";
//import { formatDateView } from "../../../../utils/formatDate";
//import Logo from '../../../../assets/img/png/icba.png';
//import moment from "moment";

export function listStudentsPdf(data, isDownload) {
  const _format = (data) => {
    return data.map((item) => {
      return [
        // { text: item.student_code },
        { text: item.name },
        { text: item.lastname },
        { text: item.phone },
        { text: item.email },
        { text: item.specialty? item.specialty.description : " sin especificar" },
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
        getLogo(),
        [
            { text:'Listado de Estudiantes', style:'title'},
        ],
      '\n',
      '\n',
      {
        style: 'table',
        table: {
          headerRow: 1,
          widths:['auto','auto',90,150,'auto'],
          body: [
            [
              // { text: "Código", style: "tableHeader" },
              { text: "Nombre", style: "tableHeader" },
              { text: "Apellido", style: "tableHeader" },
              { text: "Telefono", style: "tableHeader" },
              { text: "Email", style: "tableHeader" },
              { text: "Especialidad", style: "tableHeader" },
            ],
            ...formattedData,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoEstudiantes");
  } else {
    openPdf(docDefinition);
  }
}

export function studentDetailPdf(data, isDownload) {
  const _format = (data) => {
    return [
      { text: `Nombre: ${data.name ? data.name : ""}`, style:'list' },
      { text: `Apellido: ${data.lastname ? data.lastname : ""}` , style:'list'},
      { text: `Telefono : ${data.phone ? data.phone : ""}`, style:'list' },
      { text: `Numero de documento: ${data.documentNumber ? data.documentNumber : ""}` , style:'list'},
      { text: `E-mail: ${data.email ? data.email : ""}`, style:'list' },
      // { text: `Observaciones: ${data.person.observation ? data.person.observation : ""}`, style:'list' },
    ];
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
      getLogo(),
      '\n',
      [
          { text:'Detalle de Estudiante', style:'title'},
      ],
      '\n',
      '\n',
      {
        type: "none",
        ol: [...formattedData],
      },
    ],
    styles:getStyles()
  };
  let title = `${data.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}
