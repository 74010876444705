import React, { useState, useEffect } from "react";
import {
  Redirect,
} from "react-router-dom";
import { getAccessToken } from "../../../api/auth";
import { getInscriptionsByStudentApi } from "../../../api/inscription";
import { getStudentApi } from "../../../api/student";
import ListStudentCourses from "../../../components/Admin/Inscriptions/ListStudentCourses";
//import Error404 from "../../Error404.js";

export default function StudentCourses(props) {
  const [studentData, setStudentData] = useState({});
  const [inscriptionCourses, setInscriptionCourses] = useState([]);
  const [reloadInscriptionCourses, setReloadInscriptionCourses] =
    useState(false);
  const token = getAccessToken();
  const { id } = props.location.state ? props.location.state : false;
  
  useEffect(() => {
    let isMounted=true;
    if (studentData && studentData._id) {
        getInscriptionsByStudentApi(token, id).then((response) => {
          if(isMounted){
           setInscriptionCourses(response.inscriptions);
          }
        });
    }
    setReloadInscriptionCourses(false);
    return ()=>{
      isMounted=false;
    }
  }, [studentData, reloadInscriptionCourses]);

  useEffect(() => {
    let isMounted=true;
      if (id !== false && id !== undefined) {
        getStudentApi(token, id).then(async(response) => {
          if(isMounted){
            setStudentData(response.student);
          }
        });
      }
    return ()=>{
      isMounted=false;
    }
  }, [token,id]);

  return (
    <div className="inscriptions">
      {id ? (
        <>
          <h3>
            {" "}
            Listado de Cursos del Estudiante "
            {studentData.name? studentData.name + " " + studentData.lastname : null 
            }
            "
          </h3>
            <ListStudentCourses
              studentData={studentData}
              inscriptions={inscriptionCourses}
              setReloadInscriptionCourses={setReloadInscriptionCourses}
            />
        </>
      ) : (
        <>
          <Redirect to="/admin/students" />
        </>
      )}
    </div>
  );
}
