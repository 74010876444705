import {
  downloadPdf,
  openPdf,
  getStyles,
  getHeader,
  getLogo,
  //getLogoHeader,
  lineVerticalPage, 
  verticalFooter
} from "../../../../utils/pdfManager"; //docDefinitionTable
// import { convertTypeIva } from "../../../../utils/convert";
//import { getSchoolYearApi } from "../../../../api/schoolYear";
//import { useEffect } from "react";
//import { formatDateView } from "../../../../utils/formatDate";
//import Logo from '../../../../assets/img/png/icba.png';
//import moment from "moment";

export function reportTeacherHistory(teacher, data, isDownload) {
  const _formatCourses = (data) => {
    return data.map((item) => {
      return [
        { text: item.name, alignment: "center" },
        { text: item.department ? item.department.description : "", alignment: "center" },
        { text: item.workload ? item.workload.hours : "", alignment: "center" },
      ];
    });
  };

  const _formatYear = (data) => {
    const info = Object.keys(data);
    info
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      })
      .reverse();

    return info.map((item) => {
      return [
        "\n",
        { text: `Ciclo ${item}`, style: "titleLeft" },
        "\n",
        {
          style: "table",
          table: {
            headerRow: 1,
            widths: ["auto", "*", "*"],
            border: [false, false, false, false],
            margin: [0, 0, 0, 0],
            body: [
              [
                // { text: "Código", style: "tableHeader" },
                { text: "Nombre del curso/seminario", style: "tableHeaderWithoutColor", alignment: "center" },
                { text: "Departamento", style: "tableHeaderWithoutColor", alignment: "center" },
                { text: "Carga Horaria", style: "tableHeaderWithoutColor", alignment: "center" },
              ],
              ..._formatCourses(data[item]),
            ],
          },
          layout: "noBorders",
        },
        "\n",
      ];
    });
    // return print;
  };
  //Agrupar por schoolYear
  const result = data.reduce(function (a, b) {
    a[b.schoolYear.year] = a[b.schoolYear.year] || [];
    a[b.schoolYear.year].push(b);
    return a;
  }, Object.create(null));

  // console.log(result);

  const formattedData = _formatYear(result);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [40, 40, 40, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      [
        lineVerticalPage(),
        { text: "REPORTE DOCENTE", style: "title" }
      ],
      "\n",
      [
        { text: `Docente: ${teacher.description ? teacher.description : ""}` },
        "\n",
        { text: `E-mail: ${teacher.email ? teacher.email : ""}` },
      ],
      "\n",
      {
        // style: "table",
        table: {
          headerRow: 1,
          widths: ["*"],
          margin: [0, 0, 0, 0],
          body: [
            [
              { text: "CURSOS", style: "tableHeaderWithoutColor" }
            ]
          ],
        },
      },
      "\n",
      ...formattedData,
    ],
    footer: verticalFooter,
    /*
    footer: [
      {
        text: `Instituto Clínico de Buenos Aires - Callao 1033 5° Piso - C.P(1023) - Tel: 4816-7292`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
      {
        text: `e-mail: icba@eol.org.ar`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
    ],*/
    //currentPage.toString() + ' de ' + pageCount
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteDocente");
  } else {
    openPdf(docDefinition);
  }
}

export function reportTeacherCourses(teacher, data, isDownload) {
  const _formatCourses = (data) => {
    return data.map((item) => {
      return [
        { text: item.name, alignment: "center" },
        { text: item.typeCourse ? item.typeCourse.description : "", alignment: "center" },
        { text: item.department ? item.department.description : "", alignment: "center" },
      ];
    });
  };

  const _formatYear = (data) => {
    const info = Object.keys(data);
    info
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      })
      .reverse();

    return info.map((item) => {
      return [
        "\n",
        { text: `Ciclo ${item}`, style: "titleLeft" },
        "\n",
        {
          style: "table",
          table: {
            headerRow: 1,
            widths: ["auto", "*", "*"],
            border: [false, false, false, false],
            margin: [0, 0, 0, 0],
            body: [
              [
                // { text: "Código", style: "tableHeader" },
                { text: "Nombre del curso/seminario", style: "tableHeaderWithoutColor", alignment: "center" },
                { text: "Tipo Curso", style: "tableHeaderWithoutColor", alignment: "center" },
                { text: "Departamento", style: "tableHeaderWithoutColor", alignment: "center" },
              ],
              ..._formatCourses(data[item]),
            ],
          },
          layout: "noBorders",
        },
        "\n",
      ];
    });
    // return print;
  };
  //Agrupar por schoolYear
  const result = data.reduce(function (a, b) {
    a[b.schoolYear.year] = a[b.schoolYear.year] || [];
    a[b.schoolYear.year].push(b);
    return a;
  }, Object.create(null));

  // console.log(result);

  const formattedData = _formatYear(result);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [40, 40, 40, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      [
        lineVerticalPage(),
        { text: "REPORTE DOCENTE", style: "title" }
      ],
      "\n",
      [
        { text: `Docente: ${teacher.description ? teacher.description : ""}` },
        "\n",
        { text: `E-mail: ${teacher.email ? teacher.email : ""}` },
      ],
      "\n",
      {
        // style: "table",
        table: {
          headerRow: 1,
          widths: ["*"],
          margin: [0, 0, 0, 0],
          body: [
            [
              { text: "CURSOS", style: "tableHeaderWithoutColor" }
            ]
          ],
        },
      },
      "\n",
      ...formattedData,
    ],
    footer: verticalFooter,
    /*
    footer: [
      {
        text: `Instituto Clínico de Buenos Aires - Callao 1033 5° Piso - C.P(1023) - Tel: 4816-7292`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
      {
        text: `e-mail: icba@eol.org.ar`,
        style: "footer",
        alignment: "center",
        // opacity: 0.5,
        fontSize: 9,
      },
    ],*/
    //currentPage.toString() + ' de ' + pageCount
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteDocente");
  } else {
    openPdf(docDefinition);
  }
}

export function listTeachersPdf(data, isDownload, schoolYearData) {
  const _format = (data) => {
    return data.map((item) => {
      return [
        // { text: item.student_code },
        { text: item.description },
        { text: item.email },
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
        getLogo(),
        [
            { text:'Listado de Docentes', style:'title'},
        ],
      '\n',
      schoolYearData !=="" ?
        [
            { text:'Ciclo Lectivo: '+schoolYearData},
        ] : null,
      '\n',
      {
        style: 'table',
        table: {
          headerRow: 1,
          widths:['auto','auto'],
          body: [
            [
              // { text: "Código", style: "tableHeader" },
              { text: "Apellido y Nombre", style: "tableHeader" },
              { text: "Email", style: "tableHeader" },
            ],
            ...formattedData,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoDocentes");
  } else {
    openPdf(docDefinition);
  }
}

export function reportTeachersCoursesYear(
  teachers,
  year,
  isDownload
) {
  const yearInt = parseInt(year);

  const _formatCourses = (data) => {
    return data.map((item) => {
      return [
        { text: item.name },
        { text: item.typeCourse ? item.typeCourse.description : "" },
        { text: item.department ? item.department.description : "" },
      ];
    });
  };

  const _formatYear = (teachers, year) => {
    // teachers.sort(function (a, b) {
    //   return a.lastname.localeCompare(b.lastname);
    // });

    return teachers.map((teacher) => {
      let dataCourses = teacher.courses;

      if (
        dataCourses 
      ) {

        const finalData = _formatCourses(dataCourses);
        return [
          "\n",
          "\n",
          { text: `${teacher.description}`, style: "titleLeft", fontSize:12},
          {
            table : { 
                headerRows : 1,
                alignment: "center",
                widths: [510],
                body : [
                        [''],
                        ['']
                        ]
            },
            layout : 'headerLineOnly'
          },
          {
            style: "table",
            table: {
              headerRow: 1,
              widths: [220, 120, 200],
              border: [false, false, false, false],
              margin: [0, 0, 0, 0],
              body: [
                [
                  {
                    text: "Curso",
                    style: "tableHeaderWithoutColor",
                    // alignment: "center",
                  },
                  {
                    text: "Tipo Curso",
                    style: "tableHeaderWithoutColor",
                    // alignment: "center",
                  },
                  {
                    text: "Departamento",
                    style: "tableHeaderWithoutColor",
                    // alignment: "center",
                  },
                ],
                ...finalData,
              ],
            },
            layout: "noBorders",
          },
          "\n",
          // "\n",
        ];
      }
    });
  };

  const formattedData = _formatYear(teachers, yearInt);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    pageMargins: [30, 40, 30, 60], //PARA AMPLIAR LA ALTURA DEL FOOTER
    background: getLogo(),
    header: getHeader(),
    content: [
      //getLogoHeader(),
      [lineVerticalPage(), { text: `REPORTE DOCENTES CICLO ${year}`, style: "title" }],
      "\n",
      [
        {
          text: `Ciclo: ${year}`,
          style: "titleLeft",
        },
      ],
      "\n",
      ...formattedData,
    ],
    //footer: landscapeFooter,
    styles: getStyles(),
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ReporteDocentes");
  } else {
    openPdf(docDefinition);
  }
}

export function reportTeachersCoursesYearExcel(
  teachers,
  year,
  isDownload
) {
  const yearInt = parseInt(year);
  let dataFinal = [];

  const _formatYear = (teachers, year) => {
    teachers.map((teacher) => {
      let dataCourses = teacher.courses;

      if (
        dataCourses 
      ) {

        dataCourses.map((item) => {
          dataFinal.push(
          { description: teacher.description,
            name: item.name ,
            typeCourse: item.typeCourse ? item.typeCourse.description : "" ,
            department: item.department ? item.department.description : "" 
          })
        });
      }
    });
  }


  const formattedData = _formatYear(teachers, yearInt);

  return dataFinal;
}