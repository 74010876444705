import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getAdvisersActiveApi, getAdvisersPaginateApi } from "../../../api/adviser";
import ListAdvisers from "../../../components/Admin/Advisers/ListAdvisers";


export default function Advisers() {
    const [advisersActive, setAdvisersActive] = useState([]);
    const [advisersInactive, setAdvisersInactive] = useState([]);
    const [reloadAdvisers, setReloadAdvisers ] = useState(false);
    const token = getAccessToken();
    const [paginationActive, setPaginationActive]= useState({page:1});
    const [paginationInactive, setPaginationInactive]= useState({page:1});

    // useEffect(() => {
    //     getAdvisersActiveApi(token, true).then(response => {
    //         setAdvisersActive(response.advisers);
    //     });
    //     getAdvisersActiveApi(token, false).then(response => {
    //         setAdvisersInactive(response.advisers);
    //     });
    //     setReloadAdvisers(false);
    // }, [token, reloadAdvisers]);

    useEffect(() => {
        getAdvisersPaginateApi(token, true,50,paginationActive.page,paginationActive.filter).then(response => {
            setAdvisersActive(response.advisers);
        });
        setReloadAdvisers(false);
    }, [token, paginationActive,reloadAdvisers]);

    useEffect(() => {
        getAdvisersPaginateApi(token, false, 50,paginationInactive.page,paginationActive.filter).then(response => {
            setAdvisersInactive(response.advisers);
        });
        setReloadAdvisers(false);
    }, [token, paginationInactive,reloadAdvisers]);

    return (
        <div className="advisers">
            <h3>Listado de Consejeros</h3>
            <ListAdvisers 
                setPaginationActive={setPaginationActive} setPaginationInactive={setPaginationInactive}
                advisersActive={advisersActive} advisersInactive={advisersInactive} setReloadAdvisers={setReloadAdvisers}
                />

        </div>
    );
}