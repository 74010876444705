import { downloadPdf, openPdf , getStyles, getHeader, getLogo} from "../../../../utils/pdfManager";
import moment from "moment";

export function listCoursesStudentPdf(data, title, isDownload) {
  const _format = (data) => {
    return data.map((item) => {
      return [
        // { text: item.schoolYear? item.schoolYear.year? item.schoolYear.year : "" : "" },
        { text: item.name },
        // { text: item.days? item.days.map(function(elem){
        //   return elem.description
        // }).join(" ,") : "" },
        // {text: item.hour_start? moment(item.hour_start,"HH:mm").isValid()?  moment(new Date(item.hour_start),"HH:mm").format("HH:mm") : item.hour_start : ""},
        // {text: item.hour_end? moment(item.hour_end,"HH:mm").isValid()?  moment(new Date(item.hour_end),"HH:MM").format("HH:mm") : item.hour_end : ""},
        // {text: item.teachers? item.teachers.map(function(elem){
        //    return elem.description
        // }).join(" ,") : "" },
        // { text: item.weeks? item.weeks.map(week=> week.description) : "sin datos" }
        // { text: item.place? item.place.description : "sin datos" },
        // { text: item.duration? item.duration.description : "sin datos" },
        // { text: item.quota? item.quota : "sin datos" },
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
        getLogo(),
        [
            { text:'Listado de Cursos', style:'title'},
            { text:"Estudiante: " + title, style:'title'},
        ],
      '\n',
      '\n',
      {
        style: 'table',
        table: {
          headerRow: 1,
          // widths:['*','auto', '*', '*', '*', '*'],
          widths:['*'],
          body: [
            [
              // { text: "Ciclo", style: "tableHeader" },
              { text: "Nombre", style: "tableHeader" },
              // { text: "Dia", style: "tableHeader" },
              // { text: "Horario Inicio", style: "tableHeader" },
              // { text: "Horario Fin", style: "tableHeader" },
              // { text: "Docente", style: "tableHeader" },
              // { text: "Cupo", style: "tableHeader" },
            ],
            ...formattedData,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoEstudiantes");
  } else {
    openPdf(docDefinition);
  }
}
