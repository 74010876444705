import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
    getTeacherCollabsActiveApi, 
    // getTeacherCollabsPaginateApi 
} from "../../../api/teacherCollab";
import ListTeacherCollabsReports from "../../../components/Admin/TeacherCollabs/ReportsTeacherCollabs";


export default function TeacherCollabsReports() {
    const [teacherCollabsActive, setTeacherCollabsActive] = useState([]);
    const [teacherCollabsInactive, setTeacherCollabsInactive] = useState([]);
    const [reloadTeacherCollabs, setReloadTeacherCollabs ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getTeacherCollabsActiveApi(token, true).then(response => {
            setTeacherCollabsActive(response.teacherCollabs);
        });
        getTeacherCollabsActiveApi(token, false).then(response => {
            setTeacherCollabsInactive(response.teacherCollabs);
        });
        setReloadTeacherCollabs(false);
    }, [token, reloadTeacherCollabs]);

    // const [paginationActive, setPaginationActive]= useState({page:1});
    // const [paginationInactive, setPaginationInactive]= useState({page:1});

    // useEffect(() => {
    //     getTeacherCollabsActiveApi(token, true,50,paginationActive.page,paginationActive.filter).then(response => {
    //         setTeacherCollabsActive(response.teacherCollabs);
    //     });
    //     setReloadTeacherCollabs(false);
    // }, [token, paginationActive,reloadTeacherCollabs]);

    // useEffect(() => {
    //     getTeacherCollabsActiveApi(token, false, 50,paginationInactive.page,paginationActive.filter).then(response => {
    //         setTeacherCollabsInactive(response.teacherCollabs);
    //     });
    //     setReloadTeacherCollabs(false);
    // }, [token, paginationInactive,reloadTeacherCollabs]);

    return (
        <div className="teachercollabs">
            <h3>Reporte de Colaboradores</h3>
            <ListTeacherCollabsReports 
            // setPaginationActive={setPaginationActive} 
            // setPaginationInactive={setPaginationInactive} 
            teacherCollabsActive={teacherCollabsActive} teacherCollabsInactive={teacherCollabsInactive} setReloadTeacherCollabs={setReloadTeacherCollabs}
                />

        </div>
    );
}