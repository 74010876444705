import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getSpecialtiesActiveApi } from "../../../api/specialty";
import ListSpecialties from "../../../components/Admin/Specialties/ListSpecialties";


export default function Specialties() {
    const [specialtiesActive, setSpecialtiesActive] = useState([]);
    const [specialtiesInactive, setSpecialtiesInactive] = useState([]);
    const [reloadSpecialties, setReloadSpecialties ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getSpecialtiesActiveApi(token, true).then(response => {
            setSpecialtiesActive(response.specialties);
        });
        getSpecialtiesActiveApi(token, false).then(response => {
            setSpecialtiesInactive(response.specialties);
        });
        setReloadSpecialties(false);
    }, [token, reloadSpecialties]);

    return (
        <div className="specialties">
            <h3>Listado de Especialidades</h3>
            <ListSpecialties specialtiesActive={specialtiesActive} specialtiesInactive={specialtiesInactive} setReloadSpecialties={setReloadSpecialties}
                />

        </div>
    );
}