import React, { useState, useEffect } from "react";
import {
  Switch,
  BackTop,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  Space,
  Select,
  Col, 
  Row
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DownOutlined,
  UpOutlined,
  FileDoneOutlined,
  DeleteOutlined,
  CheckOutlined,
  //DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  PlusOutlined,
  FileOutlined,
  ProfileOutlined
} from "@ant-design/icons";
// import { Pagination as PaginationAntd } from "antd";
import { Popover } from "antd";

import Modal from "../../../Modal";
import EditCourseForm from "../EditCourseForm";
import AddCourseForm from "../AddCourseForm";
import AdvancedSearchCourseForm from "./AdvancedSearchForm";
import { activateCourseApi, deleteCourseApi  } from "../../../../api/course"; //getExportCoursesApi
import { getInscriptionsApi, getInscriptionByCourseApi, getInscriptionsBySchoolYearApi } from "../../../../api/inscription";
import { getAbsencesByCourseApi } from "../../../../api/absence";
import { listCoursesPdf } from "./pdfCourses";
import { attendanceDetailPdf } from "./pdfAttendance";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import jwtDecode from "jwt-decode";
// import useRouteProps from "../../../../hooks/UseRouteProps"
// import { PluralizeEsp } from "../../../../utils/convert";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from "moment";

import {Link} from "react-router-dom";

import "./ListCourses.scss";

const { confirm } = ModalAntd;
const { Search } = Input;
const { Option } = Select;

export default function ListCourses(props) {
  const { setPaginationActive, setPaginationInactive, coursesActive, coursesInactive, setReloadCourses,
  coursesActiveExport, coursesInactiveExport } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [inscriptions, setInscriptions] = useState([]);
  const [viewCoursesActives, setViewCoursesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  //filtro del buscador principal
  const [filterText, setFilterText] = useState("");
  //filtros para busqueda avanzada
  const [filters, setFilters] = useState([]);
  const [schoolYear, setSchoolYear]= useState(null);
  const [coursesActiveFilter, setCoursesActiveFilter] = useState([]);
  const [coursesInactiveFilter, setCoursesInactiveFilter] = useState([]);
  const [exportActivePdf, setExportActivePdf]=useState([]);
  const [exportActiveExcel, setExportActiveExcel] = useState([]);
  const [exportInactivePdf, setExportInactivePdf]=useState([]);
  const [exportInactiveExcel, setExportInactiveExcel] = useState([]);
  const [listSchoolYears, setListSchoolYears] = useState([]);

  const [propsPaginationActive, setPropsPaginationActive]=useState({});
  const [propsPaginationInactive, setPropsPaginationInactive]=useState({});
  const [expand, setExpand] = useState(false);
  // const [downloadDisabled, setDownloadDisabled] = useState(true);
  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  //console.log("userToken", userToken);
  // console.log(useRouteProps());

  //EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const pdf_download = (
    <div>
      Descargar PDF
    </div>
  );

  const xls_download = (
    <div>
      Descargar Excel
    </div>
  );

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    if(schoolYear && schoolYear !=="all" && schoolYear !== undefined){
      getInscriptionsBySchoolYearApi(accessToken,schoolYear).then(response => {
        setInscriptions(response.inscriptions);
      });

    }else if(schoolYear){
      getInscriptionsApi(accessToken).then(response => {
        setInscriptions(response.inscriptions);
      });
    }
  }, [schoolYear]);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then(response => {
      setListSchoolYears(response.schoolYears);
      if(response.schoolYears && response.schoolYears.length > 0){
        setSchoolYear(response.schoolYears[0]._id);
      }
    });
  }, []);

  useEffect(() => {
    if(coursesActive){
      setPropsPaginationActive({
        //total: coursesActive.totalDocs,
        total: coursesActive.totalDocs? coursesActive.totalDocs : null,
        pageSize: coursesActive.limit? coursesActive.limit : null,
        defaultCurrent: coursesActive.page? coursesActive.page : null,
        current: coursesActive.page? coursesActive.page : null,
      });
    }
  }, [coursesActive]);

  useEffect(() => {
    if(coursesInactive){
      setPropsPaginationInactive({
        //total: coursesInactive.totalDocs,
        total: coursesInactive.totalDocs,
        pageSize:coursesInactive.limit,
        defaultCurrent:coursesInactive.page,
        current:coursesInactive.page
      });
    }
  }, [coursesInactive]);

  useEffect(() => {
    // const listActive = [];
    setListData();
    if (filterText) {
      setPaginationActive({page:1, state:1, filter:filterText,filters:filters, schoolYear:schoolYear});
      setPaginationInactive({page:1, state:2, filter:filterText, filters:filters, schoolYear:schoolYear});
    }
    else if (filterText==="") {
      setPaginationActive({page:1, state:1,filters:filters, schoolYear:schoolYear});
      setPaginationInactive({page:1, state:2,filters:filters, schoolYear:schoolYear});
    }
  }, [filterText, filters, schoolYear]);

  useEffect(() => {
    if (coursesActive  && coursesActive.docs) {
      setCoursesActiveFilter(coursesActive.docs);
    }
  }, [coursesActive]);

  useEffect(() => {
    if (coursesInactive && coursesInactive.docs) {
      setCoursesInactiveFilter(coursesInactive.docs);
    }
  }, [coursesInactive]);

  useEffect(()=>{
    if(schoolYear && coursesActiveExport){
      setExportActivePdf(coursesActiveExport);
      setExportActiveExcel(formatDataExportExcel(coursesActiveExport));
    }
  },[coursesActiveExport, inscriptions]);

  useEffect(()=>{
    if(schoolYear && coursesInactiveExport){
      setExportInactivePdf(coursesInactiveExport);
      setExportInactiveExcel(formatDataExportExcel(coursesInactiveExport));
    }
  },[coursesInactiveExport,inscriptions]);

  const setListData = ()=>{
    // setCoursesActiveFilter([]);
    // setCoursesInactiveFilter([]);
    setPropsPaginationInactive({});
    setPropsPaginationActive({});
    setExportActiveExcel([]);
    setExportActivePdf([]);
    setExportInactiveExcel([]);
    setExportInactivePdf([]);
  }

  const getYearSchoolYear = (schoolYearId)=>{
    let result=listSchoolYears.findIndex(i=>i._id === schoolYearId);
    if(result !==-1){
      return listSchoolYears[result].year;
    }else{
      return false;
    }
  }

  const formatDataExportExcel= (courses)=>{
    let exportData=[];
    courses.map(course=>{
      let teachers=course.teachers.map(function(elem){
        return elem.description;
    }).join(" -");
      let days=course.days.map(function(elem){
        return elem.description;
      }).join(" ,");
      let courseData={
          name: course.name ,
          typeCourse: course.typeCourse? course.typeCourse.description : "" ,
          teachers: course.teachers? teachers  : "" ,
          days: course.days? days : "",
          place: course.place? course.place.description : "" ,
          duration: course.duration? course.duration.description : "" ,
          quota: course.quota? course.quota : "" ,
          enrolled: countStudentsCourseInscription(course._id),
          hour_start: course.hour_start? moment(course.hour_start,"HH:mm").isValid()?  moment(new Date(course.hour_start),"HH:mm").format("HH:mm") : course.hour_start : "",
          hour_end: course.hour_end? moment(course.hour_end,"HH:mm").isValid()?  moment(new Date(course.hour_end),"HH:MM").format("HH:mm") : course.hour_end : "",
       }
      exportData.push(courseData);
      return true; //retorno algo por el warning
    })
    return exportData;
  };

  const addCourseModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear -> Curso");
    setModalWidth(1000);
    setModalContent(
      <AddCourseForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadCourses={setReloadCourses}
      />
    );
  };

  const countStudentsCourseInscription = (courseId) => {
    // let result = inscriptions.filter((i) => i.course === courseId);
    // if(result.length > 0){
    let result = inscriptions.findIndex((i) => i.course === courseId);
    if(result!==-1){
      //let count=result[0].students? result[0].students.length : 0;
      let count=inscriptions[result].students? inscriptions[result].students.length : 0;
      return count;
    } else{
      return null;
    }
    // let result = inscriptions.filter((i) => i.course === courseId);
    // if(inscription){
    //   let count=inscription.students? inscription.students.length : 0;
    //   return count;
    // } else{
    //   return null;
    // }
  };
  

  // const exportCoursesPdf = (active) => {
  //   const token=accessToken;
  //   getExportCoursesApi(token, active, filterText, filters,schoolYear).then(response => {
  //     if(response.courses){
  //       if(response.courses.length > 0){
  //         listCoursesPdf(response.courses, false)
  //       }
  //     }
  //   });
  // };

 
  // const style = {
  //   height: 40,
  //   width: 40,
  //   lineHeight: '40px',
  //   borderRadius: 4,
  //   backgroundColor: '#1088e9',
  //   color: '#fff',
  //   textAlign: 'center',
  //   fontSize: 14,
  // };
  // let newData=[];
  // if(coursesActive && coursesActive.length >=0){
  //     coursesActive.map(c=>{
  //       let result = inscriptions.filter((i) => i.course === c._id);
  //       console.log(result)
  //       if(result && result.length > 0){
  //         c.inscription = result[0]
  //       }
  //       console.log(c)
  //       newData.push(c)
  //     })
  //     setCoursesActiveFilter(newData);
  // }

  return (
    <div className="list-courses">
      <div className="list-courses__header">
        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-courses__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewCoursesActives(!viewCoursesActives)}
              />
              <span>
                {viewCoursesActives ? "Activos" : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addCourseModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "courses", "all") ||
      checkAction(permissionsActive, userToken.role, "courses", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-courses__search">
          <Row gutter={24}>
           <Col span={4}>
          <Select 
            showSearch
            value={schoolYear}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }} 
            onChange={e=>setSchoolYear(e)}>
            {listSchoolYears? listSchoolYears.map(item=>{
                    return (
                  <Option key={item._id}>{item.year}</Option>)
                }) : null}
                <Option key={"all"}>{"Todos"}</Option>)
          </Select>
            </Col>
            <Col span={20}>
            <Search
              className="Buscar"
              allowClear
              placeholder=""
              onSearch={(e) => setFilterText(e)}
              enterButton
              width="40%"
            />
            </Col>
            </Row>

            {/* <Search
              className="Buscar"
              allowClear
              placeholder=""
              onSearch={(e) => setFilterText(e)}
              enterButton
              width="40%"
            /> */}
            <h2>
            <Button type="link" style={{fontSize: 12}}
              onClick={() => {
                setExpand(!expand);
              }}>
              {expand ? <UpOutlined /> : <DownOutlined />} Búsqueda Avanzada (filtros)
            </Button>
            {expand? (
              <div>
                {/* busqueda avanzada */}
                <AdvancedSearchCourseForm filters={filters} setFilters={setFilters}/>
              </div>): null}

            </h2>
            <h3>
              <strong>Info:</strong> debe crear primero el año lectivo y la carga horaria, luego el curso.
            </h3>
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewCoursesActives? (
        <>
          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={pdf_download}>
                <Space align="center" style={{ marginBottom: 18 }}>
                  <Button
                    //size="small"
                    type="default"
                    shape="round"
                    style={{ marginRight: 10 }}
                    disabled={exportActivePdf? exportActivePdf.length > 0? false : true : true}
                    onClick={() => {
                      // exportCoursesPdf(true);
                      listCoursesPdf(exportActivePdf, getYearSchoolYear(schoolYear), false)
                    }}
                  >
                    <FilePdfOutlined />
                  </Button>
                </Space>
              </Popover>

              <>
              <ExcelFile filename={'listadocursos'} element={
                <Popover content={xls_download}>
                  <Button
                    type="default"
                    shape="round"
                    disabled={exportActiveExcel? exportActiveExcel.length > 0? false : true : true}
                    >
                    <FileExcelOutlined />
                  </Button>
                </Popover>
                }>
                <ExcelSheet data={exportActiveExcel} name="Cursos">
                  <ExcelColumn label="Nombre" value="name" />
                  <ExcelColumn label="Tipo Curso" value="typeCourse" />
                   <ExcelColumn label="Docentes" value="teachers" />
                   <ExcelColumn label="lugar" value="place" />
                   <ExcelColumn label="Duracion" value="duration" />
                   <ExcelColumn label="Cupo" value="quota" />
                   <ExcelColumn label="Inscriptos" value="enrolled" />
                   <ExcelColumn label="Horario Inicio" value="hour_start" />
                   <ExcelColumn label="Horario Fin" value="hour_end" />
                   <ExcelColumn label="Dias" value="days" />
                </ExcelSheet>
              </ExcelFile>
            </>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "view") || isAdmin(roleById) ? (
            <>
              <CoursesActive
                coursesActive={coursesActiveFilter}
                propsPagination={propsPaginationActive}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadCourses={setReloadCourses}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                inscriptions={inscriptions}
                setPagination={setPaginationActive}
                filterText={filterText}
                filters={filters}
                schoolYear={schoolYear}
                countStudentsCourseInscription={countStudentsCourseInscription}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={pdf_download}>
                <Space align="center" style={{ marginBottom: 16 }}>
                  <Button
                    type="default"
                    shape="round"
                    style={{ marginRight: 10 }}
                    disabled={exportInactivePdf? exportInactivePdf.length > 0? false : true : true}
                    onClick={() => {
                       listCoursesPdf(exportInactivePdf, getYearSchoolYear(schoolYear), false);
                    }}
                  >
                    <FilePdfOutlined />
                  </Button>
                </Space>
              </Popover>

              <>
              <ExcelFile filename={'listadocursosInactivos'} element={
                <Popover content={xls_download}>
                  <Button
                    type="default"
                    shape="round"
                    disabled={exportInactiveExcel? exportInactiveExcel.length > 0? false : true : true}
                    >
                    <FileExcelOutlined />
                  </Button>
                </Popover>
                }>
                <ExcelSheet data={exportInactiveExcel} name="Cursos">
                  <ExcelColumn label="Nombre" value="name" />
                  <ExcelColumn label="Tipo Curso" value="typeCourse" />
                   <ExcelColumn label="Docentes" value="teachers" />
                   <ExcelColumn label="lugar" value="place" />
                   <ExcelColumn label="Duracion" value="duration" />
                   <ExcelColumn label="Cupo" value="quota" />
                   <ExcelColumn label="Inscriptos" value="enrolled" />
                   <ExcelColumn label="Horario Inicio" value="hour_start" />
                   <ExcelColumn label="Horario Fin" value="hour_end" />
                   <ExcelColumn label="Dias" value="days" />
                </ExcelSheet>
              </ExcelFile>
            </>
            </>
          ) : (
            <>
            </>
          )}
          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "view") || isAdmin(roleById) ? (
            <>
              <CoursesInactive
                coursesInactive={coursesInactiveFilter}
                propsPagination={propsPaginationInactive}
                setReloadCourses={setReloadCourses}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationActive}
                filterText={filterText}
                filters={filters}
                schoolYear={schoolYear}
                inscriptions={inscriptions}
                countStudentsCourseInscription={countStudentsCourseInscription}
                // setState={setState}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop />

    </div>
  );
}

function CoursesActive(props) {
  const { inscriptions, coursesActive,
    setPagination, propsPagination, filterText, filters, schoolYear,
     setReloadCourses, setIsVisibleModal, setModalTitle, setModalWidth,countStudentsCourseInscription,
      setModalContent,accessToken, userToken, permissionsActive, roleById } = props; //showDeleteConfirm

  // const [listCourses, setListCourses]=useState([]);
  //    useEffect(() => {
  //     let newData=[];
  //     coursesActive.map(c=>{
  //       let result = inscriptions.filter((i) => i.course === c._id);
  //       console.log(result)
  //       if(result && result.length > 0){
  //         c.inscription = result[0]
  //       }
  //       console.log(c)
  //       newData.push(c)
  //     })
  //     setListCourses(newData);
  //   }, [inscriptions, coursesActive]);
  
    const editCourse = (course) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar Curso ${course.name ? course.name : "curso"}`);
    setModalWidth(1000);
    setModalContent(
      <EditCourseForm
        course={course}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCourses={setReloadCourses}
      />
    );
  };

  const desactivateCourse = (course) => {
    activateCourseApi(accessToken, course._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadCourses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showAttendanceCourse = (course) => {
    let data =inscriptions.filter((i) => i.course === course._id);
    if(data && data.length > 0){
      attendanceDetailPdf(course,data[0] , false)
    }
  };


  const showDeleteConfirm = async(course) => {
    // let result = await verifyCourseDeletion(course);
    let response = await getInscriptionByCourseApi(accessToken, course._id);
    // console.log(response);
    let response2 = await getAbsencesByCourseApi(accessToken, course._id);
    //Verifico que exista inscripcion
      if (response.inscription || response2.absences) {
        //Inscripcion con estudiantes asociados
        if((response.inscription.students.length > 0) || (response2.absences.length > 0)){
          confirm({
            title: "Eliminando -> Curso",
            content: `No se puede eliminar ${course.name} existen estudiantes o inasistencias asociados ¿Deseas desactivarlo?`,
            okText: "Desactivar",
            okType: "danger",
            cancelText: "Cancelar",
            onOk() {
              desactivateCourse(course);
            },
          });
        }else if((response.inscription.students.length === 0) && (response2.absences.length === 0)){
          //inscripcion que no tiene estudiantes e inasistencias
            confirm({
              title: "Eliminando -> Curso",
              content: `¿Estás seguro que quieres eliminar a ${course.name ? course.name : "curso"}?`,
              okText: "Eliminar",
              okType: "danger",
              cancelText: "Cancelar",
              onOk() {
                      deleteCourseApi(accessToken, course._id)
                      .then((response) => {
                        notification["success"]({
                          message: response,
                        });
                        setReloadCourses(true);
                      })
                      .catch((err) => {
                        notification["error"]({
                          message: err,
                        });
                      });
                  }
            });
          }
      }else {
        //Inscripcion inexistente o hubo error al consultar (VERIFICAR)
        //  ModalAntd.error({
        //   title: 'Eliminando -> Curso',
        //   content: `No se pudo verificar que ${course.name ? course.name : "curso"} no tenga estudiantes inscriptos`,
        // });
        confirm({
          title: "Eliminando -> Curso",
          content: `${course.name ? course.name : "curso"} no tiene inscripción asociada. ¿Estás seguro que quieres eliminarlo?`,
          okText: "Eliminar",
          okType: "danger",
          cancelText: "Cancelar",
          onOk() {
                  deleteCourseApi(accessToken, course._id)
                  .then((response) => {
                    notification["success"]({
                      message: response,
                    });
                    setReloadCourses(true);
                  })
                  .catch((err) => {
                    notification["error"]({
                      message: err,
                    });
                  });
              }
        });
      }
  };

  // const countStudentsCourseInscription = (courseId) => {
  //   let result = inscriptions.filter((i) => i.course === courseId);
  //   if(result.length > 0){
  //     let count=result[0].students? result[0].students.length : 0;
  //     return count;
  //   } else{
  //     return null;
  //   }
  //   // let result = inscriptions.filter((i) => i.course === courseId);
  //   // if(inscription){
  //   //   let count=inscription.students? inscription.students.length : 0;
  //   //   return count;
  //   // } else{
  //   //   return null;
  //   // }
  // };

  /*
  const verifyCourseInscription = (course, inscriptions) => {
    if(inscriptions.filter((i) => i.course === course._id).length > 0){
      console.log(course.name);
      //notification["success"]({
      //  message:
      //    "existe inscripcion",
      //});

      return(
        <Link to={{pathname:`/admin/studentsCourse/${course.name}`,
          state:{ id:`${course._id}`}}} >
        </Link>
        );

    } else{
      console.log(course.name);

      return(
        <Link to={{pathname:`/admin/inscriptions`}} >
        </Link>
      );
      //notification["error"]({
      //  message:
      //    "debe crear la inscripcion del curso",
    //});
  }
}
*/

  //VOLVER HACIA ATRAS
  // const onChangePage = (newPage) => {
  //   history.push(`${location.pathname}?page=${newPage}`);
  // };

  const edit_course = (
    <div>
      {" "}
      <p>Editar curso</p>{" "}
    </div>
  );

  const desactivate_course = (
    <div>
      {" "}
      <p>Pasar a cursos inactivos</p>{" "}
    </div>
  );

  const delete_course = (
    <div>
      {" "}
      <p>Eliminar curso</p>{" "}
    </div>
  );

  const attendance_course = (
    <div>
      {" "}
      <p>Asistencia</p>{" "}
    </div>
  );

  const absence_course = (
    <div>
      {" "}
      <p>Inasistencias</p>{" "}
    </div>
  );


  // const download_course = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle curso</p>{" "}
  //   </div>
  // );
  const enroll_students = (
    <div>
      {" "}
      <p>Inscribir</p>{" "}
    </div>
  );

  const columns = [
    // {
    //   title: "Código",
    //   //dataIndex: "course_code",
    //   render: course => course.id_old ? course.id_old: course.course_code,
    // },
    {
      title: "Nombre",
      dataIndex: "name",
      width: '30%'
    },
    {
      title: "Tipo",
      dataIndex: "typeCourse",
      width: '8%',
      render:(typeCourse) =>
        typeCourse? typeCourse.description : ""
    },
    // {
    //   title: "Lugar",
    //   dataIndex: "place",
    //   render:(place)=>
    //     place? place.description : ""
    // },
    {
      title: "Docentes",
      dataIndex: "teachers",
      width: '15%',
      render: teachers => teachers? (
        teachers.map(teacher =>
            teacher? teacher.description : null
        )
      ): null,
    },
    {
      title: "Inscriptos",
      dataIndex: "_id",
      width: '10%',
      render: _id=>
        _id? countStudentsCourseInscription(_id) : "-" 
    },
    {
      title: "Cupos",
      dataIndex: "quota",
      width: '8%'
    },
    /*
    {
      title: "Inscriptos",
      //dataIndex: "place",
    },
    */
    // {
    //   title: "Año",
    //   dataIndex: "schoolYear",
    //   render: (schoolYear) =>
    //   schoolYear? schoolYear.year : "",
    //   width: '5%'
    // },
    {
      title: "Acción",
      key: "action",
      width: '30%',
      ellipsis:true,
      render: (text, course) => (
        <div>
          {
            checkAction(permissionsActive, userToken.role, "courses", "all") ||
            checkAction(permissionsActive, userToken.role, "courses", "edit") ||
            isAdmin(roleById) ? (
              <>
                <Popover content={edit_course}>
                  <Button
                    className="button-edit"
                    onClick={() => editCourse(course)}
                  >
                    <EditOutlined />
                  </Button>
                </Popover>
              </>
            ) : (
              <>
              </>
            )
          }

          {
            checkAction(permissionsActive, userToken.role, "courses", "all") ||
            checkAction(permissionsActive, userToken.role, "courses", "enable") ||
            isAdmin(roleById) ? (
              <>
                <Popover content={desactivate_course}>
                  <Button
                    className="button-course"
                    type="primary"
                    onClick={() => desactivateCourse(course)}
                  >
                    <StopOutlined />
                  </Button>
                </Popover>
              </>
            ) : (
              <>
              </>
            )
          }

          {
            checkAction(permissionsActive, userToken.role, "courses", "all") ||
            checkAction(permissionsActive, userToken.role, "courses", "delete") ||
            isAdmin(roleById) ? (
              <>
                <Popover content={delete_course}>
                  <Button
                    className="button-course"
                    type="danger"
                    onClick={() => showDeleteConfirm(course)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Popover>
              </>
            ) : (
              <>
              </>
            )
          }

          {
            checkAction(permissionsActive, userToken.role, "courses", "all") ||
            checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
            isAdmin(roleById) ? (
              <>
                
                  {inscriptions?
                    // (inscriptions.filter((i) => i.course === course._id).length > 0 ?
                    (inscriptions.findIndex((i) => i.course === course._id)!==-1 ?
                    <>
                       <Popover content={enroll_students}>
                        <Link to={{pathname:`/admin/studentsCourse/${course.name}`,
                          state:{ id:`${course._id}`}}} >
                          <Button
                            className="button-enroll"
                          >
                            <FileDoneOutlined />
                          </Button>
                         {/* BOTON PARA EXPORTAR PDF DE ASISTENCIAS */}
                        </Link>
                        </Popover>
                        <Popover content={attendance_course}>
                          <Button
                          className="button-attendance"
                          onClick={() => showAttendanceCourse(course)}
                        >
                          <FileOutlined />
                        </Button>
                        </Popover>
                         {/* BOTON PARA LAS INASISTENCIAS */}
                        <Popover content={absence_course}>
                        <Link to={{pathname:`/admin/courseAbsences/${course.name}`,
                              state:{ id:`${course._id}`}}} >
                              <Button
                                className="button-absences"
                              >
                                <ProfileOutlined />
                              </Button>
                            </Link>
                        </Popover>
                    </>
                    :
                        <Link to={{pathname:`/admin/inscriptions`}} >
                          <Button
                            className="button-enroll"
                          >
                            <FileDoneOutlined />
                          </Button>
                        </Link>
                    )
                    : (<Button
                      className="button-enroll" disabled={true}
                    >
                      <FileDoneOutlined />
                    </Button>)
                  }
              </>
            ) : (
              <>
              </>
            )}
        {/* BOTON PARA EXPORTAR PDF DE ASISTENCIAS */}
        {/* {
            checkAction(permissionsActive, userToken.role, "courses", "all") ||
            checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
            isAdmin(roleById) ? (
              <>
                <Popover content={attendance_course}>
                  {inscriptions?
                    (inscriptions.filter((i) => i.course === course._id).length > 0 ?
                          <Button
                            className="button-attendance"
                            onClick={() => showAttendanceCourse(course)}
                          >
                            <FileOutlined />
                          </Button>
                    :
                    null
                    ): null
                 }
                </Popover>
              </>
            ) : (
              <>
              </>
            )} */}
        {/* BOTON PARA LAS INASISTENCIAS */}
        {/* {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "enroll") || isAdmin(roleById) ? (
            <>
              <Popover content={absence_course}>
              {inscriptions?
               (inscriptions.filter((i) => i.course === course._id).length > 0 ?
                  (<Link to={{pathname:`/admin/courseAbsences/${course.name}`,
                              state:{ id:`${course._id}`}}} >
                              <Button
                                className="button-absences"
                              >
                                <ProfileOutlined />
                              </Button>
                            </Link>)
                  : null
                )
                :null
                }
              </Popover>
            </>
          ) : (
            <>
            </>
          )} */}
        </div>
      ),
    },
  ];

  return (
    <>
    <Table
      footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      pagination={{
      ...propsPagination,
      showSizeChanger:false,
      onChange:(newPage) => {
        setPagination({page:newPage,
          state:1,
          filter:filterText,
          filters:filters,
          schoolYear:schoolYear})
        }
      }}
      dataSource={coursesActive}
      columns={columns}
      rowKey="_id"
    />
    </>
  );
}

function CoursesInactive(props) {
  const { coursesInactive,
          setReloadCourses,
          accessToken,
          userToken,
          permissionsActive,
          roleById,
          setPagination,
          propsPagination,
          filterText,
          filters,
          schoolYear,
          inscriptions,
          countStudentsCourseInscription
          // setIsVisibleModal,
          // setModalContent,
          // setModalTitle,
          // setModalWidth
        } = props; //showDeleteConfirm

  const activateCourse = (course) => {
    //const accessToken = getAccessToken();

    activateCourseApi(accessToken, course._id, true)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadCourses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async(course) => {
    let response = await getInscriptionByCourseApi(accessToken, course._id);
    let response2 = await getAbsencesByCourseApi(accessToken, course._id);
      if (response.inscription || response2.absences) {
        if((response.inscription.students.length > 0) || (response2.absences.length > 0)){
          ModalAntd.info({
            title:"Eliminando -> Curso",
            content:<p> No se puede eliminar {course.name} existen estudiantes o inasistencias asociados</p>,
            onOk(){},
          })
        }else if((response.inscription.students.length === 0) && (response.inscription.students.length === 0)){
            confirm({
              title: "Eliminando -> Curso",
              content: `¿Estás seguro que quieres eliminar a ${course.name ? course.name : "curso"}?`,
              okText: "Eliminar",
              okType: "danger",
              cancelText: "Cancelar",
              onOk() {
                      deleteCourseApi(accessToken, course._id)
                      .then((response) => {
                        notification["success"]({
                          message: response,
                        });
                        setReloadCourses(true);
                      })
                      .catch((err) => {
                        notification["error"]({
                          message: err,
                        });
                      });
                  }
            });
          }
      }else {
         //Inscripcion inexistente o hubo error al consultar (VERIFICAR)
        //  ModalAntd.error({
        //   title: 'Eliminando -> Curso',
        //   content: `No se pudo verificar que ${course.name ? course.name : "curso"} no tenga estudiantes inscriptos`,
        // });
        confirm({
          title: "Eliminando -> Curso",
          content: `${course.name ? course.name : "curso"} no tiene inscripción asociada. ¿Estás seguro que quieres eliminarlo?`,
          okText: "Eliminar",
          okType: "danger",
          cancelText: "Cancelar",
          onOk() {
                  deleteCourseApi(accessToken, course._id)
                  .then((response) => {
                    notification["success"]({
                      message: response,
                    });
                    setReloadCourses(true);
                  })
                  .catch((err) => {
                    notification["error"]({
                      message: err,
                    });
                  });
              }
        });
      }
  };

  // const countStudentsCourseInscription = (courseId) => {
  //   let result= inscriptions.filter((i) => i.course === courseId);
  //   if(result.length > 0){
  //     let count=result[0].students? result[0].students.length : 0;
  //     return count;
  //   } else{
  //     return null;
  //   }
  // };

  const activate_course = (
    <div>
      {" "}
      <p>Pasar a cursos activos</p>{" "}
    </div>
  );

  const delete_course = (
    <div>
      {" "}
      <p>Eliminar curso</p>{" "}
    </div>
  );

  // const download_course = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle curso</p>{" "}
  //   </div>
  // );

  const columns = [
    // {
    //   title: "Código",
    //   //dataIndex: "course_code",
    //   render: course => course.id_old ? course.id_old: course.course_code,
    // },
    {
      title: "Nombre",
      dataIndex: "name",
      width: '40%'
    },
    {
      title: "Tipo",
      dataIndex: "typeCourse",
      width: '5%',
      render:(typeCourse)=>
        typeCourse? typeCourse.description : ""
    },
    {
      title: "Docentes",
      dataIndex: "teachers",
      width: '20%',
      render: teachers => teachers? (
          teachers.map(teacher =>
              teacher? teacher.description : null
          )
      ) : null,
    },
    {
      title: "Inscriptos",
      dataIndex: "_id",
       render: _id=>
        _id? countStudentsCourseInscription(_id) : "-" 
    },
    {
      title: "Cupos",
      dataIndex: "quota",
      width: '5%',
    },
    // {
    //   title: "Año",
    //   dataIndex: "schoolYear",
    //   render:(schoolYear)=>
    //   schoolYear? schoolYear.year : "",
    //   width: '5%'
    // },
    {
      title: "Acción",
      key: "action",
      width: '25%',
      render: (text, course) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_course}>
                <Button
                  className="button-activate"
                  onClick={() => activateCourse(course)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_course}>
                <Button
                  className="button-course"
                  type="danger"
                  onClick={() => showDeleteConfirm(course)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      pagination={{
        ...propsPagination,
        showSizeChanger:false,
        onChange:(newPage) => {
          setPagination({page:newPage,
          state:2,
          filter:filterText,
          filters:filters,
          schoolYear:schoolYear})
        }
      }}
        dataSource={coursesInactive}
        columns={columns}
        rowKey="_id"
    />
  );
}