import React, { useState, useEffect } from "react"; //useCallback

import { Form, Input, Select, Button, Row, Col, notification } from "antd";

import { MODULES } from '../../../../utils/constants';
import { convertModulesEngToEsp } from "../../../../utils/convert"; //convertActionsEngToEsp

// import {
//   updateMenuApi,
// } from "../../../../api/menu";

import { getAccessToken } from "../../../../api/auth";
import { updateMenuApi, getMenusActiveApi } from "../../../../api/menu";

import "./EditMenuForm.scss";
//import { useForm } from "antd/lib/form/util";
//import MenuItem from "antd/lib/menu/MenuItem";

export default function EditMenuForm(props) {
  const { menu, setIsVisibleModal, setReloadMenu } = props;
  const [menuData, setMenuData] = useState({});
  const [menuItemsList, setMenuItemsList] = useState([]);

  useEffect(() => {
    setMenuData({
      title: menu.title,
      url: menu.url,
      nameModule: menu.nameModule,
      higher: menu.higher,
      order: menu.order,
      active: menu.active,
    });
  }, [menu]);

  console.log(menu);

  useEffect(() => {
    //lista que almacena los menus
    const token = getAccessToken();
    getMenusActiveApi(token, true).then((response) => {
      console.log(response.menu);
      setMenuItemsList(response.menu);
    });
  }, []);

  const updateMenu = (e) => {
    const token = getAccessToken();
    let menuUpdate = menuData;

    if (!menuData.title || !menuData.url) {
      notification.error({ message: "obligatorios: Modulo, Titulo y URL" });
      return;
    } else {
        updateMenuApi(token, menu._id,menuUpdate).then(result => {
        notification.success({ message: result.message });
          setIsVisibleModal(false);
          setReloadMenu(true);
        });
    }
  };

  return (
    <div className="edit-menu-form">
      <EditForm
        menuData={menuData}
        setMenuData={setMenuData}
        updateMenu={updateMenu}
        menuItemsList={menuItemsList}
        MODULES={MODULES}
      />
    </div>
  );
}

function EditForm(props) {
  const { menuData, setMenuData, updateMenu, MODULES, menuItemsList } = props;
  const { Option } = Select;

  return (
    <Form className="form-edit" onFinish={updateMenu}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
      <label className="control-label">
           Modulo
      </label>
      <Form.Item>
        <Select
          onChange={(e) => setMenuData({ ...menuData, nameModule: e })}
          value={menuData.nameModule}
        >
          {MODULES
                ? MODULES.map((i) => {
                    return <Option key={i}>{convertModulesEngToEsp(i)}</Option>;
                  })
                : "No hay modulos disponibles"}
        </Select>
      </Form.Item>
      </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={24}>
      <label className="control-label">
            <span className="control-required">*</span> Titulo (renombrar)
      </label>
      <Form.Item>
        <Input
          placeholder="Titulo"
          value={menuData.title}
          onChange={(e) => {
            setMenuData({ ...menuData, title: e.target.value });
          }}
        />
      </Form.Item>
      </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
             Superior
          </label>
          <Form.Item>
            <Select
              onChange={(e) => setMenuData({ ...menuData, higher: e })}
              value={menuData.higher? menuData.higher._id : null}
            >
              {menuItemsList
                ? menuItemsList.map((i) => {
                    return <Option key={i._id}>{(i.title)}</Option>;
                  })
                : "No hay menus disponibles"}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <label className="control-label">
            <span className="control-required">*</span>URL
      </label>
      </Row>
      <Form.Item>
        <Input
          placeholder="url"
          value={menuData.url}
          onChange={(e) => {
            setMenuData({ ...menuData, url: e.target.value });
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="btn-submit">
          Actualizar menu
        </Button>
      </Form.Item>
    </Form>
  );
}
