import React, { useState, useEffect } from "react";
//import difference from 'lodash/difference';
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Divider,
  Table,
  Select,
  Button,
  Row,
  Col,
  notification,
  Modal,
  Alert,
  Spin
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { updateAbsenceApi } from "../../../../api/absence";
import { getCourseApi, getCoursesActiveApi } from "../../../../api/course";
import { getStudentsByCourseApi } from "../../../../api/inscription";
import { getAccessToken } from "../../../../api/auth";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import {
  //formatDateView, 
  formatDateCalendar
} from "../../../../utils/formatDate";

import "./EditAbsenceForm.scss";

export default function EditAbsenceForm(props) {
  const { absence, setIsVisibleModal, setReloadAbsences } = props; //absente trae los student en null
  const [ absenceData, setAbsenceData] = useState({});
  const [ courseData, setCourseData] = useState({});
  const [listCoursesActive, setListCoursesActive]=useState([]);
  const [listStudentsCourse, setListStudentsCourses]=useState([]);
  const [listStudentsAbsences, setListStudentsAbsences]=useState([]);
  // const [listStateCoursesActive, setListStateCoursesActive] = useState([]);
  const [loading, setLoading]=useState(false);

  const accessToken = getAccessToken();

  //trae los datos de la inasistencia
  useEffect(() => {
    setAbsenceData({
      absence_code: absence.absence_code,
      course: absence.course._id,
      date:absence.date? formatDateCalendar(absence.date): null,
      students: absence.students //los student estan en null
    });
  }, [absence]);

  // console.log("absence: ", absence);
  //console.log("absenceData: ", absenceData);

  useEffect(() => {
    setCourseData({
      name: absence.course.name,
      absencesAllowed: absence.course.absencesAllowed
    });
  }, [absence]);

  useEffect(() => {
    let isMounted=true;
    getCoursesActiveApi(accessToken, true).then((response) => {
      if(isMounted){
        setListCoursesActive(response.courses);
      }
    });
    return ()=>{
      isMounted=false;
    }
  }, []);

  // useEffect(() => {
  //   let isMounted=true;
  //   getStateCoursesActiveApi(accessToken, true).then((response) => {
  //     if(isMounted){
  //       setListStateCoursesActive(response.stateCourses);
  //     }
  //   });
  //   return ()=>{
  //     isMounted=false;
  //   }
  // }, []);

  useEffect(() => {
    let isMounted=true;
    if(absenceData.course){
      getStudentsByCourseApi(accessToken, absenceData.course).then((response) => {
        if(isMounted){
          setListStudentsCourses(response.students);
        }
      });
    }
    return()=>{
      isMounted=false;
    }
  }, [absenceData.course]);

  useEffect(() => {
    if(absence.students){
      let listStudents=[];
      absence.students.map(data=>{
        let newData={
          student:data.student._id,
          studentData:data.student,
          justify:data.justify
        }
        listStudents.push(newData);
        return true; //retorno algo por el warning
      })
      setListStudentsAbsences(listStudents);
    }
  }, [absence]);
  

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !absenceData.date ||
      !absenceData.course
    ) {
      //BUG
      notification["error"]({
        message: "Obligatorios: Fecha y Curso.",
      });
      errorExists = true;
    }if(validateStudents(listStudentsAbsences)){
      notification["error"]({
        message: "Error en estudiantes ingresados",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const validateStudents=(list)=>{
    let err=false;
    list.map(i=>{
      if(!i.student){
        err=true;
      }
      return true; //retorno algo por el warning
    })
    return err;
  };

  const updateAbsence = async (e) => {
    e.preventDefault();
    setLoading(true);
    const error = isFormValid();
    absenceData.students=listStudentsAbsences;
    if (!error) {
      const token = getAccessToken();
      let absenceUpdate = absenceData; //es let porque se va actualizando

      await updateAbsenceApi(token, absenceUpdate, absence._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
          setLoading(false);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
          setLoading(false);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          setIsVisibleModal(false);
          setLoading(false);
          setReloadAbsences(true);
          //console.log("respuesta: " + result.message);
          
        }
      });
    }else{
      setLoading(false);}
  };

  const infoResult=(err, message, count)=>{
    Modal.info({
      title:'Curso Finalizado',
      content:(
        <div>
          <p> {message} </p>
          <p> Cantidad de estudiantes actualizados: {count} </p>
        </div>
      )
    })
  }

  return (
    <div className="edit-absence-form">
      <EditForm
        absenceData={absenceData}
        setAbsenceData={setAbsenceData}
        courseData={courseData}
        updateAbsence={updateAbsence}
        listCoursesActive={listCoursesActive}
        listStudentsAbsences={listStudentsAbsences}
        setListStudentsAbsences={setListStudentsAbsences}
        listStudentsCourse={listStudentsCourse}
        loading={loading}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    absenceData,
    setAbsenceData,
    courseData,
    updateAbsence,
    listStudentsAbsences,
    setListStudentsAbsences,
    listStudentsCourse,
    loading
  } = props;
  const { Option } = Select;

  const addStudents = () => {
    if (absenceData.student) {
        let result = listStudentsCourse.filter((i) => i._id === absenceData.student);
        if(result!=="undefined"){
          if(!listStudentsAbsences.filter((i) => i.student === result[0].student._id ).length > 0){
            const newData = {
              student: result[0].student._id,
              studentData: result[0].student,
              justify: false,
            };
            setListStudentsAbsences([...listStudentsAbsences, newData] );
            setAbsenceData({...absenceData, student:null});
          }else{
            notification["error"]({
              message:
                "El estudiante ya se encuentra seleccionado",
            });
          }
      }
    } else {
      notification["error"]({
        message:
          "Debe elegir al menos un estudiante",
      });
    }
  };
 
  return (
    <Form className="form-edit" onSubmit={updateAbsence}>
      {/* //onFinish={updateUser} */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>Código
          </label>
          <Form.Item>
            <InputNumber
              // prefix={<TagOutlined />}
              placeholder="Automático"
              value={absenceData.absence_code}
              onChange={(e) => setAbsenceData({ ...absenceData, absence_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha
          </label>
          <Form.Item>
            <Input
              // prefix={<CalendarOutlined/>}
              type="date"
              placeholder="Fecha "
              value={absenceData.date}
              onChange={(e) =>
                setAbsenceData({ ...absenceData, date: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Curso
          </label>
          <Form.Item>
          <Input
              placeholder="Curso "
              value={courseData.name}
              // onChange={(e) =>
              //   setAbsenceData({ ...absenceData, course: e.target.value })
              // }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={16}>
          <label className="control-label">
            <span className="control-required">*</span> Estudiantes Inscriptos
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Seleccione los estudiantes"
              value={absenceData.student}
              onChange={(e) =>
                setAbsenceData({
                  ...absenceData,
                  student: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStudentsCourse
                ? listStudentsCourse.map((i) => {
                  return <Option key={i._id}>{i.student.name + " " + i.student.lastname + " (" + (i.student.email? i.student.email : "")+ ") "}</Option>;
                  })
                : "No hay estudiantes disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button
              type="primary"
              className="button-add"
              size="middle"
              onClick={addStudents}
            >
              <PlusOutlined /> Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Students studentsData={listStudentsAbsences} setStudentsData={setListStudentsAbsences} />
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          loading={loading}
          onClick={updateAbsence}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}

function Students(props) {
  const { studentsData, setStudentsData } = props;
  // console.log("students",studentsData);

  const showDeleteConfirm = (record) => {
    const d = studentsData.filter((i) => i !== record);
    if (d.length === 0) {
      setStudentsData([]);
    } else {
      setStudentsData(d);
    }
  };

  const changeJustify = (rowIndex, columnKey) => event => {
    const newData = [...studentsData];
    newData[rowIndex][columnKey] = event.target.checked;
    setStudentsData(newData);
  };

  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "studentData",
    //   //render: (studentData, row) =>
    //   //  row.studentData ? row.studentData.student_code : "row",
    //   width: "10%",
    //   editable: false,
    //   render: (studentData) =>
    //   studentData? studentData.student_code : null,
    // },
    {
      title: "Nombre",
      dataIndex: "studentData",
      render: (studentData) =>
        studentData ? studentData.name : null,
      width: "15%",
      editable: false,
    },
    {
      title: "Apellido",
      dataIndex: "studentData",
      render: (studentData) =>
        studentData ? studentData.lastname : null,
      width: "15%",
      editable: false,
    },
    {
      title: "E-mail",
      dataIndex: "studentData",
      render: (studentData) =>
        studentData ? studentData.email : null,
      width: "30%",
      editable: false,
    },
    {
      title: "Justificado",
      dataIndex: "justify",
      render: (value, record, rowIndex) => (
        <Checkbox
          checked={value}
          onChange={changeJustify(rowIndex, "justify")}
        />
      ),
      width: "10%",
      editable: true,
    },
    {
      title: "Acción",
      dataIndex: "operation",
      width: "10%",
      render: (_, record) => {
        return (
          <span>
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={(e) => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        )
      }
    }
  ];
  return (
    <Table
      columns={columns}
      dataSource={studentsData}
      scroll={{ y: 250 }}
      rowKey="student"
    />
  );
}