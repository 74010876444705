// import { notification, message } from "antd";
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { getAccessToken } from "../../../api/auth";
import { getInscriptionByCourseApi } from "../../../api/inscription";
import ListStudentsCourse from "../../../components/Admin/Inscriptions/ListStudentsCourse";

export default function StudentsCourse(props) {
  const [inscriptionCourse, setInscriptionCourse] = useState([]);
  const [reloadInscriptionCourse, setReloadInscriptionCourse] = useState(false);
  const token = getAccessToken();
  //const { courseID } = props.location.state.id ? props.location.state.id : false;

  const courseID = props.location.state.id;
  //console.log(props.location.state.id);

  useEffect(() => {
    let isMounted=true;
    if (props.location.state) {
        getInscriptionByCourseApi(token, props.location.state.id).then(
          (response) => {
            if(isMounted){
              setInscriptionCourse(response.inscription);
            }
          }
        );
    }
    setReloadInscriptionCourse(false);
    return ()=>{
      isMounted=false;
    }
  }, [token, reloadInscriptionCourse]);

  //console.log(inscriptionCourse.course);

  return (
    <div className="inscriptions">
      {props.location.state ? (
        <>
          <h3>
            Listado de Estudiantes del Curso "
            {inscriptionCourse.course ? inscriptionCourse.course.name : null}" 
            <span>{inscriptionCourse? inscriptionCourse.students? " / Cantidad de inscriptos: " + inscriptionCourse.students.length : null : null}</span>
          </h3>
          {inscriptionCourse && inscriptionCourse.course?
          <ListStudentsCourse
            inscription={inscriptionCourse}
            courseID={courseID}
            setReloadInscriptionCourse={setReloadInscriptionCourse}
          />:null}
        </>
      ) : (
        <>
          <Redirect to="/admin/courses" />
        </>
      )}
    </div>
  );
}

/*
notification["error"]({
    message: response.message,
  });
*/
