import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
    //getTeachersActiveApi, 
    getTeachersApi 
} from "../../../api/teacher";
import ListTeachersReports from "../../../components/Admin/Teachers/ReportsTeachers";


export default function TeachersReports() {
    const [teachersActive, setTeachersActive] = useState([]);
    const [teachersInactive, setTeachersInactive] = useState([]);
    const [reloadTeachers, setReloadTeachers ] = useState(false);
    const token = getAccessToken();
    const [paginationActive, setPaginationActive]= useState({page:1});
    const [paginationInactive, setPaginationInactive]= useState({page:1});

    useEffect(() => {
        getTeachersApi(token, true,50,paginationActive.page,paginationActive.filter).then(response => {
            setTeachersActive(response.teachers);
        });
        setReloadTeachers(false);
    }, [token, paginationActive,reloadTeachers]);

    useEffect(() => {
        getTeachersApi(token, false, 50,paginationInactive.page,paginationActive.filter).then(response => {
            setTeachersInactive(response.teachers);
        });
        setReloadTeachers(false);
    }, [token, paginationInactive,reloadTeachers]);

    return (
        <div className="teachers">
            <h3>Reporte de Docentes</h3>
            <ListTeachersReports setPaginationActive={setPaginationActive} setPaginationInactive={setPaginationInactive} teachersActive={teachersActive} teachersInactive={teachersInactive} setReloadTeachers={setReloadTeachers}
                />

        </div>
    );
}