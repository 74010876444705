import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditWorkloadForm from "../EditWorkloadForm";
import AddWorkloadForm from "../AddWorkloadForm";
import {
  activateWorkloadApi,
  deleteWorkloadApi,
} from "../../../../api/workload";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getCoursesByWorkloadApi } from "../../../../api/course";
import jwtDecode from "jwt-decode";

import "./ListWorkloads.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListWorkloads(props) {
  const { workloadsActive, workloadsInactive, setReloadWorkloads } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewWorkloadsActives, setViewWorkloadsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [workloadsActiveFilter, setWorkloadsActiveFilter] = useState([]);
  const [workloadsInactiveFilter, setWorkloadsInactiveFilter] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (workloadsActive) {
      workloadsActive.forEach((element) => {
          if (
            element.description.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          (element.workload_code && element.workload_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()))||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setWorkloadsActiveFilter(listActive);
  }, [filterText, workloadsActive]);

  useEffect(() => {
    const listInactive = [];
    if (workloadsInactive) {
      workloadsInactive.forEach((element) => {
          if (
            element.description.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          (element.workload_code && element.workload_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()))||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setWorkloadsInactiveFilter(listInactive);
  }, [filterText, workloadsInactive]);

  const addWorkloadModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Carga Horaria");
    setModalContent(
      <AddWorkloadForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadWorkloads={setReloadWorkloads}
      />
    );
  };

  return (
    <div className="list-workloads">
      <div className="list-workloads__header">
        {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
        checkAction(permissionsActive, userToken.role, "workloads", "enable") ||
        isAdmin(roleById) ? (
          <>
            <div className="list-workloads__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewWorkloadsActives(!viewWorkloadsActives)}
              />
              <span>{viewWorkloadsActives ? "Activos" : "Inactivos"}</span>
            </div>
          </>
        ) : (
          <></>
        )}

        {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
        checkAction(permissionsActive, userToken.role, "workloads", "add") ||
        isAdmin(roleById) ? (
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={addWorkloadModal}
            >
              Nuevo
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
      checkAction(permissionsActive, userToken.role, "workloads", "view") ||
      isAdmin(roleById) ? (
        <>
          <div className="list-workloads__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2></h2>
            <h3>
              <strong>Info:</strong> ejemplos de carga horaria "16 a 18 hs", "16-18".
            </h3>
          </div>
        </>
      ) : (
        <></>
      )}

      {viewWorkloadsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
          checkAction(permissionsActive, userToken.role, "workloads", "view") ||
          isAdmin(roleById) ? (
            <>
              <WorkloadsActive
                workloadsActive={workloadsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadWorkloads={setReloadWorkloads}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
          checkAction(permissionsActive, userToken.role, "workloads", "view") ||
          isAdmin(roleById) ? (
            <>
              <WorkloadsInactive
                workloadsInactive={workloadsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadWorkloads={setReloadWorkloads}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function WorkloadsActive(props) {
  const {
    workloadsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadWorkloads,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props;

  const editWorkload = (workload) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${
        workload.description ? workload.description : "sin descripcion"
      }`
    );
    setModalContent(
      <EditWorkloadForm
        workload={workload}
        setIsVisibleModal={setIsVisibleModal}
        setReloadWorkloads={setReloadWorkloads}
      />
    );
  };

  const desactivateWorkload = (workload) => {
    const accessToken = getAccessToken();

    activateWorkloadApi(accessToken, workload._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadWorkloads(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (workload) => {
    const accessToken = getAccessToken();
    let response = await getCoursesByWorkloadApi(accessToken, workload._id);
    if (response.courses.length > 0) {
      confirm({
        title: "Eliminando -> Carga Horaria",
        content: `No se puede eliminar '${workload.description}' porque existen cursos asociados, ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateWorkload(workload);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Carga Horaria",
        content: `¿Estás seguro que quieres eliminar a ${ workload.description ? workload.description : "sin descripción"}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteWorkloadApi(accessToken, workload._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadWorkloads(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });

        },
      });
    }
  };

  const edit_workload = (
    <div>
      {" "}
      <p>Editar carga horaria</p>{" "}
    </div>
  );

  const desactivate_workload = (
    <div>
      {" "}
      <p>Pasar a carga horaria inactivos</p>{" "}
    </div>
  );

  const delete_workload = (
    <div>
      {" "}
      <p>Eliminar carga horaria</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "workload_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Inasistencias Permitidas",
      dataIndex: "absencesAllowed",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, workload) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
          checkAction(permissionsActive, userToken.role, "workloads", "edit") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={edit_workload}>
                <Button
                  className="button-edit"
                  onClick={() => editWorkload(workload)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "workloads",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_workload}>
                <Button
                  className="button-workload"
                  type="primary"
                  onClick={() => desactivateWorkload(workload)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "workloads",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_workload}>
                <Button
                  className="button-workload"
                  type="danger"
                  onClick={() => showDeleteConfirm(workload)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return workloadsActive.length > 0? `Total de registros: ${workloadsActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={workloadsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function WorkloadsInactive(props) {
  const {
    workloadsInactive,
    setReloadWorkloads,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props;

  const activateWorkload = (workload) => {
    const accessToken = getAccessToken();

    activateWorkloadApi(accessToken, workload._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadWorkloads(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (workload) => {
    const accessToken = getAccessToken();
    let response = await getCoursesByWorkloadApi(accessToken, workload._id);
    if (response.courses.length > 0) {
      ModalAntd.info({
        title: "Eliminando -> Carga Horaria",
        content: `No se puede eliminar '${workload.description}' porque existen cursos asociados.`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Carga Horaria",
        content: `¿Estás seguro que quieres eliminar a ${ workload.description ? workload.description : "sin descripción"}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteWorkloadApi(accessToken, workload._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadWorkloads(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });

        },
      });
    }
  };

  const activate_workload = (
    <div>
      {" "}
      <p>Pasar a carga horaria activos</p>{" "}
    </div>
  );

  const delete_workload = (
    <div>
      {" "}
      <p>Eliminar carga horaria</p>
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "workload_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Inasistencias Permitidas",
      dataIndex: "absencesAllowed",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, workload) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
          checkAction(permissionsActive, userToken.role, "workloads", "edit") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={activate_workload}>
                <Button
                  className="button-activate"
                  onClick={() => activateWorkload(workload)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "workloads", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "workloads",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_workload}>
                <Button
                  className="button-workload"
                  type="danger"
                  onClick={() => showDeleteConfirm(workload)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return workloadsInactive.length > 0? `Total de registros: ${workloadsInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={workloadsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
