import { downloadPdf, openPdf , getStyles, getHeader, getLogo} from "../../../../utils/pdfManager"; //docDefinitionTable
// import { convertTypeIva } from "../../../../utils/convert";
import { formatDateView } from "../../../../utils/formatDate";
//import Logo from '../../../../assets/img/png/icba.png';
//import moment from "moment";

export function absenceDetailPdf(data, isDownload) {
  const _formatCourse = (data) => {
    return [
      { text: `Nombre: ${data.name ? data.name : ""}`, style:'list' },
      { text: `Docentes: ${data.teachers? data.teachers.map(teacher=> teacher.description) : "sin datos"}` , style:'list'},
    ];
  };

  const _formatStudent = (data) => {
    return data.map((item) => {
      return [
        { text: `${item.student ? item.student.lastname : ""}` },
        { text: `${item.student? item.student.name : "sin datos"}`},
        { text: `${item.student ? item.student.email : ""}`},
        { text: `${item.justify===true ? "Sí" : "No"}`},
      ];
    });
  };

  const formattedDataCourse = _formatCourse(data.course);

  const formattedDataStudents = _formatStudent(data.students);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
      getLogo(),
      '\n',
      [
          { text:'Detalle de inasistencia', style:'title'},
      ],
      '\n',
      [
        { text:`Fecha:${formatDateView(data.date)}`}
      ],
      '\n',
      {
        type: "none",
        ol: [...formattedDataCourse],
      },
      {
        style: 'table',
        table: {
          headerRow: 1,
          widths:['*','*','*','auto'],
          body: [
            [
              { text: "Apellido", style: "tableHeader" },
              { text: "Nombre", style: "tableHeader" },
              { text: "Email", style: "tableHeader" },
              { text: "Justificado", style: "tableHeader" },
            ],
            ...formattedDataStudents,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  let title = `${data.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}