// Layout
import LayoutAdmin from "../layouts/LayoutAdmin";
import LayoutBasic from "../layouts/LayoutBasic";

// Admin Pages
// se puede poner otro nombre cuando la exportacion es default, si es normal hay que aclarar: import AdminHome, { pepe } from
import AdminHome from "../pages/Admin";
import AdminSignIn from "../pages/Admin/SignIn";
import AdminUsers from "../pages/Admin/Users";
import AdminSchoolYears from "../pages/Admin/SchoolYears";
import AdminStudents from "../pages/Admin/Students";
import AdminSpecialties from "../pages/Admin/Specialties";
import AdminEntryConditions from "../pages/Admin/EntryConditions";
import AdminFinalConditions from "../pages/Admin/FinalConditions";
import AdminReset from "../pages/Admin/Reset";
import AdminAdvisers from "../pages/Admin/Advisers";
import AdminTeachers from "../pages/Admin/Teachers";
import AdminTeacherCollabs from "../pages/Admin/TeacherCollabs";
import AdminStateCourses from "../pages/Admin/StateCourses";
import AdminTypeCourses from "../pages/Admin/TypeCourses";
import AdminDepartments from "../pages/Admin/Departments";
import AdminCourses from "../pages/Admin/Courses";
import AdminExports from "../pages/Admin/Exports";
import AdminImports from "../pages/Admin/Imports";
import AdminEditMenu from "../pages/Admin/Menu";
import AdminRoles from "../pages/Admin/Roles";
import AdminPermissions from "../pages/Admin/Permissions";
import AdminPersons from "../pages/Admin/Persons";
import AdminDays from "../pages/Admin/Days";
import AdminWeeks from "../pages/Admin/Weeks";
import AdminPlaces from "../pages/Admin/Places";
import AdminWorkloads from "../pages/Admin/Workloads";
import AdminDurations from "../pages/Admin/Durations";
import AdminInscriptions from "../pages/Admin/Inscriptions";
import AdminAbsences from "../pages/Admin/Absences";
import AdminStudentsCourse from "../pages/Admin/StudentsCourse";
import AdminCoursesStudent from "../pages/Admin/CoursesStudent";
import AdminStateSchoolYears from "../pages/Admin/StateSchoolYears";
import AdminDocTypes from "../pages/Admin/DocTypes";
import AdminStateStudentCourses from "../pages/Admin/StateStudentCourses";
import AdminStudentCourses from "../pages/Admin/StudentCourses";
import AdminStudentConditions from "../pages/Admin/StudentConditions";
import AdminCoursesAbsences from "../pages/Admin/CourseAbsences";

import AdminTeachersReports from "../pages/Admin/TeachersReports";
import AdminTeacherCollabsReports from "../pages/Admin/TeacherCollabsReports";
import AdminStudentsReports from "../pages/Admin/StudentsReports";



// User Pages
import Home from "../pages/Home";
import Contact from "../pages/Contact";

// Other
import Error404 from "../pages/Error404";

//todo lo que se centraliza aqui para agregar o modificar
import { getMenuApi } from "../api/menu"; //getMenuViewApi
import { getAccessToken } from "../api/auth";

const token = getAccessToken();

const componenteModule = {
    users: AdminUsers,
    students: AdminStudents,
    courses: AdminCourses,
    "menu-edition": AdminEditMenu,
    permissions: AdminPermissions,
    roles: AdminRoles,
    advisers: AdminAdvisers,
    statecourses: AdminStateCourses,
    schoolYears: AdminSchoolYears,
    exports: AdminExports,
    imports: AdminImports,
};

// PARA LOS MENUES
function routeMenu() {
    const arrayRoutes = [];
    getMenuApi(token).then((response) => {
        if (response.menu) {
        response.menu.map((i) => {
            if (i.nameModule) {
                arrayRoutes.push({
                path: i.url,
                component: componenteModule[i.nameModule],
                exact: true,
                name: `route.${i.nameModule}`,
                title: i.title
            });
            }
        });
        }
        arrayRoutes.push({
        component: Error404,
        });
    });
    return arrayRoutes;
}

//const formatMenu = routeMenu();

const routes = [
    //LAYOUT ADMIN
    {
        path: "/admin",
        component: LayoutAdmin,
        exact: false,
        routes:
        // formatMenu,
        [
            {
                path: "/admin",
                component: AdminHome,
                exact: true,
                name:"route.admin"

            },
            {
                path: "/admin/login",
                component: AdminSignIn,
                exact: true,
                name:"route.login",
                title:"Login"
            },

            {
                path: "/admin/users",
                component: AdminUsers,
                exact: true,
                name:"route.users",
                title:"Usuario"
            },
            {
                path: "/admin/config/schoolYears",
                component: AdminSchoolYears,
                exact: true,
                name:"route.schoolYears",
                title:"Año lectivo"
            },
            {
                path: "/admin/students",
                component: AdminStudents,
                exact: true,
                name:"route.students",
                title:"Estudiante"
            },
            {
                path: "/admin/specialties",
                component: AdminSpecialties,
                exact: true,
                name:"route.specialties",
                title:"Especialidades"
            },
            {
                path: "/admin/entryConditions",
                component: AdminEntryConditions,
                exact: true,
                name:"route.entryConditions",
                title:"Condición de Ingreso"
            },
            {
                path: "/admin/finalConditions",
                component: AdminFinalConditions,
                exact: true,
                name:"route.finalConditions",
                title:"Condición Final"
            },
            {
                path: "/admin/advisers",
                component: AdminAdvisers,
                exact: true,
                name:"route.advisers",
                title:"Consejero"
            },
            {
                path: "/admin/teachers",
                component: AdminTeachers,
                exact: true,
                name:"route.teachers",
                title:"Docente"
            },
            {
                path: "/admin/teacherCollabs",
                component: AdminTeacherCollabs,
                exact: true,
                name:"route.teacherCollabs",
                title:"Colaborador Docente"
            },
            {
                path: "/admin/inscriptions",
                component: AdminInscriptions,
                exact: true,
                name:"route.inscriptions",
                title:"Inscripciones"
            },
            {
                path: "/admin/absences",
                component: AdminAbsences,
                exact: true,
                name:"route.absences",
                title:"Inasistencias"
            },
            {
                path: "/admin/courseAbsences/:id",
                component: AdminCoursesAbsences,
                exact: true,
                name:"route.courseAbsences",
                title:"Inasistencias del Curso"
            },
            {
                path: "/admin/stateCourses",
                component: AdminStateCourses,
                exact: true,
                name:"route.stateCourses",
                title:"Estados de cursos"
            },
            {
                path: "/admin/config/stateSchoolYears",
                component: AdminStateSchoolYears,
                exact: true,
                name:"route.stateSchoolYears",
                title:"Estados de año lectivo"
            },
            {
                path: "/admin/typeCourses",
                component: AdminTypeCourses,
                exact: true,
                name:"route.typeCourses",
                title:"Tipos de cursos"
            },
            {
                path: "/admin/departments",
                component: AdminDepartments,
                exact: true,
                name:"route.departments",
                title:"Departamentos"
            },
            {
                path: "/admin/places",
                component: AdminPlaces,
                exact: true,
                name:"route.places",
                title:"Lugares"
            },
            {
                path: "/admin/workloads",
                component: AdminWorkloads,
                exact: true,
                name:"route.workloads",
                title:"Carga horaria"
            },
            {
                path: "/admin/courses",
                component: AdminCourses,
                exact: true,
                name:"route.courses",
                title:"Cursos"
            },
            {
                path: "/admin/durations",
                component: AdminDurations,
                exact: true,
                name:"route.durations",
                title:"Duración"
            },
            {
                path: "/admin/docTypes",
                component: AdminDocTypes,
                exact: true,
                name:"route.docTypes",
                title:"Tipos de documento"
            },
            {
                path: "/admin/config/days",
                component: AdminDays,
                exact: true,
                name:"route.days",
                title:"Dias"
            },
            {
                path: "/admin/config/weeks",
                component: AdminWeeks,
                exact: true,
                name:"route.weeks",
                title:"Semanas"
            },
            {
                path: "/admin/exports",
                component: AdminExports,
                exact: true,
                name:"route.exports",
                title:"Export"
            },
            {
                path: "/admin/imports",
                component: AdminImports,
                exact: true,
                name:"route.imports",
                title:"Import"
            },
            {
                path: "/admin/editmenu",
                component: AdminEditMenu,
                exact: true,
                name:"route.editmenu",
                title:"Menu"
            },
            {
                path: "/admin/config/roles",
                component: AdminRoles,
                exact: true,
                name:"route.roles",
                title:"Rol"
            },
            {
                path: "/admin/reports/teachers",
                component: AdminTeachersReports,
                exact: true,
                name:"route.reportsTeachers",
                title:"Reportes docentes"
            },
            {
                path: "/admin/reports/teachercollabs",
                component: AdminTeacherCollabsReports,
                exact: true,
                name:"route.reportsTeacherCollabs",
                title:"Reportes colaboradores"
            },
            {
                path: "/admin/reports/students",
                component: AdminStudentsReports,
                exact: true,
                name:"route.reportsStudents",
                title:"Reportes estudiantes"
            },
            {
                path: "/admin/config/permissions",
                component: AdminPermissions,
                exact: true,
                name:"route.permissions",
                title:"Permiso"
            },
            {
                path: "/admin/studentsCourse/:id",
                component: AdminStudentsCourse,
                exact: true,
                name:"route.inscriptions",
                title:"Estudiantes del Curso"
            },
            {
                path: "/admin/coursesStudent/:id",
                component: AdminCoursesStudent,
                exact: true,
                name:"route.inscriptions",
                title:"Cursos del Estudiante"
            },
            {
                path: "/admin/persons",
                component: AdminPersons,
                exact: true,
                name:"route.persons",
                title:"Personas"
            },
            {
                path: "/admin/stateStudentCourses",
                component: AdminStateStudentCourses,
                exact: true,
                name:"route.statestudentcourses",
                title:"Estado Estudiante Curso"
            },
            {
                path: "/admin/studentCourses/:id",
                component: AdminStudentCourses,
                exact: true,
                name:"route.statestudentcourses",
                title:"Listado de Cursos Estudiante"
            },
            {
                path: "/admin/studentConditions",
                component: AdminStudentConditions,
                exact: true,
                name:"route.studentConditions",
                title:"Listado de Condición del Estudiante"
            },
            {
                path: "/reset/:resetPasswordToken",
                component: AdminReset,
                exact: true,
            },
            {
                component: Error404
            },
        ]
    },

    //LAYOUT BASIC
    {
        path: "/",
        component: LayoutBasic,
        exact: false,
        routes: [
            {
                path: "/",
                component: Home,
                exact: true,
            },
            {
                path: "/contact",
                component: Contact,
                exact: true,
            },
            {
                path: "/reset/:resetPasswordToken",
                component: AdminReset,
                exact: true,
            },
            {
                component: Error404,
            },
        ],
    },
];

export default routes;
