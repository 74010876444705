import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getPlacesActiveApi } from "../../../api/place";
import ListPlaces from "../../../components/Admin/Places/ListPlaces";

export default function Durations() {
  const [placesActive, setPlacesActive] = useState([]);
  const [placesInactive, setPlacesInactive] = useState([]);
  const [reloadPlaces, setReloadPlaces] = useState(false);
  const token = getAccessToken();

  useEffect(() => {
    getPlacesActiveApi(token, true).then((response) => {
      setPlacesActive(response.places);
    });
    getPlacesActiveApi(token, false).then((response) => {
      setPlacesInactive(response.places);
    });
    setReloadPlaces(false);
  }, [token, reloadPlaces]);

  return (
    <div className="places">
      <h3>Listado de Lugares</h3>
      <ListPlaces
        placesActive={placesActive}
        placesInactive={placesInactive}
        setReloadPlaces={setReloadPlaces}
      />
    </div>
  );
}
