import { switchPath, apiVersion } from './config';
//import { getAbsencesByCourseApi} from "./absence";

/**FUNCIONES PARA MANEJO DEL MODULO CURSO QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getCourseExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/course-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'course.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postCourseImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/course-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addCourseApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-course`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getCoursesApi(token,state, limit, page, filter,filters,schoolYear) {
    let url = "";
    //permite armar los parametros en base a los filtros de la busqueda avanzados para enviar a la consulta
    let options= filters? (Object.keys(filters)
        .map(k => `${k}=${encodeURI(filters[k])}`)
        .join('&')): null;
    // console.log(options);
    //TODO: VERIFICAR LAS SIGUIENTES CONDICIONES EN BASE A LOS FILTROS
    if(filter!==undefined || filters !==null){
        url=`${switchPath()}/${apiVersion}/courses?state=${state}&limit=${limit}&page=${page}&filter=${filter}&${options}&schoolYear=${schoolYear}`;
    }
    else{
        url=`${switchPath()}/${apiVersion}/courses?state=${state}&limit=${limit}&page=${page}&schoolYear=${schoolYear}`;
    }
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getExportCoursesApi(token,state,filter,filters,schoolYear) {
    let url = `${switchPath()}/${apiVersion}/export-courses?state=${state}`;
    //permite armar los parametros en base a los filtros de la busqueda avanzados para enviar a la consulta
    let options= filters? (Object.keys(filters)
        .map(k => `${k}=${encodeURI(filters[k])}`)
        .join('&')): null;
    //TODO:VERIFICAR LAS SIGUIENTES CONDICIONES EN BASE A LOS FILTROS
    if(filter!==undefined && filter !== null && filter !== ""){
        url=url.concat(`&filter=${filter}`);
    }
    if(filters !==undefined && filters !== null){
        url=url.concat(`&${options}`);
    }
    if(schoolYear !== undefined && schoolYear !== null){
        url=url.concat(`&schoolYear=${schoolYear}`)
    }
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/courses-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCourseApi(token, courseId) {
    const url = `${switchPath()}/${apiVersion}/course/${courseId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesByStateCourseApi(token, stateCourseId) {
    const url = `${switchPath()}/${apiVersion}/courses-statecourse/${stateCourseId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesByTeacherApi(token, teacherId) {
    const url = `${switchPath()}/${apiVersion}/courses-teacher/${teacherId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesByTeacherAndSchoolYearApi(token, teacherId, schoolYearId) {
    const url = `${switchPath()}/${apiVersion}/courses-teacher-schoolyear?teacher=${teacherId}&&schoolYear=${schoolYearId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesByTeacherCollabApi(token, teacherCollabId) {
    const url = `${switchPath()}/${apiVersion}/courses-teachercollab/${teacherCollabId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function getCoursesByTypeCourseApi(token, typeCourseId) {
    const url = `${switchPath()}/${apiVersion}/courses-typecourse/${typeCourseId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesByDurationApi(token, durationId) {
    const url = `${switchPath()}/${apiVersion}/courses-duration/${durationId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesByDepartmentApi(token, departmentId) {
    const url = `${switchPath()}/${apiVersion}/courses-department/${departmentId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesBySchoolYearApi(token, schoolYearId) {
    const url = `${switchPath()}/${apiVersion}/courses-schoolyear/${schoolYearId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function getCoursesByPlaceApi(token, placeId) {
    const url = `${switchPath()}/${apiVersion}/courses-place/${placeId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesByWorkloadApi(token, workloadId) {
    const url = `${switchPath()}/${apiVersion}/courses-workload/${workloadId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesByDayApi(token, dayId) {
    const url = `${switchPath()}/${apiVersion}/courses-day/${dayId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCoursesByWeekApi(token, weekId) {
    const url = `${switchPath()}/${apiVersion}/courses-week/${weekId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function updateCourseApi(token, courseData, courseId) {
    const url = `${switchPath()}/${apiVersion}/update-course/${courseId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(courseData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function updateCoursesBySchoolYearEndApi(token, schoolYearId, stateCourse) {
    const url = `${switchPath()}/${apiVersion}/update-coursesstatecourse/${schoolYearId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        //body: stateCourse
        body: JSON.stringify(stateCourse)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
    //getAbsencesByCourseApi(token)
}

export function activateCourseApi(token, courseId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-course/${courseId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteCourseApi(token, courseId) {
    const url = `${switchPath()}/${apiVersion}/delete-course/${courseId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

