//import { responsiveArray } from 'antd/lib/_util/responsiveObserve';
//import { Result } from 'antd';
import { switchPath, apiVersion } from './config';


//FUNCIONES DEL MODULO AÑO LECTIVO QUE CONSUMEN LOS ENDPOINTS DEL SERVER

export function getSchoolYearExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/schoolyear-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'schoolyear.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postSchoolYearImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/schoolyear-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addSchoolYearApi(token, data) {
    const url = `${switchPath()}/${apiVersion}/add-schoolyear`;

    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function addSchoolYearApi2(token, data){
    const url = `${switchPath()}/${apiVersion}/add-schoolyear`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };
    //console.log(data);

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            if (result.schoolYear) {
                return {
                    ok: true,
                    message: "Año lectivo creado!"
                };
            }
            return {
                ok: false,
                message: result.message
            };
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });

        /*
        fetch(url, params)
            .then(response => {
                return response.json();
            }).then(result => {
                return result;
            })*/
}

export function getSchoolYearsApi(token) {
    const url = `${switchPath()}/${apiVersion}/schoolyears`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getSchoolYearApi(token, schoolYearId) {
    const url = `${switchPath()}/${apiVersion}/schoolyear/${schoolYearId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getSchoolYearsActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/schoolyears-active?active=${status}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getSchoolYearByStateSchoolYearApi(token, stateSchoolYearId) {
    const url = `${switchPath()}/${apiVersion}/schoolyears-stateschoolyear/${stateSchoolYearId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function uploadSchoolYearAvatarApi(token, avatar, schoolYearId) {
    const url = `${switchPath()}/${apiVersion}/upload-avatar-schoolyear/${schoolYearId}`;
    const formData = new FormData();
    formData.append("avatar", avatar, avatar.name);

    const params = {
        method: "PUT",
        body: formData,
        headers: {
            Authorization: token
        }
    }
    return fetch(url, params) //tira aca el error cuando actualizo el schoolyear
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}
export function getSchoolYearAvatarApi(avatarName) {
    const url = `${switchPath()}/${apiVersion}/get-avatar-schoolyear/${avatarName}`;
    return fetch(url)
    .then(response => {
        return response.url;
    })
    .catch(err => {
        return err.message;
    });
}

export function updateSchoolYearApi(token, schoolYear, schoolYearId) {
    const url = `${switchPath()}/${apiVersion}/update-schoolyear/${schoolYearId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(schoolYear)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateSchoolYearApi(token, schoolYearId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-schoolyear/${schoolYearId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteSchoolYearApi(token, schoolYearId) {
    const url = `${switchPath()}/${apiVersion}/delete-schoolyear/${schoolYearId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

