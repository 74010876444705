import React, { useState, createContext } from "react";

export const RouteContext = createContext();

export default function RouteProvider(props) { //devuelve user y isLoading
    const { children, titleRoute } = props;
    //console.log(children);
    const [title, setTitle] = useState({
        title:titleRoute
    });

    return <RouteContext.Provider value={title}>{children}</RouteContext.Provider>;
}

