import { switchPath, apiVersion } from './config';


/**FUNCIONES PARA MANEJO DEL MODULO INSCRIPCION QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getInscriptionExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/inscription-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'inscription.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postInscriptionImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/inscription-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addInscriptionApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-inscription`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getInscriptionsApi(token) {
    const url = `${switchPath()}/${apiVersion}/inscriptions`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getInscriptionsBySchoolYearApi(token, schoolYearId) {
    //console.log(schoolYearId)
    const url = `${switchPath()}/${apiVersion}/inscriptions-schoolyear/${schoolYearId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getInscriptionsFullBySchoolYearApi(token, schoolYearId) {
    //console.log(schoolYearId)
    const url = `${switchPath()}/${apiVersion}/inscriptions-total-schoolyear/${schoolYearId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getInscriptionsPaginateApi(token,state, limit, page, filter) {
    const url = `${switchPath()}/${apiVersion}/inscriptions-paginate?state=${state}&limit=${limit}&page=${page}&filter=${filter}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function getInscriptionsActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/inscriptions-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateInscriptionApi(token, inscriptionData, inscriptionId) {
    const url = `${switchPath()}/${apiVersion}/update-inscription/${inscriptionId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(inscriptionData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateInscriptionApi(token, inscriptionId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-inscription/${inscriptionId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteInscriptionApi(token, inscriptionId) {
    const url = `${switchPath()}/${apiVersion}/delete-inscription/${inscriptionId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}

export function getStudentsByCourseApi(token, courseId) {
    const url = `${switchPath()}/${apiVersion}/inscription-students-course/${courseId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getInscriptionByCourseApi(token, courseId) {
    const url = `${switchPath()}/${apiVersion}/inscription-course/${courseId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getInscriptionByCourseCodeApi(token, courseCode) {
    const url = `${switchPath()}/${apiVersion}/inscription-course-code/${courseCode}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getInscriptionsByStudentApi(token, studentId) {
    const url = `${switchPath()}/${apiVersion}/inscriptions-student/${studentId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return  result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getInscriptionsByStudentReportApi(token, studentId) {
    const url = `${switchPath()}/${apiVersion}/inscriptions-student-report/${studentId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return  result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getInscriptionsByStudentCodeApi(token, studentCode) {
    const url = `${switchPath()}/${apiVersion}/inscriptions-student-code/${studentCode}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

//SE USA PARA LA FUNCIONALIDAD DE CONTAR INASISTENCIAS, PARA SETEAR stateStudentCourse a "desaprobado"
export function getInscriptionsByCourseApi(token, courseId) {
    const url = `${switchPath()}/${apiVersion}/inscriptions-course/${courseId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function insertStudentByInscriptionIdApi(token, studentData, inscriptionId) {
    const url = `${switchPath()}/${apiVersion}/insert-student-inscription/${inscriptionId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(studentData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function insertStudentInscriptionByCourseIdApi(token, studentData, courseId) {
    const url = `${switchPath()}/${apiVersion}/insert-student-inscription-course/${courseId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(studentData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function deleteStudentByInscriptionIdApi(token, studentData, inscriptionId) {
    const url = `${switchPath()}/${apiVersion}/delete-student-inscription/${inscriptionId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(studentData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}


export function deleteStudentInscriptionByCourseIdApi(token, studentData, courseId) {
    const url = `${switchPath()}/${apiVersion}/delete-student-inscription-course/${courseId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(studentData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function insertStudentsByInscriptionIdApi(token, studentsData, inscriptionId) {
    const url = `${switchPath()}/${apiVersion}/insert-students-inscription/${inscriptionId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(studentsData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function deleteStudentsCourseInscriptionApi(token, studentsData, courseId) {
    const url = `${switchPath()}/${apiVersion}/delete-students-inscription/${courseId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(studentsData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export async function updateStateStudentByInscriptionIdApi(token, studentsData, inscriptionId) {
    const url = `${switchPath()}/${apiVersion}/update-state-student-inscription/${inscriptionId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(studentsData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export async function updateStateStudentsByInscriptionIdApi(token, studentsData, inscriptionId) {
    const url = `${switchPath()}/${apiVersion}/update-state-students-inscription/${inscriptionId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(studentsData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export async function updateOwnHoursStudentsByInscriptionIdApi(token, inscriptionId) {
    const url = `${switchPath()}/${apiVersion}/update-ownhours-students-inscription/${inscriptionId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function getCoursedHoursStudentToYearApi(token, yearData, studentId) {
    const url = `${switchPath()}/${apiVersion}/inscriptions-coursedhours-student-year?student=${studentId}&year=${yearData}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function getCoursedHoursTotalByStudentApi(token, studentId) {
    const url = `${switchPath()}/${apiVersion}/inscriptions-coursedhours-student/${studentId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}