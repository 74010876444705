import React, { useState, useEffect } from "react";
import { Form, Descriptions, Button, notification } from "antd"; //Table
import { ExportOutlined } from "@ant-design/icons";
/*
import {
    MenuOutlined,
    HomeOutlined,
    TeamOutlined,
    UserOutlined,
    IdcardOutlined,
    SettingOutlined,
    SwapOutlined,
    OrderedListOutlined,
    UnorderedListOutlined,
    SkinOutlined,
    ProfileOutlined,
    ContactsOutlined,
    ContainerOutlined,
    FileTextOutlined,
    AuditOutlined,
    ImportOutlined,
    TagsOutlined,
} from "@ant-design/icons"; */

//import { formatDateView } from '../../../../utils/formatDate';
//import { convertState } from "../../../../utils/convert";
import "./ListExports.scss";
import { getUserExportApi } from "../../../../api/user";
import { getStudentExportApi } from "../../../../api/student";
import { getSchoolYearExportApi } from "../../../../api/schoolYear";
import { getTeacherExportApi } from "../../../../api/teacher";
import { getTeacherCollabExportApi } from "../../../../api/teacherCollab";
import { getAdviserExportApi } from "../../../../api/adviser";
import { getSpecialtyExportApi } from "../../../../api/specialty";
import { getEntryConditionExportApi } from "../../../../api/entryCondition";
import { getFinalConditionExportApi } from "../../../../api/finalCondition";
import { getDayExportApi } from "../../../../api/day";
import { getWeekExportApi } from "../../../../api/week";
import { getDurationExportApi } from "../../../../api/duration";
import { getPlaceExportApi } from "../../../../api/place";
import { getDepartmentExportApi } from "../../../../api/department";
import { getWorkloadExportApi } from "../../../../api/workload";
import { getStateCourseExportApi } from "../../../../api/stateCourse";
import { getStateSchoolYearExportApi } from "../../../../api/stateSchoolYear";
import { getCourseExportApi } from "../../../../api/course";
import { getTypeCourseExportApi } from "../../../../api/typeCourse";
import { getStateStudentCourseExportApi } from "../../../../api/stateStudentCourse";
import { getDocTypeExportApi } from "../../../../api/docType";
import { getInscriptionExportApi } from "../../../../api/inscription";
import { getAbsenceExportApi } from "../../../../api/absence";

import { getPermissionsActiveApi, getPermissionExportApi } from "../../../../api/permission";
import { getRoleByIdApi, getRoleExportApi } from "../../../../api/role";
import { getMenuExportApi} from "../../../../api/menu";

import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import jwtDecode from "jwt-decode";


//import JSZip from "jszip";

export default function ListExports(props) {
    const { token } = props;
    const [permissionsActive, setPermissionsActive] = useState([]);
    const [roleById, setRoleById] = useState([]);
    //const accessToken = getAccessToken();

    const accessToken = getAccessToken();
    const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

    useEffect(() => {
        getPermissionsActiveApi(accessToken, true).then(response => {
        setPermissionsActive(response.permissions);
        });
    }, []);

    useEffect(() => {
        getRoleByIdApi(accessToken, userToken.role).then(response => {
        setRoleById(response.role);
        });
    }, []);

    const allExports = async () => {

        await getUserExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });

    };

    const exportUsers = () => {
        getUserExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportStudents = () => {
        getStudentExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportSchoolYears = () => {
        getSchoolYearExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportStateSchoolYears = () => {
        getStateSchoolYearExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportAdvisers = () => {
        getAdviserExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportTeachers = () => {
        getTeacherExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportTeacherCollabs = () => {
        getTeacherCollabExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportAbsences = () => {
        getAbsenceExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportInscriptions = () => {
        getInscriptionExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportSpecialties = () => {
        getSpecialtyExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportStateCourses = () => {
        getStateCourseExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportEntryConditions = () => {
        getEntryConditionExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportFinalConditions = () => {
        getFinalConditionExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportDays = () => {
        getDayExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportWeeks = () => {
        getWeekExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportCourses = () => {
        getCourseExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportTypeCourses = () => {
        getTypeCourseExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportDurations = () => {
        getDurationExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportDepartments = () => {
        getDepartmentExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportPlaces = () => {
        getPlaceExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportWorkloads = () => {
        getWorkloadExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportDocTypes = () => {
        getDocTypeExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportStateStudentCourse = () => {
        getStateStudentCourseExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportPermissions = () => {
        getPermissionExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportRoles = () => {
        getRoleExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };

    const exportMenu = () => {
        getMenuExportApi(token)
        .then((response) => {
            console.log("Archivo descargado");
        })
        .catch((err) => {
            notification["error"]({
                message: "Servidor caido",
            });
        });
    };



    return (
        <div className="list-export">
            <ViewForm
                token={token}
                exportUsers={exportUsers}
                exportStudents={exportStudents}
                exportSchoolYears={exportSchoolYears}
                exportAdvisers={exportAdvisers}
                exportTeachers={exportTeachers}
                exportTeacherCollabs={exportTeacherCollabs}
                exportSpecialties={exportSpecialties}
                exportStateCourses={exportStateCourses}
                exportCourses={exportCourses}
                exportPermissions={exportPermissions}
                exportRoles={exportRoles}
                exportDays={exportDays}
                exportEntryConditions={exportEntryConditions}
                exportFinalConditions={exportFinalConditions}
                exportWeeks={exportWeeks}
                exportDepartments={exportDepartments}
                exportDurations={exportDurations}
                exportWorkloads={exportWorkloads}
                exportPlaces={exportPlaces}
                exportStateSchoolYears={exportStateSchoolYears}
                exportDocTypes={exportDocTypes}
                exportTypeCourses={exportTypeCourses}
                exportStateStudentCourse={exportStateStudentCourse}
                exportInscriptions={exportInscriptions}
                exportAbsences={exportAbsences}
                allExports={allExports}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                exportMenu={exportMenu}
            />
        </div>
    );
}

function ViewForm(props) {
    const { exportUsers, exportStudents, exportSchoolYears, exportAdvisers, exportTeachers,
            exportSpecialties, exportTeacherCollabs, exportAbsences, exportInscriptions,
            exportStateCourses, exportCourses, exportPermissions, exportRoles,
            exportDays, exportWeeks, exportFinalConditions, exportEntryConditions,
            exportDurations, exportDepartments, exportWorkloads, exportPlaces, exportStateSchoolYears,
            exportDocTypes, exportTypeCourses, exportStateStudentCourse,
            //allExports, accessToken,
            userToken, permissionsActive, roleById, exportMenu } = props;

    return (
        <div>
            {checkAction(permissionsActive, userToken.role, "exports", "menu-sider") || isAdmin(roleById) ? (
                <>
                    <Form className="form-view" >
                        <h1 className="title">Exportar registros</h1>
                        <Descriptions column={2} layout="vertical" size="small" bordered>
                            <label className="title">Usuarios</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" htmlType="submit" onClick={exportUsers}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Estudiantes</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportStudents}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Estado Estudiante-Curso</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportStateStudentCourse}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Docentes</label>
                            <Descriptions.Item className="normal" span={2}>
                                <Button type="primary" onClick={exportTeachers}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Colaboradores Docentes</label>
                            <Descriptions.Item className="normal" span={2}>
                                <Button type="primary" onClick={exportTeacherCollabs}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Consejeros</label>
                            <Descriptions.Item className="normal" span={2}>
                                <Button type="primary" onClick={exportAdvisers}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Especialidades</label>
                            <Descriptions.Item className="normal" span={2}>
                                <Button type="primary" onClick={exportSpecialties}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Años lectivos</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportSchoolYears}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Estados Año lectivo</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportStateSchoolYears}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Días</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportDays}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Semanas</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportWeeks}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Duración</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportDurations}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Lugares</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportPlaces}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Departamento</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportDepartments}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Carga Horaria</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportWorkloads}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Especialidades</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportSpecialties}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Condición de Ingreso</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportEntryConditions}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Condición Final</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportFinalConditions}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Tipos de documento</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportDocTypes}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Cursos</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportCourses}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Estados de Curso</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportStateCourses}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Tipos de Curso</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportTypeCourses}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Inscripciones</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportInscriptions}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Inasistencias</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportAbsences}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>

                            <label className="title">Roles de usuarios</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportRoles}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>
                            <label className="title">Permisos de roles</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportPermissions}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>
                            <label className="title">Menu</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={exportMenu}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>
                            {/**
                            <label className="title">TODOS</label>
                            <Descriptions.Item className="normal">
                                <Button type="primary" onClick={allExports}>
                                    CSV
                                    <ExportOutlined />
                                </Button>
                            </Descriptions.Item>
                            **/}
                        </Descriptions>
                    </Form>
                </>
            ) : (
                <>
                </>
            )}
        </div>
    );
}