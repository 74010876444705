//variables por si se quieren cambiar los nombres en un futuro

export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const administradores = [ "admin", "Admin", "ADMIN", "administrador", "ADMINISTRADOR", "Administrador" ]
export const supervisores = [ "super", "Super", "SUPER", "supervisor", "SUPERVISOR", "Supervisor" ]

//ACCIONES DE LOS PERMISOS
export const ACTIONS = [
    "menu-sider",
    "add",
    "edit",
    "delete",
    "view",
    "enroll",
    "enable",
    "pdf",
    //"menu-config",
    "all"
]

//MODULOS DE LOS PERMISOS
export const MODULES = [
    //MODULES
    "absences",
    "advisers",
    "courses",
    "days",
    "deliveryterms",
    "departments",
    "doctypes",
    "durations",
    "entryconditions",
    "finalconditions",
    "inscriptions",
    "permissions",
    "places",
    "roles",
    "schoolyears",
    "specialties",
    "statecourses",
    "stateschoolyears",
    "students",
    "teachers",
    "teachercollabs",
    "typecourses",
    "users",
    "weeks",
    "workloads",
    "config-menu",
    "persons-menu",
    //MENUES
    "courses-menu",
    "menu-edition",
    //"user-role",
    "exports",
    "imports",
]

export const SYSTEM_MODULES = [
    //MODULOS DEL SISTEMA
    "absence",
    "adviser",
    "course",
    "day",
    //"deliveryTerm",
    "department",
    "docType",
    "duration",
    "entryCondition",
    "finalCondition",
    "inscription",
    "menu",
    "permission",
    "place",
    "role",
    "schoolYear",
    "specialty",
    "stateCourse",
    "stateSchoolyear",
    "stateStudentCourse",
    "student",
    "teacher",
    "teacherCollab",
    "typeCourse",
    "user",
    "week",
    "workload"
]

//MONEDAS
export const COINS = [
    "pesos",
    "dolar americano",
    "euro",
    "libra"
]
