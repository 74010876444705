import React, { useState } from "react";

import { Button, Tag, DatePicker, Row, Col, Space} from "antd";
import moment from "moment";

import {CalendarOutlined , SearchOutlined} from "@ant-design/icons";
//import formatDateView from "../../../utils/formatDate";
import Modal from "../../Modal";

import "./SearchByDates.scss";

const { RangePicker } = DatePicker;

export default function SearchByDates(props) {
  const { setInitDate, setFinishDate, setReload} = props;

  const [init, setInit] = useState("");
  const [finish, setFinish] = useState("");
  const [titleTag, setTitleTag]= useState("");

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleTag, setIsVisibleTag] = useState(false);

  const searchByDates = () => {
    setReload(true);
    setIsVisibleTag(false);
    setIsVisibleModal(true);
  };

  // const search = () => {
  //   if(initDate && finishDate){
  //     // console.log(moment(initDate));
  //     let newList = searchList.filter(
  //       (data) =>
  //         // moment(data.date).isSameOrAfter(moment(initDate).subtract(1,"days")) &&
  //         // moment(data.date).isSameOrBefore(moment(finishDate).add(1,"days"))
  //         moment(data[dataSearch]).isSameOrAfter(moment(initDate).subtract(1,"days")) &&
  //         moment(data[dataSearch]).isSameOrBefore(moment(finishDate))
  //     );
  //     setSearchList(newList);
  //     setIsVisibleTag(true);
  //   }
  // };

  const search = ()=>{
    setInitDate(init);
    setFinishDate(finish);
    setIsVisibleTag(true);
    setIsVisibleModal(false);
  }

  function closedTag(e) {
    setInitDate("");
    setFinishDate("");
    setInit("");
    setFinish("");
    setIsVisibleTag(false);
    setReload(true);
  }

  const setDates = (dates, datesString) => {
    setInit(dates[0]);
    setFinish(dates[1]);
    setTitleTag(`${datesString[0]} al ${datesString[1]}`);
  };

  return (
    <div>
    <div className="search-date">
      <Button
        className="button-search"
        type="default"
        icon={<CalendarOutlined />}
        onClick={searchByDates}
      >
        Buscar
      </Button>

      <Modal
        title="Buscar por fechas"
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        <Space>
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
          }}
          footer={false}
          format="DD/MM/YYYY"
          placeholder={["desde", "hasta"]}
          onChange={setDates}
          required={true}
        />
        
        <Button 
          type="primary" 
          className="button-search-modal"
          icon={<SearchOutlined />}
          onClick={search}>
          Buscar
        </Button>
        </Space>
      </Modal>
    </div>
        <div className="search-date__tag">
          <Row>
            <Col span={2}>
              {isVisibleTag ? (
                <Tag color="blue" closable onClose={closedTag}>
                  {titleTag}
                </Tag>
              ) : null}
            </Col>
            </Row>
         </div>
      </div>
  );
}
