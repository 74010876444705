import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
    //getInscriptionsActiveApi , 
    getInscriptionsPaginateApi
} from "../../../api/inscription";
import ListInscriptions from "../../../components/Admin/Inscriptions/ListInscriptions";


export default function Inscriptions() {
    const [inscriptionsActive, setInscriptionsActive] = useState([]);
    const [inscriptionsInactive, setInscriptionsInactive] = useState([]);
    const [reloadInscriptions, setReloadInscriptions ] = useState(false);
    const [paginationActive, setPaginationActive]= useState({page:1});
    const [paginationInactive, setPaginationInactive]= useState({page:1});
    const token = getAccessToken();

    useEffect(() => {
        getInscriptionsPaginateApi(token, true, 50, paginationActive.page, paginationActive.filter).then(response => {
            setInscriptionsActive(response.inscriptions);
        });
        setReloadInscriptions(false);
    }, [token, paginationActive, reloadInscriptions]);

    useEffect(() => {
        getInscriptionsPaginateApi(token, false, 50, paginationInactive.page, paginationInactive.filter).then(response => {
            setInscriptionsInactive(response.inscriptions);
        });
        setReloadInscriptions(false);
    }, [token, paginationInactive, reloadInscriptions]);

    return (
        <div className="inscriptions">
            <h3>Listado de Inscripciones</h3>
            <ListInscriptions inscriptionsActive={inscriptionsActive} 
            inscriptionsInactive={inscriptionsInactive} setReloadInscriptions={setReloadInscriptions}
            setPaginationActive={setPaginationActive} setPaginationInactive={setPaginationInactive}
                />

        </div>
    );
}