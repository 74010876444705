import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Checkbox,
  Divider,
  Table,
  Select,
  Button,
  Row,
  Col,
  notification,
  Modal
} from "antd";

//import difference from 'lodash/difference';
import { DeleteOutlined, TagOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import { getCourseApi , getCoursesBySchoolYearApi} from "../../../../api/course"; //getCourseApi
import { getStudentsByCourseApi } from "../../../../api/inscription";
import { addAbsenceApi } from "../../../../api/absence";
import { getAccessToken } from "../../../../api/auth";
import { formatDateCalendar } from "../../../../utils/formatDate";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";

import "./AddAbsenceForm.scss";

export default function AddAbsenceForm(props) {
  const { course, setIsVisibleModal, setReloadAbsences } = props;
  const [listCoursesActive, setListCoursesActive] = useState([]);
  const [listStudentsCourse, setListStudentsCourse] = useState([]);
  const [listStudentsAbsences, setListStudentsAbsences] = useState([]);
  // const [listStateCoursesActive, setListStateCoursesActive] = useState([]);
  const [listSchoolYearsActive, setListSchoolYearsActive] = useState([]);
  const [schoolYear, setSchoolYear]=useState(null);
  const [loading, setLoading] = useState(false);
  const [disabledSubmit, setDisabledSubmit]=useState(false);
  const accessToken = getAccessToken();

  useEffect(() => {
    // getCoursesActiveApi(accessToken, true).then((response) => {
    //   setListCoursesActive(response.courses);
    // });
    if(schoolYear!==null){
      getCoursesBySchoolYearApi(accessToken, schoolYear).then((response) => {
        setListCoursesActive(response.courses);
      });
    }
  }, [schoolYear]);

  // useEffect(() => {
  //   getStateCoursesActiveApi(accessToken, true).then((response) => {
  //     setListStateCoursesActive(response.stateCourses);
  //   });
  // }, []);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListSchoolYearsActive(response.schoolYears);
      let year = new Date().getFullYear();
      let result=response.schoolYears.filter(i=>i.year === year);
      if(result && result.length > 0){
        setSchoolYear(result[0]._id);
      }else{
        setSchoolYear(response.schoolYears[0]._id);
      }
    });
  }, []);

  useEffect(() => {
    if (course) {
      getStudentsByCourseApi(accessToken, course._id).then((response) => {
        setListStudentsCourse(response.students);
      });
    }
  }, [course]);

  const [inputs, setInputs] = useState({
    course: course ? course._id : null,
    date: formatDateCalendar(moment()),
  });

  const isFormValid = (e) => {
    let errorExists = false;
    // console.log(inputs);
    if (!inputs.course || !inputs.date) {
      notification["error"]({
        message: "Obligatorios: curso y fecha.",
      });
      errorExists = true;
    } else if (listStudentsAbsences.length === 0) {
      notification["error"]({
        message: "Debe seleccionar algún estudiante.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addAbsence = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!isFormValid()) {
      if(!disabledSubmit){
        setDisabledSubmit(true);
        const accessToken = getAccessToken();
        inputs.students = listStudentsAbsences;
        await addAbsenceApi(accessToken, inputs)
          .then((response) => {
            if (
              response === "ERR_CONNECTION_REFUSED" ||
              response.message === "Failed to fetch"||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setDisabledSubmit(false);
              setLoading(false);
            } else if (response.code !== 200) {
              notification["error"]({
                message: response.message,
              });
              setDisabledSubmit(false);
              setLoading(false);
              setReloadAbsences(true);
            } else {
              notification["success"]({
                message: "Inasistencia creada",
              });
              setIsVisibleModal(false);
              setLoading(false);
              setReloadAbsences(true);
            }
            //setReloadAbsences(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
            setLoading(false);
          });
      }else{
        setLoading(false);
      }
    }else{
      setLoading(false);
    }
  };

  // const infoResult=(err, message)=>{
  //   Modal.info({
  //     title:'Curso Finalizado',
  //     content:(
  //       <div>
  //         <p> {message}</p>
  //       </div>
  //     )
  //   })
  // }

  return (
    <div className="add-absence-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        course={course}
        listCoursesActive={listCoursesActive}
        listStudentsCourse={listStudentsCourse}
        setListStudentsCourse={setListStudentsCourse}
        listStudentsAbsences={listStudentsAbsences}
        setListStudentsAbsences={setListStudentsAbsences}
        listSchoolYearsActive={listSchoolYearsActive}
        schoolYear={schoolYear}
        setSchoolYear={setSchoolYear}
        addAbsence={addAbsence}
        accessToken={accessToken}
        loading={loading}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    addAbsence,
    //course,
    listCoursesActive,
    listStudentsCourse,
    setListStudentsCourse,
    listStudentsAbsences,
    setListStudentsAbsences,
    accessToken,
    loading,
    listSchoolYearsActive,
    schoolYear,
    setSchoolYear
  } = props;
  const { Option } = Select;

  useEffect(() => {
    if (inputs.course) {
      setInputs({ ...inputs, student: null });
      getStudentsByCourseApi(accessToken, inputs.course).then((response) => {
        setListStudentsCourse(response.students);
      });
    }
  }, [inputs.course]);

  useEffect(() => {
    if (inputs.course) {
      setListStudentsAbsences([]);
    }
  }, [inputs.course]);

  useEffect(() => {
    setInputs({...inputs,course:null})
  }, [schoolYear]);

  const addStudents = (e) => {
    e.preventDefault();
    if (inputs.student) {
      let result = listStudentsCourse.filter((i) => i._id === inputs.student);
      if (result !== "undefined") {
        if (
          !listStudentsAbsences.filter(
            (i) => i.student === result[0].student._id
          ).length > 0
        ) {
          const newData = {
            student: result[0].student._id,
            studentData: result[0].student,
            justify: false,
          };
          setListStudentsAbsences([...listStudentsAbsences, newData]);
          setInputs({ ...inputs, student: null });
        } else {
          notification["error"]({
            message: "El estudiante ya se encuentra seleccionado",
          });
        }
      }
    } else {
      notification["error"]({
        message: "Debe elegir al menos un estudiante",
      });
    }
  };

  return (
    <Form className="form-add" onSubmit={addAbsence}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={4}>
          <label className="control-label">
            <span className="control-required">*</span>Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.course_code}
              onChange={(e) => setInputs({ ...inputs, absence_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha
          </label>
          <Form.Item>
            <Input
              // prefix={<CalendarOutlined/>}
              type="date"
              placeholder="Fecha "
              value={inputs.date}
              onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Ciclo
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona el ciclo"
              value={schoolYear}
              onChange={(e) => setSchoolYear(e)}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSchoolYearsActive
                ? listSchoolYearsActive.map((year) => {
                    return <Option key={year._id}> {year.year}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Curso
          </label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona el curso"
              value={inputs.course}
              onChange={(e) => setInputs({ ...inputs, course: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listCoursesActive
                ? listCoursesActive.map((course) => {
                    return <Option key={course._id}> {course.name}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={16}>
          <label className="control-label">
            <span className="control-required">*</span> Estudiantes Inscriptos
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Seleccione los estudiantes"
              // name="items"
              value={inputs.student}
              onChange={(e) =>
                setInputs({
                  ...inputs,
                  student: e,
                })
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStudentsCourse
                ? listStudentsCourse.map((i) => {
                    //console.log(i.student);
                    return (
                      <Option key={i._id}>
                        {i.student.name +
                          " " +
                          i.student.lastname +
                          " (" +
                          (i.student.email ? i.student.email : "") +
                          ") "}
                      </Option>
                    );
                  })
                : "No hay estudiantes disponibles"}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Button
              type="primary"
              className="button-add"
              size="middle"
              onClick={addStudents}
            >
              <PlusOutlined /> Agregar
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Students
            studentsData={listStudentsAbsences}
            setStudentsData={setListStudentsAbsences}
          />
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          loading={loading}
          onClick={addAbsence}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}

function Students(props) {
  const { studentsData, setStudentsData } = props;

  const showDeleteConfirm = (record) => {
    const d = studentsData.filter((i) => i !== record);
    if (d.length === 0) {
      setStudentsData([]);
    } else {
      setStudentsData(d);
    }
  };

  const changeJustify = (rowIndex, columnKey) => (event) => {
    const newData = [...studentsData];
    newData[rowIndex][columnKey] = event.target.checked;
    setStudentsData(newData);
  };

  //console.log(studentsData);

  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "studentData",
    //   //render: (studentData, row) =>
    //   //  row.studentData ? row.studentData.student_code : "row",
    //   width: "10%",
    //   editable: false,
    //   render: (studentsData) =>
    //     studentsData? studentsData.student_code : null,
    // },
    {
      title: "Nombre",
      dataIndex: "studentData",
      render: (studentsData) => (studentsData ? studentsData.name : null),
      width: "15%",
      editable: false,
    },
    {
      title: "Apellido",
      dataIndex: "studentData",
      render: (studentsData) => (studentsData ? studentsData.lastname : null),
      width: "15%",
      editable: false,
    },
    {
      title: "E-mail",
      dataIndex: "studentData",
      render: (studentsData) => (studentsData ? studentsData.email : null),
      width: "30%",
      editable: false,
    },
    {
      title: "Justificado",
      dataIndex: "justify",
      render: (value, record, rowIndex) => (
        <Checkbox
          checked={value}
          onChange={changeJustify(rowIndex, "justify")}
        />
      ),
      width: "10%",
      editable: true,
    },
    {
      title: "Acción",
      dataIndex: "operation",
      width: "10%",
      render: (_, record) => {
        return (
          <span>
            <Button
              className="button-item"
              type="danger"
              size="small"
              onClick={(e) => showDeleteConfirm(record)}
            >
              <DeleteOutlined />
            </Button>
          </span>
        );
      },
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={studentsData}
      scroll={{ y: 250 }}
      rowKey="student"
    />
  );
}
