import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getDepartmentsActiveApi } from "../../../api/department";
import ListDepartments from "../../../components/Admin/Departments/ListDepartments";


export default function Departments() {
    const [departmentsActive, setDepartmentsActive] = useState([]);
    const [departmentsInactive, setDepartmentsInactive] = useState([]);
    const [reloadDepartments, setReloadDepartments ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getDepartmentsActiveApi(token, true).then(response => {
            setDepartmentsActive(response.departments);
        });
        getDepartmentsActiveApi(token, false).then(response => {
            setDepartmentsInactive(response.departments);
        });
        setReloadDepartments(false);
    }, [token, reloadDepartments]);

    return (
        <div className="departments">
            <h3>Listado de departamentos</h3>
            <ListDepartments departmentsActive={departmentsActive} departmentsInactive={departmentsInactive} setReloadDepartments={setReloadDepartments}
                />

        </div>
    );
}