import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditEntryConditionForm from "../EditEntryConditionForm";
import AddEntryConditionForm from "../AddEntryConditionForm";
import { activateEntryConditionApi, deleteEntryConditionApi } from "../../../../api/entryCondition";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getStudentsByEntryConditionApi } from "../../../../api/student";
import jwtDecode from "jwt-decode";

import "./ListEntryConditions.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListEntryConditions(props) {
  const {
    entryConditionsActive,
    entryConditionsInactive,
    setReloadEntryConditions,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewEntryConditionsActives, setViewEntryConditionsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [entryConditionsActiveFilter, setEntryConditionsActiveFilter] = useState(
    []
  );
  const [ entryConditionsInactiveFilter, setEntryConditionsInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (entryConditionsActive) {
      entryConditionsActive.forEach((element) => {
          if (
            element.description
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.entryCondition_code.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setEntryConditionsActiveFilter(listActive);
  }, [filterText,entryConditionsActive]);

  useEffect(() => {
    const listInactive = [];
    if (entryConditionsInactive) {
      entryConditionsInactive.forEach((element) => {
          if (
            element.description
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.entryCondition_code.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setEntryConditionsInactiveFilter(listInactive);
  }, [filterText, entryConditionsInactive]);

  const addEntryConditionModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Condicion de Ingreso");
    setModalContent(
      <AddEntryConditionForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadEntryConditions={setReloadEntryConditions}
      />
    );
  };

  return (
    <div className="list-entryconditions">
      <div className="list-entryconditions__header">
        {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "entryconditions", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-entryconditions__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewEntryConditionsActives(!viewEntryConditionsActives)
                }
              />
              <span>
                {viewEntryConditionsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "entryconditions", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addEntryConditionModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
      checkAction(permissionsActive, userToken.role, "entryconditions", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-entryconditions__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de condicion de ingreso ''
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewEntryConditionsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "entryconditions", "view") || isAdmin(roleById) ? (
            <>
              <EntryConditionsActive
                entryConditionsActive={entryConditionsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadEntryConditions={setReloadEntryConditions}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "entryconditions", "view") || isAdmin(roleById) ? (
            <>
              <EntryConditionsInactive
                entryConditionsInactive={entryConditionsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadEntryConditions={setReloadEntryConditions}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function EntryConditionsActive(props) {
  const {
    entryConditionsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadEntryConditions,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editEntryCondition = (entryCondition) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${entryCondition.description ? entryCondition.description : "sin descripcion"}`
    );
    setModalContent(
      <EditEntryConditionForm
        entryCondition={entryCondition}
        setIsVisibleModal={setIsVisibleModal}
        setReloadEntryConditions={setReloadEntryConditions}
      />
    );
  };

  const desactivateEntryCondition = (entryCondition) => {
    activateEntryConditionApi(accessToken, entryCondition._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadEntryConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (entryCondition) => {
    let response = await getStudentsByEntryConditionApi(accessToken, entryCondition._id);
    if (response.students.length > 0) {
      confirm({
        title: "Eliminando -> Condición de ingreso",
        content: `No se puede eliminar '${entryCondition.description}' porque existen estudiantes asociados ¿Deseas desactivarla?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateEntryCondition(entryCondition);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Condición de ingreso",
        content: `¿Estás seguro que quieres eliminar a ${entryCondition.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteEntryConditionApi(accessToken, entryCondition._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadEntryConditions(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_entryCondition = (
    <div>
      {" "}
      <p>Editar Condición de ingreso</p>{" "}
    </div>
  );

  const desactivate_entryCondition = (
    <div>
      {" "}
      <p>Pasar a Condición de ingreso inactivos</p>{" "}
    </div>
  );

  const delete_entryCondition = (
    <div>
      {" "}
      <p>Eliminar Condición de ingreso</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "entryCondition_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, entryCondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "entryconditions", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_entryCondition}>
                <Button
                  className="button-edit"
                  onClick={() => editEntryCondition(entryCondition)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "entryconditions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_entryCondition}>
                <Button
                  className="button-entrycondition"
                  type="primary"
                  onClick={() => desactivateEntryCondition(entryCondition)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "entryconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_entryCondition}>
                <Button
                  className="button-entrycondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(entryCondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return entryConditionsActive.length > 0? `Total de registros: ${entryConditionsActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={entryConditionsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function EntryConditionsInactive(props) {
  const { entryConditionsInactive,
          setReloadEntryConditions,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateEntryCondition= (entryCondition) => {
    activateEntryConditionApi(accessToken, entryCondition._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadEntryConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (entryCondition) => {
    let response = await getStudentsByEntryConditionApi(accessToken, entryCondition._id);
    if (response.students.length > 0) {
      ModalAntd.info({
        title: "Eliminando -> Condición de ingreso",
        content:`No se puede eliminar '${entryCondition.description}' porque existen estudiantes asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Condición de ingreso",
        content: `¿Estás seguro que quieres eliminar a ${entryCondition.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteEntryConditionApi(accessToken, entryCondition._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadEntryConditions(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_entryCondition = (
    <div>
      {" "}
      <p>Pasar a Condición de ingreso activos</p>{" "}
    </div>
  );

  const delete_entryCondition = (
    <div>
      {" "}
      <p>Eliminar Condición de ingreso</p>
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "entryCondition_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, entryCondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "entryconditions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_entryCondition}>
                <Button
                  className="button-activate"
                  onClick={() => activateEntryCondition(entryCondition)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "entryconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "entryconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_entryCondition}>
                <Button
                  className="button-entrycondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(entryCondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return entryConditionsInactive.length > 0? `Total de registros: ${entryConditionsInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={entryConditionsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
