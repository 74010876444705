import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditDepartmentForm from "../EditDepartmentForm";
import AddDepartmentForm from "../AddDepartmentForm";
import { activateDepartmentApi, deleteDepartmentApi } from "../../../../api/department";
import { getCoursesByDepartmentApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListDepartments.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListDepartments(props) {
  const {
    departmentsActive,
    departmentsInactive,
    setReloadDepartments,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewDepartmentsActives, setViewDepartmentsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [departmentsActiveFilter, setDepartmentsActiveFilter] = useState(
    []
  );
  const [ departmentsInactiveFilter, setDepartmentsInactiveFilter ] = useState([]);
  // const [typeFilter, setMethodFilter] = useState("description");

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (departmentsActive) {
      departmentsActive.forEach((element) => {
        // if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.department_code.toString()
              .includes(filterText.toUpperCase())||
            filterText === ""
          ) {
            listActive.push(element);
          }
        // }
      });
    }
    setDepartmentsActiveFilter(listActive);
  }, [filterText, departmentsActive]);

  useEffect(() => {
    const listInactive = [];
    if (departmentsInactive) {
      departmentsInactive.forEach((element) => {
        // if (typeFilter === "description") {
          if (
            element.description
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
              element.description
              .toString()
              .includes(filterText.toUpperCase())||
            filterText === ""
          ) {
            listInactive.push(element);
          }
        // }
      });
    }
    setDepartmentsInactiveFilter(listInactive);
  }, [filterText, departmentsInactive]);

  const addDepartmentModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Departamento");
    setModalContent(
      <AddDepartmentForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadDepartments={setReloadDepartments}
      />
    );
  };

  return (
    <div className="list-departments">
      <div className="list-departments__header">
        {checkAction(permissionsActive, userToken.role, "departments", "all") ||
        checkAction(permissionsActive, userToken.role, "departments", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-departments__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewDepartmentsActives(!viewDepartmentsActives)
                }
              />
              <span>
                {viewDepartmentsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "departments", "all") ||
        checkAction(permissionsActive, userToken.role, "departments", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addDepartmentModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "departments", "all") ||
      checkAction(permissionsActive, userToken.role, "departments", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-departments__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de departamentos
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewDepartmentsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "departments", "all") ||
          checkAction(permissionsActive, userToken.role, "departments", "view") || isAdmin(roleById) ? (
            <>
              <DepartmentsActive
                departmentsActive={departmentsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadDepartments={setReloadDepartments}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "departments", "all") ||
          checkAction(permissionsActive, userToken.role, "departments", "view") || isAdmin(roleById) ? (
            <>
              <DepartmentsInactive
                departmentsInactive={departmentsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadDepartments={setReloadDepartments}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function DepartmentsActive(props) {
  const {
    departmentsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadDepartments,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editDepartment = (department) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${department.description ? department.description : "sin descripción"}`
    );
    setModalContent(
      <EditDepartmentForm
        department={department}
        setIsVisibleModal={setIsVisibleModal}
        setReloadDepartments={setReloadDepartments}
      />
    );
  };

  const desactivateDepartment = (department) => {
    activateDepartmentApi(accessToken, department._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDepartments(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (department) => {
    let response = await getCoursesByDepartmentApi(accessToken, department._id);
    if (response.courses.length > 0) {
      confirm({
        title: "Eliminando -> Departamento",
        content: `No se puede eliminar '${department.description}' porque existen cursos asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateDepartment(department);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Departamento",
        content: `¿Estás seguro que quieres eliminar a ${department.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteDepartmentApi(accessToken, department._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadDepartments(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_department = (
    <div>
      {" "}
      <p>Editar Departamento</p>{" "}
    </div>
  );

  const desactivate_department = (
    <div>
      {" "}
      <p>Pasar a Departamentos inactivos</p>{" "}
    </div>
  );

  const delete_department = (
    <div>
      {" "}
      <p>Eliminar Departamento</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "department_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, department) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "departments", "all") ||
          checkAction(permissionsActive, userToken.role, "departments", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_department}>
                <Button
                  className="button-edit"
                  onClick={() => editDepartment(department)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "departments", "all") ||
          checkAction(permissionsActive, userToken.role, "departments", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_department}>
                <Button
                  className="button-department"
                  type="primary"
                  onClick={() => desactivateDepartment(department)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "departments", "all") ||
          checkAction(permissionsActive, userToken.role, "departments", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_department}>
                <Button
                  className="button-department"
                  type="danger"
                  onClick={() => showDeleteConfirm(department)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return departmentsActive.length > 0? `Total de registros: ${departmentsActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={departmentsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function DepartmentsInactive(props) {
  const { departmentsInactive,
          setReloadDepartments,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateDepartment = (department) => {
    activateDepartmentApi(accessToken, department._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadDepartments(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (department) => {
    let response = await getCoursesByDepartmentApi(accessToken, department._id);
    if(response.courses.length > 0){
      ModalAntd.info({
        title: "Eliminando -> Departmento",
        content:`No se puede eliminar '${department.description}' porque existen cursos asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Departamento",
        content: `¿Estás seguro que quieres eliminar a ${department.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteDepartmentApi(accessToken, department._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadDepartments(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_department = (
    <div>
      {" "}
      <p>Pasar a Departamentos activos</p>{" "}
    </div>
  );

  const delete_department = (
    <div>
      {" "}
      <p>Eliminar Departamento</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "department_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, department) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "departments", "all") ||
          checkAction(permissionsActive, userToken.role, "departments", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_department}>
                <Button
                  className="button-activate"
                  onClick={() => activateDepartment(department)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "departments", "all") ||
          checkAction(permissionsActive, userToken.role, "departments", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_department}>
                <Button
                  className="button-department"
                  type="danger"
                  onClick={() => showDeleteConfirm(department)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return departmentsInactive.length > 0? `Total de registros: ${departmentsInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={departmentsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
