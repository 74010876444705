import { downloadPdf, openPdf , getStyles, getHeader, getLogo} from "../../../../utils/pdfManager"; //docDefinitionTable
// import { convertTypeIva } from "../../../../utils/convert";
//import { formatDateView } from "../../../../utils/formatDate";
//import Logo from '../../../../assets/img/png/icba.png';
import moment from "moment";

export function listCoursesPdf(data, year, isDownload) {
  const _format = (data) => {
    return data.map((item) => {
      return [
        { text: item.name },
        { text: item.typeCourse? item.typeCourse.description : "sin datos" },
        { text: item.teachers? item.teachers.map(teacher=> teacher.description) : "sin datos" },
        { text: item.place? item.place.description : "sin datos" },
        { text: item.duration? item.duration.description : "sin datos" },
        { text: item.quota? item.quota : "sin datos" }
        // { text: item.hour_start? moment(item.hour_start,"HH:mm").isValid()?  moment(new Date(item.hour_start),"HH:mm").format("HH:mm") : item.hour_start : ""},
        // { text: item.hour_end? moment(item.hour_end,"HH:mm").isValid()?  moment(new Date(item.hour_end),"HH:MM").format("HH:mm") : item.hour_end : ""},
        // { text: item.days? item.days.map(day=> day.description) : "sin datos" }
      ];
    });
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical", //"landscape"
    header:getHeader(),
    content: [
        getLogo(),
        [
            { text:'Listado de Cursos', style:'title'},
        ],
      '\n',
      '\n',
      year!==false? {text:'Ciclo Lectivo ' + year,  bold: true,
      margin: [10, 0, 0, 0]} : null,
      '\n',
      {
        style: 'table',
        table: {
          headerRow: 1,
          widths:['*','auto','*','auto','auto','auto'],
          body: [
            [
              { text: "Nombre", style: "tableHeader" },
              { text: "Tipo", style: "tableHeader" },
              { text: "Docentes", style: "tableHeader" },
              { text: "Lugar", style: "tableHeader" },
              { text: "Duración", style: "tableHeader" },
              { text: "Cupo", style: "tableHeader" }
              // { text: "Hora Inicio", style: "tableHeader" },
              // { text: "Hora Fin", style: "tableHeader" },
              // { text: "Dias", style: "tableHeader" }
            ],
            ...formattedData,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  if (isDownload) {
    downloadPdf(docDefinition, "ListadoCursos");
  } else {
    openPdf(docDefinition);
  }
}

export function courseDetailPdf(data, isDownload) {
  const _format = (data) => {
    return [
      { text: `Nombre: ${data.name ? data.name : ""}`, style:'list' },
      { text: `Docente: ${data.teacher ? data.teacher : ""}` , style:'list'},
      { text: `Lugar : ${data.place ? data.place : ""}`, style:'list' },
      { text: `Duración: ${data.duration ? data.duration : ""}` , style:'list'},
    ];
  };

  const formattedData = _format(data);

  const docDefinition = {
    pageSize: "A4",
    pageOrientation: "vertical",
    header:getHeader(),
    content: [
      getLogo(),
      '\n',
      [
          { text:'Detalle del curso', style:'title'},
      ],
      '\n',
      '\n',
      {
        type: "none",
        ol: [...formattedData],
      },
    ],
    styles:getStyles()
  };
  let title = `${data.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}
