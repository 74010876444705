import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Dropdown,
  Table,
  Select,
  BackTop,
  Row,
  Col,
  Space,
  Spin,
  Progress,
  Divider,
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  FilePdfOutlined,
  PlusOutlined,
  FileExcelOutlined,
  DownOutlined,
  UpOutlined,
  DownloadOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditAdviserForm from "../EditAdviserForm";
import AddAdviserForm from "../AddAdviserForm";
import {
  activateAdviserApi,
  deleteAdviserApi,
  getAdvisersActiveApi,
} from "../../../../api/adviser";
import {
  getStudentsFinalConditionsByAdviserApi
} from "../../../../api/student";
import {
  // getInscriptionsByStudentReportApi,
  // getCoursedHoursStudentToYearApi,
  getCoursedHoursTotalByStudentApi,
} from "../../../../api/inscription";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getStudentsByAdviserApi } from "../../../../api/student";
import {
  reportAdviserHistory,
  reportAdviserYear,
  reportAdviserHistoryExcel,
  reportAdviserYearExcel,
  reportAdvisersYear,
  reportAdvisersYearCompleted,
  reportAdvisersYearExcel,
  reportAdvisersYearCompleted2,
  reportAdvisersYearCompletedExcel,
} from "./pdfAdvisersReport";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import { formatDateView2 } from "../../../../utils/formatDate";
import AdvancedSearchAdviserForm from "./AdvancedSearchForm";
import jwtDecode from "jwt-decode";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from "moment";

import "./ListAdvisers.scss";

const { confirm } = ModalAntd;
const { Search } = Input;
const { Option } = Select;
//EXCEL
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ListAdvisers(props) {
  const {
    setPaginationActive,
    setPaginationInactive,
    advisersActive,
    advisersInactive,
    setReloadAdvisers,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewAdvisersActives, setViewAdvisersActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [advisersActiveFilter, setAdvisersActiveFilter] = useState([]);
  const [advisersInactiveFilter, setAdvisersInactiveFilter] = useState([]);
  const [listAdvisersActive, setListAdvisersActiveFilter] = useState([]);
  const [listAdvisersInactiveFilter, setListAdvisersInactiveFilter] = useState(
    []
  );
  const [exportActiveExcel, setExportActiveExcel] = useState([]);
  const [listSchoolYearsActive, setListSchoolYearsActive] = useState([]);
  // const [modalOpen, setModelOpen] = useState(false);
  const [dataExcel, setDataExcel] = useState([]);

  const [propsPaginationActive, setPropsPaginationActive] = useState({});
  const [propsPaginationInactive, setPropsPaginationInactive] = useState({});

  //filtros para busqueda avanzada
  const [filters, setFilters] = useState([]);
  const [expand, setExpand] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [expandDownload, setExpandDownload] = useState(false);
  const [schoolYearData, setSchoolYearData] = useState("");
  const [loading, setLoading] = useState(false);

  const [advisersActiveExcel, setAdvisersActiveExcel] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListSchoolYearsActive(response.schoolYears);
    });
  }, []);

  useEffect(() => {
    getAdvisersActiveApi(accessToken, true).then((response) => {
      setListAdvisersActiveFilter(response.advisers);
    });
  }, []);

  useEffect(() => {
    getAdvisersActiveApi(accessToken, false).then((response) => {
      setListAdvisersInactiveFilter(response.advisers);
    });
  }, []);

  useEffect(() => {
    if (advisersActive) {
      setPropsPaginationActive({
        total: advisersActive.totalDocs,
        pageSize: advisersActive.limit,
        defaultCurrent: advisersActive.page,
        current: advisersActive.page,
      });
    }
  }, [advisersActive]);

  useEffect(() => {
    if (advisersInactive) {
      setPropsPaginationInactive({
        total: advisersInactive.totalDocs,
        pageSize: advisersInactive.limit,
        defaultCurrent: advisersInactive.page,
        current: advisersInactive.page,
      });
    }
  }, [advisersInactive]);

  useEffect(() => {
    if (advisersActive && advisersActive.docs) {
      setAdvisersActiveFilter(advisersActive.docs);
    }
  }, [advisersActive]);

  useEffect(() => {
    if (advisersInactive && advisersInactive.docs) {
      setAdvisersInactiveFilter(advisersInactive.docs);
    }
  }, [advisersInactive]);

  useEffect(() => {
    if (filterText) {
      setPaginationActive({ page: 1, state: 1, filter: filterText });
      setPaginationInactive({ page: 1, state: 2, filter: filterText });
    } else if (filterText === "") {
      setPaginationActive({ page: 1, state: 1 });
      setPaginationInactive({ page: 1, state: 2 });
    }
    // setTeachersActiveFilter(listActive);
  }, [filterText]);

  useEffect(() => {
    if (filters && filters.schoolYear) {
      setAdvisersActiveFilter([]);
      setAdvisersActiveExcel([]);
      setLoading(true);
      AdvisersByYear2(listAdvisersActive, filters.schoolYear).then((result) => {
        setAdvisersActiveFilter(result);
        setAdvisersActiveExcel(
          reportAdvisersYearCompletedExcel(result, filters.schoolYear)
        );
        setLoading(false);
      });
      // });

      setAdvisersInactiveFilter([]);
      AdvisersByYear2(listAdvisersInactiveFilter, filters.schoolYear).then(
        (result) => {
          setAdvisersInactiveFilter(result);
        }
      );

      setAdvancedSearch(true);
      // })
    } else {
      setAdvancedSearch(false);
    }
    // setTeachersActiveFilter(listActive);
  }, [filters]);

  const addAdviserModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Consejero");
    setModalContent(
      <AddAdviserForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadAdvisers={setReloadAdvisers}
      />
    );
  };

  // const adviserHistory = (adviser) => {
  //   getStudentsFinalConditionsByAdviserApi(accessToken, adviser._id, false)
  //     .then((response) => {
  //       reportAdviserHistory(adviser, response.students, false);
  //     })
  //     .catch((err) => {
  //       notification["error"]({
  //         message: err,
  //       });
  //     });
  // };

  // const adviserHistoryExcel = (adviser) => {
  //   getStudentsFinalConditionsByAdviserApi(accessToken, adviser._id, false)
  //     .then((response) => {
  //       setDataExcel(
  //         reportAdviserHistoryExcel(adviser, response.students, false)
  //       );
  //       console.log(dataExcel);
  //     })
  //     .catch((err) => {
  //       notification["error"]({
  //         message: err,
  //       });
  //     });
  // };

  const selectedYear = (adviser) => {
    setIsVisibleModal(true);
    setModalTitle(`Reporte Consejero por Ciclo`);
    setModalContent(
      <HistoryByYear
        adviser={adviser}
        setIsVisibleModal={setIsVisibleModal}
        listSchoolYearsActive={listSchoolYearsActive}
        accessToken={accessToken}
      />
    );
  };

  const selectedTypeFile = (adviser) => {
    setIsVisibleModal(true);
    setModalTitle(`Reporte Consejero Historico`);
    setModalContent(
      <HistoryCompleted
        adviser={adviser}
        setIsVisibleModal={setIsVisibleModal}
        accessToken={accessToken}
      />
    );
  };

  const selectedAdvisersYear = (advisers) => {
    setIsVisibleModal(true);
    setModalTitle(`Reporte Consejero por Ciclo`);
    setModalContent(
      <AdvisersByYear
        advisers={advisers}
        setIsVisibleModal={setIsVisibleModal}
        listSchoolYearsActive={listSchoolYearsActive}
        accessToken={accessToken}
      />
    );
  };

  return (
    <div className="list-advisers">
      <div className="list-advisers__header">
        {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
        checkAction(permissionsActive, userToken.role, "advisers", "enable") ||
        isAdmin(roleById) ? (
          <>
            <div className="list-advisers__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewAdvisersActives(!viewAdvisersActives)}
              />
              <span>{viewAdvisersActives ? "Activos" : "Inactivos"}</span>
            </div>
          </>
        ) : (
          <></>
        )}
        {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
        checkAction(permissionsActive, userToken.role, "advisers", "add") ||
        isAdmin(roleById) ? (
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={addAdviserModal}
            >
              Nuevo
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
      checkAction(permissionsActive, userToken.role, "advisers", "view") ||
      isAdmin(roleById) ? (
        <>
          <div className="list-advisers__search">
            <Search
              className="search"
              allowClear
              placeholder=""
              onSearch={(e) => setFilterText(e)}
              enterButton="Buscar"
              style={{ width: "100%" }}
            />
            <h1>
              <Button
                type="link"
                style={{ fontSize: 12 }}
                onClick={() => {
                  setExpand(!expand);
                }}
              >
                {expand ? <UpOutlined /> : <DownOutlined />} Búsqueda Avanzada
                (filtros)
              </Button>
              {expand ? (
                <div>
                  {/* busqueda avanzada */}
                  <AdvancedSearchAdviserForm
                    filters={filters}
                    setFilters={setFilters}
                    setSchoolYearData={setSchoolYearData}
                  />
                </div>
              ) : null}
            </h1>
          </div>
        </>
      ) : (
        <></>
      )}

      {viewAdvisersActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(permissionsActive, userToken.role, "advisers", "pdf") ||
          isAdmin(roleById) ? (
            !advancedSearch ? (
              <>
                <Popover content={"consejeros "}>
                  <Space align="center" style={{ marginBottom: 18 }}>
                    <Button
                      //size="small"
                      type="default"
                      shape="round"
                      style={{ marginRight: 10 }}
                      // disabled={exportActivePdf? exportActivePdf.length > 0? false : true : true}
                      onClick={() => {
                        selectedAdvisersYear(listAdvisersActive);
                        // exportCoursesPdf(true);
                        // listCoursesPdf(exportActivePdf, getYearSchoolYear(schoolYear), false)
                      }}
                    >
                      <FilePdfOutlined />
                    </Button>
                  </Space>
                </Popover>
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginRight: 10, marginBottom: 10 }}
                  shape="round"
                  size={"small"}
                  onClick={() => {
                    setExpandDownload(!expandDownload);
                  }}
                >
                  {<DownloadOutlined />} Descargas{" "}
                  {!expandDownload ? <RightOutlined /> : <LeftOutlined />}
                </Button>
                {expandDownload ? (
                  <>
                    <Space
                      split={<Divider type="vertical" />}
                      style={{ marginBottom: 10 }}
                    >
                      <Popover content={"pdf_download"}>
                        <Button
                          type="default"
                          shape="round"
                          size={"small"}
                          disabled={
                            advisersActiveFilter
                              ? advisersActiveFilter.length > 0
                                ? false
                                : true
                              : true
                          }
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            reportAdvisersYearCompleted2(
                              advisersActiveFilter,
                              filters.schoolYear
                            );
                          }}
                        >
                          <FilePdfOutlined /> Listado Pdf
                        </Button>
                      </Popover>

                      <>
                        <ExcelFile
                          filename={"ListadoEstudiantesyConsejeros"+(filters && filters.schoolYear? filters.schoolYear:"")}
                          element={
                            <Popover content={"xls_download"}>
                              <Button
                                type="default"
                                size={"small"}
                                shape="round"
                                disabled={
                                  advisersActiveFilter
                                    ? advisersActiveFilter.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Listado xls
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={advisersActiveExcel}
                            name="Consejeros"
                          >
                            <ExcelColumn label="Consejero" value="adviser" />
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn label="Estado" value="state" />
                            <ExcelColumn label="Email" value="email" />
                            <ExcelColumn label="Telefono" value="phone" />
                            
                          </ExcelSheet>
                        </ExcelFile>
                      </>
                    </Space>
                  </>
                ) : null}
              </>
            )
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(permissionsActive, userToken.role, "advisers", "view") ||
          isAdmin(roleById) ? (
            <>
              <AdvisersActive
                advisersActive={advisersActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadAdvisers={setReloadAdvisers}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                selectedYear={selectedYear}
                setPagination={setPaginationActive}
                propsPagination={propsPaginationActive}
                filterText={filterText}
                selectedTypeFile={selectedTypeFile}
                advancedSearch={advancedSearch}
                loading={loading}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(permissionsActive, userToken.role, "advisers", "pdf") ||
          isAdmin(roleById) ? (
            !advancedSearch ? (
              <>
                <Popover content={"consejeros "}>
                  <Space align="center" style={{ marginBottom: 18 }}>
                    <Button
                      //size="small"
                      type="default"
                      shape="round"
                      style={{ marginRight: 10 }}
                      // disabled={exportActivePdf? exportActivePdf.length > 0? false : true : true}
                      onClick={() => {
                        selectedAdvisersYear(listAdvisersInactiveFilter);
                        // exportCoursesPdf(true);
                        // listCoursesPdf(exportActivePdf, getYearSchoolYear(schoolYear), false)
                      }}
                    >
                      <FilePdfOutlined />
                    </Button>
                  </Space>
                </Popover>
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginRight: 10, marginBottom: 10 }}
                  shape="round"
                  size={"small"}
                  onClick={() => {
                    setExpandDownload(!expandDownload);
                  }}
                >
                  {<DownloadOutlined />} Descargas{" "}
                  {!expandDownload ? <RightOutlined /> : <LeftOutlined />}
                </Button>
                {expandDownload ? (
                  <>
                    <Space
                      split={<Divider type="vertical" />}
                      style={{ marginBottom: 10 }}
                    >
                      <Popover content={"pdf_download"}>
                        <Button
                          type="default"
                          shape="round"
                          size={"small"}
                          disabled={
                            advisersInactiveFilter
                              ? advisersInactiveFilter.length > 0
                                ? false
                                : true
                              : true
                          }
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            reportAdvisersYearCompleted2(
                              advisersInactiveFilter,
                              filters.schoolYear
                            );
                          }}
                        >
                          <FilePdfOutlined /> Listado Pdf
                        </Button>
                      </Popover>

                      <>
                        <ExcelFile
                          filename={"ListadoEstudiantesyConsejerosinactivos"+(filters && filters.schoolYear? filters.schoolYear:"")}
                          element={
                            <Popover content={"xls_download"}>
                              <Button
                                type="default"
                                size={"small"}
                                shape="round"
                                disabled={
                                  advisersInactiveFilter
                                    ? advisersInactiveFilter.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Listado xls
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={advisersInactiveFilter}
                            name="Consejeros"
                          >
                            <ExcelColumn label="Consejero" value="adviser" />
                            <ExcelColumn label="Apellido" value="lastname" />
                            <ExcelColumn label="Nombre" value="name" />
                            <ExcelColumn label="Estado" value="state" />
                          </ExcelSheet>
                        </ExcelFile>
                      </>
                    </Space>
                  </>
                ) : null}
              </>
            )
          ) : (
            <></>
          )}
          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(permissionsActive, userToken.role, "advisers", "view") ||
          isAdmin(roleById) ? (
            <>
              <AdvisersInactive
                advisersInactive={advisersInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadAdvisers={setReloadAdvisers}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationActive}
                propsPagination={propsPaginationInactive}
                filterText={filterText}
                selectedTypeFile={selectedTypeFile}
                advancedSearch={advancedSearch}
                loading={loading}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop />
    </div>
  );
}

function AdvisersActive(props) {
  const {
    advisersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadAdvisers,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    selectedYear,
    propsPagination,
    setPagination,
    filterText,
    selectedTypeFile,
    advancedSearch,
    loading,
  } = props;

  const editAdviser = (adviser) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar ${adviser.name ? adviser.name : "sin nombre"}`);
    setModalContent(
      <EditAdviserForm
        adviser={adviser}
        setIsVisibleModal={setIsVisibleModal}
        setReloadAdvisers={setReloadAdvisers}
      />
    );
  };

  const desactivateAdviser = (adviser) => {
    activateAdviserApi(accessToken, adviser._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadAdvisers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (adviser) => {
    let response = await getStudentsByAdviserApi(accessToken, adviser._id);
    if (response.students.length > 0) {
      confirm({
        title: "Eliminando -> Consejero",
        content: `No se puede eliminar '${adviser.name} ${adviser.lastname}' porque existen estudiantes asociados ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateAdviser(adviser);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Consejero",
        content: `¿Estás seguro que quieres eliminar a ${adviser.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteAdviserApi(accessToken, adviser._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadAdvisers(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_adviser = (
    <div>
      {" "}
      <p>Editar Consejero</p>{" "}
    </div>
  );

  const desactivate_adviser = (
    <div>
      {" "}
      <p>Pasar a Consejeros inactivos</p>{" "}
    </div>
  );

  const delete_adviser = (
    <div>
      {" "}
      <p>Eliminar Consejero</p>{" "}
    </div>
  );

  const history_complete = (
    <div>
      {" "}
      <p>Historial completo del Consejero</p>{" "}
    </div>
  );

  const history_year = (
    <div>
      {" "}
      <p>Historial del Consejero por año</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      //dataIndex: "adviser_code",
      render: (adviser) =>
        adviser.id_old ? adviser.id_old : adviser.adviser_code,
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Apellido",
      dataIndex: "lastname",
    },
    {
      title: "Correo",
      dataIndex: "email",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, adviser) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(permissionsActive, userToken.role, "advisers", "edit") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={edit_adviser}>
                <Button
                  className="button-edit"
                  onClick={() => editAdviser(adviser)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "advisers",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_adviser}>
                <Button
                  className="button-adviser"
                  type="primary"
                  onClick={() => desactivateAdviser(adviser)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "advisers",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_adviser}>
                <Button
                  className="button-adviser"
                  type="danger"
                  onClick={() => showDeleteConfirm(adviser)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "advisers",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={history_complete}>
                <Button
                  className="button-pdf-complete"
                  type="primary"
                  onClick={() => selectedTypeFile(adviser)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "advisers",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={history_year}>
                <Button
                  className="button-pdf-year"
                  type="primary"
                  onClick={() => selectedYear(adviser)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  if (advancedSearch) {
    return (
      <Table
        footer={() => {
          return advisersActive
            ? `Total de registros: ${advisersActive.length}`
            : false;
        }}
        dataSource={advisersActive}
        loading={loading}
        pagination={{
          pageSize: 50,
        }}
        columns={columns}
        rowKey="_id"
      />
    );
  } else {
    return (
      <Table
        footer={() => {
          return propsPagination.total
            ? `Total de registros: ${propsPagination.total}`
            : false;
        }}
        pagination={{
          ...propsPagination,
          showSizeChanger: false,
          onChange: (newPage) => {
            setPagination({ page: newPage, state: 1, filter: filterText });
          },
        }}
        dataSource={advisersActive}
        columns={columns}
        rowKey="_id"
      />
    );
  }
}

function AdvisersInactive(props) {
  const {
    advisersInactive,
    setReloadAdvisers,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    adviserHistory,
    propsPagination,
    setPagination,
    filterText,
    selectedTypeFile,
    advancedSearch,
    loading,
  } = props;

  const activateAdviser = (adviser) => {
    activateAdviserApi(accessToken, adviser._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadAdvisers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (adviser) => {
    let response = await getStudentsByAdviserApi(accessToken, adviser._id);
    if (response.students.length > 0) {
      ModalAntd.info({
        title: "Eliminando -> Consejero",
        content: `No se puede eliminar '${adviser.name} ${adviser.lastname}' porque existen estudiantes asociados a él`,
        onOk() {},
      });
    } else {
      confirm({
        title: "Eliminando -> Consejero",
        content: `¿Estás seguro que quieres eliminar a ${adviser.name}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteAdviserApi(accessToken, adviser._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadAdvisers(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_adviser = (
    <div>
      {" "}
      <p>Pasar a Consejeros activos</p>{" "}
    </div>
  );

  const delete_adviser = (
    <div>
      {" "}
      <p>Eliminar Consejero</p>
    </div>
  );

  const history_adviser = (
    <div>
      {" "}
      <p>Historial del Consejero</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      //dataIndex: "adviser_code",
      render: (adviser) =>
        adviser.id_old ? adviser.id_old : adviser.adviser_code,
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Apellido",
      dataIndex: "lastname",
    },
    {
      title: "Correo",
      dataIndex: "email",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, adviser) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "advisers",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={activate_adviser}>
                <Button
                  className="button-activate"
                  onClick={() => activateAdviser(adviser)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "advisers",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_adviser}>
                <Button
                  className="button-adviser"
                  type="danger"
                  onClick={() => showDeleteConfirm(adviser)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "advisers", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "advisers",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={history_adviser}>
                <Button
                  className="button-pdf"
                  type="primary"
                  onClick={() => selectedTypeFile(adviser)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  if (advancedSearch) {
    return (
      <Table
        footer={() => {
          return advisersInactive
            ? `Total de registros: ${advisersInactive.length}`
            : false;
        }}
        dataSource={advisersInactive}
        loading={loading}
        pagination={{
          pageSize: 50,
        }}
        columns={columns}
        rowKey="_id"
      />
    );
  } else {
    return (
      <Table
        footer={() => {
          return propsPagination.total
            ? `Total de registros: ${propsPagination.total}`
            : false;
        }}
        pagination={{
          ...propsPagination,
          showSizeChanger: false,
          onChange: (newPage) => {
            setPagination({ page: newPage, state: 1, filter: filterText });
          },
        }}
        dataSource={advisersInactive}
        columns={columns}
        rowKey="_id"
      />
    );
  }
}

function HistoryByYear(props) {
  const { adviser, accessToken, setIsVisibleModal, listSchoolYearsActive } =
    props;

  const [yearSelected, setYearSelected] = useState(null);
  const [dataStudentsAdviser, setDataStudentsAdviser] = useState([]);
  const [dataStudentsAdviserByYear, setDataStudentsAdviserByYear] = useState(
    []
  );
  const [countStudents, setCountStudents] = useState(0);
  const [list, setList] = useState([]);
  const [nameAdviserFile, setNameAdviserFile] = useState([]);
  const [nameAdviser, setNameAdviser] = useState([]);
  const isMounted = React.useRef(true);
  const [count, setCount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let data = "";
    let nameFile = "";
    if (adviser.lastname) {
      data = data + adviser.lastname;
      nameFile = adviser.lastname;
    }
    if (adviser.name) {
      data = data.concat(" " + adviser.name);
      nameFile = nameFile.concat(adviser.name);
    }
    setNameAdviser(data);
    setNameAdviserFile(nameFile);
  }, []);

  // useEffect(() => {
  //   if (listSchoolYearsActive && listSchoolYearsActive.length > 0) {
  //     setYearSelected(listSchoolYearsActive[0].year); //selecciono un año por defecto
  //   }
  // }, [listSchoolYearsActive]);

  useEffect(() => {
    //primero filtramos por año seleccionado
    if (isMounted.current) {
      if (dataStudentsAdviser) {
        setCount(0);
        decline(0);
        setDataStudentsAdviserByYear([]);
        setLoading(true);
        let list = studentsByYear(dataStudentsAdviser, yearSelected);
        setCountStudents(list.length);
        getHistoryStudents(list).then((result) => {
          if (isMounted.current) {
            setDataStudentsAdviserByYear(result);
            setList(reportAdviserYearExcel(adviser, result, yearSelected));
            setLoading(false);
          }
        });
      }
    }
    // setList(
    //   reportAdviserYearExcel(adviser,dataStudentsAdviser, yearSelected)
    // );
  }, [dataStudentsAdviser, yearSelected]);

  useEffect(() => {
    getStudentsFinalConditionsByAdviserApi(accessToken, adviser._id, false)
      .then((response) => {
        if (isMounted.current) {
          setDataStudentsAdviser(response.students);
        }
        // setList(
        //   reportAdviserYearExcel(adviser, response.students, yearSelected)
        // );
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
    return () => {
      isMounted.current = false;
    };
  }, [listSchoolYearsActive]);

  const studentsByYear = (students, year) => {
    let list = [];
    students.map((student) => {
      let states = student.finalConditions.filter(
        (i) =>
          new Date(i.initDate).getFullYear() === parseInt(year) &&
          i.adviser === adviser._id
      );
      if (states && states.length > 0) {
        list.push(student);
      }
      return true;
    });

    return list;
  };

  const adviserHistoryByYear = () => {
    if (yearSelected && yearSelected !== null) {
      if (dataStudentsAdviser.length > 0) {
        reportAdviserYear(
          adviser,
          dataStudentsAdviserByYear,
          yearSelected,
          false
        );
      }
    }
  };

  const getHistoryStudents = async (students) => {
    const accessToken = getAccessToken();
    let data = [];
    for await (const item of students) {
      ///VERIFICAR PORQUE NO ALMACENA EL VALOR E INCREMENTA
      //let result= await getCoursedHoursStudentToYearApi(accessToken,yearSelected,item._id);
      if (isMounted.current) {
        let result = await getCoursedHoursTotalByStudentApi(
          accessToken,
          item._id
        );
        if (result) {
          if (isMounted.current) {
            item.coursedHours =
              result.coursedHours && typeof result.coursedHours === "number"
                ? result.coursedHours
                : null;
            data.push(item);
            increaseCount();
            increase();
          }
        } else {
          break;
        }
      }
    }
    return data;
  };

  const increase = () => {
    if (isMounted.current) {
      setPercent((prevPercent) => {
        const newPercent = prevPercent + 100 / countStudents;
        //Math.ceil(100/students.length);
        if (newPercent > 100) {
          return 100;
        }
        return newPercent;
      });
    }
  };

  const decline = () => {
    if (isMounted.current) {
      setPercent((prevPercent) => {
        return 0;
      });
    }
  };

  const increaseCount = () => {
    if (isMounted.current) {
      setCount((prevCount) => {
        const newCount = prevCount + 1;
        return newCount;
      });
    }
  };

  const multiDataSet = [
    {
      columns: ["Reporte Consejero", "Fecha Consulta"],
      data: [[nameAdviser, formatDateView2(moment())]],
    },
    {
      ySteps: 1,
      columns: [
        "Ciclo",
        "Apellido y Nombre",
        "Estado",
        "Condición",
        "Hs Cursadas",
        "Email",
        "Telefono",
      ],
      data: list,
    },
  ];

  const closed = () => {
    setIsVisibleModal(false);
  };

  return (
    <div>
      <p>Seleccione ciclo lectivo </p>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <Select
            placeholder="Año"
            value={yearSelected}
            onChange={(e) => setYearSelected(e)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
          >
            {listSchoolYearsActive
              ? listSchoolYearsActive.map((schoolYear) => {
                  return (
                    <Option key={schoolYear.year}>{schoolYear.year}</Option>
                  );
                })
              : null}
          </Select>
        </Col>
        {loading ? (
          <Col>
            <Spin tip="Buscando estudiantes" size="medium"></Spin>
          </Col>
        ) : null}
      </Row>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {dataStudentsAdviserByYear &&
        dataStudentsAdviserByYear.length === 0 &&
        loading === false &&
        yearSelected !== null ? (
          <Col span={12}>
            <span style={{ fontWeight: "bold" }}> Sin resultados </span>
          </Col>
        ) : null}
        {dataStudentsAdviserByYear && dataStudentsAdviserByYear.length > 0 ? (
          <>
            <Col span={12}>
              <span style={{ fontWeight: "bold" }}>Total de estudiantes:</span>{" "}
              {dataStudentsAdviserByYear.length}
            </Col>
            <Col span={6}>
              <Popover content={"Pdf"}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    adviserHistoryByYear();
                  }}
                >
                  <FilePdfOutlined />
                  PDF
                </Button>
              </Popover>
            </Col>
            <Col span={6}>
              <>
                <ExcelFile
                  filename={"Consejero" + nameAdviserFile}
                  element={
                    <Popover content={"Excel"}>
                      <Button type="primary" style={{ width: "100%" }}>
                        Excel
                        <FileExcelOutlined />
                      </Button>
                    </Popover>
                  }
                >
                  <ExcelSheet
                    // data={list && list.length > 0 ? list : []}
                    dataSet={multiDataSet}
                    name="Consejeros"
                  >
                    {/* <ExcelColumn label="Ciclo" value="year" />
                <ExcelColumn label="Apellido y Nombre" value="name" />
                <ExcelColumn label="Estado" value="state" />
                <ExcelColumn label="E-mail" value="email" />
                <ExcelColumn label="Telefono" value="phone" /> */}
                  </ExcelSheet>
                </ExcelFile>
              </>
            </Col>
          </>
        ) : null}
      </Row>
      {/* <Row>
        <Col span={24}>
          <Button type="primary"  style={{marginTop:25, width:"100%"}} onClick={closed}>
            Salir
          </Button>
        </Col>
      </Row> */}
    </div>
  );
}

function HistoryCompleted(props) {
  const { adviser, accessToken, setIsVisibleModal } = props;

  const [dataStudentsAdviser, setDataStudentsAdviser] = useState([]);
  const [list, setList] = useState([]);
  const [nameAdviserFile, setNameAdviserFile] = useState([]);
  const [nameAdviser, setNameAdviser] = useState([]);
  const isMounted = React.useRef(true);

  useEffect(() => {
    let data = "";
    let nameFile = "";
    if (adviser.lastname) {
      data = data + adviser.lastname;
      nameFile = adviser.lastname;
    }
    if (adviser.name) {
      data = data.concat(" " + adviser.name);
      nameFile = nameFile.concat(adviser.name);
    }
    setNameAdviser(data);
    setNameAdviserFile(nameFile);
  }, []);

  useEffect(() => {
    getStudentsFinalConditionsByAdviserApi(accessToken, adviser._id, false)
      .then((response) => {
        if (isMounted.current) {
          setList(reportAdviserHistoryExcel(adviser, response.students, false));
          setDataStudentsAdviser(response.students);
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
    return () => {
      isMounted.current = false;
    };
  }, [adviser]);

  const adviserHistoryPdf = () => {
    // getStudentsFinalConditionsByAdviserApi(accessToken, adviser._id, false)
    // .then((response) => {
    if (dataStudentsAdviser.length > 0) {
      reportAdviserHistory(adviser, dataStudentsAdviser, false);
    } else {
      notification["error"]({
        message: "No hay datos para mostrar ó reintente nuevamente",
      });
    }
  };

  const multiDataSet = [
    {
      columns: ["Reporte Consejero Historico", "Fecha Consulta"],
      data: [[nameAdviser, formatDateView2(moment())]],
    },
    {
      ySteps: 1,
      columns: [
        "Ciclo",
        "Apellido y Nombre",
        "Estado",
        "Condición",
        "Email",
        "Telefono",
      ],
      data: list,
    },
  ];

  const closed = () => {
    setIsVisibleModal(false);
  };

  return (
    <div>
      <p>Seleccione el tipo de archivo para su descarga </p>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={10} offset={1}>
          <Popover content={"Pdf"}>
            <Button
              type="primary"
              // disabled={exportActivePdf? exportActivePdf.length > 0? false : true : true}
              style={{ width: "90%" }}
              onClick={() => {
                adviserHistoryPdf();
              }}
            >
              <FilePdfOutlined />
              PDF
            </Button>
          </Popover>
        </Col>
        <Col span={10} offset={3}>
          <>
            <ExcelFile
              filename={"Consejero" + nameAdviserFile}
              element={
                <Popover content={"Excel"}>
                  <Button type="primary" style={{ width: "90%" }}>
                    Excel
                    <FileExcelOutlined />
                  </Button>
                </Popover>
              }
            >
              <ExcelSheet
                // data={list && list.length > 0 ? list : []}
                dataSet={multiDataSet}
                name="Consejero"
              >
                {/* <ExcelColumn label="Ciclo" value="year" />
                <ExcelColumn label="Apellido y Nombre" value="name" />
                <ExcelColumn label="Estado" value="state" />
                <ExcelColumn label="E-mail" value="email" />
                <ExcelColumn label="Telefono" value="phone" /> */}
              </ExcelSheet>
            </ExcelFile>
          </>
        </Col>
      </Row>
      {/* <Row>
        <Col span={24}>
        <Button type="primary" style={{marginTop:25, width:"100%"}} onClick={closed}>
          Salir
        </Button>
        </Col>
      </Row> */}
    </div>
  );
}

function AdvisersByYear(props) {
  const { advisers, accessToken, setIsVisibleModal, listSchoolYearsActive } =
    props;

  const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
  const [dataStudentsAdviser, setDataStudentsAdviser] = useState([]);
  const [dataStudentsAdvisersByYear, setDataStudentsAdvisersByYear] = useState(
    []
  );
  const [countStudents, setCountStudents] = useState(0);
  const [list, setList] = useState([]);
  const [nameAdviserFile, setNameAdviserFile] = useState([]);
  const [nameAdviser, setNameAdviser] = useState([]);
  const isMounted = React.useRef(true);
  const [count, setCount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let data = "";
    let nameFile = "";
    // if(adviser.lastname){
    //   data=data+adviser.lastname;
    //   nameFile=adviser.lastname;
    // }
    // if(adviser.name){
    //   data=data.concat(" " + adviser.name);
    //   nameFile=nameFile.concat(adviser.name);
    // }
    setNameAdviser(data);
    setNameAdviserFile(nameFile);
  }, []);

  // useEffect(() => {
  //   if (listSchoolYearsActive && listSchoolYearsActive.length > 0) {
  //     setYearSelected(listSchoolYearsActive[0].year); //selecciono un año por defecto
  //   }
  // }, [listSchoolYearsActive]);

  useEffect(() => {
    //primero filtramos por año en curso
    if (isMounted.current) {
      if (advisers && advisers.length > 0) {
        setCount(0);
        decline(0);
        setDataStudentsAdvisersByYear([]);
        setLoading(true);
        // let list=studentsByYear(dataStudentsAdviser, yearSelected);
        // setCountStudents(list.length);
        getStudentsByAdvisers(advisers).then((result) => {
          if (isMounted.current) {
            setDataStudentsAdvisersByYear(result);
            setList(reportAdvisersYearExcel(advisers, result, yearSelected));
            // reportAdvisersYear(advisers,result, yearSelected)
            setLoading(false);
          }
        });
      }
    }
    // setList(
    //   reportAdviserYearExcel(adviser,dataStudentsAdviser, yearSelected)
    // );
  }, [advisers]);

  const studentsByYear = (adviser, students, year) => {
    let list = [];
    students.map((student) => {
      let states = student.finalConditions.filter(
        (i) =>
          new Date(i.initDate).getFullYear() === parseInt(year) &&
          i.adviser === adviser._id
      );
      if (states && states.length > 0) {
        list.push(student);
      }
      return true;
    });

    return list;
  };

  const studentsByCondition = (adviser, students, condition) => {
    let list = [];
    students.map((student) => {
      let states = student.finalConditions.filter(
        (i) =>
          i.finalCondition &&
          i.finalCondition.description.toLowerCase() ===
            condition.toLowerCase() &&
          i.adviser === adviser._id &&
          !i.finalDate
      );
      if (states && states.length > 0) {
        list.push(student);
      }
      return true;
    });

    return list;
  };

  const adviserHistoryByYear = () => {
    if (yearSelected && yearSelected !== null) {
      if (dataStudentsAdvisersByYear.length > 0) {
        reportAdvisersYear(
          advisers,
          dataStudentsAdvisersByYear,
          yearSelected,
          false
        );
      }
    }
  };

  const adviserHistoryByYearCompleted = () => {
    if (yearSelected && yearSelected !== null) {
      if (dataStudentsAdvisersByYear.length > 0) {
        reportAdvisersYearCompleted(
          advisers,
          dataStudentsAdvisersByYear,
          yearSelected,
          false
        );
      }
    }
  };

  const getStudentsByAdvisers = async (advisers) => {
    const accessToken = getAccessToken();
    let data = [];
    for await (const item of advisers) {
      ///VERIFICAR PORQUE NO ALMACENA EL VALOR E INCREMENTA
      //let result= await getCoursedHoursStudentToYearApi(accessToken,yearSelected,item._id);
      if (isMounted.current) {
        let result = await getStudentsFinalConditionsByAdviserApi(
          accessToken,
          item._id
        );
        if (result) {
          if (isMounted.current) {
            //Filtramos los estudiantes asociados al consejero por año seleccionado
            let list = studentsByYear(item, result.students, yearSelected);
            let list2 = studentsByCondition(item, list, "en curso");
            data.push({
              adviser: item._id,
              students: list2,
            });
            increaseCount();
            increase();
          }
        } else {
          break;
        }
      }
    }
    return data;
  };

  const increase = () => {
    if (isMounted.current) {
      setPercent((prevPercent) => {
        const newPercent = prevPercent + 100 / countStudents;
        //Math.ceil(100/students.length);
        if (newPercent > 100) {
          return 100;
        }
        return newPercent;
      });
    }
  };

  const decline = () => {
    if (isMounted.current) {
      setPercent((prevPercent) => {
        return 0;
      });
    }
  };

  const increaseCount = () => {
    if (isMounted.current) {
      setCount((prevCount) => {
        const newCount = prevCount + 1;
        return newCount;
      });
    }
  };

  const multiDataSet = [
    {
      columns: ["Reporte Consejero", "Fecha Consulta"],
      data: [[nameAdviser, formatDateView2(moment())]],
    },
    {
      ySteps: 1,
      columns: ["Consejero", "Cantidad alumnos"],
      data: list,
    },
  ];

  const multiDataSetCompleted = [
    {
      columns: ["Reporte Consejero", "Fecha Consulta"],
      data: [[nameAdviser, formatDateView2(moment())]],
    },
    {
      ySteps: 1,
      columns: ["Consejero", "Estudiante", "Condicion", "Email", "Telefono"],
      data: list,
    },
  ];

  const closed = () => {
    setIsVisibleModal(false);
  };

  return (
    <div>
      {/* <p>Seleccione ciclo lectivo </p> */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {/* <Col span={12}>
          <Select
            placeholder="Año"
            value={yearSelected}
            onChange={(e) => setYearSelected(e)}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
          >
            {listSchoolYearsActive
              ? listSchoolYearsActive.map((schoolYear) => {
                  return (
                    <Option key={schoolYear.year}>{schoolYear.year}</Option>
                  );
                })
              : null}
          </Select>
        </Col> */}
        {loading ? (
          <Col>
            <Spin tip="Buscando estudiantes" size="medium"></Spin>
          </Col>
        ) : null}
      </Row>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {dataStudentsAdvisersByYear &&
        dataStudentsAdvisersByYear.length === 0 &&
        loading === false &&
        yearSelected !== null ? (
          <Col span={12}>
            <span style={{ fontWeight: "bold" }}> Sin resultados </span>
          </Col>
        ) : null}
        {dataStudentsAdvisersByYear && dataStudentsAdvisersByYear.length > 0 ? (
          <>
            <Col span={6}>
              <Popover content={"Pdf"}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    adviserHistoryByYear();
                  }}
                >
                  <FilePdfOutlined />
                  PDF
                </Button>
              </Popover>
            </Col>
            <Col span={8}>
              <Popover content={"Pdf"}>
                <Button
                  type="primary"
                  style={{ width: "110%" }}
                  onClick={() => {
                    adviserHistoryByYearCompleted();
                  }}
                >
                  <FilePdfOutlined />
                  PDF Integral
                </Button>
              </Popover>
            </Col>
            <Col span={6}>
              <>
                <ExcelFile
                  filename={"Consejeros" + nameAdviserFile}
                  element={
                    <Popover content={"Excel"}>
                      <Button type="primary" style={{ width: "100%" , marginLeft:"10%"}}>
                        Excel
                        <FileExcelOutlined />
                      </Button>
                    </Popover>
                  }
                >
                  <ExcelSheet
                    // data={list && list.length > 0 ? list : []}
                    dataSet={multiDataSet}
                    name="Consejeros"
                  >
                    {/* <ExcelColumn label="Ciclo" value="year" />
                <ExcelColumn label="Apellido y Nombre" value="name" />
                <ExcelColumn label="Estado" value="state" />
                <ExcelColumn label="E-mail" value="email" />
                <ExcelColumn label="Telefono" value="phone" /> */}
                  </ExcelSheet>
                </ExcelFile>
              </>
            </Col>
          </>
        ) : null}
      </Row>
      {/* <Row>
        <Col span={24}>
          <Button type="primary"  style={{marginTop:25, width:"100%"}} onClick={closed}>
            Salir
          </Button>
        </Col>
      </Row> */}
    </div>
  );
}

async function AdvisersByYear2(advisers, yearSelected) {

  let dataStudentsAdvisersByYear = [];


  const studentsByYear = async (adviser, students, year) => {
    let list = [];
    students.map((student) => {
      let states = student.finalConditions.filter(
        (i) =>
          new Date(i.initDate).getFullYear() === parseInt(year) &&
          i.adviser === adviser._id
      );

      if (states && states.length > 0) {
        list.push(student);
      }
      return true;
    });
    return list;
  };

  const getStudentsByAdvisers = async (advisers) => {
    const accessToken = getAccessToken();
    let data = [];
    for await (const item of advisers) {
      ///VERIFICAR PORQUE NO ALMACENA EL VALOR E INCREMENTA
      //let result= await getCoursedHoursStudentToYearApi(accessToken,yearSelected,item._id);
      let result = await getStudentsFinalConditionsByAdviserApi(
        accessToken,
        item._id
      );
      if (result) {
        //Filtramos los estudiantes asociados al consejero por año seleccionado
        let list = await studentsByYear(item, result.students, yearSelected);
        if (list.length > 0) {
          data.push({
            ...item,
            students: list,
          });
        }
      }
    }
    return data;
  };

  if (advisers && advisers.length > 0) {
    dataStudentsAdvisersByYear = await getStudentsByAdvisers(advisers);
  }

  return dataStudentsAdvisersByYear;
}
