import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditStateSchoolYearForm from "../EditStateSchoolYearForm";
import AddStateSchoolYearForm from "../AddStateSchoolYearForm";
import { activateStateSchoolYearApi, deleteStateSchoolYearApi } from "../../../../api/stateSchoolYear";
import { getSchoolYearByStateSchoolYearApi } from "../../../../api/schoolYear";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListStateSchoolYears.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListStateSchoolYears(props) {
  const { stateSchoolYearsActive, stateSchoolYearsInactive, setReloadStateSchoolYears } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewStateSchoolYearsActives, setViewStateSchoolYearsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [stateSchoolYearsActiveFilter, setStateSchoolYearsActiveFilter] = useState(
    []
  );
  const [ stateSchoolYearsInactiveFilter, setStateSchoolYearsInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (stateSchoolYearsActive) {
      stateSchoolYearsActive.forEach((element) => {
          if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.stateschoolyear_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setStateSchoolYearsActiveFilter(listActive);
  }, [filterText, stateSchoolYearsActive]);

  useEffect(() => {
    const listInactive = [];
    if (stateSchoolYearsInactive) {
      stateSchoolYearsInactive.forEach((element) => {
          if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.stateschoolyear_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setStateSchoolYearsInactiveFilter(listInactive);
  }, [filterText, stateSchoolYearsInactive]);

  const addStateSchoolYearModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Estado");
    setModalContent(
      <AddStateSchoolYearForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateSchoolYears={setReloadStateSchoolYears}
      />
    );
  };

  return (
    <div className="list-stateschoolyears">
      <div className="list-stateschoolyears__header">
        {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
        checkAction(permissionsActive, userToken.role, "stateschoolyears", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-stateschoolyears__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewStateSchoolYearsActives(!viewStateSchoolYearsActives)
                }
              />
              <span>
                {viewStateSchoolYearsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
        checkAction(permissionsActive, userToken.role, "stateschoolyears", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addStateSchoolYearModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
      checkAction(permissionsActive, userToken.role, "stateschoolyears", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-stateschoolyears__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de estados 'en curso, vigente, cancelado'
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewStateSchoolYearsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "stateschoolyears", "view") || isAdmin(roleById) ? (
            <>
              <StateSchoolYearsActive
                stateSchoolYearsActive={stateSchoolYearsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadStateSchoolYears={setReloadStateSchoolYears}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "stateschoolyears", "view") || isAdmin(roleById) ? (
            <>
              <StateSchoolYearsInactive
                stateSchoolYearsInactive={stateSchoolYearsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadStateSchoolYears={setReloadStateSchoolYears}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function StateSchoolYearsActive(props) {
  const {
    stateSchoolYearsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadStateSchoolYears,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editStateSchoolYear = (state) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${state.description ? state.description : "sin descripción"}`
    );
    setModalContent(
      <EditStateSchoolYearForm
        stateSchoolYear={state}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateSchoolYears={setReloadStateSchoolYears}
      />
    );
  };

  const desactivateStateSchoolYear = (state) => {
    activateStateSchoolYearApi(accessToken, state._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateSchoolYears(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (state) => {
    let response = await getSchoolYearByStateSchoolYearApi(accessToken, state._id);
    if (response.schoolYears.length > 0) {
      confirm({
        title: "Eliminando -> Estado",
        content: `No se puede eliminar '${state.description}' porque existen años lectivos asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateStateSchoolYear(state);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Estado",
        content: `¿Estás seguro que quieres eliminar a ${state.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteStateSchoolYearApi(accessToken, state._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadStateSchoolYears(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_state = (
    <div>
      {" "}
      <p>Editar Estado</p>{" "}
    </div>
  );

  const desactivate_state = (
    <div>
      {" "}
      <p>Pasar a Estados inactivos</p>{" "}
    </div>
  );

  const delete_state = (
    <div>
      {" "}
      <p>Eliminar Estado</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "stateschoolyear_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, state) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "stateschoolyears", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_state}>
                <Button
                  className="button-edit"
                  onClick={() => editStateSchoolYear(state)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "stateschoolyears", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_state}>
                <Button
                  className="button-stateschoolyear"
                  type="primary"
                  onClick={() => desactivateStateSchoolYear(state)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "stateschoolyears", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_state}>
                <Button
                  className="button-stateschoolyear"
                  type="danger"
                  onClick={() => showDeleteConfirm(state)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return stateSchoolYearsActive.length > 0? `Total de registros: ${stateSchoolYearsActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={stateSchoolYearsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function StateSchoolYearsInactive(props) {
  const { stateSchoolYearsInactive,
          setReloadStateSchoolYears,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateStateSchoolYear = (state) => {
    activateStateSchoolYearApi(accessToken, state._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateSchoolYears(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (state) => {
    let response = await getSchoolYearByStateSchoolYearApi(accessToken, state._id);
    if (response.schoolYears.length > 0) {
      ModalAntd.info({
        title: "Eliminando -> Estado",
        content:`No se puede eliminar '${state.description}' porque existen años lectivos asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando Estado",
        content: `¿Estás seguro que quieres eliminar a ${state.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteStateSchoolYearApi(accessToken, state._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadStateSchoolYears(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_state = (
    <div>
      {" "}
      <p>Pasar a Estados activos</p>{" "}
    </div>
  );

  const delete_state = (
    <div>
      {" "}
      <p>Eliminar Estado</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "stateschoolyear_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, state) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "stateschoolyears", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_state}>
                <Button
                  className="button-activate"
                  onClick={() => activateStateSchoolYear(state)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "stateschoolyears", "all") ||
          checkAction(permissionsActive, userToken.role, "stateschoolyears", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_state}>
                <Button
                  className="button-stateschoolyear"
                  type="danger"
                  onClick={() => showDeleteConfirm(state)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return stateSchoolYearsInactive.length > 0? `Total de registros: ${stateSchoolYearsInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={stateSchoolYearsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
