import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  Space,
  BackTop,
  Col,
  Row,
  Select,
} from "antd";
import {
  DeleteOutlined,
  //DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FormOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import EditStateStudentCourseForm from "../EditStateStudentCourseForm";
import { deleteStudentInscriptionByCourseIdApi } from "../../../../api/inscription";
// import { getCourseApi } from "../../../../api/course";
import {
  //getAbsencesByCourseApi,
  getAbsencesByStudentApi,
} from "../../../../api/absence";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import { listStudentCoursesPdf } from "./pdfStudentCourses";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import { getCourseApi } from "../../../../api/course";
import { updateOwnHoursByStudentIdApi } from "../../../../api/student";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
// import useRouteProps from "../../../../hooks/UseRouteProps"
import ReactExport from "@ibrahimrahmani/react-export-excel";
import moment from "moment";

import "./ListStudentCourses.scss";

const { confirm } = ModalAntd;
const { Search } = Input;
const { Option } = Select;

//Listado de los Cursos del Estudiante
export default function ListStudentCourses(props) {
  const { studentData, inscriptions, setReloadInscriptionCourses } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [coursesFilter, setCoursesFilter] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);
  // const [listAbsences, setListAbsences] = useState([]);
  const [listInscriptions, setListInscriptions] = useState([]);
  const [listAbsencesCourses, setListAbsencesCourses] = useState([]);
  //para filtrar por ciclo
  const [schoolYear, setSchoolYear] = useState("all");
  const [schoolYearDescription, setSchoolYearDescription] = useState("");
  const [listSchoolYears, setListSchoolYears] = useState([]);
  const [listStateCoursesActive, setListStateCoursesActive]=useState([]);
  //para VOLVER HACIA ATRAS
  const history = useHistory();

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  //console.log("userToken", userToken);
  // console.log(useRouteProps());
  //EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const pdf_download = <div>Descargar PDF</div>;

  const xls_download = <div>Descargar Excel</div>;


  useEffect(() => {
    let isMounted=true;
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      if (response.schoolYears && response.schoolYears.length > 0){
        if(isMounted){ 
          setListSchoolYears(response.schoolYears);
          setSchoolYearDescription(response.schoolYears[0].year);
          let currentYear=response.schoolYears.filter(i=>i.year === new Date().getFullYear());
          if(currentYear && currentYear.length > 0){
            setSchoolYear(currentYear[0]._id);
          }else{
            setSchoolYear(response.schoolYears[0]._id);
          }
        }
      }
    });
    return()=>{
      isMounted=false;
    }
  }, []);

  useEffect(() => {
    let isMounted=true;
    setListInscriptions(inscriptions);
    if (studentData && studentData._id) {
      getAbsencesByStudentApi(accessToken, studentData._id).then((response) => {
        if(isMounted){
          setListAbsencesCourses(response.absences);
        }
      });
    }
    return ()=>{
      isMounted=false;
    }
  }, [inscriptions]);

  useEffect(() => {
    // if (schoolYear && schoolYear !== "all" && schoolYear !== undefined) {
    if (schoolYear && schoolYear !== undefined) {
      if(schoolYear !== "all"){
        let description=listSchoolYears.filter(i=>i._id === schoolYear);
        if(description && description.length > 0){
          setSchoolYearDescription(description[0].year);
        }
      }else{
        setSchoolYearDescription("");
      }
    } 
  }, [schoolYear]);

  useEffect(() => {
    let isMounted=true;
    getStateCoursesActiveApi(accessToken, true).then((response) => {
      if(isMounted){
        setListStateCoursesActive(response.stateCourses);
      }
    });
    return()=>{
      isMounted=false;
    }
  }, []);

  // console.log("inscriptions", inscriptions);

  useEffect(() => {
    let listCourses = [];
    let index = 0;
    if (listAbsencesCourses) {
      listInscriptions.map((i) => {
        let newData = {
          _id: index,
          inscription: i,
          course: i.course,
          absences: getAbsenceCourse(i.course),
          stateStudentCourse: getStateStudent(i.students),
        };
        listCourses.push(newData);
        index++; //return index++
        return null;
      });
    }
    setStudentCourses(listCourses);
  }, [listAbsencesCourses]);

  //console.log("listAbsencesCourses", listAbsencesCourses);

  useEffect(() => {
    const list = [];
    if (studentCourses.length > 0) {
      studentCourses.forEach((element) => {
        //filtro por año
        if(element.course.schoolYear._id.includes(schoolYear) || schoolYear === "all"){
          if ((element.course &&
            element.course.name
              .toUpperCase()
              .includes(filterText.toUpperCase())) ||
            (element.stateStudentCourse && element.stateStudentCourse
              .toUpperCase()
              .includes(filterText.toUpperCase()) )||
            filterText === ""
          ) {
            list.push(element);
          }
        }
      });
    }

    setCoursesFilter(list);
    //console.log("list", list);
  }, [filterText, schoolYear, studentCourses]);

  useEffect(() => {
    let isMounted=true;
    getPermissionsActiveApi(accessToken, true).then((response) => {
      if(isMounted){
        setPermissionsActive(response.permissions);
      }
    });
    return ()=>{
      isMounted=false;
    }
  }, []);

  useEffect(() => {
    let isMounted=true;
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      if(isMounted){
        setRoleById(response.role);
      }
    });
    return ()=>{
      isMounted=false;
    }
  }, []);

  //console.log("studentCourses", studentCourses); //de aca usa las inasistencias en la columna
  //console.log("coursesFilter", coursesFilter); //de aca usa las inasistencias en la columna

  const getAbsenceCourse = (course) => {
    let result = listAbsencesCourses.filter((i) => i.course === course._id);
    if (result !== undefined && result.length > 0) {
      let count = 0;
      result.map((absence) => {
        absence.students.map((item) => {
          if (item.student._id === studentData._id) {
            if (item.justify !== undefined && item.justify === false) {
              count++;
            }
          }
          return null; //retorno algo por el warning
        });
        return null; //retorno algo por el warning
      });
      return count;
    } else {
      return null;
    }
  };

  const getStateStudent = (list) => {
    let state = list.filter((i) => i.student === studentData._id);
    if (state !== undefined && state.length > 0) {
      if (state[0].stateStudentCourse !== undefined) {
        return state[0].stateStudentCourse.description;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const deleteCourseStudent = (record, updateOwnHourStudent) => {
    const token = getAccessToken();
    deleteStudentInscriptionByCourseIdApi(token, studentData, record._id).then(
      async (result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
           //verifico si hay que actualizar las horas del estudiante (True)
          if(updateOwnHourStudent)
          {
            notification["info"]({
              message: "El curso al que se elimino se encuentra finalizado, se actualizará la cantidad de horas del estudiante",
              duration:6
            });
            let result = await updateOwnHoursByStudentIdApi(token, studentData._id);
              if(result.code===200){
                notification["success"]({
                  message: "Actualización de las horas del estudiante correcta",
                  duration:5
                });
                setIsVisibleModal(false);
              }else{
                notification["warning"]({
                  message: "Error al actualizarlas horas del estudiante",
                  duration:5
                });
                setIsVisibleModal(false);
              }
          }else{
            setIsVisibleModal(false);
          }
          setReloadInscriptionCourses(true);
        }
      }
    );
  };

  //PARA EL EXCEL

  //PARA RENOMBRAR LA CLAVE DE UN OBJETO
  function renameKey(obj, old_key, new_key) {
    // comprobar si la clave antigua = la nueva clave
    if (obj !== undefined) {
      if (obj !== null) {
        //console.log("obj", obj);
        if (old_key !== undefined) {
          if (old_key !== null) {
            if (old_key !== new_key) {
              //Modificar la antigua descripción de extracción de claves del objeto
              //console.log("getOwnPropertyDescriptor: ", Object.getOwnPropertyDescriptor(obj, old_key));
              if (
                Object.getOwnPropertyDescriptor(obj, old_key) !== undefined &&
                Object.getOwnPropertyDescriptor(obj, old_key) !== null &&
                Object.getOwnPropertyDescriptor(obj, old_key) !== "" &&
                Object.getOwnPropertyDescriptor(obj, old_key) !== "null"
              ) {
                //sino tira error
                //console.log("getOwnPropertyDescriptor: " + Object.getOwnPropertyDescriptor(obj, old_key));
                Object.defineProperty(
                  obj,
                  new_key,
                  Object.getOwnPropertyDescriptor(obj, old_key)
                );
                delete obj[old_key]; //elimina la clave anterior
              }
            }
          }
        }
      }
    }
  }
  /*
  function renameId(lista) {
    lista.forEach((obj) => renameKey(obj, "_id", "statestudentcourse_id"));
    //console.log(lista);
  }
  */

  //let inscripCourses = [];

  //A CADA ESTUDIANTE DE CADA INSCRIPCION LE MODIFICO EL CAMPO _id
  //PARA LUEGO PODER USAR Object.assign SIN PISAR KEYS
  if (listInscriptions != null) {
    listInscriptions.forEach((i) => {
      //console.log("i", i);
      i.students.forEach((k) => {
        if (k !== null || k !== undefined) {
          if (i.student !== null || i.student !== undefined) {
            //console.log("k.statestudentcourse", k.stateStudentCourse);
            if (
              k.stateStudentCourse !== null ||
              k.stateStudentCourse !== undefined
            ) {
              renameKey(k.stateStudentCourse, "_id", "statestudentcourse_id");
            }
          }
        }
      });
    });
  }

  // if (inscriptions != null) {
  //   inscriptions.map((item) => {
  //     item.students.map((i) => {
  //       //SI EN LOS ESTUDIANTES INSCRIPTOS ENCUENTRO AL ESTUDIANTE QUE SELECCIONÉ
  //       if (i.student != null || i.student === undefined) {
  //         if (i.student === studentData._id) {
  //           if (i.stateStudentCourse !== undefined) {
  //             //Y TIENE CONDICIÓN DE ESTUDIANTE
  //             // console.log("i", i);
  //             //AGREGO UN ARRAY CON EL CURSO Y EL ESTADO DEL ESTUDIANTE
  //             inscripCourses.push([item.course, i.stateStudentCourse]);
  //             //inscripStudent.push(i.stateStudentCourse);
  //           } else {
  //             //AGREGO SOLO EL CURSO
  //             inscripCourses.push(item.course);
  //           }
  //           //else {
  //           //  inscripStudent.push("no hay datos");
  //           //}
  //         }
  //       }
  //     });
  //   });
  //console.log("inscripCourses: ", inscripCourses);

  //inscripCourses.sort((a,b)=>{
  //  return a.lastname.localeCompare(b.lastname) ||
  //  a.name.localeCompare(b.name);
  //});
  //}

  let listCoursesMerge = [];
  //let objectResultMerge = {};

  if (coursesFilter.length > 0) {
    coursesFilter.map((i) => {
      let nameCourse = i.course ? i.course.name : "";
      let absencesAllowed = i.course.workload
        ? i.course.workload.absencesAllowed
        : "";
      let days=i.course.days.map(function(elem){
          return elem.description;
        }).join(" ,");
      let teachers=i.course.teachers.map(function(elem){
          return elem.description;
      }).join(" -");
      listCoursesMerge.push({
        name: nameCourse,
        teachers:teachers,
        days: days,
        hour_start: i.course.hour_start? moment(i.course.hour_start,"HH:mm").isValid()?  moment(new Date(i.course.hour_start),"HH:mm").format("HH:mm") : i.course.hour_start : "",
        hour_end: i.course.hour_end? moment(i.course.hour_end,"HH:mm").isValid()?  moment(new Date(i.course.hour_end),"HH:MM").format("HH:mm") : i.course.hour_end : "",
        absences: i.absences,
        absencesAllowed: absencesAllowed,
        stateStudentCourse: i.stateStudentCourse,
      });
      return null; //retorno algo por el warning
    });
  }
  //console.log("coursesFilter", coursesFilter); //de aca usa las inasistencias en la columna
  //console.log("listCoursesMerge", listCoursesMerge); //se usa para excel

  //let inscriptions2 = Object.create(inscriptions);
  //Object.freeze(inscriptions);
  //let inscripCourses2 = Object.create(inscripCourses);

  // if (inscripCourses2) {
  //   inscripCourses2.map((item) => {
  //     //SI ITEM TIENE MAS DE 1 ELEMENTO (ARRAY CON EL CURSO Y EL ESTADO DEL ESTUDIANTE), ENTONCES HAY QUE UNIR ESOS ELEMENTOS
  //     if (item.length > 1) {
  //       //renameKey(item[1], '_id', 'statestudentcourse_id');
  //       //lastList.prototype.push.apply(item[0].name, item[1].description);
  //       objectResultMerge = Object.assign(item[0], item[1]);
  //       listCoursesMerge.push(objectResultMerge);
  //     } else {
  //       //si hay menos de 2 items que agregue el item completo xq no importa
  //       //console.log("item sin condicion", item);
  //       listCoursesMerge.push(item);
  //     }
  //     //console.log("inscripCourses2:", item);
  //   });
  // }
  //console.log("listCoursesMerge:", listCoursesMerge);

  return (
    <div className="list-inscriptions">
      <div className="list-inscriptions__header">
        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
        isAdmin(roleById) ? (
          <>
            {/*VOLVER HACIA ATRAS*/}
            <Button
              type="primary"
              icon={<ArrowLeftOutlined />}
              onClick={() => history.goBack()}
            >
              Atrás
            </Button>
            {/* <Button type="primary" icon={<PlusOutlined/>} onClick={addCoursesStudentModal}>
              Agregar Cursos
            </Button> */}
          </>
        ) : (
          <></>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "courses", "all") ||
      checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
      isAdmin(roleById) ? (
        <>
          <Row gutter={24}>
            <Col span={4}>
              <Select
                showSearch
                value={schoolYear}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                style={{ width: "100%" }}
                onChange={(e) => setSchoolYear(e)}
              >
                {listSchoolYears
                  ? listSchoolYears.map((item) => {
                      return <Option key={item._id}>{item.year}</Option>;
                    })
                  : null}
                <Option key={"all"}>{"Todos"}</Option>)
              </Select>
            </Col>
            <Col span={20}>
              <div className="list-inscriptions__search">
                <Search
                  className="Buscar"
                  allowClear
                  placeholder=""
                  onSearch={(e) => setFilterText(e)}
                  enterButton
                  width="40%"
                />
                <></>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
      <>
        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "pdf") ||
        isAdmin(roleById) ? (
          <>
            <Popover content={pdf_download}>
              <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                  type="default"
                  shape="round"
                  onClick={() => {
                    listStudentCoursesPdf(
                      coursesFilter,
                      studentData.name + " " + studentData.lastname,
                      schoolYearDescription,
                      false
                    );
                  }}
                >
                  <FilePdfOutlined />
                </Button>
              </Space>
            </Popover>
          </>
        ) : (
          <></>
        )}

        <>&nbsp;</>

        {/* FALTA QUE TRAIGA EL NOMBRE DE LOS CURSOS Y EL ESTADO */}
        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "pdf") ||
        isAdmin(roleById) ? (
          <>
            <ExcelFile
              filename={
                schoolYearDescription ===""? 
                "ListadoInscripciones-" + studentData.name +
                studentData.lastname :
                "ListadoInscripciones-" +
                studentData.name +
                studentData.lastname +"-ciclo" + schoolYearDescription
              }
              element={
                <Popover content={xls_download}>
                  <Button type="default" shape="round">
                    <FileExcelOutlined />
                  </Button>
                </Popover>
              }
            >
              <ExcelSheet data={listCoursesMerge} name={"Inscripciones"}>
                {/* listCoursesMerge label="Estado del curso" value={(col) => col.description ? col.description : "no hay datos"}*/}
                <ExcelColumn label="Nombre" value="name" />
                <ExcelColumn label="Dia" value="days" />
                <ExcelColumn label="Horario Inicio" value="hour_start" />
                <ExcelColumn label="Horario Fin" value="hour_end" />
                <ExcelColumn label="Docente" value="teachers" />
                <ExcelColumn label="Faltas" value="absences" />
                <ExcelColumn
                  label="Inasistencias Permitidas"
                  value="absencesAllowed"
                />
                <ExcelColumn
                  label="Estado del curso"
                  value="stateStudentCourse"
                />
                {/* <ExcelColumn
                  label="Estado del curso"
                  value={(col) =>
                    col.description ? col.description : "no hay datos"
                  }
                /> */}
              </ExcelSheet>
            </ExcelFile>
          </>
        ) : (
          <></>
        )}

        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
        isAdmin(roleById) ? (
          <>
            <Courses
              courses={coursesFilter}
              student={studentData}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
              setReloadInscriptionCourses={setReloadInscriptionCourses}
              setModalWidth={setModalWidth}
              accessToken={accessToken}
              userToken={userToken}
              permissionsActive={permissionsActive}
              roleById={roleById}
              deleteCourseStudent={deleteCourseStudent}
              listStateCoursesActive={listStateCoursesActive}
              //listAbsences={listAbsencesCourses}
              //showDeleteConfirm={showDeleteConfirm}
            />
          </>
        ) : (
          <></>
        )}
      </>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop />
    </div>
  );
}

function Courses(props) {
  const {
    courses,
    student,
    deleteCourseStudent,
    setReloadInscriptionCourses,
    setIsVisibleModal,
    setModalTitle,
    setModalWidth,
    setModalContent,
    userToken,
    //listAbsences,
    //accessToken,
    permissionsActive,
    roleById,
    listStateCoursesActive
  } = props; //showDeleteConfirm
  // console.log(courses);

  const editStateCourseStudent = (inscription, stateStudentCourse) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar Estado del Estudiante del curso: ${
        inscription ? inscription.course.name : "curso"
      }`
    );
    setModalWidth(800);
    setModalContent(
      <EditStateStudentCourseForm
        inscription={inscription}
        student={student}
        setIsVisibleModal={setIsVisibleModal}
        setReloadInscriptions={setReloadInscriptionCourses}
        stateStudentCourse={stateStudentCourse}
      />
    );
  };

  const showDeleteConfirm = async(course) => {
    const token = getAccessToken();
     //verifico si el curso esta finalizado para actualizar
     let responseCourse= await getCourseApi(token, course._id);
     let resultCourse=responseCourse.course? responseCourse.course : null;
     let stateCourseFinish=listStateCoursesActive.filter(i=>i.description.toLowerCase() === "finalizado");
     if(stateCourseFinish!== undefined && stateCourseFinish.length > 0 && resultCourse!== null 
         && resultCourse.stateCourse && resultCourse.stateCourse === stateCourseFinish[0]._id){
          confirm({
            title: "Eliminando -> Estudiante del Curso",
            content: `El curso se encuentra finalizado. ¿Estás seguro que quieres eliminar la inscripción del estudiante al ${
              course ? course.name : "curso"
            }?`,
            okText: "Eliminar",
            okType: "danger",
            cancelText: "Cancelar",
            onOk() {
              deleteCourseStudent(course, true);
            },
          });
    }else{
      confirm({
        title: "Eliminando -> Estudiante del Curso",
        content: `¿Estás seguro que quieres eliminar la inscripción del estudiante al ${
          course ? course.name : "curso"
        }?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteCourseStudent(course, false);
        },
      });
    }
  };

  const update_state = (
    <div>
      {" "}
      <p>Estado del Curso</p>{" "}
    </div>
  );

  const delete_course = (
    <div>
      {" "}
      <p>Eliminar inscripción al curso</p>{" "}
    </div>
  );

  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "course",
    //   render: (course) => (course ? course.course_code : null),
    // },
    {
      title: "Nombre",
      dataIndex: "course",
      render: (course) => (course ? course.name : null),
      width: "35%",
    },
    {
      title: "Faltas",
      dataIndex: "absences",
      // render: (course) => (course ? getAbsenceCourse(course) : null),
      render: (absences) =>
        absences ? (absences !== null ? absences : "-") : "-",
      width: "10%",
    },
    {
      title: "Inasistencias permitidas",
      dataIndex: "course",
      render: (course) => (course ? course.workload.absencesAllowed : 0),
      width: "10%",
    },
    {
      title: "Estado del curso",
      dataIndex: "stateStudentCourse",
      render: (stateStudentCourse) =>
        stateStudentCourse ? stateStudentCourse : null,
      width: "20%",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, data) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={update_state}>
                <Button
                  className="button-edit"
                  onClick={() => editStateCourseStudent(data.inscription, data.stateStudentCourse)}
                >
                  <FormOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "enroll") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_course}>
                <Button
                  className="button-inscription"
                  type="danger"
                  onClick={() => showDeleteConfirm(data.course)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={() => {
        return courses.length > 0
          ? `Total de registros: ${courses.length}`
          : false;
      }}
      pagination={false}
      // loading={courses.length <=0 ? true : false}
      dataSource={courses}
      columns={columns}
      rowKey="_id"
    />
  );
}
