import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { updateWorkloadApi } from "../../../../api/workload";
import { getAccessToken } from "../../../../api/auth";

import "./EditWorkloadForm.scss";

export default function EditWorkloadForm(props) {
  const { workload, setIsVisibleModal, setReloadWorkloads } = props;
  const [workloadData, setWorkloadData] = useState({});
  console.log(workload);
  //trae los datos de carga horaria
  useEffect(() => {
    setWorkloadData({
      workload_code: workload.workload_code,
      description: workload.description,
      absencesAllowed: workload.absencesAllowed
        ? workload.absencesAllowed
        : null,
    });
  }, [workload]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!workloadData.description) {
      notification["error"]({
        message: "Obligatorio: descripción.",
      });
      errorExists = true;
    } else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateWorkload = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let workloadUpdate = workloadData; //es let porque se va actualizando

      updateWorkloadApi(token, workloadUpdate, workload._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadWorkloads(true);
        }
      });
    }
  };

  return (
    <div className="edit-workload-form">
      <EditForm
        workloadData={workloadData}
        setWorkloadData={setWorkloadData}
        updateWorkload={updateWorkload}
      />
    </div>
  );
}

function EditForm(props) {
  const { workloadData, setWorkloadData, updateWorkload } = props;

  return (
    <Form className="form-edit" onSubmit={updateWorkload}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={workloadData.workload_code}
              onChange={(e) =>
                setWorkloadData({ ...workloadData, workload_code: e })
              }
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              //   placeholder="descripción"
              value={workloadData.description}
              onChange={(e) =>
                setWorkloadData({
                  ...workloadData,
                  description: e.target.value,
                })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Inasistencias permitidas</label>
          <Form.Item>
            <InputNumber
              //   placeholder="descripcion"
              value={workloadData.absencesAllowed}
              onChange={(e) =>
                setWorkloadData({ ...workloadData, absencesAllowed: e })
              }
              min={0}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateWorkload}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
