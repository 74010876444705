import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
  getStudentsApi, 
  getStudentsHasCoursesApi , 
  //getStudentExportApi, 
  getExportStudentsApi
} from "../../../api/student"; //getStudentsActiveApi
import ListStudents from "../../../components/Admin/Students/ListStudents";
//import { getInscriptionsActiveApi } from "../../../api/inscription";

import "./Students.scss";

export default function Students() {
  const [studentsActive, setStudentsActive] = useState([]);
  const [studentsInactive, setStudentsInactive] = useState([]);
  const [studentsHasCourses, setStudentsHasCourses] = useState([]);
  const [studentsActiveExport, setStudentsActiveExport] = useState([]);
  const [studentsInactiveExport, setStudentsInactiveExport] = useState([]);
  //const [studentsHasNoCourses, setStudentsHasNoCourses] = useState([]);
  const [reloadStudents, setReloadStudents] = useState(false);
  const token = getAccessToken();
  const [paginationActive, setPaginationActive] = useState({ page: 1 });
  const [paginationInactive, setPaginationInactive] = useState({ page: 1 });
  //const [inscriptionActiveCourses, setInscriptionActiveCourses] = useState([]);
  //const [inscriptionInactiveCourses, setInscriptionInactiveCourses] = useState([]);


  //var start = Date.now();
  //var end = "";
  useEffect(() => {
    let isMounted= true;
    getStudentsApi(
      token,
      true,
      50,
      paginationActive.page,
      paginationActive.filter,
      paginationActive.filters
    ).then((response) => {
      if(isMounted){
       setStudentsActive(response.students);
      }
    });

    getExportStudentsApi(token, true, paginationActive.filter, paginationActive.filters).then(response => {
      if(isMounted){
        if(response.students){
          setStudentsActiveExport(response.students);
        }
      }
    });
    setReloadStudents(false);

    return()=>{
      isMounted=false;
    }

  }, [token, paginationActive, reloadStudents]);

  useEffect(() => {
    let isMounted= true;
    getStudentsApi(
      token,
      false,
      50,
      paginationInactive.page,
      paginationInactive.filter,
      paginationInactive.filters
    ).then((response) => {
      if(isMounted){
        setStudentsInactive(response.students);
      }
    });

    getExportStudentsApi(token, false, paginationActive.filter, paginationActive.filters).then(response => {
      if(isMounted){
        if(response.students){
            setStudentsInactiveExport(response.students);
        }
      }
    });
    setReloadStudents(false);
    
    return()=>{
      isMounted=false;
    }
  }, [token, paginationInactive, reloadStudents]);

  /*
  useEffect(() => {
    getInscriptionsActiveApi(token, true).then(response => {
      setInscriptionActiveCourses(response.inscriptions);
    });
  }, []);

  useEffect(() => {
    getInscriptionsActiveApi(token, false).then(response => {
      setInscriptionInactiveCourses(response.inscriptions);
    });
  }, []);
  */

  useEffect(() => {
    getStudentsHasCoursesApi(token, true).then(response => {
      setStudentsHasCourses(response.students);
    });
  }, []);

  /*
  useEffect(() => {
    getStudentsHasCoursesApi(token, false).then(response => {
      setStudentsHasNoCourses(response.students);
    });
  }, []);
  */

  //console.log(studentsHasCourses.length);
  //console.log(studentsHasNoCourses.length);


  //inscriptionActiveCourses.map((inscription) => {
  //  console.log(inscription.students);
  //});

  //console.log((inscriptionActiveCourses.students).find(i => i.student._id === "61a61768defbb6036b70dfd4"));
  //console.log(inscriptionActiveCourses.some(i => i.course._id === "618ea63ce264180197c51cad"));
  /*
  console.log((inscriptionActiveCourses).some(i => i.students.map((student) => {
    if (student === "618c36b94243826177a0d834"){
      return true;
    }
  })));
  */

  //Array.from(inscriptionActiveCourses).some(([key, value]) => /* something */ true)
  //Array.from(inscriptionActiveCourses).some(([key, value]) => /* something */ true)

  /* FUNCIONA
  inscriptionActiveCourses.map((inscription) => {
    //console.log(inscription.students.some(i => i._id === "61a61452defbb6036b70dfca"));
    console.log(inscription.students.some(i => i.student._id === "618c36b94243826177a0d7fe"));
  });
  */

  /*
  inscriptionActiveCourses.map((inscription) => {
    inscription.students.map((student) => {
      //console.log(student._id);
      if (student._id === "61a61452defbb6036b70dfca"){
        return true;
        //console.log("ok");
      }
    })
  });
  */

  return (
    //end = Date.now(),
    //console.log("tiempo pages: ", (end - start)/1000),
    <div className="students">
      <h3>Listado de Estudiantes</h3>
      <ListStudents
        setPaginationActive={setPaginationActive}
        setPaginationInactive={setPaginationInactive}
        studentsActive={studentsActive}
        studentsInactive={studentsInactive}
        //inscriptionActiveCourses={inscriptionActiveCourses}
        //inscriptionInactiveCourses={inscriptionInactiveCourses}
        studentsHasCourses={studentsHasCourses}
        studentsActiveExport={studentsActiveExport}
        studentsInactiveExport={studentsInactiveExport}
        //studentsHasNoCourses={studentsHasNoCourses}
        setReloadStudents={setReloadStudents}
      />
    </div>
  );
}
