import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditSpecialtyForm from "../EditSpecialtyForm";
import AddSpecialtyForm from "../AddSpecialtyForm";
import {
  activateSpecialtyApi,
  deleteSpecialtyApi,
} from "../../../../api/specialty";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getStudentsBySpecialtyApi } from "../../../../api/student";
import jwtDecode from "jwt-decode";

import "./ListSpecialties.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListSpecialties(props) {
  const { specialtiesActive, specialtiesInactive, setReloadSpecialties } =
    props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewSpecialtiesActives, setViewSpecialtiesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [specialtiesActiveFilter, setSpecialtiesActiveFilter] = useState([]);
  const [specialtiesInactiveFilter, setSpecialtiesInactiveFilter] = useState(
    []
  );

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (specialtiesActive) {
      specialtiesActive.forEach((element) => {
        if (
          element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.specialty_code
            .toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          filterText === ""
        ) {
          listActive.push(element);
        }
      });
    }
    setSpecialtiesActiveFilter(listActive);
  }, [filterText, specialtiesActive]);

  useEffect(() => {
    const listInactive = [];
    if (specialtiesInactive) {
      specialtiesInactive.forEach((element) => {
        if (
          element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.specialty_code
            .toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          filterText === ""
        ) {
          listInactive.push(element);
        }
      });
    }
    setSpecialtiesInactiveFilter(listInactive);
  }, [filterText, specialtiesInactive]);

  const addSpecialtyModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Especialidad");
    setModalContent(
      <AddSpecialtyForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadSpecialties={setReloadSpecialties}
      />
    );
  };

  return (
    <div className="list-specialties">
      <div className="list-specialties__header">
        {checkAction(permissionsActive, userToken.role, "specialties", "all") ||
        checkAction(
          permissionsActive,
          userToken.role,
          "specialties",
          "enable"
        ) ||
        isAdmin(roleById) ? (
          <>
            <div className="list-specialties__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewSpecialtiesActives(!viewSpecialtiesActives)
                }
              />
              <span>{viewSpecialtiesActives ? "Activos" : "Inactivos"}</span>
            </div>
          </>
        ) : (
          <></>
        )}

        {checkAction(permissionsActive, userToken.role, "specialties", "all") ||
        checkAction(permissionsActive, userToken.role, "specialties", "add") ||
        isAdmin(roleById) ? (
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={addSpecialtyModal}
            >
              Nuevo
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "specialties", "all") ||
      checkAction(permissionsActive, userToken.role, "specialties", "view") ||
      isAdmin(roleById) ? (
        <>
          <div className="list-specialties__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2></h2>
            <h3>
              <strong>Info:</strong> ejemplos de estados de especialidades
              'psicología, ingeniería, tecnicatura'
            </h3>
          </div>
        </>
      ) : (
        <></>
      )}

      {viewSpecialtiesActives ? (
        <>
          {checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "view"
          ) ||
          isAdmin(roleById) ? (
            <>
              <SpecialtiesActive
                specialtiesActive={specialtiesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadSpecialties={setReloadSpecialties}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "view"
          ) ||
          isAdmin(roleById) ? (
            <>
              <SpecialtiesInactive
                specialtiesInactive={specialtiesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadSpecialties={setReloadSpecialties}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>

    </div>
  );
}

function SpecialtiesActive(props) {
  const {
    specialtiesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadSpecialties,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props;

  const editSpecialty = (specialty) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${
        specialty.description ? specialty.description : "sin descripcion"
      }`
    );
    setModalContent(
      <EditSpecialtyForm
        specialty={specialty}
        setIsVisibleModal={setIsVisibleModal}
        setReloadSpecialties={setReloadSpecialties}
      />
    );
  };

  const desactivateSpecialty = (specialty) => {
    activateSpecialtyApi(accessToken, specialty._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadSpecialties(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (specialty) => {
    let response = await getStudentsBySpecialtyApi(accessToken, specialty._id);
    if (response.students.length > 0) {
      confirm({
        title: "Eliminando -> Especialidad",
        content: `No se puede eliminar '${specialty.description}' porque existen estudiantes asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateSpecialty(specialty);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Especialidad",
        content: `¿Estás seguro que quieres eliminar a ${specialty.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteSpecialtyApi(accessToken, specialty._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadSpecialties(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_specialty = (
    <div>
      {" "}
      <p>Editar Especialidad</p>{" "}
    </div>
  );

  const desactivate_specialty = (
    <div>
      {" "}
      <p>Pasar a Especialidad inactivos</p>{" "}
    </div>
  );

  const delete_specialty = (
    <div>
      {" "}
      <p>Eliminar Especialidad</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "specialty_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, specialty) => (
        <div>
          {checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "edit"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={edit_specialty}>
                <Button
                  className="button-edit"
                  onClick={() => editSpecialty(specialty)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "enable"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_specialty}>
                <Button
                  className="button-specialty"
                  type="primary"
                  onClick={() => desactivateSpecialty(specialty)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_specialty}>
                <Button
                  className="button-specialty"
                  type="danger"
                  onClick={() => showDeleteConfirm(specialty)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return specialtiesActive.length > 0? `Total de registros: ${specialtiesActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={specialtiesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function SpecialtiesInactive(props) {
  const {
    specialtiesInactive,
    setReloadSpecialties,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
  } = props;

  const activateSpecialty = (specialty) => {
    activateSpecialtyApi(accessToken, specialty._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadSpecialties(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (specialty) => {
    let response = await getStudentsBySpecialtyApi(accessToken, specialty._id);
    if (response.students.length > 0) {
      ModalAntd.info({
        title: "Eliminando -> Especialidad",
        content:`No se puede eliminar '${specialty.description}' porque existen estudiantes asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Especialidad",
        content: `¿Estás seguro que quieres eliminar a ${specialty.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteSpecialtyApi(accessToken, specialty._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadSpecialties(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_specialty = (
    <div>
      {" "}
      <p>Pasar a Especialidad activos</p>{" "}
    </div>
  );

  const delete_specialty = (
    <div>
      {" "}
      <p>Eliminar Especialidad</p>
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "specialty_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, specialty) => (
        <div>
          {checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "edit"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={activate_specialty}>
                <Button
                  className="button-activate"
                  onClick={() => activateSpecialty(specialty)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "all"
          ) ||
          checkAction(
            permissionsActive,
            userToken.role,
            "specialties",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_specialty}>
                <Button
                  className="button-specialty"
                  type="danger"
                  onClick={() => showDeleteConfirm(specialty)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return specialtiesInactive.length > 0? `Total de registros: ${specialtiesInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={specialtiesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
