
export function convertState (stateCourse) {
    if (stateCourse === "valid") {
        return "Vigente";
    } else if (stateCourse === "rejected") {
        return "Rechazada";
    } else if (stateCourse === "cancelled") {
        return "Cancelada";
    } else if (stateCourse === "in-course") {
    return "En curso";
    } else {
        return "-";
    }
};

export function convertPaid (a) {
    if (a === true) {
        return "Si";
    } else if (a === false) {
        return "No";
    }
};

export function convertMovementType (type) {
    if (type === "input") {
        return "Entrada"
    } else if(type === "output") {
        return "Salida"
    }
};

export function convertOrigin (a) {
    if (a === "inventory") {
        return "Inventario";
    } else if (a==="adjustment") {
        return "Ajuste a Inventario";
    } else if (a==="buyBill") {
        return "Factura de Compra";
    } else if (a==="transfer") {
        return "Traslado";
    } else if (a==="billSell") {
        return "Factura de Venta";
    } else if (a==="canceledBuy") {
        return "Cancelacion de Compra";
    } else if (a==="canceledSell") {
        return "Cancelacion de Venta";
    }
};

export function convertActionsEngToEsp (a) {
    switch (a) {
        case "all":
            return "Todas";
        case "add":
            return "Agregar";
        case "edit":
            return "Editar";
        case "delete":
            return "Eliminar";
        case "view":
            return "Ver";
        case "enable":
            return "Habilitar/deshabilitar";
        case "enroll":
            return "Inscribir";
        case "pdf":
            return "PDF";
        case "menu-sider":
            return "Menú lateral";
        case "menu-edition":
            return "Edición de menú";
        default:
            return null;
    }
};

export function convertActionsEspToEng (a) {
    switch (a) {
        case "Todas":
            return "all";
        case "Agregar":
            return "add";
        case "Editar":
            return "edit";
        case "Eliminar":
            return "delete";
        case "Ver":
            return "view";
        case "Habilitar/deshabilitar":
            return "enable";
        case "Inscribir":
            return "enroll";
        case "PDF":
            return "pdf";
        case "Menú lateral":
            return "menu-sider";
        case "Edición de menú":
            return "menu-edition";
        default:
            return null;
    }
};

export function convertModulesEngToEsp (m) {
    switch (m) {
        case "users":
            return "Usuarios";
        //case "user-role":
        //    return "Rol de Usuario";
        case "persons-menu":
            return "Menú de personas";
        case "persons":
            return "Personas";
        case "students":
            return "Estudiantes";
        case "docTypes":
            return "Tipos de documento";
        case "inscriptions":
            return "Inscripciones";
        case "absences":
            return "Inasistencias";
        case "specialties":
            return "Especialidades";
        case "entryconditions":
            return "Condición de Ingreso";
        case "finalconditions":
            return "Condición Final";
        case "teachers":
            return "Docentes";
        case "teachercollabs":
            return "Colaboradores Docentes";
        case "advisers":
            return "Consejeros";
        case "courses-menu":
            return "Menú de cursos";
        case "courses":
            return "Cursos";
        case "statecourses":
            return "Estados de cursos";
        case "typecourses":
            return "Tipos de cursos";
        case "departments":
            return "Departamentos";
        case "places":
            return "Lugares";
        case "durations":
            return "Duración";
        case "workloads":
            return "Carga horaria";
        case "config-menu":
            return "Menú de configuración";
        case "permissions":
            return "Permisos";
        case "roles":
            return "Roles";
        case "schoolyears":
            return "Años lectivos";
        case "stateschoolyears":
            return "Estados Año lectivo";
        case "days":
            return "Días";
        case "weeks":
            return "Semanas";
        case "exports":
            return "Exportaciones";
        case "imports":
            return "Importaciones";
        case "menu-edition":
            return "Edicion Menu";
        case "menus":
            return "Menues";
        /*
        case "advisers-menu":
            return "Menú de Consejeros";
        case "suppliers-menu":
            return "Menú de proveedores";
        case "courses-menu":
            return "Menú de cursos";
        case "teachers-menu":
            return "Menú de docentes";
        */
        default:
            return null;
    }
};

export function convertModulesEspToEng (m) {
    switch (m) {
        case "Usuarios":
            return "users";
        //case "Rol de Usuario":
        //    return "user-role";
        case "Menú de personas":
            return "persons-menu";
        case "Personas":
            return "persons";
        case "Estudiantes":
            return "students";
        case "Tipos de documento":
            return "docTypes";
        case "Especialidades":
            return "specialties";
        case "Inscripciones":
            return "inscriptions";
        case "Inasistencias":
            return "absences";
        case "Condición de Ingreso":
            return "entryconditions";
        case "Condición Final":
            return "finalconditions";
        case "Docentes":
            return "teachers";
        case "Colaboradores Docentes":
            return "teachercollabs";
        case "Consejeros":
            return "advisers";
        case "Menú de cursos":
            return "courses-menu";
        case "Cursos":
            return "courses";
        case "Estados de cursos":
            return "statecourses";
        case "Tipos de cursos":
            return "typecourses";
        case "Años lectivos":
            return "schoolyears";
        case "Estado Año lectivo":
            return "stateschoolyears";
        case "Departamentos":
            return "departments";
        case "Lugares":
            return "places";
        case "Duración":
            return "durations";
        case "Carga horaria":
            return "workloads";
        case "Menú de configuración":
            return "config-menu";
        case "Permisos":
            return "permissions";
        case "Roles":
            return "roles";
        case "Días":
            return "days";
        case "Semanas":
            return "weeks";
        case "Exportaciones":
            return "exports";
        case "Importaciones":
            return "imports";
        //case "Menú de consejeros":
        //    return "advisers-menu";
        //case "Menú de proveedores":
        //    return "suppliers-menu";
        case "Edición Menu":
            return "menu-edition";
        case "Menues":
            return "menus";
        //case "Menú de docentes":
        //    return "teachers-menu";
        default:
            return null;
    }
};

export function PluralizeEsp(data) {
    // eslint-disable-next-line no-useless-escape
    // const plural= /[^aeiouAEIOU]$/;

    // const result = plural.test(data);
    // if(result) {
    //     return data.concat("es");
    // } else {
    //     return data.concat("s");
    // }
    var plural = require('pluralize-es');
    return plural(data);
}

