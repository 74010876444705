import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Radio,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop,
  Space,
  Divider
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined,
  UpOutlined,
  DownOutlined,
  DownloadOutlined,
  RightOutlined,
  LeftOutlined,
  FileExcelOutlined,
  FilePdfOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditTeacherForm from "../EditTeacherForm";
import AddTeacherForm from "../AddTeacherForm";
import AdvancedSearchTeacherForm from "./AdvancedSearchForm";
import { activateTeacherApi, deleteTeacherApi } from "../../../../api/teacher";
import { getCoursesBySchoolYearApi, getCoursesByTeacherApi, getCoursesByTeacherAndSchoolYearApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import { listTeachersPdf , reportTeacherCourses, reportTeachersCoursesYear, reportTeachersCoursesYearExcel} from "../../Teachers/ReportsTeachers/pdfTeacherReport";

import "./ListTeachers.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListTeachers(props) {
  const {
    setPaginationActive,
    setPaginationInactive,
    teachersActive,
    teachersInactive,
    setReloadTeachers,
    teachersExportActive,
    teachersExportInactive,
  } = props;

  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewTeachersActives, setViewTeachersActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [teachersActiveFilter, setTeachersActiveFilter] = useState(
    []
  );
  const [ teachersInactiveFilter, setTeachersInactiveFilter ] = useState([]);
  const [exportActivePdf, setExportActivePdf] = useState([]);
  const [exportActiveExcel, setExportActiveExcel] = useState([]);
  const [exportInactivePdf, setExportInactivePdf] = useState([]);
  const [exportInactiveExcel, setExportInactiveExcel] = useState([]);
  
  const [propsPaginationActive, setPropsPaginationActive]=useState({});
  const [propsPaginationInactive, setPropsPaginationInactive]=useState({});

   //filtros para busqueda avanzada
  const [filters, setFilters] = useState([]);
  const [expand, setExpand] = useState(false);
  const [expandDownload, setExpandDownload] = useState(false);
  const [schoolYearData, setSchoolYearData] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  // const [listCoursesBySchoolYear, setListCoursesBySchoolYear] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  useEffect(() => {
    if(teachersActive){
      setPropsPaginationActive({
        total: teachersActive.totalDocs,
        pageSize: teachersActive.limit,
        defaultCurrent: teachersActive.page,
        current: teachersActive.page
      })
    }
  }, [teachersActive]);

  useEffect(() => {
    if(teachersInactive){
      setPropsPaginationInactive({
        total: teachersInactive.totalDocs,
        pageSize:teachersInactive.limit,
        defaultCurrent:teachersInactive.page,
        current: teachersInactive.page
      })
    }
  }, [teachersInactive]);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    // const listActive = [];
    if (teachersActive && teachersActive.docs) {
      // teachersActive.docs.forEach((element) => {
      //     if (
      //       element.description
      //         .toUpperCase()
      //         .includes(filterText.toUpperCase()) ||
      //       element.teacher_code.toString()
      //         .toUpperCase()
      //         .includes(filterText.toUpperCase()) ||
      //       filterText === ""
      //     ) {
      //       listActive.push(element);
      //     }
      // });
      setTeachersActiveFilter(teachersActive.docs);

    }
    // setTeachersActiveFilter(listActive);
  }, [teachersActive]);

  useEffect(() => {
    // const listActive = [];
    if (filterText) {
      setPaginationActive({page:1, state:1, filter:filterText,  filters:filters });
      setPaginationInactive({page:1, state:2, filter:filterText,  filters:filters});
    }
    else if (filterText==="") {
      setPaginationActive({page:1, state:1,  filters:filters});
      setPaginationInactive({page:1, state:2,  filters:filters});
    }
    // setTeachersActiveFilter(listActive);
  }, [filterText,filters]);

  const pdf_download = <div>Descargar PDF</div>;

  const xls_download = <div>Descargar Excel</div>;

  // useEffect(() => {
  //   // const listActive = [];
  //   if(filters.schoolYear){
  //     getCoursesBySchoolYearApi(accessToken, filters.schoolYear).then(response => {
  //       console.log(response)
  //       setListCoursesBySchoolYear(response.courses);
  //     });
  //   }else
  //   if (filterText) {
  //     setPaginationActive({page:1, state:1, filter:filterText });
  //     setPaginationInactive({page:1, state:2, filter:filterText});
  //   }
  //   else if (filterText==="") {
  //     setPaginationActive({page:1, state:1});
  //     setPaginationInactive({page:1, state:2});
  //   }
  //   // setTeachersActiveFilter(listActive);
  // }, [filters]);

  // useEffect(() => {
  //   if(filters.schoolYear){
  //   if (teachersInactive && teachersInactive.docs) {
  //     let list=[];
  //     teachersInactive.docs.forEach((element) => {
  //       const result=listCoursesBySchoolYear.filter(i=>i.teachers.find((x)=>x===element._id));
  //       if(result && result.length > 0){
  //         list.push(element);
  //       }
  //     });
  //     setTeachersInactiveFilter(list);
  //   }

  //   if (teachersActive && teachersActive.docs) {
  //     let listActive=[];
  //     teachersActive.docs.forEach((element) => {
  //       const result=listCoursesBySchoolYear.filter(i=>i.teachers.find((x)=>x===element._id));
  //       if(result && result.length > 0){
  //         listActive.push(element);
  //       }
  //     });
  //     setTeachersActiveFilter(listActive);
  //   }
  // }

  // }, [listCoursesBySchoolYear]);

  useEffect(() => {
    // const listInactive = [];
    if (teachersInactive && teachersInactive.docs) {
      // teachersInactive.docs.forEach((element) => {
      //     if (
      //       element.description
      //         .toUpperCase()
      //         .includes(filterText.toUpperCase()) ||
      //       element.teacher_code.toString()
      //         .toUpperCase()
      //         .includes(filterText.toUpperCase()) ||
      //       filterText === ""
      //     ) {
      //       listInactive.push(element);
      //     }
      // });
      setTeachersInactiveFilter(teachersInactive.docs);
    }
  }, [filterText, teachersInactive]);

  useEffect(() => {
    let isMounted = true;
    if (teachersExportActive) {
      if (isMounted) {
        setExportActivePdf([]);
        setExportActiveExcel([]);
        if(filters && filters.schoolYear){
          getCoursesByTeachers(teachersExportActive).then((result)=>{
            setExportActivePdf(result);
            setExportActiveExcel(reportTeachersCoursesYearExcel(result));
          })
        }else{
          setExportActivePdf(teachersExportActive);
          setExportActiveExcel(formatDataExportExcel(teachersExportActive));
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [teachersExportActive]);

  useEffect(() => {
    let isMounted = true;
    if (teachersExportInactive) {
      if (isMounted) {
        setExportInactivePdf([]);
        setExportInactiveExcel([]);
        if(filters && filters.schoolYear){
          getCoursesByTeachers(teachersExportInactive).then((result)=>{
            setExportInactivePdf(result);
            setExportInactiveExcel(reportTeachersCoursesYearExcel(result));
          })
        }else{
          setExportInactivePdf(teachersExportInactive);
          setExportInactiveExcel(formatDataExportExcel(teachersExportInactive));
        }
      }
    }
    return () => {
      isMounted = false;
    };
  }, [teachersExportInactive]);

  
  const formatDataExportExcel = (teachers) => {
    let exportData = [];
    teachers.map((teacher) => {
      let teacherData = {
        description: teacher.description,
        email: teacher.email ? teacher.email : "",
      };
      exportData.push(teacherData);
      return null; //retorno algo por warnings
    });
    return exportData;
  };

  const addTeacherModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Docente");
    setModalContent(
      <AddTeacherForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadTeachers={setReloadTeachers}
      />
    );
  };

  const getCoursesByTeachers = async (teachers) => {
    const accessToken = getAccessToken();
    let data = [];
    for await (const item of teachers) {
        let result = await getCoursesByTeacherAndSchoolYearApi(
          accessToken,
          item._id,
          filters.schoolYear
        );
        if (result) {
            item.courses=result.courses;
            data.push(item);
        } else {
          break;
        }
      
    }
    return data;
  };

  return (
    <div className="list-teachers">
      <div className="list-teachers__header">
        {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
        checkAction(permissionsActive, userToken.role, "teachers", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-teachers__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewTeachersActives(!viewTeachersActives)
                }
              />
              <span>
                {viewTeachersActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
        checkAction(permissionsActive, userToken.role, "teachers", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addTeacherModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
      checkAction(permissionsActive, userToken.role, "teachers", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-teachers__search">
            <Search
              className="search"
              allowClear
              placeholder=""
              onSearch={(e) => setFilterText(e)}
              enterButton="Buscar"
              style={{width:"100%"}}
            />
            <h1>
                <Button
                  type="link"
                  style={{ fontSize: 12 }}
                  onClick={() => {
                    setExpand(!expand);
                  }}
                >
                  {expand ? <UpOutlined /> : <DownOutlined />} Búsqueda Avanzada
                  (filtros)
                </Button>
                {expand ? (
                  <div>
                    {/* busqueda avanzada */}
                    <AdvancedSearchTeacherForm
                      filters={filters}
                      setFilters={setFilters}
                      setSchoolYearData={setSchoolYearData}
                    />
                  </div>
                ) : null}
              </h1>
            {/*
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> docentes.
            </h3>
            */}
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewTeachersActives ? (
        <>
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(permissionsActive, userToken.role, "teachers", "pdf") ||
            isAdmin(roleById) ? (
              <>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginRight: 10, marginBottom: 10 }}
                  shape="round"
                  size={"small"}
                  onClick={() => {
                    setExpandDownload(!expandDownload);
                  }}
                >
                  {<DownloadOutlined />} Descargas{" "}
                  {!expandDownload ? <RightOutlined /> : <LeftOutlined />}
                </Button>
                {expandDownload ? (filters && filters.schoolYear)? (
                  <>
                    <Space
                      split={<Divider type="vertical" />}
                      style={{ marginBottom: 10 }}
                    >
                      <Popover content={pdf_download}>
                        <Button
                          type="default"
                          shape="round"
                          size="small"
                          style={{ marginRight: 10 }}
                          disabled={
                            exportActivePdf
                              ? exportActivePdf.length > 0
                                ? false
                                : true
                              : true
                          }
                          onClick={() => {
                            // listTeachersPdf(exportActivePdf, false, schoolYearData);
                            reportTeachersCoursesYear(exportActivePdf,schoolYearData);
                          }}
                        >
                          <FilePdfOutlined /> Listado Pdf
                        </Button>
                      </Popover>

                      <>
                        <ExcelFile
                          filename={filters && filters.schoolYear? "ListadoDocentes"+(schoolYearData? schoolYearData : "") : "ListadoDocentes"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                size="small"
                                shape="round"
                                disabled={
                                  exportActiveExcel
                                    ? exportActiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Listado xls
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportActiveExcel}
                            name="Docentes"
                          >
                            <ExcelColumn label="Apellido y Nombre" value="description" />
                            <ExcelColumn label="Curso" value="name" />
                            <ExcelColumn label="Tipo Curso" value="typeCourse" />
                            <ExcelColumn label="Departamento" value="department" />
                          </ExcelSheet>
                        </ExcelFile>
                      </>

                  </Space>
                  </>
                ) :<>
                <Space
                  split={<Divider type="vertical" />}
                  style={{ marginBottom: 10 }}
                >
                  <Popover content={pdf_download}>
                    <Button
                      type="default"
                      shape="round"
                      size="small"
                      style={{ marginRight: 10 }}
                      disabled={
                        exportActivePdf
                          ? exportActivePdf.length > 0
                            ? false
                            : true
                          : true
                      }
                      onClick={() => {
                        listTeachersPdf(exportActivePdf, false, schoolYearData);
                        //reportTeachersCoursesYear(exportActivePdf,schoolYearData);
                      }}
                    >
                      <FilePdfOutlined /> Listado Pdf
                    </Button>
                  </Popover>

                  <>
                    <ExcelFile
                      filename={"ListadoDocentes"}
                      element={
                        <Popover content={xls_download}>
                          <Button
                            type="default"
                            size="small"
                            shape="round"
                            disabled={
                              exportActiveExcel
                                ? exportActiveExcel.length > 0
                                  ? false
                                  : true
                                : true
                            }
                          >
                            <FileExcelOutlined /> Listado xls
                          </Button>
                        </Popover>
                      }
                    >
                      <ExcelSheet
                        data={exportActiveExcel}
                        name="Docentes"
                      >
                        <ExcelColumn label="Apellido y Nombre" value="description" />
                        <ExcelColumn label="Email" value="email" />
                      </ExcelSheet>
                    </ExcelFile>
                  </>

              </Space>
              </> : null}
              </>
            ) : (
              <></>
            )}
          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "view") || isAdmin(roleById) ? (
            <>
              <TeachersActive
                teachersActive={teachersActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadTeachers={setReloadTeachers}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationActive}
                propsPagination={propsPaginationActive}
                filterText={filterText}
                filters={filters}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
         {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(permissionsActive, userToken.role, "teachers", "pdf") ||
            isAdmin(roleById) ? (
              <>
                <Button
                  type="primary"
                  style={{ fontSize: 12, marginRight: 10, marginBottom: 10 }}
                  shape="round"
                  size={"small"}
                  onClick={() => {
                    setExpandDownload(!expandDownload);
                  }}
                >
                  {<DownloadOutlined />} Descargas{" "}
                  {!expandDownload ? <RightOutlined /> : <LeftOutlined />}
                </Button>
                {expandDownload ? (filters && filters.schoolYear)? (
                  <>
                    <Space
                      split={<Divider type="vertical" />}
                      style={{ marginBottom: 10 }}
                    >
                      <Popover content={pdf_download}>
                        <Button
                          type="default"
                          shape="round"
                          size="small"
                          style={{ marginRight: 10 }}
                          disabled={
                            exportInactivePdf
                              ? exportInactivePdf.length > 0
                                ? false
                                : true
                              : true
                          }
                          onClick={() => {
                            // listTeachersPdf(exportActivePdf, false, schoolYearData);
                            reportTeachersCoursesYear(exportInactivePdf,schoolYearData);
                          }}
                        >
                          <FilePdfOutlined /> Listado Pdf
                        </Button>
                      </Popover>

                      <>
                        <ExcelFile
                          filename={filters && filters.schoolYear? "ListadoDocentesInactivos"+(schoolYearData? schoolYearData : "") : "ListadoDocentes"}
                          element={
                            <Popover content={xls_download}>
                              <Button
                                type="default"
                                size="small"
                                shape="round"
                                disabled={
                                  exportInactiveExcel
                                    ? exportInactiveExcel.length > 0
                                      ? false
                                      : true
                                    : true
                                }
                              >
                                <FileExcelOutlined /> Listado xls
                              </Button>
                            </Popover>
                          }
                        >
                          <ExcelSheet
                            data={exportInactiveExcel}
                            name="Docentes"
                          >
                            <ExcelColumn label="Apellido y Nombre" value="description" />
                            <ExcelColumn label="Curso" value="name" />
                            <ExcelColumn label="Tipo Curso" value="typeCourse" />
                            <ExcelColumn label="Departamento" value="department" />
                          </ExcelSheet>
                        </ExcelFile>
                      </>

                  </Space>
                  </>
                ) :<>
                <Space
                  split={<Divider type="vertical" />}
                  style={{ marginBottom: 10 }}
                >
                  <Popover content={pdf_download}>
                    <Button
                      type="default"
                      shape="round"
                      size="small"
                      style={{ marginRight: 10 }}
                      disabled={
                        exportInactivePdf
                          ? exportInactivePdf.length > 0
                            ? false
                            : true
                          : true
                      }
                      onClick={() => {
                        listTeachersPdf(exportInactivePdf, false, schoolYearData);
                        //reportTeachersCoursesYear(exportActivePdf,schoolYearData);
                      }}
                    >
                      <FilePdfOutlined /> Listado Pdf
                    </Button>
                  </Popover>

                  <>
                    <ExcelFile
                      filename={"ListadoDocentesInactivos"}
                      element={
                        <Popover content={xls_download}>
                          <Button
                            type="default"
                            size="small"
                            shape="round"
                            disabled={
                              exportInactiveExcel
                                ? exportInactiveExcel.length > 0
                                  ? false
                                  : true
                                : true
                            }
                          >
                            <FileExcelOutlined /> Listado xls
                          </Button>
                        </Popover>
                      }
                    >
                      <ExcelSheet
                        data={exportInactiveExcel}
                        name="Docentes"
                      >
                        <ExcelColumn label="Apellido y Nombre" value="description" />
                        <ExcelColumn label="Email" value="email" />
                      </ExcelSheet>
                    </ExcelFile>
                  </>

              </Space>
              </> : null}
              </>
            ) : (
              <></>
            )}
          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "view") || isAdmin(roleById) ? (
            <>
              <TeachersInactive
                teachersInactive={teachersInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadTeachers={setReloadTeachers}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationInactive}
                propsPagination={propsPaginationInactive}
                filterText={filterText}
                filters={filters}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function TeachersActive(props) {
  const {
    teachersActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadTeachers,
    accessToken,
    userToken,
    permissionsActive,
    roleById,
    propsPagination,
    setPagination,
    filterText,
    filters
  } = props;

  const editTeacher = (teacher) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${teacher.description ? teacher.description : "sin descripción"}`
    );
    setModalContent(
      <EditTeacherForm
        teacher={teacher}
        setIsVisibleModal={setIsVisibleModal}
        setReloadTeachers={setReloadTeachers}
      />
    );
  };

  const desactivateTeacher = (teacher) => {
    const accessToken = getAccessToken();

    activateTeacherApi(accessToken, teacher._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadTeachers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (teacher) => {
    const accessToken = getAccessToken();
    let response = await getCoursesByTeacherApi(accessToken, teacher._id);
    if(response.courses.length > 0){
      confirm({
        title: "Eliminando -> Docente",
        content:`No se puede eliminar '${teacher.description? teacher.description : "sin descripción"}' porque existen cursos asociados a él,¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateTeacher(teacher);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Docente",
        content: `¿Estás seguro que quieres eliminar a ${teacher.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteTeacherApi(accessToken, teacher._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadTeachers(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
    
  };

  const edit_teacher = (
    <div>
      {" "}
      <p>Editar Docente</p>{" "}
    </div>
  );

  const desactivate_teacher = (
    <div>
      {" "}
      <p>Pasar a Docentes inactivos</p>{" "}
    </div>
  );

  const delete_teacher = (
    <div>
      {" "}
      <p>Eliminar Docente</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      //dataIndex: "teacher_code",
      render: teacher => teacher.id_old ? teacher.id_old: teacher.teacher_code,
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, teacher) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_teacher}>
                <Button
                  className="button-edit"
                  onClick={() => editTeacher(teacher)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_teacher}>
                <Button
                  className="button-teacher"
                  type="primary"
                  onClick={() => desactivateTeacher(teacher)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_teacher}>
                <Button
                  className="button-teacher"
                  type="danger"
                  onClick={() => showDeleteConfirm(teacher)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      //footer={()=>{return teachersActive? `Total de registros: ${teachersActive.length}` : false}}      
      pagination={{
        ...propsPagination,
        showSizeChanger:false,
        onChange:(newPage) => {
          setPagination({page:newPage, state: 1, filter:filterText, filters:filters})}
      }}
      dataSource={teachersActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function TeachersInactive(props) {
  const { teachersInactive,
          setReloadTeachers,
          accessToken,
          userToken,
          permissionsActive,
          roleById,
          propsPagination,
          setPagination,
          filterText,
          filters
        } = props;
  
  // console.log(teachersInactive);

  const activateTeacher = (teacher) => {
    const accessToken = getAccessToken();

    activateTeacherApi(accessToken, teacher._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadTeachers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (teacher) => {
    const accessToken = getAccessToken();
    let response = await getCoursesByTeacherApi(accessToken, teacher._id);
    if(response.courses.length > 0){
      ModalAntd.info({
        title: "Eliminando -> Docente",
        content:`No se puede eliminar '${teacher.description? teacher.description : "sin descripción"}' porque existen cursos asociados a él`,
        onOk() {
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Docente",
        content: `¿Estás seguro que quieres eliminar a ${teacher.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteTeacherApi(accessToken, teacher._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadTeachers(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_teacher = (
    <div>
      {" "}
      <p>Pasar a Docentes activos</p>{" "}
    </div>
  );

  const delete_teacher = (
    <div>
      {" "}
      <p>Eliminar Docente</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      //dataIndex: "teacher_code",
      render: teacher => teacher.id_old ? teacher.id_old: teacher.teacher_code,
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, teacher) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_teacher}>
                <Button
                  className="button-activate"
                  onClick={() => activateTeacher(teacher)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_teacher}>
                <Button
                  className="button-teacher"
                  type="danger"
                  onClick={() => showDeleteConfirm(teacher)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      //footer={()=>{return teachersInactive? `Total de registros: ${teachersInactive.length}` : false}}   
      pagination={{  
        ...propsPagination,
        showSizeChanger:false,
        onChange:(newPage) => {
          setPagination({page:newPage, state: 1, filter:filterText, filters:filters})}
      }}
      dataSource={teachersInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
