import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getWorkloadsActiveApi } from "../../../api/workload";
import ListWorkloads from "../../../components/Admin/Workloads/ListWorkloads";

export default function Workloads() {
  const [workloadsActive, setWorkloadsActive] = useState([]);
  const [workloadsInactive, setWorkloadsInactive] = useState([]);
  const [reloadWorkloads, setReloadWorkloads] = useState(false);
  const token = getAccessToken();

  useEffect(() => {
    getWorkloadsActiveApi(token, true).then((response) => {
      setWorkloadsActive(response.workloads);
    });
    getWorkloadsActiveApi(token, false).then((response) => {
      setWorkloadsInactive(response.workloads);
    });
    setReloadWorkloads(false);
  }, [token, reloadWorkloads]);

  return (
    <div className="workloads">
      <h3>Listado de Carga Horaria</h3>
      <ListWorkloads
        workloadsActive={workloadsActive}
        workloadsInactive={workloadsInactive}
        setReloadWorkloads={setReloadWorkloads}
      />
    </div>
  );
}
