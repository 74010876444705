import React from 'react';
import { Layout, Tabs } from "antd";
import { Redirect } from "react-router-dom";
import Logo from '../../../assets/img/png/icba.png';
import RegisterForm from '../../../components/Admin/RegisterForm';
import LoginForm from '../../../components/Admin/LoginForm';
import RecoverForm from '../../../components/Admin/RecoverForm';
import { getAccessToken } from '../../../api/auth';

import './SignIn.scss';

export default function SignIn() {
    const { Content } = Layout;
    const { TabPane } = Tabs;

    //si hay token almacenado, hay un usuario logueado, por lo tanto no debe entrar a este layout
    if (getAccessToken()) {
        return <Redirect to="/admin" />
    }

    return (
        <Layout className="sign-in">
            <Content className="sign-in__content">
                <h1 className="sign-in__content-logo">
                    <img src={Logo} alt="LALALALALA" />
                </h1>
                <div className="sign-in__content-tabs">
                    <Tabs type="card" tabBarGutter={2} onTabScroll={"left"}>
                        <TabPane tab={<span>Entrar</span>} key="1">
                            <LoginForm />
                        </TabPane>
                        <TabPane tab={<span>Nuevo usuario</span>} key="2">
                            <RegisterForm />
                        </TabPane>
                        <TabPane tab={<span>Recuperar contraseña</span>} key="3" hidden="true">
                            <RecoverForm />
                        </TabPane>
                    </Tabs>
                </div>
            </Content>
        </Layout>
    );
}