import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Row,
  Col,
  DatePicker,
  notification,
  Divider
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  TeamOutlined,
  TagOutlined,
} from "@ant-design/icons";

import { addStudentApi } from "../../../../api/student";
import { getAdvisersActiveApi } from "../../../../api/adviser";
import { getSpecialtiesActiveApi } from "../../../../api/specialty";
import { getFinalConditionsActiveApi } from "../../../../api/finalCondition";
import { getEntryConditionsActiveApi } from "../../../../api/entryCondition";
import { getDocTypesActiveApi } from "../../../../api/docType";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import { getStudentConditionsActiveApi } from "../../../../api/studentCondition";
import { getAccessToken } from "../../../../api/auth";
import {
  emailValidation,
  numberValidation,
  phoneValidation,
  removeClassErrorSuccess
} from "../../../../utils/formValidation";
import moment from "moment";
import {
  // formatDateView,
  // getDateYear,
  getDateYearActual,
  formatDateHour,
  formatDateYear,
  formatDateCalendar
} from "../../../../utils/formatDate";

// import {
//   getDateYear
// } from "../../../../utils/formatDate";

//import {CITIES} from "../../../../utils/cities";
import {COUNTRIES} from "../../../../utils/countries";
import "./AddStudentForm.scss";


const { TextArea } = Input;

export default function AddStudentForm(props) {
  const { setIsVisibleModal, setReloadStudents } = props;
  const [listAdvisersActive, setListAdvisersActive] = useState([]);
  const [listSpecialtiesActive, setListSpecialtiesActive] = useState([]);
  const [listEntryConditionsActive, setListEntryConditionsActive] = useState([]);
  const [listFinalConditionsActive, setListFinalConditionsActive] = useState([]);
  const [listSchoolYearsActive, setListSchoolYearsActive] = useState([]);
  const [listStudentConditionsActive, setListStudentConditionsActive] = useState([]);
  const [listDocTypesActive, setListDocTypesActive] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const accessToken = getAccessToken();

  useEffect(() => {
    getAdvisersActiveApi(accessToken, true).then((response) => {
      setListAdvisersActive(response.advisers);
    });
  }, []);

  useEffect(() => {
    getSpecialtiesActiveApi(accessToken, true).then((response) => {
      setListSpecialtiesActive(response.specialties);
    });
  }, []);

  useEffect(() => {
    getEntryConditionsActiveApi(accessToken, true).then((response) => {
      setListEntryConditionsActive(response.entryConditions);
    });
  }, []);

  useEffect(() => {
    getFinalConditionsActiveApi(accessToken, true).then((response) => {
      setListFinalConditionsActive(response.finalConditions);
    });
  }, []);

  useEffect(() => {
    getDocTypesActiveApi(accessToken, true).then((response) => {
      setListDocTypesActive(response.docTypes);
    });
  }, []);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListSchoolYearsActive(response.schoolYears);
    });
  }, []);

  useEffect(() => {
    getStudentConditionsActiveApi(accessToken, true).then((response) => {
      setListStudentConditionsActive(response.studentConditions);
    });
  }, []);

  // useEffect(() => {
  //   getSchoolYearsActiveApi(accessToken, true).then((response) => {
  //     setListSchoolYearsActive(response.schoolYears);
  //   });
  // }, []);

  // useEffect(() => {
  //   getStudentConditionsActiveApi(accessToken, true).then((response) => {
  //     setListStudentConditionsActive(response.studentConditions);
  //   });
  // }, []);

  const [inputs, setInputs] = useState({
    country:"Argentina"
    //name:null,
    // lastname:null,
    // documentNumber:null,
    // phone:null,
    //cycle:{}
  });

  const [inputsCycle, setInputsCycle] = useState({
    // name:null,
    // lastname:null,
    // documentNumber:null,
    // phone:null,
    //cycle:{}
  });

  const [inputYearsAbandonment, setInputYearsAbandonment] = useState({
  });

  const [formValid, setFormValid] = useState({
  });

  const inputValidation = (e) => {
    const { name } = e.target;

    removeClassErrorSuccess(e.target);

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });
    if (e.target.value !== "") {
      if (name === "email") {
        setFormValid({
          ...formValid,
          [name]: emailValidation(e.target),
        });
      }

      if (name === "phone") {
        setFormValid({
          ...formValid,
          [name]: phoneValidation(e.target),
        });
      }
      if (name === "documentNumber") {
        setFormValid({
          ...formValid,
          [name]: numberValidation(e.target),
        });
      }
    }
  };

  const isFormValid = (e) => {
    let errorExists = false;
    //console.log(inputs);
    if (
      !inputs.name ||
      !inputs.lastname ||
      !inputs.documentNumber ||
      !inputs.phone ||
      !inputs.email ||
      !inputsCycle.schoolYear ||
      !inputsCycle.studentCondition ||
      !inputYearsAbandonment.schoolYear
    ) {
      //inputs.perception_bsas ===null || inputs.perception_caba ===null
      notification["error"]({
        message: "Obligatorios: *",
      });
      errorExists = true;
    }
    else if (
      formValid.documentNumber === false) {
      //BUG
      notification["error"]({
        message: "DNI inválido",
      });
      errorExists = true;
    }
    else if (formValid.phone === false) {
      //BUG
      notification["error"]({
        message: "Telefóno inválido",
      });
      errorExists = true;
    } else if (formValid.email === false && inputs.email !== "") {
      //BUG
      notification["error"]({
        message: "Email inválido",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addNewFinalCondition = (studentData) =>{
    let newDate={
      finalCondition:studentData.finalCondition};
    if(studentData.adviser){
      newDate.adviser=studentData.adviser
    }
    //Verifico en caso de que el Año de Admision sea un año proximo (mayor al actual)
    if(studentData.yearAdmission && moment.isMoment(studentData.yearAdmission) &&
       new Date().getFullYear() < studentData.yearAdmission.year()){
      let year=studentData.yearAdmission.year();
      let dateNew= formatDateHour([year,1,1]);
      newDate.initDate=dateNew;
    }else{
      newDate.initDate=formatDateHour(moment())
    }
    studentData.finalConditions=[];
    studentData.finalConditions.push(newDate);
    return true;
  };

  const addNewAdviser = (studentData) =>{
    let newAdviser={ 
        adviser: studentData.adviser};
    //Verifico en caso de que el Año de Admision sea un año proximo (mayor al actual)
    if(studentData.yearAdmission && moment.isMoment(studentData.yearAdmission) &&
       new Date().getFullYear() < studentData.yearAdmission.year()){
      let year=studentData.yearAdmission.year();
      let dateNew= formatDateHour([year,1,1]);
      newAdviser.initDate=dateNew;
    }else{
      newAdviser.initDate=formatDateHour(moment())
    }
    studentData.advisers=[];
    studentData.advisers.push(newAdviser);
      return true;
  };


  const addStudent = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      if(!disabledSubmit){
        setDisabledSubmit(true);
        const accessToken = getAccessToken();
        // getYear();
        inputs.cycles = [
          {
            schoolYear:inputsCycle.schoolYear,
            studentCondition: inputsCycle.studentCondition
          }
        ];
        //Verifico que la condicion final seleccionada sea el de Abandono
        if(listFinalConditionsActive.findIndex(i=>i._id === inputs.finalCondition)!==-1 ){
          let finalIndex= listFinalConditionsActive.findIndex(i=>i._id === inputs.finalCondition);
          if(listFinalConditionsActive[finalIndex].description.toLowerCase()==="abandonó" || 
          listFinalConditionsActive[finalIndex].description.toLowerCase()==="abandono"
          || listFinalConditionsActive[finalIndex].description.toLowerCase()==="recursante"){
            inputs.yearsAbandonment = [
              {
                schoolYear:inputYearsAbandonment.schoolYear,
              }
            ];    
          }
        }
        // if(listFinalConditionsActive.findIndex(i=>i._id === inputs.finalCondition)!==-1 ){
        //   let finalIndex= listFinalConditionsActive.findIndex(i=>i._id === inputs.finalCondition);
        //   if(listFinalConditionsActive[finalIndex].description.toLowerCase()==="recursante"){
        //     inputs.yearsAbandonment = [
        //       {
        //         schoolYear:inputs.yearAbandonment,
        //       }
        //     ];    
        //   }
        // }
        if(inputs.adviser){
          addNewAdviser(inputs);
        }

        if( inputs.finalCondition){ 
          addNewFinalCondition(inputs);
        }
        // console.log(inputs);
        await addStudentApi(accessToken, inputs)
          .then((response) => {
            if (
              response === "ERR_CONNECTION_REFUSED" ||
              (response.message && response.message === "Failed to fetch") ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setDisabledSubmit(false);
            } else if (response.code !== 200) {
              notification["error"]({
                message: response.message,
              });
              setDisabledSubmit(false);
              setReloadStudents(true);
            } else {
              notification["success"]({
                message: "Estudiante creado",
              });
              setIsVisibleModal(false);
              setReloadStudents(true);
            }
            //setReloadStudents(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      }
    }
  };

  return (
    <div className="add-student-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        inputsCycle={inputsCycle}
        setInputsCycle={setInputsCycle}
        inputYearsAbandonment={inputYearsAbandonment}
        setInputYearsAbandonment={setInputYearsAbandonment}
        addStudent={addStudent}
        listAdvisersActive={listAdvisersActive}
        listSpecialtiesActive={listSpecialtiesActive}
        listEntryConditionsActive={listEntryConditionsActive}
        listFinalConditionsActive={listFinalConditionsActive}
        listSchoolYearsActive={listSchoolYearsActive}
        listStudentConditionsActive={listStudentConditionsActive}
        listDocTypesActive={listDocTypesActive}
        inputValidation={inputValidation}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    inputs,
    setInputs,
    inputsCycle,
    setInputsCycle,
    inputYearsAbandonment,
    setInputYearsAbandonment,
    addStudent,
    listAdvisersActive,
    listSpecialtiesActive,
    listEntryConditionsActive,
    listFinalConditionsActive,
    listSchoolYearsActive,
    listStudentConditionsActive,
    listDocTypesActive,
    inputValidation,
  } = props;
  const { Option } = Select;
  const [showRecurringFields, setShowRecurringFields]=useState(false);
  const [newValue, setNewValue] = useState('');

  const handleSelect=(e)=>{
    //console.log(studentData);
    //setNewValue({ ...studentData, finalCondition: e });
    listFinalConditionsActive.map((i) => {
      if(i._id === e){
        setNewValue(i.description);
      }
      return null; //retorno algo por warnings
    })
    //setNewValue(e);
  }

  useEffect(() => {
    if(inputs.finalCondition){
      let result=listFinalConditionsActive.filter(e=> e._id === inputs.finalCondition);
      if(result.length > 0){
        setShowRecurringFields(result[0].description.toLowerCase() === "recursante"? true : false);
      }
    }
  }, [inputs.finalCondition])

  useEffect(() => {
    if(inputs.country){
      setInputs({...inputs, city:null});
    }
  }, [inputs.country]);

  //Si el año de admision en mayor o igual al año actual le asigna automaticamente la condicion final "en curso"
  //siempre y cuando la condicion final sea null
  useEffect(() => {
    if(inputs.yearAdmission && !inputs.finalCondition){
      if(moment(inputs.yearAdmission).year() >= new Date().getFullYear()){
        let newFinalCondition=listFinalConditionsActive.findIndex((i)=>i.description.toLowerCase()==="en curso");
        if(newFinalCondition!==-1){
          setInputs({...inputs, finalCondition:listFinalConditionsActive[newFinalCondition]._id});
        }
      }
    }
  }, [inputs.yearAdmission]);

  useEffect(()=>{
    if(listSchoolYearsActive.length > 0){
      let data=listSchoolYearsActive[0]._id;
      // console.log(listSchoolYearsActive);
      setInputsCycle({inputsCycle, schoolYear : data}); 
    }
  }, [listSchoolYearsActive]);

  useEffect(()=>{
    if(listSchoolYearsActive.length > 0){
      let data=listSchoolYearsActive[0]._id;
      // console.log(listSchoolYearsActive);
      setInputYearsAbandonment({inputYearsAbandonment, schoolYear : data}); 
    }
  }, [listSchoolYearsActive]);

  return (
    <Form className="form-add" onSubmit={addStudent}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span>Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.student_code}
              onChange={(e) => setInputs({ ...inputs, student_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="nombre"
              value={inputs.name}
              onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Apellido
          </label>
          <Form.Item>
            <Input
              prefix={<UserOutlined />}
              placeholder="Apellido"
              value={inputs.lastname}
              onChange={(e) =>
                setInputs({ ...inputs, lastname: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Tipo de documento</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona un tipo de documento"
              value={inputs.docType}
              onChange={(e) => setInputs({ ...inputs, docType: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listDocTypesActive
                ? listDocTypesActive.map((docType) => {
                    return (
                      <Option key={docType._id}>
                        {docType.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Nº documento
          </label>
          <Form.Item>
            <Input
              // prefix={<EnvironmentOutlined />}
              placeholder="DNI"
              name="documentNumber"
              maxLength={20}
              value={inputs.documentNumber}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Ciudad</label>
          <Form.Item>
           {/* {(inputs.country!=="Argentina" && inputs.country!==undefined)? */}
             <Input
              prefix={<EnvironmentOutlined />}
              placeholder="Ciudad"
              value={inputs.city}
              onChange={(e) => setInputs({ ...inputs, city: e.target.value })}
            /> 
            {/* :
             <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una ciudad"
              value={inputs.city}
              onChange={(e) => setInputs({ ...inputs, city: e })}
              optionFilterProp="children"
              dropdownStyle={{minWidth:"40%"}}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {CITIES
                ? CITIES.localidades.map((city) => {
                    return (
                      <Option key={city.nombre}>
                        {city.municipio.nombre? city.nombre +"("+city.municipio.nombre +","+city.provincia.nombre+")" : city.nombre + "("+city.provincia.nombre +")"}
                      </Option>
                    );
                  })
                : null}
            </Select>
          } */}
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">País</label>
          <Form.Item>
            {/* <Input
              prefix={<EnvironmentOutlined />}
              placeholder="País"
              value={inputs.country}
              onChange={(e) => setInputs({ ...inputs, country: e.target.value })}
            /> */}
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona un país"
              value={inputs.country}
              //defaultValue={"Argentina"}
              onChange={(e) => setInputs({ ...inputs, country: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {COUNTRIES
                ? COUNTRIES.map((country) => {
                    return (
                      <Option key={country}>
                        {country}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
            <span className="control-required">*</span> Teléfono
          </label>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Telefono"
              name="phone"
              value={inputs.phone}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">
          <span className="control-required">*</span> Email</label>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              placeholder="Correo Electrónico"
              type="email"
              name="email"
              value={inputs.email}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Ciclo Lectivo</label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona un ciclo lectivo"
              value={inputs.schoolYear}
              onChange={(e) => setInputs({ ...inputs, cycle:{schoolYear: e}})}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSchoolYearsActive
                ? listSchoolYearsActive.map((year) => {
                    return (
                      <Option key={year._id}>
                        {year.year}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Condición del Estudiante</label>
          <Form.Item>
          <Select
              showSearch
              placeholder="Selecciona una condicion"
              value={inputs.studentCondition}
              onChange={(e) => setInputs({ ...inputs, cycle:{studentCondition:e}})}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStudentConditionsActive
                ? listStudentConditionsActive.map((condition) => {
                    return (
                      <Option key={condition._id}>
                        {condition.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row> */}

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Especialidad</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una especialidad"
              value={inputs.specialty}
              onChange={(e) => setInputs({ ...inputs, specialty: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSpecialtiesActive
                ? listSpecialtiesActive.map((specialty) => {
                    return (
                      <Option key={specialty._id}>
                        {specialty.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Año ingreso</label>
          <Form.Item>
            <DatePicker
                // prefix={<CalendarOutlined/>}
                type="date"
                placeholder="Año de admisión"
                value={inputs.yearAdmission}
                picker="year"
                onChange={(e) =>
                  setInputs({ ...inputs, yearAdmission: e })
                }
                style={{width:"100%"}}
              />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Año egreso</label>
          <Form.Item>
          <DatePicker
                // prefix={<CalendarOutlined/>}
                type="date"
                placeholder="Año de graduación"
                value={inputs.yearGraduation}
                format="YYYY"
                picker="year"
                onChange={(e) =>
                  setInputs({ ...inputs, yearGraduation: e })
                }
                style={{width:"100%"}}
              />
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Consejero</label>
          <Form.Item>
            <Select
              prefix={<TeamOutlined />}
              showSearch
              placeholder="Selecciona un consejero"
              value={inputs.adviser}
              onChange={(e) => setInputs({ ...inputs, adviser: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listAdvisersActive
                ? listAdvisersActive.map((advisers) => {
                    return (
                      <Option key={advisers._id}>
                        {advisers.name + " " + advisers.lastname}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={6}>
          <label className="control-label">Condición de Ingreso</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una condición de ingreso"
              value={inputs.entryCondition}
              onChange={(e) => setInputs({ ...inputs, entryCondition: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listEntryConditionsActive
                ? listEntryConditionsActive.map((entryCondition) => {
                    return (
                      <Option key={entryCondition._id}>
                        {entryCondition.description? entryCondition.description : ""}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <label className="control-label">Condición final</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona una condición final"
              value={inputs.finalCondition}
              onChange={(e) => setInputs({ ...inputs, finalCondition: e })}
              onSelect={handleSelect}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listFinalConditionsActive
                ? listFinalConditionsActive.map((finalCondition) => {
                    return (
                      <Option key={finalCondition._id}>
                        {finalCondition.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>

        {newValue ? (
          newValue ===
          "Abandonó" ? (
            <Col span={6}>
              <label className="control-label">Año de Abandono</label>
              <Form.Item>
                <Select
                  // prefix={<CalendarOutlined/>}
                  //type="date"
                  placeholder="Año de abandono"
                  value={inputYearsAbandonment.schoolYear}
                  //picker="day"
                  onChange={(e) =>
                    setInputYearsAbandonment({ ...inputYearsAbandonment, schoolYear: e })
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {listSchoolYearsActive
                  ? listSchoolYearsActive.map((schoolYear) => {
                    return (
                      <Option key={schoolYear._id}>
                        {schoolYear.year}
                      </Option>
                    );
                  })
                : null}
                </Select>
              </Form.Item>
            </Col>
          ) : null
        ) : null}


        {showRecurringFields ? (
            <Col span={6}>
              <label className="control-label">Cantidad de horas</label>
              <Form.Item>
                <InputNumber
                  placeholder="Cantidad de horas cursadas anteriormente"
                  name="numberHours"
                  value={inputs.hoursCoursed}
                  onChange={(e) => setInputs({ ...inputs, hoursCoursed: e })}
                  style={{ width: "90%" }}
                />
              </Form.Item>
            </Col>
          ) : null}
        {showRecurringFields ? (
          <Col span={6}>
            <label className="control-label">Año Abandono</label>
            {/* <Form.Item>
            <DatePicker
                // prefix={<CalendarOutlined/>}
                type="date"
                placeholder="Año de abandono"
                value={inputs.yearAbandonment}
                picker="year"
                format="YYYY"
                onChange={(e) =>
                  setInputs({ ...inputs, yearAbandonment: e })
                }
                style={{width:"100%"}}
              />
            </Form.Item> */}
            <Form.Item>
                <Select
                  // prefix={<CalendarOutlined/>}
                  //type="date"
                  placeholder="Año de abandono"
                  value={inputYearsAbandonment.schoolYear}
                  //picker="day"
                  onChange={(e) =>
                    setInputYearsAbandonment({ ...inputYearsAbandonment, schoolYear: e })
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {listSchoolYearsActive
                  ? listSchoolYearsActive.map((schoolYear) => {
                    return (
                      <Option key={schoolYear._id}>
                        {schoolYear.year}
                      </Option>
                    );
                  })
                : null}
                </Select>
              </Form.Item>
          </Col>
        ):null}
      </Row>
      <Divider></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>
            Ciclo Lectivo
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona un ciclo lectivo"
              value={inputsCycle.schoolYear}
              onChange={(e) => setInputsCycle({ ...inputsCycle, schoolYear: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSchoolYearsActive
                ? listSchoolYearsActive.map((year) => {
                    return <Option key={year._id}>{year.year}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>
            Condición del Estudiante
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona una condicion"
              value={inputsCycle.studentCondition}
              onChange={(e) => setInputsCycle({ ...inputsCycle, studentCondition: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStudentConditionsActive
                ? listStudentConditionsActive.map((condition) => {
                    return (
                      <Option key={condition._id}>
                        {condition.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider></Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Observaciones</label>
          <Form.Item>
            <TextArea
              placeholder="Observaciones"
              value={inputs.observation}
              onChange={(e) =>
                setInputs({ ...inputs, observation: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addStudent}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
