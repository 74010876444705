import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Select,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import {
  UserOutlined,
  TagOutlined
} from "@ant-design/icons";
import { updateInscriptionApi } from "../../../../api/inscription";
import { getCoursesActiveApi } from "../../../../api/course";
import { getStudentsActiveApi } from "../../../../api/student";
import { getAccessToken } from "../../../../api/auth";

import { formatDateCalendar} from "../../../../utils/formatDate";

import "./EditInscriptionForm.scss";

//const { TextArea } = Input;

export default function EditInscriptionForm(props) {
  const { inscription, setIsVisibleModal, setReloadInscriptions } = props;
  const [ inscriptionData, setInscriptionData] = useState({});
  const [ students, setStudents] = useState([]);
  const [listCoursesActive, setListCoursesActive] = useState([]);
  const [listStudentsActive, setListStudentsActive] = useState([]);
  const accessToken = getAccessToken();

  //trae los datos de la inscripcion
  useEffect(() => {
    setInscriptionData({
      inscription_code: inscription.inscription_code,
      date:inscription.date? formatDateCalendar(inscription.date) : null,
      course: inscription.course._id,
      students: inscription.students
    });
  }, [inscription]);

  useEffect(() => {
    getCoursesActiveApi(accessToken, true).then((response) => {
      setListCoursesActive(response.courses);
    });
  }, []);

  useEffect(() => {
    getStudentsActiveApi(accessToken, true).then((response) => {
      setListStudentsActive(response.students);
    });
  }, []);
  
  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !inscription.date ||
      !inscription.course
    ) {
      //BUG
      notification["error"]({
        message: "Obligatorios: Fecha y Curso.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateInscription = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      
      let inscriptionUpdate = inscriptionData; //es let porque se va actualizando

      updateInscriptionApi(token, inscriptionUpdate, inscription._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadInscriptions(true);
        }
      });
    }
  };

  return (
    <div className="edit-inscription-form">
      <EditForm
        inscriptionData={inscriptionData}
        setInscriptionData={setInscriptionData}
        updateInscription={updateInscription}
        listCoursesActive={listCoursesActive}
        listStudentsActive={listStudentsActive}
        students={students}
        setStudents={setStudents}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    inscriptionData,
    setInscriptionData,
    updateInscription,
    listCoursesActive,
  } = props;
  const { Option } = Select;

  //console.log(inscriptionData.students? inscriptionData.students : null );
  return (
    <Form className="form-edit" onSubmit={updateInscription}>
      {/* //onFinish={updateUser} */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inscriptionData.inscription_code}
              onChange={(e) =>
                setInscriptionData({ ...inscriptionData, inscription_code: e })
              }
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span> Fecha
          </label>
          <Form.Item
          >
            <Input
              prefix={<UserOutlined />}
              type="date"
              placeholder="Fecha"
              value={inscriptionData.date}
              onChange={(e) =>
                setInscriptionData({ ...inscriptionData, date: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">Curso</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona el curso"
              value={inscriptionData.course}
              onChange={(e) => setInscriptionData({ ...inscriptionData, course: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listCoursesActive
                ? listCoursesActive.map((course) => {
                    return (
                      <Option key={course._id}>
                        {course.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Students studentsData={inscriptionData.students}/>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateInscription}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}

function Students(props) {
  const { studentsData} = props;
  //console.log(inscriptionData.students? inscriptionData.students : null );
  console.log(studentsData);

  const columns = [
    {
      title: "Código",
      dataIndex: "student",
      render: (studentData) =>
      studentData ? studentData.student_code : null,
      width: "10%",
    },
    {
      title: "Apellido",
      dataIndex: "student",
      render: (studentData) =>
      studentData ? studentData.lastname : null,
      width: "20%"
    },
    {
      title: "Nombre",
      dataIndex: "student",
      render: (studentData) =>
      studentData ? studentData.name : null,
      width: "20%"
    },

    {
      title: "E-mail",
      dataIndex: "student",
      render: (studentData) =>
      studentData ? studentData.email : null,
      width: "20%"
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={studentsData}
      //rowKey="_id"
      rowKey="student"
    />
  );
}