import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  MailOutlined
} from "@ant-design/icons";
import { addAdviserApi } from "../../../../api/adviser";
import { getAccessToken } from "../../../../api/auth";
import { emailValidation, removeClassErrorSuccess } from "../../../../utils/formValidation";

import "./AddAdviserForm.scss";

export default function AddAdviserForm(props) {
  const { setIsVisibleModal, setReloadAdvisers } = props;

  const [inputs, setInputs] = useState({
    adviser_code: "",
    name: "",
    lastname: "",
    email: "",
  });

  const [formValid, setFormValid] = useState({
  });

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const inputValidation = (e) => {
    const { type, name } = e.target;

    removeClassErrorSuccess(e.target);

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });
    if (e.target.value !== "") {
      if (name === "email") {
          setFormValid({
            ...formValid,
            [name]: emailValidation(e.target),
          });
        }
    }
  };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.name) {
      notification["error"]({
        message: "Obligatorios: nombre.",
      });
      errorExists = true;
    }
    else if (formValid.email === false && inputs.email !== "") {
      //BUG
      notification["error"]({
        message: "Email inválido",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addAdviser = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      if(!disabledSubmit){
        setDisabledSubmit(true);
        const accessToken = getAccessToken();

        await addAdviserApi(accessToken, inputs)
          .then((response) => {
            if (
              response === "ERR_CONNECTION_REFUSED" ||
              response.message === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setDisabledSubmit(false);
            } else if (response.code !== 200) {
              notification["error"]({
                message: response.message,
              });
              setDisabledSubmit(false);
              setReloadAdvisers(true);
            } else {
              notification["success"]({
                message: "Consejero creado",
              });
              setIsVisibleModal(false);
              setReloadAdvisers(true);
            }
            //setReloadAdvisers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      }
    }
  };

  return (
    <div className="add-adviser-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        inputValidation={inputValidation}
        addAdviser={addAdviser}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addAdviser, inputValidation } = props;

  return (
    <Form className="form-add" onSubmit={addAdviser}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.adviser_code}
              onChange={(e) => setInputs({ ...inputs, adviser_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Nombre
          </label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={inputs.name}
              onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Apellido</label>
          <Form.Item>
            <Input
              placeholder="Apellido"
              name="lastname"
              value={inputs.lastname}
              onChange={(e) =>
                setInputs({ ...inputs, lastname: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">E-mail</label>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              placeholder="E-mail"
              name="email"
              value={inputs.email}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addAdviser}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
