import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  FileDoneOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import EditInscriptionForm from "../EditInscriptionForm";
// import AddStudentsInscriptionForm from "../AddStudentsInscriptionForm";
import AddInscriptionForm from "../AddInscriptionForm";
import { activateInscriptionApi, deleteInscriptionApi } from "../../../../api/inscription";
import { getCourseApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";
// import useRouteProps from "../../../../hooks/UseRouteProps"
// import { PluralizeEsp } from "../../../../utils/convert";
import { formatDateView } from "../../../../utils/formatDate";

import {Link} from "react-router-dom";

import "./ListInscriptions.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListInscriptions(props) {
  const {  setPaginationActive, setPaginationInactive, inscriptionsActive, inscriptionsInactive, setReloadInscriptions } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewInscriptionsActives, setViewInscriptionsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [inscriptionsActiveFilter, setInscriptionsActiveFilter] = useState([]);
  const [inscriptionsInactiveFilter, setInscriptionsInactiveFilter] = useState([]);
  const [propsPaginationActive, setPropsPaginationActive]=useState({});
  const [propsPaginationInactive, setPropsPaginationInactive]=useState({});

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  //console.log("userToken", userToken);
  // console.log(useRouteProps());

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  
  useEffect(() => {
    if(inscriptionsActive){
      setPropsPaginationActive({
        //total: coursesActive.totalDocs,
        total: inscriptionsActive.totalDocs? inscriptionsActive.totalDocs : null,
        pageSize: inscriptionsActive.limit? inscriptionsActive.limit : null,
        defaultCurrent: inscriptionsActive.page? inscriptionsActive.page : null,
        current: inscriptionsActive.page? inscriptionsActive.page : null,
      });
    }
  }, [inscriptionsActive]);

  useEffect(() => {
    if(inscriptionsInactive){
      setPropsPaginationInactive({
        //total: inscriptionsInactive.totalDocs,
        total: inscriptionsInactive.totalDocs,
        pageSize:inscriptionsInactive.limit,
        defaultCurrent:inscriptionsInactive.page,
        current:inscriptionsInactive.page
      });
    }
  }, [inscriptionsInactive]);

  useEffect(() => {
    if (filterText) {
      setPaginationActive({page:1, state:1, filter:filterText});
      setPaginationInactive({page:1, state:2, filter:filterText});
    }
    else if (filterText==="") {
      setPaginationActive({page:1, state:1});
      setPaginationInactive({page:1, state:2});
    }
  }, [filterText]);

  useEffect(() => {
    if (inscriptionsActive  && inscriptionsActive.docs) {
      setInscriptionsActiveFilter(inscriptionsActive.docs);
    }
  }, [inscriptionsActive]);

  useEffect(() => {
    if (inscriptionsInactive && inscriptionsInactive.docs) {
      setInscriptionsInactiveFilter(inscriptionsInactive.docs);
    }
  }, [inscriptionsInactive]);

  // useEffect(() => {
  //   const listActive = [];
  //   if (inscriptionsActive) {
  //     inscriptionsActive.forEach((element) => {
  //         if (
  //           (element.course && element.course.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
  //             -1 ) ||
  //           filterText === ""
  //         ) {
  //           listActive.push(element);
  //         }
  //     });
  //   }
  //   setInscriptionsActiveFilter(listActive);
  // }, [filterText, inscriptionsActive]);

  // useEffect(() => {
  //   const listInactive = [];
  //   if (inscriptionsInactive) {
  //     inscriptionsInactive.forEach((element) => {
  //         if (
  //           (element.course && element.course.name.toUpperCase().indexOf(filterText.toUpperCase()) !==
  //             -1 )||
  //           filterText === ""
  //         ) {
  //           listInactive.push(element);
  //         }
  //     });
  //   }
  //   setInscriptionsInactiveFilter(listInactive);
  // }, [filterText, inscriptionsInactive]);

  const addInscriptionModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear -> Inscripción");
    setModalWidth(1300);
    setModalContent(
      <AddInscriptionForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadInscriptions={setReloadInscriptions}
      />
    );
  };

  return (
    <div className="list-inscriptions">
      <div className="list-inscriptions__header">
        {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
        checkAction(permissionsActive, userToken.role, "inscriptions", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-inscriptions__header-switch">
              <Switch
                defaultChecked
                onChange={() => setViewInscriptionsActives(!viewInscriptionsActives)}
              />
              <span>
                {viewInscriptionsActives ? "Activos" : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
          <>
          </>
        )}

        {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
        checkAction(permissionsActive, userToken.role, "incriptions", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addInscriptionModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
      checkAction(permissionsActive, userToken.role, "inscriptions", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-inscriptions__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> se debe crear una inscripción para cada curso y así poder agregar estudiantes
            </h3>
          </div>
        </>
        ) : (
          <>
          </>
      )}

      {viewInscriptionsActives ? (
        <>

          {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
          checkAction(permissionsActive, userToken.role, "inscriptions", "view") || isAdmin(roleById) ? (
            <>
              <InscriptionsActive
                inscriptionsActive={inscriptionsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadInscriptions={setReloadInscriptions}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationActive}
                filterText={filterText}
                propsPagination={propsPaginationActive}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
          checkAction(permissionsActive, userToken.role, "inscription", "view") || isAdmin(roleById) ? (
            <>
              <InscriptionsInactive
                inscriptionsInactive={inscriptionsInactiveFilter}
                setReloadInscriptions={setReloadInscriptions}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationInactive}
                filterText={filterText}
                propsPagination={propsPaginationInactive}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function InscriptionsActive(props) {
  const { inscriptionsActive, setReloadInscriptions, 
    setPagination, propsPagination, filterText,
    setIsVisibleModal, setModalTitle, setModalWidth, setModalContent,accessToken, userToken, permissionsActive, roleById } = props; //showDeleteConfirm

  const editInscription = (inscription) => {
    setIsVisibleModal(true);
    setModalTitle(`Editar Inscripcion: ${inscription.course ? inscription.course.name : "curso"}`);
    setModalWidth(1000);
    setModalContent(
      <EditInscriptionForm
        inscription={inscription}
        setIsVisibleModal={setIsVisibleModal}
        setReloadInscriptions={setReloadInscriptions}
      />
    );
  };

  const desactivateInscription = (inscription) => {
    activateInscriptionApi(accessToken, inscription._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadInscriptions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (inscription) => {
    let response = await getCourseApi(accessToken, inscription.course._id);
    if (response.course) {
      confirm({
        title: "Eliminando -> Inscripción",
        content: `No se puede eliminar ${inscription.course ? inscription.course.name : "inscripcion"} porque existe un curso asociado a él ¿Deseas desactivarla?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateInscription(inscription);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Inscripción",
        content: `¿Estás seguro que quieres eliminar las inscripciones al curso: ${inscription.course ? inscription.course.name : "inscripcion"}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteInscriptionApi(accessToken, inscription._id)
            .then((response) => {
              notification["success"]({
                message: response.message,
              });
              setReloadInscriptions(true);
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    };
  }

  const edit_inscription = (
    <div>
      {" "}
      <p>Editar inscripción</p>{" "}
    </div>
  );

  const desactivate_inscription = (
    <div>
      {" "}
      <p>Pasar a inscripciones inactivas</p>{" "}
    </div>
  );

  const delete_inscription = (
    <div>
      {" "}
      <p>Eliminar inscripcion</p>{" "}
    </div>
  );

  const enroll_students = (
    <div>
      {" "}
      <p>Estudiantes inscriptos</p>{" "}
    </div>
  );

  // const download_inscription = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle inscripción</p>{" "}
  //   </div>
  // );
  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "inscription_code",
    //   width: '5%'
    // },
    {
      title: "Fecha",
      dataIndex: "date",
      render: date =>formatDateView(date),
      width: '10%'
    },
    {
      title: "Curso",
      dataIndex: "course",
      render: course =>course? course.name : "sin datos",
      width: '60%'
    },
    {
      title: "Acción",
      key: "action",
      width: '30%',
      render: (text, inscription) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
          checkAction(permissionsActive, userToken.role, "inscriptions", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_inscription}>
                <Button
                  className="button-edit"
                  onClick={() => editInscription(inscription)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
          checkAction(permissionsActive, userToken.role, "inscriptions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_inscription}>
                <Button
                  className="button-inscription"
                  type="primary"
                  onClick={() => desactivateInscription(inscription)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
          checkAction(permissionsActive, userToken.role, "inscriptions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_inscription}>
                <Button
                  className="button-inscription"
                  type="danger"
                  onClick={() => showDeleteConfirm(inscription)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
          {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
          checkAction(permissionsActive, userToken.role, "inscriptions", "all") || isAdmin(roleById) ? (
            <>
              <Popover content={enroll_students}>
                {inscription.course?
                <Link to={{pathname:`/admin/studentsCourse/${inscription.course.name}`,
                    state:{ id:`${inscription.course._id}`}}} >
                    <Button
                        className="button-enroll"
                    >
                      <FileDoneOutlined />
                    </Button>
                </Link> : null}
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

        </div>
      ),
    },
  ];

  return (
    <Table
    footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      pagination={{
        ...propsPagination,
        showSizeChanger:false,
        onChange:(newPage) => {
          setPagination({page:newPage,
            state:1,
            filter:filterText})
          }
        }}
      dataSource={inscriptionsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function InscriptionsInactive(props) {
  const { inscriptionsInactive,
          setReloadInscriptions,
          setPagination, propsPagination, filterText,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props; //showDeleteConfirm

  const activateInscription = (inscription) => {
    activateInscriptionApi(accessToken, inscription._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadInscriptions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (inscription) => {
    let response = await getCourseApi(accessToken, inscription.course._id);
    if (response.course) {
      ModalAntd.info({
        title: "Eliminando -> Inscripción",
        content:`No se puede eliminar '${inscription.course ? inscription.course.name : "inscripcion"}' porque existe un curso asociado`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Inscripción",
        content: `¿Estás seguro que quieres eliminar las inscripciones al curso: ${inscription.course ? inscription.course.name : "curso"}?`,
        okText: "Eliminar",
        okType: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteInscriptionApi(accessToken, inscription._id)
            .then((response) => {
              notification["success"]({
                message: response,
              });
              setReloadInscriptions(true);
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_inscription = (
    <div>
      {" "}
      <p>Pasar a inscripciones activas</p>{" "}
    </div>
  );

  const delete_inscription = (
    <div>
      {" "}
      <p>Eliminar inscripción</p>{" "}
    </div>
  );
  // const download_inscription = (
  //   <div>
  //     {" "}
  //     <p>Descargar detalle inscripción</p>{" "}
  //   </div>
  // );

  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "inscription_code",
    //   width: '5%'
    // },
    {
      title: "Fecha",
      dataIndex: "date",
      render: date =>formatDateView(date),
      width: '10%'
    },
    {
      title: "Curso",
      dataIndex: "course",
      render: course =>course? course.name : "sin datos",
      width: '60%'
    },
    {
      title: "Acción",
      key: "action",
      width: '30%',
      render: (text, inscription) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
          checkAction(permissionsActive, userToken.role, "inscriptions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_inscription}>
                <Button
                  className="button-activate"
                  onClick={() => activateInscription(inscription)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "inscriptions", "all") ||
          checkAction(permissionsActive, userToken.role, "inscriptions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_inscription}>
                <Button
                  className="button-inscription"
                  type="danger"
                  onClick={() => showDeleteConfirm(inscription)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      pagination={{
        ...propsPagination,
        showSizeChanger:false,
        onChange:(newPage) => {
          setPagination({page:newPage,
            state:2,
            filter:filterText})
          }
        }}
      dataSource={inscriptionsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
