import React, { useState, useEffect } from "react";
import {
  Form,
  Table,
  Divider,
  Modal as ModalAntd,
  Popover,
  Button,
  Select,
  Row,
  Col,
  notification,
} from "antd";
import {
  DeleteOutlined,
  EyeFilled,
} from "@ant-design/icons";
//import Modal from "../../../Modal";
import {
  getStudentApi,
  insertCycleStudentApi,
  deleteCycleStudentApi,
} from "../../../../api/student";
import { getAccessToken } from "../../../../api/auth";
import { getSchoolYearsActiveApi } from "../../../../api/schoolYear";
import { getStudentConditionsActiveApi } from "../../../../api/studentCondition";

import "./AddEditStudentCyclesForm.scss";

const { confirm } = ModalAntd;

export default function AddEditStudentCycleForm(props) {
  const { student, setIsVisibleModal, setReloadStudents } = props;
  const [studentData, setStudentData ]=useState([]);
  const [reload, setReload]=useState(false);
  const [listSchoolYearsActive, setListSchoolYearsActive] = useState([]);
  const [listStudentConditionsActive, setListStudentConditionsActive] =
    useState([]);

  const accessToken = getAccessToken();

  useEffect(() => {
    getStudentApi(accessToken, student._id).then((response) => {
      setStudentData(response.student);
    });
    setReload(false);
  }, [student,reload]);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListSchoolYearsActive(response.schoolYears);
    });
  }, []);

  useEffect(() => {
    getStudentConditionsActiveApi(accessToken, true).then((response) => {
      setListStudentConditionsActive(response.studentConditions);
    });
  }, []);

  const [inputs, setInputs] = useState({
    schoolYear: "",
    studentCondition: "",
  });

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.schoolYear || !inputs.studentCondition) {
      notification["error"]({
        message: "Obligatorio: Ciclo Lectivo y Condición.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  // Verificar que no exista ciclo con el mismo año seleccionado
  const uniqueCycle = (cycle) => {
    let result=studentData.cycles.filter((element) => element.schoolYear._id === cycle.schoolYear);
    if(result && result.length > 0){
      return false;
    }
      return true;
  };

  const addCycleStudent = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      //verifico que el ciclo no se repita
      if (uniqueCycle(inputs)) {
        // let cycleData={...inputs};

        await insertCycleStudentApi(accessToken, inputs, student._id)
          .then((response) => {
            if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else if (response.code !== 200) {
              notification["error"]({
                message: response.message,
              });
            } else {
              notification["success"]({
                message: "Ciclo guardado",
              });
              setIsVisibleModal(false);
            }
            setReloadStudents(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      }else{
          notification["error"]({
            message: "El ciclo lectivo ya se encuentra registrado",
          });
      }
    }
  };

  return (
    <div className="add-studentcycles-form">
      <AddForm
        student={studentData}
        setReload={setReload}
        setReloadStudents={setReloadStudents}
        inputs={inputs}
        setInputs={setInputs}
        addCycleStudent={addCycleStudent}
        listSchoolYearsActive={listSchoolYearsActive}
        listStudentConditionsActive={listStudentConditionsActive}
      />
    </div>
  );
}

function AddForm(props) {
  const {
    student,
    setReload,
    setReloadStudents,
    inputs,
    setInputs,
    addCycleStudent,
    listStudentConditionsActive,
    listSchoolYearsActive,
  } = props;
  const { Option } = Select;

  const [viewCyclesStudent, setViewCyclesStudent] = useState(false);

  const viewCycles = () => {
    setViewCyclesStudent(!viewCyclesStudent);
  };

  return (
    <Form className="form-add" onSubmit={addCycleStudent}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>
            Ciclo Lectivo
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona un ciclo lectivo"
              value={inputs.schoolYear}
              onChange={(e) => setInputs({ ...inputs, schoolYear: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {listSchoolYearsActive
                ? listSchoolYearsActive.map((year) => {
                    return <Option key={year._id}>{year.year}</Option>;
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <label className="control-label">
            <span className="control-required">*</span>
            Condición del Estudiante
          </label>
          <Form.Item>
            <Select
              showSearch
              placeholder="Selecciona una condicion"
              value={inputs.studentCondition}
              onChange={(e) => setInputs({ ...inputs, studentCondition: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStudentConditionsActive
                ? listStudentConditionsActive.map((condition) => {
                    return (
                      <Option key={condition._id}>
                        {condition.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-add"
            onClick={addCycleStudent}
          >
            Guardar
          </Button>
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-view"
            onClick={viewCycles}
            icon={<EyeFilled />}
          >
            Ver ciclos
          </Button>
        </Col>
      </Row>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {viewCyclesStudent ? (student.cycles?(
          <Col span={24}>
            <Cycles cycles={student.cycles} student={student} setReload={setReload} setReloadStudents={setReloadStudents}/>
          </Col>
        ) : null) : null}
      </Row>
    </Form>
  );
}

function Cycles(props) {
  const {
    cycles,
    setReload,
    setReloadStudents,
    student
  } = props; //showDeleteConfirm

  const showDeleteConfirm = (cycle) => {
    const accessToken = getAccessToken();
    //console.log("cycle: ", cycle);
    //console.log("student: ", student);

    confirm({
      title: "Eliminando -> Ciclo del Estudiante",
      content: `¿Estás seguro que quieres eliminar el ciclo ${cycle.schoolYear.year +"-" +cycle.studentCondition.description} del estudiante?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteCycleStudentApi(accessToken,cycle,student._id).then(response=>{
          if(response.code===200){
            setReload(true);
            setReloadStudents(true)
          }
        });
      },
    });
  }
  const delete_cycle = (
    <div>
      {" "}
      <p>Eliminar ciclo</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Ciclo",
      dataIndex: "schoolYear",
      sorted: (a, b) => b.schoolYear.year - a.schoolYear.year,
      render: (schoolYear) => (schoolYear ? schoolYear.year : null),
      width: "30%",
    },
    {
      title: "Condición",
      dataIndex: "studentCondition",
      render: (studentCondition) =>
        studentCondition ? studentCondition.description : null,
      width: "50%",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, student) => (
        <div>
            <>
              <Popover content={delete_cycle}>
                <Button
                  className="button-inscription"
                  type="danger"
                  onClick={() => showDeleteConfirm(student)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
        </div>
      )
    },
  ];

  return (
    <Table
      pagination={{ pageSize: 6 }}
      dataSource={cycles}
      size="small"
      columns={columns}
      rowKey="_id"
    />
  );
}
