import { switchPath, apiVersion } from './config';

/**FUNCIONES PARA MANEJO DEL MODULO DOCENTE QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getTeacherExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/teacher-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'teacher.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postTeacherImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/teacher-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addTeacherApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-teacher`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getTeachersApi(token, state, limit, page, filter, filters) {
    let url = "";

    let options= filters? (Object.keys(filters)
        .map(k => `${k}=${encodeURI(filters[k])}`)
        .join('&')): null;
    // console.log(options);
    //VERIFICAR LAS SIGUIENTES CONDICIONES EN BASE A LOS FILTROS
    if(filter && filter !=="" || filters !==null){
        url=`${switchPath()}/${apiVersion}/teachers?state=${state}&limit=${limit}&page=${page}&filter=${filter}&${options}`;
    }else{
        url=`${switchPath()}/${apiVersion}/teachers?state=${state}&limit=${limit}&page=${page}`;
    }

    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getTeachersExportApi(token, state,  filter, filters) {
    let url = "";

    let options= filters? (Object.keys(filters)
        .map(k => `${k}=${encodeURI(filters[k])}`)
        .join('&')): null;
    // console.log(options);
    //VERIFICAR LAS SIGUIENTES CONDICIONES EN BASE A LOS FILTROS
    if(filter && filter !=="" || filters !==null){
        url=`${switchPath()}/${apiVersion}/teachers-export?state=${state}&filter=${filter}&${options}`;
    }else{
        url=`${switchPath()}/${apiVersion}/teachers-export?state=${state}`;
    }

    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getTeachersActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/teachers-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateTeacherApi(token, teacherData, teacherId) {
    const url = `${switchPath()}/${apiVersion}/update-teacher/${teacherId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(teacherData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateTeacherApi(token, teacherId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-teacher/${teacherId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteTeacherApi(token, teacherId) {
    const url = `${switchPath()}/${apiVersion}/delete-teacher/${teacherId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}
