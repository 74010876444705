import React from "react";


import {
    HomeOutlined,
    TeamOutlined,
    UserOutlined,
    SettingOutlined,
    SwapOutlined,
    OrderedListOutlined,
    UnorderedListOutlined,
    SkinOutlined,
    ExportOutlined,
    ProfileOutlined,
    ContainerOutlined,
    FileTextOutlined,
    AuditOutlined,
    ImportOutlined,
    TagsOutlined,
  } from "@ant-design/icons";

 export const menuIcons={
    persons: <ExportOutlined/>,
    users: <TeamOutlined/>,
    students: <ExportOutlined/>,
    billbuys: <FileTextOutlined/>,
    billsells: <AuditOutlined/>,
    billsystems: <ProfileOutlined/>,
    billtypes: <AuditOutlined/>,
    categories: <AuditOutlined />,
    concepts: <AuditOutlined />,
    documenttypes:  <ProfileOutlined />,
    items:     <ContainerOutlined />,
    ivaconditions:<ProfileOutlined/>,
    "menu-edition": <SettingOutlined />,
    "config-menu": <SettingOutlined />,
    "stock-menu":<SkinOutlined />,
    "sellers-menu":<UserOutlined/>,
    "suppliers-menu":<ImportOutlined />,
    "bills-menu":<UnorderedListOutlined />,
    "payments-menu":<UnorderedListOutlined />,
    movements: <SwapOutlined />,
    paymentmethods: <AuditOutlined />,
    paymentorders: <AuditOutlined />,
    paymenttypes:  <AuditOutlined />,
    permissions: <ProfileOutlined />,
    buyBills: <FileTextOutlined/>,
    roles: <ProfileOutlined />,
    saleconditions: <ProfileOutlined />,
    salepoints: <ProfileOutlined />,
    sellers: <ExportOutlined/>,
    statebills: <AuditOutlined />,
    stockinventories: <OrderedListOutlined />,
    suppliers:  <ImportOutlined />,
    suppliertypes: <UserOutlined/>,
    // "vouchertypes":Admin
    exports: <ExportOutlined />,
    imports: <ImportOutlined />,
  }