import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { addDurationApi } from "../../../../api/duration";
import { getAccessToken } from "../../../../api/auth";

import "./AddDurationForm.scss";

export default function AddDurationForm(props) {
  const { setIsVisibleModal, setReloadDurations } = props;

  const [inputs, setInputs] = useState({
    //duration_code: "",
    description: "",
  });

  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorio: descripcion.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addDuration = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      if(!disabledSubmit){
        setDisabledSubmit(true);
        const accessToken = getAccessToken();

        await addDurationApi(accessToken, inputs)
          .then((response) => {
            if (
              response.message === "ERR_CONNECTION_REFUSED" ||
              response.message === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setDisabledSubmit(false);
            } else if (!response.duration) {
              notification["error"]({
                message: response.message,
              });
              setDisabledSubmit(false);
              setReloadDurations(true);
            } else {
              notification["success"]({
                message: "Duración creado",
              });
              setIsVisibleModal(false);
              setReloadDurations(true);
            }
            //setReloadDurations(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
        }
    }
  };

  return (
    <div className="add-duration-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addDuration={addDuration}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addDuration } = props;

  return (
    <Form className="form-add" onSubmit={addDuration}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.duration_code}
              onChange={(e) => setInputs({ ...inputs, duration_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
              //   placeholder="descripcion"
              value={inputs.description}
              onChange={(e) =>
                setInputs({ ...inputs, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addDuration}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
