import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditPlaceForm from "../EditPlaceForm";
import AddPlaceForm from "../AddPlaceForm";
import { activatePlaceApi, deletePlaceApi } from "../../../../api/place";
import { getCoursesByPlaceApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListPlaces.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListPlaces(props) {
  const {
    placesActive,
    placesInactive,
    setReloadPlaces,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewPlacesActives, setViewPlacesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [placesActiveFilter, setPlacesActiveFilter] = useState(
    []
  );
  const [ placesInactiveFilter, setPlacesInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (placesActive) {
      placesActive.forEach((element) => {
          if (
            element.description
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.place_code.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setPlacesActiveFilter(listActive);
  }, [filterText, placesActive]);

  useEffect(() => {
    const listInactive = [];
    if (placesInactive) {
      placesInactive.forEach((element) => {
        if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            element.place_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
        } 
      });
    }
    setPlacesInactiveFilter(listInactive);
  }, [filterText, placesInactive]);

  const addPlaceModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Lugar");
    setModalContent(
      <AddPlaceForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadPlaces={setReloadPlaces}
      />
    );
  };

  return (
    <div className="list-places">
      <div className="list-places__header">
        {checkAction(permissionsActive, userToken.role, "places", "all") ||
        checkAction(permissionsActive, userToken.role, "places", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-places__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewPlacesActives(!viewPlacesActives)
                }
              />
              <span>
                {viewPlacesActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "places", "all") ||
        checkAction(permissionsActive, userToken.role, "places", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addPlaceModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "places", "all") ||
      checkAction(permissionsActive, userToken.role, "places", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-places__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de lugar
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewPlacesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "places", "all") ||
          checkAction(permissionsActive, userToken.role, "places", "view") || isAdmin(roleById) ? (
            <>
              <PlacesActive
                placesActive={placesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadPlaces={setReloadPlaces}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "places", "all") ||
          checkAction(permissionsActive, userToken.role, "places", "view") || isAdmin(roleById) ? (
            <>
              <PlacesInactive
                placesInactive={placesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadPlaces={setReloadPlaces}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function PlacesActive(props) {
  const {
    placesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadPlaces,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editPlace = (place) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${place.description ? place.description : "sin descripcion"}`
    );
    setModalContent(
      <EditPlaceForm
        place={place}
        setIsVisibleModal={setIsVisibleModal}
        setReloadPlaces={setReloadPlaces}
      />
    );
  };

  const desactivatePlace = (place) => {
    activatePlaceApi(accessToken, place._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadPlaces(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (place) => {
    let response = await getCoursesByPlaceApi(accessToken, place._id);
    if (response.courses.length > 0) {
      confirm({
        title: "Eliminando -> Lugar",
        content: `No se puede eliminar '${place.description}' porque existen cursos asociados a él ¿Deseas desactivarlo?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivatePlace(place);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Lugar",
        content: `¿Estás seguro que quieres eliminar a ${place.description? place.description : "sin descripción"}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deletePlaceApi(accessToken, place._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadPlaces(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_place= (
    <div>
      {" "}
      <p>Editar lugar</p>{" "}
    </div>
  );

  const desactivate_place = (
    <div>
      {" "}
      <p>Pasar a lugar inactivos</p>{" "}
    </div>
  );

  const delete_place = (
    <div>
      {" "}
      <p>Eliminar lugar</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "place_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, place) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "places", "all") ||
          checkAction(permissionsActive, userToken.role, "places", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_place}>
                <Button
                  className="button-edit"
                  onClick={() => editPlace(place)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "places", "all") ||
          checkAction(permissionsActive, userToken.role, "places", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_place}>
                <Button
                  className="button-place"
                  type="primary"
                  onClick={() => desactivatePlace(place)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "places", "all") ||
          checkAction(permissionsActive, userToken.role, "places", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_place}>
                <Button
                  className="button-place"
                  type="danger"
                  onClick={() => showDeleteConfirm(place)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return placesActive.length > 0? `Total de registros: ${placesActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={placesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function PlacesInactive(props) {
  const { placesInactive,
          setReloadPlaces,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activatePlace = (place) => {
    activatePlaceApi(accessToken, place._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadPlaces(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (place) => {
    let response = await getCoursesByPlaceApi(accessToken, place._id);
    if(response.courses.length > 0){
      ModalAntd.info({
        title: "Eliminando -> Lugar",
        content:`No se puede eliminar '${place.description}' porque existen cursos asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Lugar",
        content: `¿Estás seguro que quieres eliminar a ${place.description? place.description : "sin descripción"}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deletePlaceApi(accessToken, place._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadPlaces(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_place = (
    <div>
      {" "}
      <p>Pasar a lugar activos</p>{" "}
    </div>
  );

  const delete_place = (
    <div>
      {" "}
      <p>Eliminar lugar</p>
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "place_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, place) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "places", "all") ||
          checkAction(permissionsActive, userToken.role, "places", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_place}>
                <Button
                  className="button-activate"
                  onClick={() => activatePlace(place)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "places", "all") ||
          checkAction(permissionsActive, userToken.role, "places", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_place}>
                <Button
                  className="button-place"
                  type="danger"
                  onClick={() => showDeleteConfirm(place)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return placesInactive.length > 0? `Total de registros: ${placesInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={placesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
