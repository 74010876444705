import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  //Radio,
  Button,
  notification,
  //Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  //DownloadOutlined,
  FilePdfOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import { getCoursesByTeacherApi } from "../../../../api/course";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { reportTeacherHistory , reportTeacherCourses} from "../../Teachers/ReportsTeachers/pdfTeacherReport";
import jwtDecode from "jwt-decode";

import "./ListTeachersReports.scss";

//const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListTeachersReports(props) {
  const {
    setPaginationActive,
    setPaginationInactive,
    teachersActive,
    teachersInactive,
    setReloadTeachers,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewTeachersActives, setViewTeachersActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [teachersActiveFilter, setTeachersActiveFilter] = useState(
    []
  );
  const [ teachersInactiveFilter, setTeachersInactiveFilter ] = useState([]);

  
  const [propsPaginationActive, setPropsPaginationActive]=useState({});
  const [propsPaginationInactive, setPropsPaginationInactive]=useState({});

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //Reporte Docente
  const report_teacher = (
    <div style={{"alignItems": "center", "textAlign": "center"}}>
      {" "}
      <p>Descargar PDF (Departamento y Carga Horaria)</p>
    </div>
  );

  const report_teacher_2 = (
    <div style={{"alignItems": "center", "textAlign": "center"}}>
      {" "}
      <p>Descargar PDF (Tipo de Curso y Departamento)</p>
    </div>
  );

  useEffect(() => {
    if(teachersActive){
      setPropsPaginationActive({
        total: teachersActive.totalDocs,
        pageSize: teachersActive.limit,
        defaultCurrent: teachersActive.page,
        current: teachersActive.page
      })
    }
  }, [teachersActive]);

  useEffect(() => {
    if(teachersInactive){
      setPropsPaginationInactive({
        total: teachersInactive.totalDocs,
        pageSize:teachersInactive.limit,
        defaultCurrent:teachersInactive.page,
        current: teachersActive.page
      })
    }
  }, [teachersInactive]);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    // const listActive = [];
    if (teachersActive && teachersActive.docs) {
      setTeachersActiveFilter(teachersActive.docs);
    }
    // setTeachersActiveFilter(listActive);
  }, [teachersActive]);

  useEffect(() => {
    // const listActive = [];
    if (filterText) {
      setPaginationActive({page:1, state:1, filter:filterText});
      setPaginationInactive({page:1, state:2, filter:filterText});
    }
    else if (filterText==="") {
      setPaginationActive({page:1, state:1});
      setPaginationInactive({page:1, state:2});
    }
    // setTeachersActiveFilter(listActive);
  }, [filterText]);

  useEffect(() => {
    // const listInactive = [];
    if (teachersInactive && teachersInactive.docs) {
      setTeachersInactiveFilter(teachersInactive.docs);
    }
  }, [filterText, teachersInactive]);

  // const addTeacherModal = () => {
  //   setIsVisibleModal(true);
  //   setModalTitle("Crear Docente");
  //   setModalContent(
  //     <AddTeacherForm
  //       setIsVisibleModal={setIsVisibleModal}
  //       setReloadTeachers={setReloadTeachers}
  //     />
  //   );
  // };

  return (
    <div className="list-teachers">
      <div className="list-teachers__header">
        {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
        checkAction(permissionsActive, userToken.role, "teachers", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-teachers__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewTeachersActives(!viewTeachersActives)
                }
              />
              <span>
                {viewTeachersActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {/* {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
        checkAction(permissionsActive, userToken.role, "teachers", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addTeacherModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )} */}
      </div>

      {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
      checkAction(permissionsActive, userToken.role, "teachers", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-teachers__search">
            <Search
              className="search"
              allowClear
              placeholder=""
              onSearch={(e) => setFilterText(e)}
              enterButton="Buscar"
              style={{width:"100%"}}
            />
            {/*
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> docentes.
            </h3>
            */}
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewTeachersActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "view") || isAdmin(roleById) ? (
            <>
              <TeachersActive
                teachersActive={teachersActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadTeachers={setReloadTeachers}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationActive}
                propsPagination={propsPaginationActive}
                filterText={filterText}
                report_teacher={report_teacher}
                report_teacher_2={report_teacher_2}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "view") || isAdmin(roleById) ? (
            <>
              <TeachersInactive
                teachersInactive={teachersInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadTeachers={setReloadTeachers}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                setPagination={setPaginationInactive}
                propsPagination={propsPaginationInactive}
                filterText={filterText}
                report_teacher={report_teacher}
                report_teacher_2={report_teacher_2}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function TeachersActive(props) {
  const {
    teachersActive,
    userToken,
    permissionsActive,
    roleById,
    propsPagination,
    setPagination,
    filterText,
    report_teacher,
    report_teacher_2
  } = props;

  const teacherHistoryPdf = (teacher) => {
     const accessToken = getAccessToken();

    getCoursesByTeacherApi(accessToken, teacher._id, false)
       .then((response) => {
         if(response.courses) {
          reportTeacherHistory(teacher,response.courses);
          // console.log(teacher, response.courses);
         }
       })
       .catch((err) => {
         notification["error"]({
           message: err,
         });
       });
  };

  const teacherCoursesPdf = (teacher) => {
    const accessToken = getAccessToken();

   getCoursesByTeacherApi(accessToken, teacher._id, false)
      .then((response) => {
        if(response.courses) {
         reportTeacherCourses(teacher,response.courses);
         // console.log(teacher, response.courses);
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
 };

  const columns = [
    {
      title: "Código",
      //dataIndex: "teacher_code",
      render: teacher => teacher.id_old ? teacher.id_old: teacher.teacher_code,
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, teacher) => (
        <div>
        {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={report_teacher}>
                <Button
                  className="button-cycle"
                  onClick={() => teacherHistoryPdf(teacher)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>

              <Popover content={report_teacher_2}>
                <Button
                  className="button-courses"
                  onClick={() => teacherCoursesPdf(teacher)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      pagination={{
        ...propsPagination,
        showSizeChanger:false,
        onChange:(newPage) => {
          setPagination({page:newPage, state: 1, filter:filterText})}
      }}
      dataSource={teachersActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function TeachersInactive(props) {
  const { teachersInactive,
          userToken,
          permissionsActive,
          roleById,
          propsPagination,
          setPagination,
          filterText,
          report_teacher,
          report_teacher_2
        } = props;
  
  // console.log(teachersInactive);

  const teacherHistoryPdf = (teacher) => {
    const accessToken = getAccessToken();

   getCoursesByTeacherApi(accessToken, teacher._id, false)
      .then((response) => {
        if(response.courses) {
          reportTeacherHistory(teacher,response.courses);
         // console.log(teacher, response.courses);
        }
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
 };

 
 const teacherCoursesPdf = (teacher) => {
  const accessToken = getAccessToken();

 getCoursesByTeacherApi(accessToken, teacher._id, false)
    .then((response) => {
      if(response.courses) {
       reportTeacherCourses(teacher,response.courses);
       // console.log(teacher, response.courses);
      }
    })
    .catch((err) => {
      notification["error"]({
        message: err,
      });
    });
};

  const columns = [
    {
      title: "Código",
      //dataIndex: "teacher_code",
      render: teacher => teacher.id_old ? teacher.id_old: teacher.teacher_code,
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, teacher) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "teachers", "all") ||
          checkAction(permissionsActive, userToken.role, "teachers", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={report_teacher}>
                <Button
                  className="button-download"
                  onClick={() => teacherHistoryPdf(teacher)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>

              <Popover content={report_teacher_2}>
                <Button
                  className="button-courses"
                  onClick={() => teacherCoursesPdf(teacher)}
                >
                  <FilePdfOutlined />
                </Button>
              </Popover>

            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return propsPagination.total? `Total de registros: ${propsPagination.total}` : false}}
      pagination={{  
        ...propsPagination,
        showSizeChanger:false,
        onChange:(newPage) => {
          setPagination({page:newPage, state: 1, filter:filterText})}
      }}
      dataSource={teachersInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
