import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
    getFinalConditionsActiveApi,
    //getFinalConditionsApi 
    } from "../../../api/finalCondition";
import ListFinalConditions from "../../../components/Admin/FinalConditions/ListFinalConditions";


export default function FinalConditions() {
    const [finalConditionsActive, setFinalConditionsActive] = useState([]);
    const [finalConditionsInactive, setFinalConditionsInactive] = useState([]);
    const [reloadFinalConditions, setReloadFinalConditions ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getFinalConditionsActiveApi(token, true).then(response => {
            setFinalConditionsActive(response.finalConditions);
        });
        getFinalConditionsActiveApi(token, false).then(response => {
            setFinalConditionsInactive(response.finalConditions);
        });
        setReloadFinalConditions(false);
    }, [token, reloadFinalConditions]);

    return (
        <div className="finalconditions">
            <h3>Listado de Condición Final</h3>
            <ListFinalConditions finalConditionsActive={finalConditionsActive} finalConditionsInactive={finalConditionsInactive} setReloadFinalConditions={setReloadFinalConditions}
                />

        </div>
    );
}