import { switchPath, apiVersion } from './config';

/**FUNCIONES PARA MANEJO DEL MODULO INASISTENCIA QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getAbsenceExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/absence-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'absence.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postAbsenceImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/absence-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addAbsenceApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-absence`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getAbsencesApi(token) {
    const url = `${switchPath()}/${apiVersion}/absences`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getAbsencesPaginateApi(token,state, limit, page, filter, schoolYear) {
    const url = `${switchPath()}/${apiVersion}/absences-paginate?state=${state}&limit=${limit}&page=${page}&filter=${filter}&schoolYear=${schoolYear}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function getAbsencesActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/absences-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

//SE USA PARA LA FUNCIONALIDAD DE CONTAR INASISTENCIAS, PARA SETEAR stateStudentCourse a "desaprobado"
export function getAbsencesByCourseApi(token, courseId) {
    const url = `${switchPath()}/${apiVersion}/absences-course/${courseId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getAbsencesByStudentApi(token, studentId) {
    const url = `${switchPath()}/${apiVersion}/absences-student/${studentId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateAbsenceApi(token, absenceData, absenceId) {
    const url = `${switchPath()}/${apiVersion}/update-absence/${absenceId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(absenceData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function getAbsenceApi(token, absenceId) {
    const url = `${switchPath()}/${apiVersion}/absenc/${absenceId}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function activateAbsenceApi(token, absenceId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-absence/${absenceId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result.message;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteAbsenceApi(token, absenceId) {
    const url = `${switchPath()}/${apiVersion}/delete-absence/${absenceId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getCountAbsenceByStudentIdApi(token, studentId) {
    const url = `${switchPath()}/${apiVersion}/count-absence-student/${studentId}`;

    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}
