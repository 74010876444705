import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getStateStudentCoursesActiveApi } from "../../../api/stateStudentCourse";
import ListStateStudentCourses from "../../../components/Admin/StateStudentCourses/ListStateStudentCourses";


export default function StateStudentCourses() {
    const [stateStudentCoursesActive, setStateStudentCoursesActive] = useState([]);
    const [stateStudentCoursesInactive, setStateStudentCoursesInactive] = useState([]);
    const [reloadStateStudentCourses, setReloadStateStudentCourses ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getStateStudentCoursesActiveApi(token, true).then(response => {
            setStateStudentCoursesActive(response.stateStudentCourses);
        });
        getStateStudentCoursesActiveApi(token, false).then(response => {
            setStateStudentCoursesInactive(response.stateStudentCourses);
        });
        setReloadStateStudentCourses(false);
    }, [token, reloadStateStudentCourses]);

    return (
        <div className="statestudentcourses">
            <h3>Listado de Estados de Estudiante Curso</h3>
            <ListStateStudentCourses stateStudentCoursesActive={stateStudentCoursesActive} stateStudentCoursesInactive={stateStudentCoursesInactive} setReloadStateStudentCourses={setReloadStateStudentCourses}
                />

        </div>
    );
}