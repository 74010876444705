import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { addSpecialtyApi } from "../../../../api/specialty";
import { getAccessToken } from "../../../../api/auth";

import "./AddSpecialtyForm.scss";

export default function AddSpecialtyForm(props) {
  const { setIsVisibleModal, setReloadSpecialties } = props;

  const [inputs, setInputs] = useState({
    //specialty_code: "",
    description: "",
  });

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorio: descripcion.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addSpecialty = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addSpecialtyApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Especialidad creada",
            });
            setIsVisibleModal(false);
          }
          setReloadSpecialties(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-specialty-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addSpecialty={addSpecialty}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addSpecialty } = props;

  return (
    <Form className="form-add" onSubmit={addSpecialty}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={12}>
          <label className="control-label">Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.specialty_code}
              onChange={(e) =>
                setInputs({ ...inputs, specialty_code: e })
              }
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
            //   placeholder="descripcion"
              value={inputs.description}
              onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addSpecialty}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
