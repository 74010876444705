import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditFinalConditionForm from "../EditFinalConditionForm";
import AddFinalConditionForm from "../AddFinalConditionForm";
import { activateFinalConditionApi, deleteFinalConditionApi } from "../../../../api/finalCondition";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { getStudentsByFinalConditionApi } from "../../../../api/student";
import jwtDecode from "jwt-decode";

import "./ListFinalConditions.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListFinalConditions(props) {
  const {
    finalConditionsActive,
    finalConditionsInactive,
    setReloadFinalConditions,
  } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewFinalConditionsActives, setViewFinalConditionsActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [finalConditionsActiveFilter, setFinalConditionsActiveFilter] = useState(
    []
  );
  const [ finalConditionsInactiveFilter, setFinalConditionsInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado
  //console.log(finalConditionsActive);
  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (finalConditionsActive) {
      finalConditionsActive.forEach((element) => {
          if (
            element.description
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            element.finalCondition_code.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setFinalConditionsActiveFilter(listActive);
  }, [filterText,finalConditionsActive]);

  useEffect(() => {
    const listInactive = [];
    if (finalConditionsInactive) {
      finalConditionsInactive.forEach((element) => {
          if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            element.finalCondition_code.toString()
              .toUpperCase()
              .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setFinalConditionsInactiveFilter(listInactive);
  }, [filterText, finalConditionsInactive]);

  const addFinalConditionModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Condicion Final");
    setModalContent(
      <AddFinalConditionForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadFinalConditions={setReloadFinalConditions}
      />
    );
  };

  return (
    <div className="list-finalconditions">
      <div className="list-finalconditions__header">
        {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "finalconditions", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-finalconditions__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewFinalConditionsActives(!viewFinalConditionsActives)
                }
              />
              <span>
                {viewFinalConditionsActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
        checkAction(permissionsActive, userToken.role, "finalconditions", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addFinalConditionModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
      checkAction(permissionsActive, userToken.role, "finalconditions", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-finalconditions__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de condicion final ''
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewFinalConditionsActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "finalconditions", "view") || isAdmin(roleById) ? (
            <>
              <FinalConditionsActive
                finalConditionsActive={finalConditionsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadFinalConditions={setReloadFinalConditions}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "finalconditions", "view") || isAdmin(roleById) ? (
            <>
              <FinalConditionsInactive
                finalConditionsInactive={finalConditionsInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadFinalConditions={setReloadFinalConditions}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function FinalConditionsActive(props) {
  const {
    finalConditionsActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadFinalConditions,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editFinalCondition = (finalCondition) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${finalCondition.description ? finalCondition.description : "sin descripcion"}`
    );
    setModalContent(
      <EditFinalConditionForm
        finalCondition={finalCondition}
        setIsVisibleModal={setIsVisibleModal}
        setReloadFinalConditions={setReloadFinalConditions}
      />
    );
  };

  const desactivateFinalCondition = (finalCondition) => {
    activateFinalConditionApi(accessToken, finalCondition._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadFinalConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (finalCondition) => {
    let response = await getStudentsByFinalConditionApi(accessToken, finalCondition._id);
    if (response.students.length > 0) {
      confirm({
        title: "Eliminando -> Condición Final",
        content: `No se puede eliminar '${finalCondition.description}' porque existen estudiantes asociados ¿Deseas desactivarla?`,
        okText: "Desactivar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          desactivateFinalCondition(finalCondition);
        },
      });
    } else {
      confirm({
        title: "Eliminando -> Condición Final",
        content: `¿Estás seguro que quieres eliminar a ${finalCondition.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteFinalConditionApi(accessToken, finalCondition._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadFinalConditions(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const edit_finalCondition = (
    <div>
      {" "}
      <p>Editar Condición final</p>{" "}
    </div>
  );

  const desactivate_finalCondition = (
    <div>
      {" "}
      <p>Pasar a Condición final inactivos</p>{" "}
    </div>
  );

  const delete_finalCondition = (
    <div>
      {" "}
      <p>Eliminar Condición final</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "finalCondition_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, finalCondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "finalconditions", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_finalCondition}>
                <Button
                  className="button-edit"
                  onClick={() => editFinalCondition(finalCondition)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "finalconditions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_finalCondition}>
                <Button
                  className="button-finalcondition"
                  type="primary"
                  onClick={() => desactivateFinalCondition(finalCondition)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "finalconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_finalCondition}>
                <Button
                  className="button-finalcondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(finalCondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return finalConditionsActive.length > 0? `Total de registros: ${finalConditionsActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={finalConditionsActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function FinalConditionsInactive(props) {
  const { finalConditionsInactive,
          setReloadFinalConditions,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateFinalCondition= (finalCondition) => {
    activateFinalConditionApi(accessToken, finalCondition._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadFinalConditions(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (finalCondition) => {
    let response = await getStudentsByFinalConditionApi(accessToken, finalCondition._id);
    if (response.students.length > 0) {
      ModalAntd.info({
        title: "Eliminando -> Condición final",
        content:`No se puede eliminar '${finalCondition.description}' porque existen estudiantes asociados a él`,
        onOk(){},
      });
    } else {
      confirm({
        title: "Eliminando -> Condición final",
        content: `¿Estás seguro que quieres eliminar a ${finalCondition.description}?`,
        okText: "Eliminar",
        okMethod: "danger",
        cancelText: "Cancelar",
        onOk() {
          deleteFinalConditionApi(accessToken, finalCondition._id)
            .then((response) => {
              if (response.code === 200) {
                notification["success"]({
                  message: response.message,
                });
                setReloadFinalConditions(true);
              } else if (response.code === 404 || response.code === 500) {
                notification["error"]({
                  message: response.message,
                });
              } else if (
                response === "ERR_CONNECTION_REFUSED" ||
                response === "Failed to fetch" ||
                response === undefined
              ) {
                notification["error"]({
                  message: "Servidor caido",
                });
              } else {
                notification["error"]({
                  message: "Error del servidor",
                });
              }
            })
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });
        },
      });
    }
  };

  const activate_finalCondition = (
    <div>
      {" "}
      <p>Pasar a Condición final activos</p>{" "}
    </div>
  );

  const delete_finalCondition = (
    <div>
      {" "}
      <p>Eliminar Condición final</p>
    </div>
  );

  const columns = [
    {
      title: "Codigo",
      dataIndex: "finalCondition_code",
    },
    {
      title: "Descripcion",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, finalCondition) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "finalconditions", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_finalCondition}>
                <Button
                  className="button-activate"
                  onClick={() => activateFinalCondition(finalCondition)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "finalconditions", "all") ||
          checkAction(permissionsActive, userToken.role, "finalconditions", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_finalCondition}>
                <Button
                  className="button-finalcondition"
                  type="danger"
                  onClick={() => showDeleteConfirm(finalCondition)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return finalConditionsInactive.length > 0? `Total de registros: ${finalConditionsInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={finalConditionsInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
