import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { updateTypeCourseApi } from "../../../../api/typeCourse";
import { getAccessToken } from "../../../../api/auth";

import "./EditTypeCourseForm.scss";

export default function EditTypeCourseForm(props) {
  const { typeCourse, setIsVisibleModal, setReloadTypeCourses } = props;
  const [typeCourseData, setTypeCourseData] = useState({});

  //trae los datos de tipo de proveedor
  useEffect(() => {
    setTypeCourseData({
      typecourse_code: typeCourse.typecourse_code,
      description: typeCourse.description,
    });
  }, [typeCourse]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!typeCourseData.description) {
      notification["error"]({
        message: "Obligatorio: descripción.",
      });
      errorExists = true;
    } else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateTypeCourse = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let typeCourseUpdate = typeCourseData; //es let porque se va actualizando

      updateTypeCourseApi(token, typeCourseUpdate, typeCourse._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadTypeCourses(true);
          }
        }
      );
    }
  };

  return (
    <div className="edit-typecourse-form">
      <EditForm
        typeCourseData={typeCourseData}
        setTypeCourseData={setTypeCourseData}
        updateTypeCourse={updateTypeCourse}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    typeCourseData,
    setTypeCourseData,
    updateTypeCourse
  } = props;

  return (
    <Form className="form-edit" onSubmit={updateTypeCourse}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={typeCourseData.statecourse_code}
              onChange={(e) => setTypeCourseData({ ...typeCourseData, statecourse_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
            //   placeholder="descripción"
              value={typeCourseData.description}
              onChange={(e) =>
                setTypeCourseData({ ...typeCourseData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateTypeCourse}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
