import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  //HomeOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
  //SwapOutlined,
  //OrderedListOutlined,
  UnorderedListOutlined,
  //SkinOutlined,
  ExportOutlined,
  ProfileOutlined,
  //ContainerOutlined,
  //FileTextOutlined,
  AuditOutlined,
  ImportOutlined,
  TagsOutlined,
} from "@ant-design/icons"; //MenuOutlined, IdcardOutlined, ContactsOutlined,

//import { getMenuApi } from "../../../api/menu";
//import menu from "../../../utils/menu.json";
import { logout, getAccessToken } from '../../../api/auth';
import { getRoleByIdApi } from "../../../api/role";
import { getPermissionsActiveApi } from "../../../api/permission";
import {
  checkAction,
  isAdmin,
  //checkUserPermission,
  //checkUserPermission2,
  //checkPermission,
} from "../../../utils/checkUserPermission";
import jwtDecode from "jwt-decode";
import { menuIcons } from "../../../utils/icons";

import "./MenuSider.scss";

const { SubMenu } = Menu;

function MenuSider(props) {
  const { menuCollapsed, location, user, menu } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  //console.log(props);
  //console.log(location.pathname);
  const { Sider } = Layout;
  // const [menuData, setMenuData] = useState([]);
  const accessToken = getAccessToken();
  
  //try {
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //console.log("userToken.role", userToken.role);

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  return (

    <Sider
      width={240} collapsed={menuCollapsed} className="layout-init">
      <Menu
        theme="light"
        mode="inline"
        breakpoint="lg"
        inlineIndent={15}
        defaultSelectedKeys={[location.pathname]}
        style={{ width: menuCollapsed ? "5%" : "auto", position: "fixed", zIndex: 1, }}
        className="admin-sider"
      >
        {menu
          ? menu.map((item, i) => {
              if (item.active) {
                if (item.childs.length > 0) {
                  return checkAction(
                    permissionsActive,
                    userToken.role,
                    item.nameModule,
                    "all"
                  ) ||
                    checkAction(
                      permissionsActive,
                      userToken.role,
                      item.nameModule,
                      "menu-sider"
                    ) ||
                    isAdmin(roleById) ? (
                    <SubMenu
                      title={item.title}
                      icon={
                        menuIcons[item.nameModule] ? (
                          menuIcons[item.nameModule]
                        ) : (
                          <UnorderedListOutlined />
                        )
                      }
                    >
                      {item.childs
                        .sort((a, b) => a.order - b.order)
                        .map(function (subMenu, i) {
                          return checkAction(
                            permissionsActive,
                            userToken.role,
                            subMenu.nameModule,
                            "all"
                          ) ||
                            checkAction(
                              permissionsActive,
                              userToken.role,
                              subMenu.nameModule,
                              "menu-sider"
                            ) ||
                            isAdmin(roleById) ? (
                            <Menu.Item
                              key={subMenu.title}
                              className="menu-top-web__item"
                            >
                              <Link to={subMenu.url}>
                                {menuIcons[subMenu.nameModule] ? (
                                  menuIcons[subMenu.nameModule]
                                ) : (
                                  <ProfileOutlined />
                                )}
                                <span className="nac-text">
                                  {subMenu.title}
                                </span>
                              </Link>
                            </Menu.Item>
                          ) : null;
                        })}
                    </SubMenu>
                  ) : null;
                } else {
                  return checkAction(
                    permissionsActive,
                    userToken.role,
                    item.nameModule,
                    "all"
                  ) ||
                    checkAction(
                      permissionsActive,
                      userToken.role,
                      item.nameModule,
                      "menu-sider"
                    ) ||
                    isAdmin(roleById) ? (
                    <Menu.Item key={item.title} className="menu-top-web__item">
                      <Link to={item.url}>
                        {menuIcons[item.nameModule] ? (
                          menuIcons[item.nameModule]
                        ) : (
                          <ProfileOutlined />
                        )}
                        <span className="nac-text">{item.title}</span>
                      </Link>
                    </Menu.Item>
                  ) : null;
                }
              }
              return true;
            })
          : null}

        {/*
        {checkUserPermission(user.role, "route.admin") ? (
          <Menu.Item key="/admin">
            <Link to={"/admin"}>
              <HomeOutlined />
              <span className="nav-text">Home</span>
            </Link>
          </Menu.Item>
        ) : null}
        */}
        {checkAction(permissionsActive, userToken.role, "users", "all") ||
        checkAction(permissionsActive, userToken.role, "users", "menu-sider") ||
        isAdmin(roleById) ? (
          //checkPermission("component.users", permissionsActive, userToken.role, "users", "menu-sider") ? (
          //&& checkAction(permissionsActive, userToken.role, "users", "menu-sider")) || isAdmin(roleById)
          <Menu.Item key="/admin/users">
            <Link to={"/admin/users"}>
              <TeamOutlined />
              <span className="nac-text">{"Usuarios"}</span>
            </Link>
          </Menu.Item>
        ) : null}

        {/* <Menu.Item key="/admin/users">
          <Link to={"/admin/users"}>
            <TeamOutlined />
            <span className="nac-text">Usuarios</span>
          </Link>
        </Menu.Item> */}

        {/* PERSONAS */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "students",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "teachers",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "teachercollabs",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "advisers",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "persons-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/person/#"
            title={
              <>
                {" "}
                <TeamOutlined /> <span>Personas</span>
              </>
            }
          >
            {/*
            {checkAction(permissionsActive, userToken.role, "persons", "all") ||
            checkAction(
              permissionsActive,
              userToken.role,
              "persons",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/persons">
                <Link to={"/admin/persons"}>
                  <TeamOutlined />
                  <span className="nac-text">{"Personas"}</span>
                </Link>
              </Menu.Item>
            ) : null}
            */}

            {/* ESTUDIANTES */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <SubMenu
                key="/admin/student/#"
                title={
                  <>
                    {" "}
                    <TeamOutlined /> <span>Estudiantes</span>
                  </>
                }
              >
                {/* ESTUDIANTE */}
                {checkAction(
                  permissionsActive,
                  userToken.role,
                  "students",
                  "all"
                ) ||
                checkAction(
                  permissionsActive,
                  userToken.role,
                  "students",
                  "menu-sider"
                ) ||
                isAdmin(roleById) ? (
                    <Menu.Item key="/admin/students">
                      <Link to={"/admin/students"}>
                        <UserOutlined />
                        <span>Estudiante</span>
                      </Link>
                    </Menu.Item>
                ) : null}
                {/* CIERRA ESTUDIANTE */}

                {/* CONFIG. ESTUDIANTES */}
                <SubMenu
                  key="/admin/student/config"
                  title={
                    <>
                      {" "}
                      <UnorderedListOutlined /> <span>Config. estudiantes</span>
                    </>
                  }
                >
                  {/* ESPECIALIDADES */}
                  {checkAction(
                    permissionsActive,
                    userToken.role,
                    "specialties",
                    "all"
                  ) ||
                  checkAction(
                    permissionsActive,
                    userToken.role,
                    "specialties",
                    "menu-sider"
                  ) ||
                  isAdmin(roleById) ? (
                    <Menu.Item key="/admin/specialties">
                      <Link to={"/admin/specialties"}>
                        <ProfileOutlined />
                        <span className="nac-text">{"Especialidades"}</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* CIERRA ESPECIALIDADES */}

                  {/* CONDICION DE INGRESO */}
                  {checkAction(
                    permissionsActive,
                    userToken.role,
                    "entryconditions",
                    "all"
                  ) ||
                  checkAction(
                    permissionsActive,
                    userToken.role,
                    "entryconditions",
                    "menu-sider"
                  ) ||
                  isAdmin(roleById) ? (
                    <Menu.Item key="/admin/entryConditions">
                      <Link to={"/admin/entryConditions"}>
                        <ProfileOutlined />
                        <span className="nac-text">{"Condición de Ingreso"}</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* CIERRA CONDICION DE INGRESO */}

                  {/* CONDICION FINAL */}
                  {checkAction(
                    permissionsActive,
                    userToken.role,
                    "finalconditions",
                    "all"
                  ) ||
                  checkAction(
                    permissionsActive,
                    userToken.role,
                    "finalconditions",
                    "menu-sider"
                  ) ||
                  isAdmin(roleById) ? (
                    <Menu.Item key="/admin/finalConditions">
                      <Link to={"/admin/finalConditions"}>
                        <ProfileOutlined />
                        <span className="nac-text">{"Condición Final"}</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* CIERRA CONDICION FINAL */}

                  {/* TIPO DE DOCUMENTO */}
                  {checkAction(
                    permissionsActive,
                    userToken.role,
                    "docTypes",
                    "all"
                  ) ||
                  checkAction(
                    permissionsActive,
                    userToken.role,
                    "docTypes",
                    "menu-sider"
                  ) ||
                  isAdmin(roleById) ? (
                    <Menu.Item key="/admin/docTypes">
                      <Link to={"/admin/docTypes"}>
                        <ProfileOutlined />
                        <span className="nac-text">{"Tipo de documento"}</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* CIERRA TIPO DE DOCUMENTO */}

                  {/* CONDICION DEL ESTUDIANTE */}
                  {checkAction(
                    permissionsActive,
                    userToken.role,
                    "studentconditions",
                    "all"
                  ) ||
                  checkAction(
                    permissionsActive,
                    userToken.role,
                    "studentconditions",
                    "menu-sider"
                  ) ||
                  isAdmin(roleById) ? (
                    <Menu.Item key="/admin/studentConditions">
                      <Link to={"/admin/studentConditions"}>
                        <ProfileOutlined />
                        <span className="nac-text">{"Condición del Estudiante"}</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* CIERRA CONDICION DEL ESTUDIANTE */}
                  {/* ESTADO ESTUDIANTE CURSO */}
                  {checkAction(
                    permissionsActive,
                    userToken.role,
                    "statestudentcourses",
                    "all"
                  ) ||
                  checkAction(
                    permissionsActive,
                    userToken.role,
                    "statestudentcourses",
                    "menu-sider"
                  ) ||
                  isAdmin(roleById) ? (
                    <Menu.Item key="/admin/stateStudentCourses">
                      <Link to={"/admin/stateStudentCourses"}>
                        <ProfileOutlined />
                        <span className="nac-text">Estados Estudiante Curso</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* CIERRA ESTADO ESTUDIANTE CURSO */}
              </SubMenu>
              {/* CIERRA CONFIG. ESTUDIANTE */}
            </SubMenu>
            ) : null}
            {/* CIERRA ESTUDIANTES */}

          {/* CIERRA ESTUDIANTES */}

            {/* DOCENTES */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "teachers",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "teachers",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <SubMenu
                key="/admin/teacher/#"
                title={
                  <>
                    {" "}
                    <TeamOutlined /> <span>Docentes</span>
                  </>
                }
              >
                {checkAction(
                  permissionsActive,
                  userToken.role,
                  "teachers",
                  "all"
                ) ||
                checkAction(
                  permissionsActive,
                  userToken.role,
                  "teachers",
                  "menu-sider"
                ) ||
                isAdmin(roleById) ? (
                  <Menu.Item key="/admin/teachers">
                    <Link to={"/admin/teachers"}>
                      <UserOutlined />
                      <span className="nac-text">{"Docente"}</span>
                    </Link>
                  </Menu.Item>
                ) : null}
              </SubMenu>
            ) : null}
            {/* CIERRA DOCENTES */}

            {/* COLABORADOR DE DOCENTES */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "teachercollabs",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "teachercollabs",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <SubMenu
                key="/admin/teachercollabs/#"
                title={
                  <>
                    {" "}
                    <TeamOutlined /> <span>Colaboradores Docente</span>
                  </>
                }
              >
                {checkAction(
                  permissionsActive,
                  userToken.role,
                  "teachercollabs",
                  "all"
                ) ||
                checkAction(
                  permissionsActive,
                  userToken.role,
                  "teachercollabs",
                  "menu-sider"
                ) ||
                isAdmin(roleById) ? (
                  <Menu.Item key="/admin/teachercollabs">
                    <Link to={"/admin/teachercollabs"}>
                      <UserOutlined />
                      <span className="nac-text">{"Colaborador Docente"}</span>
                    </Link>
                  </Menu.Item>
                ) : null}
              </SubMenu>
            ) : null}
            {/* CIERRA COLABORADOR DE DOCENTES */}

            {/* CONSEJEROS */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "advisers",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "advisers",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <SubMenu
                key="/admin/adviser/#"
                title={
                  <>
                    {" "}
                    <TeamOutlined /> <span>Consejeros</span>
                  </>
                }
              >
                {checkAction(
                  permissionsActive,
                  userToken.role,
                  "advisers",
                  "all"
                ) ||
                checkAction(
                  permissionsActive,
                  userToken.role,
                  "advisers",
                  "menu-sider"
                ) ||
                isAdmin(roleById) ? (
                  //checkPermission("component.users", permissionsActive, userToken.role, "users", "menu-sider") ? (
                  //&& checkAction(permissionsActive, userToken.role, "users", "menu-sider")) || isAdmin(roleById)
                  <Menu.Item key="/admin/advisers">
                    <Link to={"/admin/advisers"}>
                      <UserOutlined />
                      <span className="nac-text">{"Consejero"}</span>
                    </Link>
                  </Menu.Item>
                ) : null}
              </SubMenu>
            ) : null}
            {/* CIERRA CONSEJEROS */}
          </SubMenu>
          ) : null}
          {/* CIERRA PERSONAS */}

        {/* CURSOS */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "courses",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "stateCourses",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "typeCourses",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "departments",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "places",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "durations",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "workloads",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "schoolyears",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "stateschoolyears",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "statestudentcourses",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "courses-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/course/#"
            title={
              <>
                {" "}
                <UnorderedListOutlined /> <span>Cursos</span>
              </>
            }
          >
            {/* CURSOS
            {checkAction(
              permissionsActive,
              userToken.role,
              "concepts",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "concepts",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/concepts">
                <Link to={"/admin/concepts"}>
                  <AuditOutlined />
                  <span className="nac-text">Conceptos</span>
                </Link>
              </Menu.Item>
            ) : null}

            */}

            {/* CURSO */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "courses",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "courses",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/courses">
                <Link to={"/admin/courses"}>
                  <AuditOutlined />
                  <span className="nac-text">Curso</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA CURSO */}

            {/* INSCRIPCIONES */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "inscriptions",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "inscriptions",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/inscriptions">
                <Link to={"/admin/inscriptions"}>
                  <AuditOutlined />
                  <span className="nac-text">Inscripciones</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA INSCRIPCIONES */}

            {/* SUBMENÚ CONFIG. CURSOS */}
            <SubMenu
              key="/admin/course/config"
              title={
                <>
                  {" "}
                  <UnorderedListOutlined /> <span>Config. cursos</span>
                </>
              }
            >

              {/* ESTADOS DE CURSOS */}
              {checkAction(
                permissionsActive,
                userToken.role,
                "statecourses",
                "all"
              ) ||
              checkAction(
                permissionsActive,
                userToken.role,
                "statecourses",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/stateCourses">
                  <Link to={"/admin/stateCourses"}>
                    <AuditOutlined />
                    <span className="nac-text">Estados de cursos</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* CIERRA ESTADOS DE CURSOS */}

              {/* INASISTENCIAS */}
              {checkAction(
                permissionsActive,
                userToken.role,
                "absences",
                "all"
              ) ||
              checkAction(
                permissionsActive,
                userToken.role,
                "absences",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/absences">
                  <Link to={"/admin/absences"}>
                    <AuditOutlined />
                    <span className="nac-text">Inasistencias</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* CIERRA INASISTENCIAS */}

              {/* TIPOS DE CURSOS */}
              {checkAction(
                permissionsActive,
                userToken.role,
                "typeCourses",
                "all"
              ) ||
              checkAction(
                permissionsActive,
                userToken.role,
                "typeCourses",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/typeCourses">
                  <Link to={"/admin/typeCourses"}>
                    <AuditOutlined />
                    <span className="nac-text">Tipos de cursos</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* CIERRA TIPOS DE CURSOS */}

              {/* DEPARTAMENTOS */}
              {checkAction(
                permissionsActive,
                userToken.role,
                "departments",
                "all"
              ) ||
              checkAction(
                permissionsActive,
                userToken.role,
                "departments",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/departments">
                  <Link to={"/admin/departments"}>
                    <AuditOutlined />
                    <span className="nac-text">Departamentos</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* CIERRA DEPARTAMENTOS */}

              {/* LUGARES */}
              {checkAction(
                permissionsActive,
                userToken.role,
                "places",
                "all"
              ) ||
              checkAction(
                permissionsActive,
                userToken.role,
                "places",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/places">
                  <Link to={"/admin/places"}>
                    <AuditOutlined />
                    <span className="nac-text">Lugares</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* CIERRA LUGARES */}

              {/* DURACION */}
              {checkAction(
                permissionsActive,
                userToken.role,
                "durations",
                "all") ||
              checkAction(
                permissionsActive,
                userToken.role,
                "durations",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/durations">
                  <Link to={"/admin/durations"}>
                    <AuditOutlined />
                    <span className="nac-text">Duración</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* CIERRA DURACIÓN */}

              {/* CARGA HORARIA */}
              {checkAction(
                permissionsActive,
                userToken.role,
                "workloads",
                "all"
              ) ||
              checkAction(
                permissionsActive,
                userToken.role,
                "workloads",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/workloads">
                  <Link to={"/admin/workloads"}>
                    <AuditOutlined />
                    <span className="nac-text">Carga Horaria</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* CIERRA CARGA HORARIA */}

              {/* AÑOS LECTIVOS */}
              {checkAction(
                permissionsActive,
                userToken.role,
                "schoolyears",
                "all"
              ) ||
              checkAction(
                permissionsActive,
                userToken.role,
                "schoolyears",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/config/schoolYears">
                  <Link to={"/admin/config/schoolYears"}>
                    <ProfileOutlined />
                    <span className="nac-text">Años lectivos</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* CIERRA AÑOS LECTIVOS */}

              {/* ESTADO AÑOS LECTIVOS */}
              {checkAction(
                permissionsActive,
                userToken.role,
                "stateschoolyears",
                "all"
              ) ||
              checkAction(
                permissionsActive,
                userToken.role,
                "stateschoolyears",
                "menu-sider"
              ) ||
              isAdmin(roleById) ? (
                <Menu.Item key="/admin/config/stateSchoolYears">
                  <Link to={"/admin/config/stateSchoolYears"}>
                    <ProfileOutlined />
                    <span className="nac-text">Estados Año lectivo</span>
                  </Link>
                </Menu.Item>
              ) : null}
              {/* CIERRA ESTADO AÑOS LECTIVOS */}

            </SubMenu>
            {/* CIERRA CONFIG. CURSOS */}
          </SubMenu>
        ) : null}
        {/* CIERRA CURSOS */}


        {/* CONFIGURACIÓN */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "roles",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "permissions",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "days",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "weeks",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "config-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/config/#"
            title={
              <>
                {" "}
                <SettingOutlined /> <span>Configuración</span>
              </>
            }
          >
            {/* ROLES */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "roles",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "roles",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/config/roles">
                <Link to={"/admin/config/roles"}>
                  <ProfileOutlined />
                  <span className="nac-text">Roles</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA ROLES */}

            {/* PERMISOS */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "permissions",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "permissions",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/config/permissions">
                <Link to={"/admin/config/permissions"}>
                  <ProfileOutlined />
                  <span className="nac-text">Permisos</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA PERMISOS */}

            {/* DIAS */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "days",
              "all"
              ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "days",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/config/days">
                <Link to={"/admin/config/days"}>
                  <ProfileOutlined />
                  <span className="nac-text">Días</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA DIAS */}

            {/* SEMANAS */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "weeks", "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "weeks",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/config/weeks">
                <Link to={"/admin/config/weeks"}>
                  <ProfileOutlined />
                  <span className="nac-text">Semanas</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA SEMANAS */}
          </SubMenu>
        ) : null}
        {/* CIERRA CONFIGURACION */}

        {/* REPORTES */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "roles",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "permissions",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "days",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "weeks",
          "all"
        ) ||
        checkAction(
          permissionsActive,
          userToken.role,
          "config-menu",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <SubMenu
            key="/admin/reports/#"
            title={
              <>
                {" "}
                <TagsOutlined /> <span>Reportes</span>
              </>
            }
          >
            {/* DOCENTES */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "teachers",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "teachers",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/reports/teachers">
                <Link to={"/admin/reports/teachers"}>
                  <ProfileOutlined />
                  <span className="nac-text">Docentes</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA DOCENTES */}

            {/* COLABORADORES */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "permissions",
              "all"
            ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "permissions",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/reports/teachercollabs">
                <Link to={"/admin/reports/teachercollabs"}>
                  <ProfileOutlined />
                  <span className="nac-text">Colaboradores</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA COLABORADORES */}

            {/* ESTUDIANTES */}
            {checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "all"
              ) ||
            checkAction(
              permissionsActive,
              userToken.role,
              "students",
              "menu-sider"
            ) ||
            isAdmin(roleById) ? (
              <Menu.Item key="/admin/reports/students">
                <Link to={"/admin/reports/students"}>
                  <ProfileOutlined />
                  <span className="nac-text">Estudiantes</span>
                </Link>
              </Menu.Item>
            ) : null}
            {/* CIERRA ESTUDIANTES */}
          </SubMenu>
        ) : null}
        {/* CIERRA REPORTES */}

        {/* EXPORTAR */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "exports",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <Menu.Item key="/admin/exports">
            <Link to={"/admin/exports"}>
              <ExportOutlined />
              <span className="nac-text">Exportar</span>
            </Link>
          </Menu.Item>
        ) : null}
        {/* CIERRA EXPORTAR */}

        {/* IMPORTAR */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "imports",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <Menu.Item key="/admin/imports">
            <Link to={"/admin/imports"}>
              <ImportOutlined />
              <span className="nac-text">Importar</span>
            </Link>
          </Menu.Item>
        ) : null}
        {/* CIERRA IMPORTAR */}

        {/* EDITAR MENÚ */}
        {checkAction(
          permissionsActive,
          userToken.role,
          "editmenues",
          "menu-sider"
        ) || isAdmin(roleById) ? (
          <Menu.Item key="/admin/editmenu">
            <Link to={"/admin/editmenu"}>
              <SettingOutlined />
              <span className="nac-text">Editar menu</span>
            </Link>
          </Menu.Item>
        ) : null}
        {/* CIERRA EDITAR MENÚ */}
      </Menu>
    </Sider>
  );
    /*
  } catch (error) {
    console.log(error);
    console.error("Error al cargar el usuario:", error.message);
    logout(); //borra los token del localStorage
    //ahora se puede hacer un redirect o windows reload
    window.location.reload();
  }
  */
}

export default withRouter(MenuSider);
