import React, { useState, useEffect } from "react";
import ReactExport from "@ibrahimrahmani/react-export-excel";
import { useHistory } from "react-router-dom";
import {
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  Space,
  BackTop,
} from "antd";
import {
  DeleteOutlined,
  //DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import { Popover } from "antd";

import Modal from "../../../Modal";
import AddStudentsInscriptionForm from "../AddStudentsInscriptionForm";
import { deleteStudentByInscriptionIdApi, getInscriptionsActiveApi, getInscriptionsByStudentApi } from "../../../../api/inscription";
// import { getCourseApi } from "../../../../api/course";
// import { getCountAbsenceByStudentIdApi } from "../../../../api/absence";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import {
  getAbsencesByCourseApi,
  // getAbsencesByStudentApi,
} from "../../../../api/absence";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin, checkInscriptions } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { hasCoursesStudentApi } from "../../../../api/student";
import jwtDecode from "jwt-decode";
import {listStudentsCoursePdf} from "./pdfStudentsCourse";
import { updateOwnHoursByStudentIdApi } from "../../../../api/student";
import { getCourseApi } from "../../../../api/course";

import "./ListStudentsCourse.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListStudentsCourse(props) {
  const {inscription, courseID, setReloadInscriptionCourse } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  const [inscriptionData, setInscriptionData] = useState([]);
  // const [listStudents, setListStudents] = useState([]);
  // const [viewStudentsCourseActives, setViewStudentsCourseActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  // const [studentsFilter, setStudentsFilter] = useState([]);
  const [studentsActiveFilter, setstudentsActiveFilter] = useState([]);
  const [inscriptionActiveCourses, setInscriptionActiveCourses] = useState([]);
  const [listAbsencesCourse, setListAbsencesCourse] = useState([]);
  const [listStateCoursesActive, setListStateCoursesActive]=useState([]);
  const [courseFinish, setCourseFinish]=useState(false);
  //const [absencesFilter, setAbsencesFilter] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //const { courseId } = props.location.state ? props.location.state : false;
  //console.log("courseID", courseID); //uso el id del curso seleccionado

  //para VOLVER HACIA ATRAS
  const history = useHistory();

  //EXCEL
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const pdf_download = (
    <div>
      Descargar PDF
    </div>
  );

  const xls_download = (
    <div>
      Descargar Excel
    </div>
  );

  // console.log("userToken", userToken);
  // console.log(useRouteProps());

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  // useEffect(() => {
  //   getInscriptionsActiveApi(accessToken, true).then(response => {
  //     setInscriptionActiveCourses(response.inscriptions);
  //   });
  // }, []);

  useEffect(() => {
    let isMounted=true;
    getCourseApi(accessToken, courseID).then(async responseCourse=>{
        let course=responseCourse.course? responseCourse.course : null;
        let listStateResponse= await getStateCoursesActiveApi(accessToken, true);
        let listState=listStateResponse.stateCourses? listStateResponse.stateCourses : [];
        let stateCourseFinish=listState.filter(i=>i.description.toLowerCase() === "finalizado");
        if(stateCourseFinish!== undefined && stateCourseFinish.length > 0 && course!== null 
            && course.stateCourse && course.stateCourse === stateCourseFinish[0]._id){
            if(isMounted){
              setCourseFinish(true);
            }
        }
        else{
          setCourseFinish(false);
        }
      });
    return ()=>{
      isMounted=false;
    }
  }, [courseID]);

  useEffect(() => {
    if(inscription)
    {
      setInscriptionData({
        _id:inscription._id,
        course:inscription.course,
        students:inscription.students
      });
    }
  }, [inscription]);

  //console.log("inscription", inscription);
  //console.log("course", inscription.course);
  //console.log("absencesAllowed", inscription.course.absencesAllowed);

  useEffect(() => {
    getAbsencesByCourseApi(accessToken, courseID).then(response => {
      setListAbsencesCourse(response.absences);
    });
  }, []);

  //console.log("listAbsencesCourse", listAbsencesCourse);

  useEffect(() => {
    const list = [];
    if (inscriptionData.students) {
      inscriptionData.students.forEach((element) => {
        if(element.student && element.student!==null){
          if (
            (element.student.name &&
            element.student.name
              .toUpperCase().includes(filterText.toUpperCase())) ||
            (element.student.lastname && element.student.lastname
              .toUpperCase().includes(filterText.toUpperCase())) ||
            filterText === ""
          ) {
            list.push(element);
          }
        }
      });
    }
    setstudentsActiveFilter(list);
  }, [filterText, inscriptionData]);

  //console.log("inscriptionData", inscriptionData);
  //console.log("course", inscriptionData.course._id);

  const addStudentCourseModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar -> Estudiantes");
    setModalWidth(1500);
    setModalContent(
      <AddStudentsInscriptionForm
        inscription={inscriptionData}
        setIsVisibleModal={setIsVisibleModal}
        //setReloadInscriptions={setReloadInscriptionCourse}
        setReloadInscriptionCourses={setReloadInscriptionCourse}
      />
    );
  };

  /*
  const getAbsenceCourse = (courseID) => {
    let result = listAbsencesCourses.filter((i) => i.course === courseID);
    console.log("result", result);
    if (result !== undefined && result.length > 0) {
      let count = 0;
      result.map((absence) => {
        absence.students.map((item) => {
          if (item.student._id) { //=== studentData._id
            if (item.justify !== undefined && item.justify === false) {
              count++;
            }
          }
          return null; //retorno algo por el warning
        });
        return null; //retorno algo por el warning
      });
      return count;
    } else {
      return null;
    }
  };
  */

  const getAbsenceCourse = (studentData) => {
    if (listAbsencesCourse !== undefined && listAbsencesCourse.length > 0) {
      let count = 0;
      listAbsencesCourse.map((absence) => {
        absence.students.map((item) => {
          if (item.student._id === studentData._id) {
            if (item.justify !== undefined && item.justify === false) {
              count++;
            }
          }
          return null; //retorno algo por el warning
        });
        return null; //retorno algo por el warning
      });
      return count;
    } else {
      return null;
    }
  };

  //setAbsencesFilter(getAbsenceCourse(courseID));
  //console.log("absencesFilter", absencesFilter);

  const setHasNoCoursesStudent = (student) => {
    //const accessToken = getAccessToken();
    hasCoursesStudentApi(accessToken, student._id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        //setReloadStudents(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const deleteStudentCourse = (data) => {
    const token = getAccessToken();
    deleteStudentByInscriptionIdApi(token, data, inscription._id).then(async(result) => {
      //console.log("data.student", data.student)
      if (
        result.message === "ERR_CONNECTION_REFUSED" ||
        result.message === "Failed to fetch" ||
        result.message === undefined
      ) {
        notification["error"]({
          message: "Servidor caido",
        });
        setIsVisibleModal(true);
      } else if (result.code !== 200) {
        notification["error"]({
          message: result.message,
        });
        setIsVisibleModal(true);
      } else {
        notification["success"]({
          message: result.message, //el mensaje que viene del server
        });
        // setIsVisibleModal(false);
        // setReloadInscriptionCourse(true);
        //verifico si el curso esta finalizado para actualizar
        if(courseFinish){
          notification["info"]({
            message: "El curso se encuentra finalizado, se actualizará la cantidad de horas del estudiante",
            duration:6
          });
          let updateResult = await updateOwnHoursByStudentIdApi(token, data.student._id);
          if(updateResult.code===200){
            notification["success"]({
              message: "Actualización de las horas del estudiante correcta",
              duration:5
            });
            setIsVisibleModal(false);
          }else{
            notification["warning"]({
              message: "Error al actualizar las horas del estudiante",
              duration:5
            });
             setIsVisibleModal(false);
          }
        }
        //si no tiene mas inscripciones
        let inscriptionsActive=await getInscriptionsByStudentApi(token,data.student._id);
        if (inscriptionsActive && inscriptionsActive.inscriptions && inscriptionsActive.inscriptions.length === 0){
        // if (checkInscriptions(inscriptionActiveCourses, data.student._id) === false){
          // console.log("no tiene cursos, pasa a false", data.student);
          setHasNoCoursesStudent(data.student); //seteo hasCourses a false
        } else {
          // console.log("tiene cursos", data.student);
        }
        setReloadInscriptionCourse(true);
        setIsVisibleModal(false);
        //console.log("studentData", studentData);
      }
    });
  };

  //EXCEL
  let inscripStudents = [];
  let courseName = "";

  if (inscriptionData.students != null){
    inscriptionData.students.map((item) => {
      //console.log(item.student);
      //calculo inasistencias de cada estudiante
      item.student.absences=getAbsenceCourse(item.student);
      return inscripStudents.push(item.student); //le pongo return para evitar un warning
      //return true;
    });
    //console.log(inscripStudents);
    inscripStudents.sort((a,b)=>{
      return a.lastname.localeCompare(b.lastname) ||
      a.name.localeCompare(b.name);
    });
  }
  if (inscriptionData.course){
    courseName = inscriptionData.course.name;
  }

  return (
    <div className="list-inscriptions">
      <div className="list-inscriptions__header">
        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
        checkAction(permissionsActive, userToken.role, "courses", "enroll") || isAdmin(roleById) ? (
          <>
            {/*VOLVER HACIA ATRAS*/}
            <Button type="primary" icon={<ArrowLeftOutlined/>} onClick={() => history.goBack()}>Atrás</Button>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addStudentCourseModal}>
              Agregar Estudiantes
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "courses", "all") ||
      checkAction(permissionsActive, userToken.role, "courses", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-inscriptions__search">
            <Search
              className="Buscar"
              allowClear
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            {/* <h3>
              <strong>Info:</strong>
            </h3> */}
          </div>
        </>
        ) : (
          <>
          </>
      )}
        <>

        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "pdf") || isAdmin(roleById) ? (
            <>
              <Popover content={pdf_download}>
                <Space align="center" style={{ marginBottom: 16 }}>
                  <Button
                    type="default"
                    shape="round"
                    onClick={() => {
                      listStudentsCoursePdf(inscriptionData.students, inscriptionData.course.name,false);
                    }}
                  >
                    <FilePdfOutlined />
                  </Button>
                </Space>
              </Popover>
            </>

          ) : (
            <>
            </>
          )}

        <>
          &nbsp;
        </>

        {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <ExcelFile filename={'ListadoEstudiantes-'+courseName} element={
                <Popover content={xls_download}>
                  <Button
                    type="default"
                    shape="round"
                    >
                    <FileExcelOutlined />
                  </Button>
                </Popover>
                }>
                <ExcelSheet data={inscripStudents ? inscripStudents: null} name="Estudiantes">
                  <ExcelColumn label="Apellido" value="lastname" />
                  <ExcelColumn label="Nombre" value="name" />
                  <ExcelColumn label="Correo" value="email" />
                  <ExcelColumn label="Faltas" value="absences" />
                </ExcelSheet>
              </ExcelFile>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "view") || isAdmin(roleById) ? (
            <>
              <Students
                students={studentsActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadInscriptionCourse={setReloadInscriptionCourse}
                setModalWidth={setModalWidth}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
                deleteStudentCourse={deleteStudentCourse}
                //listAbsences={listAbsencesCourse}
                getAbsenceCourse={getAbsenceCourse}
                //showDeleteConfirm={showDeleteConfirm}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop/>

    </div>
  );
}

function Students(props) {
  const { students, deleteStudentCourse, userToken, permissionsActive, roleById, getAbsenceCourse } = props; //showDeleteConfirm

  const showDeleteConfirm = (student) => {
    //const accessToken = getAccessToken();

    confirm({
      title: "Eliminando -> Estudiante del Curso",
      content: `¿Estás seguro que quieres eliminar a ${student.name ? student.name : "estudiante"} del curso?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteStudentCourse(student);
      },
    });
  };

  // const edit_studentCourse = (
  //   <div>
  //     {" "}
  //     <p>Editar inscripción</p>{" "}
  //   </div>
  // );

  // const desactivate_studentCourse = (
  //   <div>
  //     {" "}
  //     <p>Pasar a estudiante inactivo</p>{" "}
  //   </div>
  // );

  const delete_studentCourse = (
    <div>
      Eliminar inscripcion
    </div>
  );

  const columns = [
    // {
    //   title: "Código",
    //   dataIndex: "student",
    //   render:(student)=>student? student.student_code : null,
    // },
    {
      title: "Apellido",
      dataIndex: "student",
      render:(student)=>student? student.lastname : null,
      sorter:(a,b)=> a.student.lastname.localeCompare(b.student.lastname) ,
      defaultSortOrder:"ascend"
    },
    {
      title: "Nombre",
      dataIndex: "student",
      render:(student)=>student? student.name : null,
      sorter:(a,b)=> a.student.name.localeCompare(b.student.name) ,
      defaultSortOrder:"ascend",
      width:"20%"
    },
    {
      title: "E-mail",
      dataIndex: "student",
      render:(student)=>student? student.email : null,
      with:"20%"
    },
    // {
    //   title:"Inasistencias",
    //   dataIndex:"absences",
    //   // render:(_id,row)=>{countAbsence(_id)}
    // },
    {
      title: "Faltas",
      dataIndex: "student",
      render: (student) => (getAbsenceCourse(student) ? getAbsenceCourse(student) !== null? getAbsenceCourse(student) : "-" : "-"),
      width: "15%",
    },  
    // {
    //   title: "Estado del Estudiante",
    //   dataIndex: "stateStudentCourse",
    //   render: (stateStudentCourse) => {return stateStudentCourse? stateStudentCourse.description? stateStudentCourse.description : null : null},
    //   width: "18%",
    // },
    {
      title: "Acción",
      key: "action",
      render: (text, student) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "courses", "all") ||
          checkAction(permissionsActive, userToken.role, "courses", "enroll") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_studentCourse}>
                <Button
                  className="button-inscription"
                  type="danger"
                  onClick={() => showDeleteConfirm(student)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  // const columnsFinal= students.some(item=>item.hasOwnProperty("stateStudentCourse"))? 
  // columns : columns.filter(col=>col.dataIndex !== "stateStudentCourse");

  return (
    <Table
      footer={()=>{return students.length > 0? `Total de registros: ${students.length}` : false}}
      pagination={{
        pageSize:50,
        showSizeChanger:false
      }}
      dataSource={students}
      columns={columns}
      rowKey={student => student._id}
    />
  );
}