import React, { useState, useEffect } from "react";
import {
  Switch,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  BackTop
} from "antd";
import {
  EditOutlined,
  StopOutlined,
  DeleteOutlined,
  CheckOutlined,
  PlusOutlined
} from "@ant-design/icons";

import { Popover } from "antd";
import Modal from "../../../Modal";
import EditStateStudentCourseForm from "../EditStateStudentCourseForm";
import AddStateStudentCourseForm from "../AddStateStudentCourseForm";
import { activateStateStudentCourseApi, deleteStateStudentCourseApi } from "../../../../api/stateStudentCourse";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import jwtDecode from "jwt-decode";

import "./ListStateStudentCourses.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListStateStudentCourses(props) {
  const { stateStudentCoursesActive, stateStudentCoursesInactive, setReloadStateStudentCourses } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);
  const [viewStateStudentCoursesActives, setViewStateStudentCoursesActives] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const [filterText, setFilterText] = useState("");
  const [stateStudentCoursesActiveFilter, setStateStudentCoursesActiveFilter] = useState(
    []
  );
  const [ stateStudentCoursesInactiveFilter, setStateStudentCoursesInactiveFilter ] = useState([]);

  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then(response => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then(response => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const listActive = [];
    if (stateStudentCoursesActive) {
      stateStudentCoursesActive.forEach((element) => {
          if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.statestudentcourse_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listActive.push(element);
          }
      });
    }
    setStateStudentCoursesActiveFilter(listActive);
  }, [filterText, stateStudentCoursesActive]);

  useEffect(() => {
    const listInactive = [];
    if (stateStudentCoursesInactive) {
      stateStudentCoursesInactive.forEach((element) => {
          if (
            element.description
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
          element.statestudentcourse_code.toString()
            .toUpperCase()
            .includes(filterText.toUpperCase()) ||
            filterText === ""
          ) {
            listInactive.push(element);
          }
      });
    }
    setStateStudentCoursesInactiveFilter(listInactive);
  }, [filterText, stateStudentCoursesInactive]);

  const addStateStudentCourseModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Crear Estado");
    setModalContent(
      <AddStateStudentCourseForm
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateStudentCourses={setReloadStateStudentCourses}
      />
    );
  };

  return (
    <div className="list-statestudentcourses">
      <div className="list-statestudentcourses__header">
        {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
        checkAction(permissionsActive, userToken.role, "statestudentcourses", "enable") || isAdmin(roleById) ? (
          <>
            <div className="list-statestudentcourses__header-switch">
              <Switch
                defaultChecked
                onChange={() =>
                  setViewStateStudentCoursesActives(!viewStateStudentCoursesActives)
                }
              />
              <span>
                {viewStateStudentCoursesActives
                  ? "Activos"
                  : "Inactivos"}
              </span>
            </div>
          </>
          ) : (
            <>
            </>
        )}

        {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
        checkAction(permissionsActive, userToken.role, "statestudentcourses", "add") || isAdmin(roleById) ? (
          <>
            <Button type="primary" icon={<PlusOutlined/>} onClick={addStateStudentCourseModal}>
              Nuevo
            </Button>
          </>
        ) : (
          <>
          </>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
      checkAction(permissionsActive, userToken.role, "statestudentcourses", "view") || isAdmin(roleById) ? (
        <>
          <div className="list-statestudentcourses__search">
            <Search
              className="Buscar"
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
            <h2>

            </h2>
            <h3>
              <strong>Info:</strong> ejemplos de estados 
            </h3>
          </div>
        </>
      ) : (
        <>
        </>
      )}

      {viewStateStudentCoursesActives ? (
        <>
          {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statestudentcourses", "view") || isAdmin(roleById) ? (
            <>
              <StateStudentCoursesActive
                stateStudentCoursesActive={stateStudentCoursesActiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReloadStateStudentCourses={setReloadStateStudentCourses}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      ) : (
        <>
          {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statestudentcourses", "view") || isAdmin(roleById) ? (
            <>
              <StateStudentCoursesInactive
                stateStudentCoursesInactive={stateStudentCoursesInactiveFilter}
                setIsVisibleModal={setIsVisibleModal}
                setReloadStateStudentCourses={setReloadStateStudentCourses}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                accessToken={accessToken}
                userToken={userToken}
                permissionsActive={permissionsActive}
                roleById={roleById}
              />
            </>
          ) : (
            <>
            </>
          )}
        </>
      )}

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={800}
      >
        {modalContent}
      </Modal>

      <BackTop/>
    </div>
  );
}

function StateStudentCoursesActive(props) {
  const {
    stateStudentCoursesActive,
    setIsVisibleModal,
    setModalTitle,
    setModalContent,
    setReloadStateStudentCourses,
    accessToken,
    userToken,
    permissionsActive,
    roleById
  } = props;

  const editStateStudentCourse = (stateStudentCourse) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${stateStudentCourse.description ? stateStudentCourse.description : "sin nombre"}`
    );
    setModalContent(
      <EditStateStudentCourseForm
        stateStudentCourse={stateStudentCourse}
        setIsVisibleModal={setIsVisibleModal}
        setReloadStateStudentCourses={setReloadStateStudentCourses}
      />
    );
  };

  const desactivateStateStudentCourse = (stateStudentCourse) => {
    activateStateStudentCourseApi(accessToken, stateStudentCourse._id, false)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateStudentCourses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (stateStudentCourse) => {
    confirm({
      title: "Eliminando Estado",
      content: `¿Estás seguro que quieres eliminar a ${stateStudentCourse.description}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteStateStudentCourseApi(accessToken, stateStudentCourse._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadStateStudentCourses(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const edit_statestudentcourse = (
    <div>
      {" "}
      <p>Editar Estado</p>{" "}
    </div>
  );

  const desactivate_statestudentcourse = (
    <div>
      {" "}
      <p>Pasar a Estados inactivos</p>{" "}
    </div>
  );

  const delete_statestudentcourse = (
    <div>
      {" "}
      <p>Eliminar Estado</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "statestudentcourse_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, stateStudentCourse) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statestudentcourses", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={edit_statestudentcourse}>
                <Button
                  className="button-edit"
                  onClick={() => editStateStudentCourse(stateStudentCourse)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statestudentcourses", "enable") || isAdmin(roleById) ? (
            <>
              <Popover content={desactivate_statestudentcourse}>
                <Button
                  className="button-statecourse"
                  type="primary"
                  onClick={() => desactivateStateStudentCourse(stateStudentCourse)}
                >
                  <StopOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statestudentcourses", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_statestudentcourse}>
                <Button
                  className="button-statestudentcourse"
                  type="danger"
                  onClick={() => showDeleteConfirm(stateStudentCourse)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return stateStudentCoursesActive.length > 0? `Total de registros: ${stateStudentCoursesActive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={stateStudentCoursesActive}
      columns={columns}
      rowKey="_id"
    />
  );
}

function StateStudentCoursesInactive(props) {
  const { stateStudentCoursesInactive,
          setReloadStateStudentCourses,
          accessToken,
          userToken,
          permissionsActive,
          roleById
        } = props;

  const activateStateStudentCourse = (stateStudentCourse) => {
    activateStateStudentCourseApi(accessToken, stateStudentCourse._id, true)
      .then((response) => {
        notification["success"]({
          message: response.message,
        });
        setReloadStateStudentCourses(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  const showDeleteConfirm = async (stateStudentCourse) => {
    confirm({
      title: "Eliminando Estado",
      content: `¿Estás seguro que quieres eliminar a ${stateStudentCourse.description}?`,
      okText: "Eliminar",
      okMethod: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteStateStudentCourseApi(accessToken, stateStudentCourse._id)
          .then((response) => {
            if (response.code === 200) {
              notification["success"]({
                message: response.message,
              });
              setReloadStateStudentCourses(true);
            } else if (response.code === 404 || response.code === 500) {
              notification["error"]({
                message: response.message,
              });
            } else if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
            } else {
              notification["error"]({
                message: "Error del servidor",
              });
            }
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      },
    });
  };

  const activate_statestudentcourse = (
    <div>
      {" "}
      <p>Pasar a Estados activos</p>{" "}
    </div>
  );

  const delete_statestudentcourse = (
    <div>
      {" "}
      <p>Eliminar Estado</p>
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "statestudentcourse_code",
    },
    {
      title: "Descripción",
      dataIndex: "description",
    },
    {
      title: "Acción",
      key: "action",
      render: (text, stateStudentCourse) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statestudentcourses", "edit") || isAdmin(roleById) ? (
            <>
              <Popover content={activate_statestudentcourse}>
                <Button
                  className="button-activate"
                  onClick={() => activateStateStudentCourse(stateStudentCourse)}
                >
                  <CheckOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}

          {checkAction(permissionsActive, userToken.role, "statestudentcourses", "all") ||
          checkAction(permissionsActive, userToken.role, "statestudentcourses", "delete") || isAdmin(roleById) ? (
            <>
              <Popover content={delete_statestudentcourse}>
                <Button
                  className="button-statestudentcourse"
                  type="danger"
                  onClick={() => showDeleteConfirm(stateStudentCourse)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={()=>{return stateStudentCoursesInactive.length > 0? `Total de registros: ${stateStudentCoursesInactive.length}`: false}}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={stateStudentCoursesInactive}
      columns={columns}
      rowKey="_id"
    />
  );
}
