import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  //EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined
} from "@ant-design/icons";
import { addTeacherApi } from "../../../../api/teacher";
import { getAccessToken } from "../../../../api/auth";
import {
  emailValidation,
  numberValidation,
  phoneValidation,
  removeClassErrorSuccess
} from "../../../../utils/formValidation";

import "./AddTeacherForm.scss";

export default function AddTeacherForm(props) {
  const { setIsVisibleModal, setReloadTeachers } = props;

  const [inputs, setInputs] = useState({
    teacher_code: "",
    description: "",
    email: "",
    phone: ""
  });

  const [formValid, setFormValid] = useState({
  });

  const [disabledSubmit, setDisabledSubmit]= useState(false);

  const inputValidation = (e) => {
    const { name } = e.target;

    removeClassErrorSuccess(e.target);

    setInputs({
      ...inputs,
      [name]: e.target.value,
    });
    if (e.target.value !== "") {
      if (name === "email") {
        setFormValid({
          ...formValid,
          [name]: emailValidation(e.target),
        });
      }

      if (name === "phone") {
        setFormValid({
          ...formValid,
          [name]: phoneValidation(e.target),
        });
      }
      if (name === "documentNumber") {
        setFormValid({
          ...formValid,
          [name]: numberValidation(e.target),
        });
      }
    }
  };

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorios: *.",
      });
      errorExists = true;
    }
    else if (formValid.phone === false) {
      //BUG
      notification["error"]({
        message: "Telefóno inválido",
      });
      errorExists = true;
    } 
    else if (formValid.email === false && inputs.email !== "") {
      //BUG
      notification["error"]({
        message: "Email inválido",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addTeacher = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      if(!disabledSubmit){
        const accessToken = getAccessToken();
        setDisabledSubmit(true);
        await addTeacherApi(accessToken, inputs)
          .then((response) => {
            if (
              response === "ERR_CONNECTION_REFUSED" ||
              response === "Failed to fetch" ||
              (response.message && response.message === "Failed to fetch") ||
              response === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setDisabledSubmit(false);
            } else if (response.code !== 200) {
              notification["error"]({
                message: response.message,
              });
              setDisabledSubmit(false);
              setReloadTeachers(true);
            } else {
              notification["success"]({
                message: "Docente creado/a",
              });
              setIsVisibleModal(false);
              setReloadTeachers(true);
            }
            //setReloadTeachers(true);
          })
          .catch((err) => {
            notification["error"]({
              message: err,
            });
          });
      }
   }
  };

  return (
    <div className="add-teacher-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addTeacher={addTeacher}
        inputValidation={inputValidation}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addTeacher, inputValidation } = props;

  return (
    <Form className="form-add" onSubmit={addTeacher}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.teacher_code}
              onChange={(e) => setInputs({ ...inputs, teacher_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
            //   placeholder="descripcion"
              value={inputs.description}
              onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Teléfono
          </label>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Telefono"
              name="phone"
              value={inputs.phone}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            Email
          </label>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              placeholder="Correo Electrónico"
              type="email"
              name="email"
              value={inputs.email}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addTeacher}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
