import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getSchoolYearsActiveApi } from "../../../api/schoolYear";
import ListSchoolYears from "../../../components/Admin/SchoolYears/ListSchoolYears";

import "./SchoolYears.scss";

//hacer un getCoursesByStateCourseEnd y luego el setReloadCourses

export default function SchoolYears() {
    const [schoolYearsActive, setSchoolYearsActive] = useState([]);
    const [schoolYearsInactive, setSchoolYearsInactive] = useState([]);
    const [ reloadSchoolYears, setReloadSchoolYears ] = useState(false);
    const token = getAccessToken();
    //console.log('schoolYearsActive', schoolYearsActive);
    //console.log('schoolYearsInactive', schoolYearsInactive);

    useEffect(() => {
        getSchoolYearsActiveApi(token, true).then(response => {
            setSchoolYearsActive(response.schoolYears);
        });
        getSchoolYearsActiveApi(token, false).then(response => {
            setSchoolYearsInactive(response.schoolYears);
        });
        setReloadSchoolYears(false);
    }, [token, reloadSchoolYears]);

    return (
        <div className="schoolyears">
            <h3>Listado de Años Lectivos</h3>
            <ListSchoolYears schoolYearsActive={schoolYearsActive} schoolYearsInactive={schoolYearsInactive} setReloadSchoolYears={setReloadSchoolYears} />
        </div>
    );
}
