import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, notification } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { emailValidation, passwordValidation} from '../../../utils/formValidation';
import { signUpApi } from '../../../api/user';

import './RegisterForm.scss';


export default function RegisterForm() {
    //return "Formulario de nuevo usuario";
    const [inputs, setInputs] = useState({
        email: "",
        password:"",
        repeatPassword: "",
        privacyPolicy: false
    });

    const [formValid, setFormValid] = useState ({
        email: false,
        password: false,
        repeatPassword: false,
        privacyPolicy: false
    });

    const changeForm = e => {
        //console.log(e);
        if (e.target.name === "privacyPolicy") {
            setInputs({
                ...inputs,
                [e.target.name]: e.target.checked
            });
        } else {
            setInputs({
                ...inputs,
                [e.target.name]: e.target.value
            });
        }
    };

    const inputValidation = e => {
        //console.log('validando...');
        const { type, name } = e.target;

        if (type === "email") {
            setFormValid({
                ...formValid,
                [name]: emailValidation(e.target)
            });
        }
        if (type === "password") {
            setFormValid({
                ...formValid,
                [name]: passwordValidation(e.target)
            });
        }
        if (type === "checkbox") {
            setFormValid({
                ...formValid,
                [name]: e.target.checked
            });
        }
    };

    const isFormValid = () =>{
        const {email, password, repeatPassword, privacyPolicy} = formValid;
        const emailVal = inputs.email;
        const passwordVal = inputs.password;
        const repeatPasswordVal = inputs.repeatPassword;
        const privacyPolicyVal = inputs.privacyPolicy;

        let errorExists = false;
        if (!emailVal || !passwordVal || !repeatPasswordVal || !privacyPolicyVal) {
            notification['error']({
                message: "Todos los campos son obligatorios"
            });
            errorExists = true;
        } else {
            if (!email) {
                notification["error"]({
                    message: "El email es inválido"
                });
                errorExists = true;
            }
            if (!password) {
                notification["error"]({
                    message: "Las contraseñas deben tener al menos 8 carácteres y debe contener mayúsculas, minúsculas y dígitos"
                });
                errorExists = true;
            } else {
                if (passwordVal !== repeatPasswordVal) {
                    notification["error"]({
                        message: "Las contraseñas tienen que ser iguales"
                    });
                    errorExists = true;
                }

            }
        }
        return errorExists;
    };

    const register = async e => {
        e.preventDefault();
        //console.log(inputs);
        //console.log(formValid);

        if(!isFormValid()){
        // TO DO: conectar con API y registrar el usuario
            const result = await signUpApi(inputs);
            //console.log(result);
            if(!result.ok) {
                notification["error"]({
                    message: result.message
                });
            } else {
                notification["success"]({
                    message: result.message
                }); //true
                resetForm();
            }
            //notification['success']({
            //    message: "Cuenta creada"
            //});
        }
    };

    const resetForm = () => {
        const inputs = document.getElementsByTagName('input');

        for(let i = 0; i < inputs.length; i++) {
            inputs[i].classList.remove("success");
            inputs[i].classList.remove("error");
        }

        setInputs({
            email: "",
            password:"",
            repeatPassword: "",
            privacyPolicy: false
        });

        setFormValid({
            email: false,
            password: false,
            repeatPassword: false,
            privacyPolicy: false
        });
    };

    return (
        <Form className="register-form" onSubmit={register} onChange={changeForm}>
            <Form.Item>
                <Input
                    prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="email"
                    name="email"
                    placeholder="Correo electrónico"
                    className="register-form__input"
                    onChange={inputValidation}
                    value={inputs.email}
                />
            </Form.Item>
            <Form.Item>
                <Input
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    className="register-form__input"
                    onChange={inputValidation}
                    value={inputs.password}
                />
            </Form.Item>
            <Form.Item>
                <Input
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="password"
                    name="repeatPassword"
                    placeholder="Repetir contraseña"
                    className="register-form__input"
                    onChange={inputValidation}
                    value={inputs.repeatPassword}
                />
            </Form.Item>
            <Form.Item>
                <Checkbox name="privacyPolicy" onChange={inputValidation} checked={inputs.privacyPolicy}>
                    He leído y acepto la política de privacidad.
                </Checkbox>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" className="register-form__button" onClick={register}>
                    Crear cuenta
                </Button>
            </Form.Item>
        </Form>
    );
}