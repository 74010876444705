import React, { useState, useEffect } from 'react';
import { Form, Input, Button, notification } from 'antd'; //Checkbox
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { passwordValidation} from '../../../utils/formValidation';
import { updatePasswordByTokenApi } from '../../../api/user';

import './ResetForm.scss';


export default function ResetForm(props) {
    const { userByToken } = props;
    const [userData, setUserData] = useState({});
    const [inputs, setInputs] = useState({
        password:"",
        repeatPassword: "",
        resetPasswordToken: "",
        resetPasswordExpires: ""
    });

    const [formValid, setFormValid] = useState ({
        password: false,
        repeatPassword: false
    });

    //trae los datos del user
    useEffect(() => {
        setUserData({
            email: userByToken.email,
            resetPasswordToken: userByToken.resetPasswordToken,
            resetPasswordExpires: userByToken.resetPasswordExpires
        });
    }, [userByToken]);

    userData.resetPasswordToken = "";
    userData.resetPasswordExpires = "";

    //console.log("resetform userByToken: ", userByToken);
    //console.log("resetform userData: ", userData);

    const changeForm = e => {
        //console.log(e);
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        });
    };

    const inputValidation = (e) => {
        const { type, name } = e.target;
        if (type === "password") {
            setFormValid({
                ...formValid,
                [name]: passwordValidation(e.target)
            });
        }
    };

    const isFormValid = (e) =>{
        let errorExists = false;
        const { password, repeatPassword } = formValid;
        const passwordVal = inputs.password;
        const repeatPasswordVal = inputs.repeatPassword;
        //console.log('passwordVal: ', passwordVal);
        //console.log('inputs.password: ', inputs.password);

        if (!passwordVal || !repeatPasswordVal) {
            notification['error']({
                message: "Todos los campos son obligatorios"
            });
            errorExists = true;
        } else {
            if (!password) {
                notification["error"]({
                    message: "Las contraseñas deben tener al menos 8 carácteres y debe contener mayúsculas, minúsculas y dígitos"
                });
                errorExists = true;
            } else {
                if (passwordVal !== repeatPasswordVal) {
                    notification["error"]({
                        message: "Las contraseñas tienen que ser iguales"
                    });
                    errorExists = true;
                }
            }
        }
        return errorExists;
    };

    const resetPassword = (e) => {
        e.preventDefault();
        //console.log(inputs);
        userData.password = inputs.password;
        //console.log("userData resetPassword:", userData);

        const error = isFormValid();
        if (!error) {
            let userUpdate = userData; //es let porque se va actualizando
            //console.log("userUpdate resetPassword:", userUpdate);
            //actualiza la password
            updatePasswordByTokenApi(userUpdate, userByToken.resetPasswordToken).then((result) => {
                if(result.message === "ERR_CONNECTION_REFUSED" || result.message === "Failed to fetch" || result.message === undefined) {
                    console.log("RESULT: ", result.message);
                    notification["error"]({
                        //message: result.message
                        message: "Servidor caido"
                    });
                } else if (result.message === "No existe token de reseteo de password") {
                    notification["error"]({
                        message: result.message
                    });
                } else if (result.message === "No se ha podido actualizar la contraseña") {
                    notification["error"]({
                        message: result.message
                    });
                } else if (result.message === "Ingresó un token diferente o caducado") {
                    notification["error"]({
                        message: result.message
                    });
                } else {
                    notification["success"]({
                        message: result.message, //el mensaje success que viene del server
                    });
                    window.location.href = "/admin/login"; //redireccion una vez logueado
                }
            });
        }
    };

    return (
        <Form  onSubmit={resetPassword} onChange={changeForm}>
            <Form.Item>
                <Input
                    prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="email"
                    name="email"
                    placeholder="Correo electrónico"
                    className="reset-form__input"
                    value={userData.email}
                    disabled={true}
                    //hidden="true"
                />
            </Form.Item>
            <Form.Item>
                <Input
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="password"
                    name="password"
                    placeholder="Contraseña"
                    className="reset-form__input"
                    onChange={inputValidation}
                    value={inputs.password}
                />
            </Form.Item>
            <Form.Item>
                <Input
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="password"
                    name="repeatPassword"
                    placeholder="Repetir contraseña"
                    className="reset-form__input"
                    onChange={inputValidation}
                    value={inputs.repeatPassword}
                />
            </Form.Item>
            <Form.Item hidden="true">
                <Input
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="text"
                    name="resetPasswordToken"
                    placeholder="resetPasswordToken"
                    className="reset-form__input"
                    value={userData.resetPasswordToken}
                />
            </Form.Item>
            <Form.Item hidden="true">
                <Input
                    prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="password"
                    name="resetPasswordExpires"
                    placeholder="resetPasswordExpires"
                    className="reset-form__input"
                    value={userData.resetPasswordExpires}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="reset-form__button" onClick={resetPassword}>
                    Actualizar Contraseña
                </Button>
            </Form.Item>
        </Form>
    );
}