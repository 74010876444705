import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Table,
  Row,
  Col,
  Space,
  BackTop,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import { Popover } from "antd";
import moment from "moment";


import Modal from "../../../Modal";
import AddCourseAbsenceForm from "../../CourseAbsences/AddCourseAbsenceForm";
import EditAbsenceForm from "../../Absences/EditAbsenceForm";
import SearchByDates from "../../SearchByDates/SearchByDates";
import { deleteAbsenceApi } from "../../../../api/absence";
import { getAccessToken } from "../../../../api/auth";
import { checkAction, isAdmin } from "../../../../utils/checkUserPermission";
import { getRoleByIdApi } from "../../../../api/role";
import { getPermissionsActiveApi } from "../../../../api/permission";
import { absenceDetailPdf } from "../../Absences/ListAbsences/pdfAbsence";
import jwtDecode from "jwt-decode";
import { formatDateView , formatDateCalendar} from "../../../../utils/formatDate";

import "./ListCourseAbsences.scss";

const { confirm } = ModalAntd;
const { Search } = Input;

export default function ListCourseAbsences(props) {
  const { course, absences, setReloadCourseAbsences } = props;
  const [permissionsActive, setPermissionsActive] = useState([]);
  const [roleById, setRoleById] = useState([]);

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [courseAbsencesFilter, setCourseAbsencesFilter] = useState([]);

  const [initDate, setInitDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  
  const accessToken = getAccessToken();
  const userToken = jwtDecode(accessToken); //para obtener los datos del user logueado

  //para VOLVER HACIA ATRAS
  const history = useHistory();

  useEffect(() => {
    getPermissionsActiveApi(accessToken, true).then((response) => {
      setPermissionsActive(response.permissions);
    });
  }, []);

  useEffect(() => {
    getRoleByIdApi(accessToken, userToken.role).then((response) => {
      setRoleById(response.role);
    });
  }, []);

  useEffect(() => {
    const list = [];
      if (absences) {
        absences.forEach((element) => {
          if (
            (element.students.filter(i=>i.student.name.toUpperCase().includes(filterText.toUpperCase()) 
            || i.student.lastname.toUpperCase().includes(filterText.toUpperCase())).length > 0) ||
            filterText === ""
          ) {
            if(
              (moment(formatDateCalendar(element["date"])).isSameOrAfter(moment(initDate),"day") &&
            moment(formatDateCalendar(element["date"])).isSameOrBefore(moment(finishDate),"day")) || 
            (initDate === "" && finishDate ==="" )){
                list.push(element);
            }

          }
        });
      }
    setCourseAbsencesFilter(list);
  }, [filterText, absences, initDate]);

  const addAbsenceCourseModal = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar -> Inasistencia");
    setModalWidth(1500);
    setModalContent(
      <AddCourseAbsenceForm
        course={course}
        setIsVisibleModal={setIsVisibleModal}
        setReloadAbsences={setReloadCourseAbsences}
      />
    );
  };

  const deleteAbsence = (data) => {
    const token = getAccessToken();

    deleteAbsenceApi(token, data._id).then((result) => {
      if (
        result.message === "ERR_CONNECTION_REFUSED" ||
        result.message === "Failed to fetch" ||
        result.message === undefined
      ) {
        notification["error"]({
          message: "Servidor caido",
        });
        setIsVisibleModal(true);
      } else if (result.code !== 200) {
        notification["error"]({
          message: result.message,
        });
        setIsVisibleModal(true);
      } else {
        notification["success"]({
          message: result.message,
        });
        setReloadCourseAbsences(true);
      }
    });
  };

  return (
    <div className="list-courseabsences">
      <div className="list-courseabsences__header">
        {checkAction(permissionsActive, userToken.role, "absences", "all") ||
        checkAction(permissionsActive, userToken.role, "absences", "add") ||
        isAdmin(roleById) ? (
          <>
            {/*VOLVER HACIA ATRAS*/}
            <Button
              type="primary"
              icon={<ArrowLeftOutlined />}
              onClick={() => history.goBack()}
            >
              Atrás
            </Button>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={addAbsenceCourseModal}
            >
              Agregar Inasistencia
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>

      {checkAction(permissionsActive, userToken.role, "absences", "all") ||
      checkAction(permissionsActive, userToken.role, "absences", "view") ||
      isAdmin(roleById) ? (
        <>
        <div className="list-courseabsences__search">
        <Row>
          <Col span={18}>
            <Search
              className="Buscar"
              // allowClear
              placeholder=""
              onChange={(e) => setFilterText(e.target.value)}
              enterButton
            />
          </Col>
          <Col span={6}>
            <SearchByDates
              setInitDate={setInitDate}
              setFinishDate={setFinishDate}
              setReload={setReloadCourseAbsences}
            />
          </Col>
        </Row>
        </div>
        </>
      ) : (
        <></>
      )}
      <>
        {checkAction(permissionsActive, userToken.role, "absences", "all") ||
        checkAction(permissionsActive, userToken.role, "absences", "view") ||
        isAdmin(roleById) ? (
          <>
            <Absences
              absences={courseAbsencesFilter}
              setReloadCourseAbsences={setReloadCourseAbsences}
              setIsVisibleModal={setIsVisibleModal}
              setModalTitle={setModalTitle}
              setModalContent={setModalContent}
              setModalWidth={setModalWidth}
              accessToken={accessToken}
              userToken={userToken}
              permissionsActive={permissionsActive}
              roleById={roleById}
              deleteAbsence={deleteAbsence}
              //showDeleteConfirm={showDeleteConfirm}
            />
          </>
        ) : (
          <></>
        )}
      </>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        modalWidth={modalWidth}
      >
        {modalContent}
      </Modal>

      <BackTop />
    </div>
  );
}

function Absences(props) {
  const {
    absences,
    deleteAbsence,
    userToken,
    setIsVisibleModal,
    setReloadCourseAbsences,
    setModalTitle,
    setModalContent,
    setModalWidth,
    permissionsActive,
    roleById,
  } = props; //showDeleteConfirm

  const showDeleteConfirm = (absence) => {
    confirm({
      title: "Eliminando -> Inasistencia del Curso",
      content: `¿Estás seguro que quieres eliminar la inasistencia ${
        absence.date ? formatDateView(absence.date) : ""
      } del curso?`,
      okText: "Eliminar",
      okType: "danger",
      cancelText: "Cancelar",
      onOk() {
        deleteAbsence(absence);
      },
    });
  };

  const editAbsence = (absence) => {
    // console.log(absence);
    setIsVisibleModal(true);
    setModalTitle(
      `Editar Inasistencia: ${absence.course ? absence.course.name : "curso"}`
    );
    setModalWidth(1000);
    setModalContent(
      <EditAbsenceForm
        absence={absence}
        setIsVisibleModal={setIsVisibleModal}
        setReloadAbsences={setReloadCourseAbsences}
      />
    );
  };

  const edit_absenceCourse = (
    <div>
      {" "}
      <p>Editar inasistencia</p>{" "}
    </div>
  );

  const delete_absenceCourse = (
    <div>
      {" "}
      <p>Eliminar inasistencia</p>{" "}
    </div>
  );

  const columns = [
    {
      title: "Código",
      dataIndex: "absence_code",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      render: (date) => formatDateView(date),
      sorter:(a,b)=>{
        if(a && a.date && b&& b.date){
          return new Date(a.date)- new Date(b.date);
        }else if(a && a.date){
          return -1;
        }else if(b && b.date){
          return 1;
        }
        return 0;
      }
    },
    {
      title: "Acción",
      key: "action",
      render: (text, absence) => (
        <div>
          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "edit") ||
          isAdmin(roleById) ? (
            <>
              <Popover content={edit_absenceCourse}>
                {/* TODO: IMPLEMENTAR QUE SE PUEDA EDITAR LA INASISTENCIA */}
                <Button
                  className="button-edit"
                  onClick={() => editAbsence(absence)}
                >
                  <EditOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(
            permissionsActive,
            userToken.role,
            "absences",
            "delete"
          ) ||
          isAdmin(roleById) ? (
            <>
              <Popover content={delete_absenceCourse}>
                <Button
                  className="button-absence"
                  type="danger"
                  onClick={() => showDeleteConfirm(absence)}
                >
                  <DeleteOutlined />
                </Button>
              </Popover>
            </>
          ) : (
            <></>
          )}

          {checkAction(permissionsActive, userToken.role, "absences", "all") ||
          checkAction(permissionsActive, userToken.role, "absences", "pdf") ||
          isAdmin(roleById) ? (
            <>
              <Space align="center" style={{ marginBottom: 16 }}>
                <Button
                  className="button-download"
                  type="default"
                  onClick={() => {
                    absenceDetailPdf(absence, false);
                  }}
                >
                  <DownloadOutlined />
                </Button>
              </Space>
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      footer={() => {
        return absences.length > 0
          ? `Total de registros: ${absences.length}`
          : false;
      }}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
      }}
      dataSource={absences}
      columns={columns}
      rowKey="_id"
    />
  );
}
