import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { 
    //getCoursesActiveApi, 
    getCoursesApi, 
    getExportCoursesApi
} from "../../../api/course";
//import { getInscriptionsApi, getInscriptionByCourseApi } from "../../../api/inscription";
//import { getSchoolYearsActiveApi } from "../../../api/schoolYear";
import ListCourses from "../../../components/Admin/Courses/ListCourses";

export default function Courses(props) {
    const [coursesActive, setCoursesActive] = useState([]);
    const [coursesInactive, setCoursesInactive] = useState([]);
    const [coursesActiveExport, setCoursesActiveExport] = useState([]);
    const [coursesInactiveExport, setCoursesInactiveExport] = useState([]);
    //const [inscriptions, setInscriptions] = useState([]);
    const [reloadCourses, setReloadCourses ] = useState(false);
    const [paginationActive, setPaginationActive]= useState({page:1, schoolYear: null});
    const [paginationInactive, setPaginationInactive]= useState({page:1, schoolYear : null});
    const token = getAccessToken();

    // useEffect(() => {
    //     //Para obtener los datos de los cursos courses.docs
    //     getSchoolYearsActiveApi(token, true).then(response => {
    //         setPaginationActive({...paginationActive.filters, schoolYear: response.schoolYears[0]._id});
    //     });
    // }, []);

    useEffect(() => {
        //Para obtener los datos de los cursos courses.docs
        let isMounted=true;
        if(paginationActive.schoolYear !== null && paginationActive.schoolYear !==  undefined){
            getCoursesApi(token, true, 50, paginationActive.page, paginationActive.filter, paginationActive.filters, paginationActive.schoolYear).then(response => {
                if(isMounted){
                    setCoursesActive(response.courses);
                }
            });
            getExportCoursesApi(token, true, paginationActive.filter, paginationActive.filters, paginationActive.schoolYear).then(response => {
                if(response.courses){
                    if(isMounted){
                        setCoursesActiveExport(response.courses);
                    }
                }
            });
        }
        setReloadCourses(false);
        return ()=>{
            isMounted=false;
        }
    }, [token,paginationActive, reloadCourses]);

    useEffect(() => {
        let isMounted=true;
        //Para obtener los datos de los cursos courses.docs
        if(paginationInactive.schoolYear !==  null && paginationInactive.schoolYear !==  undefined){
            getCoursesApi(token, false, 50, paginationInactive.page, paginationInactive.filter, paginationInactive.filters, paginationInactive.schoolYear).then(response => {
                if(isMounted){
                    setCoursesInactive(response.courses);
                }
            });
            getExportCoursesApi(token, false, paginationInactive.filter, paginationInactive.filters, paginationInactive.schoolYear).then(response => {
                if(response.courses){
                    if(isMounted){
                      setCoursesInactiveExport(response.courses);
                    }
                }
            });
        }
        setReloadCourses(false);
        return ()=>{
            isMounted=false;
        }
    }, [token,paginationInactive, reloadCourses]);

    // useEffect(() => {
    //     getInscriptionsApi(token).then(response => {
    //       setInscriptions(response.inscriptions);
    //     });
    //   }, [token,reloadCourses]);

    return (
        <div className="courses">
            <h3>Listado de Cursos</h3>
            <ListCourses setPaginationActive={setPaginationActive} setPaginationInactive={setPaginationInactive} coursesActive={coursesActive}
             coursesInactive={coursesInactive} setReloadCourses={setReloadCourses}
             coursesActiveExport={coursesActiveExport} coursesInactiveExport={coursesInactiveExport}
            //  inscriptions={inscriptions}
             />
        </div>
    );
}