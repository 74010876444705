import React, { useState, useEffect } from "react";
import {
  //Switch,
  Button,
  Modal as ModalAntd,
  Select,
  Form,
  Col,
  Input,
  Row,
  DatePicker
} from "antd";

//import Modal from "../../../Modal";
//import { activateCourseApi, deleteCourseApi } from "../../../../api/course";
import { 
  //getSchoolYearsApi, 
  getSchoolYearsActiveApi 
} from "../../../../api/schoolYear";
// import { getFinalConditionsApi } from "../../../../api/finalCondition";
import { 
  //getStudentConditionsActiveApi, 
  getStudentConditionsApi 
} from "../../../../api/studentCondition";
// import { getStudentsHasCoursesApi  } from "../../../../api/student";
import { getAccessToken } from "../../../../api/auth";

//const { confirm } = ModalAntd;
const { Option } = Select;

export default function AdvancedSearchAdviserForm (props){
  const {filters, setFilters, setSchoolYearData}=props;
  const [data, setData]=useState([]);
  const [listSchoolYears, setListSchoolYears]=useState([]);
  
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setFilters(data);
  };
  
  const accessToken = getAccessToken();

  const resetFields=()=>{
    setData([]);
    setFilters([]);
    setSchoolYearData("");
  };

  useEffect(() => {
    setData(filters);
  }, []);

  useEffect(() => {
    getSchoolYearsActiveApi(accessToken, true).then(response => {
      setListSchoolYears(response.schoolYears);
    });
  }, []);

  useEffect(() => {
    let dataYear = listSchoolYears.filter(i=>i._id===data.schoolYear);
    if(dataYear && dataYear.length>0){
      setSchoolYearData(dataYear[0].year);
    }
  }, [data]);

  //useEffect(() => {
  //   getFinalConditionsApi(accessToken).then(response => {
  //     setListFinalConditions(response.finalConditions);
  //   });
  // }, []);


  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      layout="vertical"
      size="small"
      onFinish={onFinish}
    >
      <Row gutter={24}>
      {/* <Col span={6}>
          <Form.Item label="Ciclo Lectivo">
          <DatePicker
              type="date"
              allowClear={false}
              placeholder=""
              value={data.schoolYear}
              picker="year"
              onChange={(e) =>
                setData({ ...data, schoolYear: e })
              }
              style={{width:"100%"}}
            />
          </Form.Item>

        </Col> */}
        {/* <Col span={6}>
          <Form.Item label="Condición de Ingreso">
            <Select
              showSearch
              value={data.entryCondition}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              onChange={e=>setData({...data,entryCondition:e})}>
              {listEntryConditions? listEntryConditions.map(condition=>{
                      return (
                    <Option key={condition._id}>{condition.description}</Option>)
              }) : null}
            </Select>
          </Form.Item>
        </Col> */}
        {/* <Col span={6}>
          <Form.Item label="Condición Final">
            <Select
              showSearch
              value={data.finalCondition}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              onChange={e=>setData({...data,finalCondition:e})}>
              {listFinalConditions? listFinalConditions.map(condition=>{
                      return (
                    <Option key={condition._id}>{condition.description}</Option>)
              }) : null}
            </Select>
          </Form.Item>
        </Col> */}
        <Col span={6}>
          <Form.Item label="Ciclo lectivo">
          <Select
            showSearch
            value={data.schoolYear}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
            onChange={e=>setData({...data,schoolYear:e})}>
            {listSchoolYears? listSchoolYears.map(dataYear=>{
                    return (
                  <Option key={dataYear.year}>{dataYear.year}</Option>)
            }) : null}
          </Select>
          </Form.Item>

        </Col>

        {/* <Col span={6}>
          <Form.Item label="Condición estudiante">
            <Select
              showSearch
              value={data.studentCondition}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
              onChange={e=>setData({...data,studentCondition:e})}>
              {listStudentConditions? listStudentConditions.map(condition=>{
                      return (
                    <Option key={condition._id}>{condition.description}</Option>)
              }) : null}
          </Select>
          </Form.Item>
        </Col> */}
        {/* SI busca los TRUE y NO busca los false */}
        {/* <Col span={6}>
          <Form.Item label="Ciudad">
          <Input
            // prefix={<UserOutlined />}
            // placeholder="nombre"
            name="city"
            value={data.city}
            allowClear
            //onChange={(e) => setCourseData({ ...courseData, name: e.target.value }) }
            onChange={e=>setData({...data,city:e.target.value})}
          />
          </Form.Item>
        </Col>*/}
        
        {/* <Col span={6}>
          <Form.Item label="Extranjero">
          <Switch
              defaultChecked
              onChange={(e) => setData({...data,foreign:e})}
            />
          </Form.Item>
        </Col> */}
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: 'right',
          }}
        >
          <Button type="primary" htmlType="submit">
            Aplicar
          </Button>
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => {
              resetFields();
            }}
          >
            Limpiar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};