import React, { useState } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { addTypeCourseApi } from "../../../../api/typeCourse";
import { getAccessToken } from "../../../../api/auth";

import "./AddTypeCourseForm.scss";

export default function AddTypeCourseForm(props) {
  const { setIsVisibleModal, setReloadTypeCourses } = props;

  const [inputs, setInputs] = useState({
    typecourse_code: "",
    description: "",
  });

  const isFormValid = (e) => {
    //console.log(inputs);
    let errorExists = false;
    if (!inputs.description) {
      notification["error"]({
        message: "Obligatorio: descripción.",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const addTypeCourse = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      const accessToken = getAccessToken();

      await addTypeCourseApi(accessToken, inputs)
        .then((response) => {
          if (
            response === "ERR_CONNECTION_REFUSED" ||
            response === "Failed to fetch" ||
            response === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
          } else if (response.code !== 200) {
            notification["error"]({
              message: response.message,
            });
          } else {
            notification["success"]({
              message: "Tipo creado",
            });
            setIsVisibleModal(false);
          }
          setReloadTypeCourses(true);
        })
        .catch((err) => {
          notification["error"]({
            message: err,
          });
        });
    }
  };

  return (
    <div className="add-typecourse-form">
      <AddForm
        inputs={inputs}
        setInputs={setInputs}
        addTypeCourse={addTypeCourse}
      />
    </div>
  );
}

function AddForm(props) {
  const { inputs, setInputs, addTypeCourse } = props;

  return (
    <Form className="form-add" onSubmit={addTypeCourse}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={inputs.typecourse_code}
              onChange={(e) => setInputs({ ...inputs, typecourse_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">*</span> Descripción
          </label>
          <Form.Item>
            <Input
            //   placeholder="descripcion"
              value={inputs.description}
              onChange={(e) => setInputs({ ...inputs, description: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={addTypeCourse}
        >
          Crear
        </Button>
      </Form.Item>
    </Form>
  );
}
