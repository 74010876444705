import { switchPath, apiVersion } from './config';


/**FUNCIONES PARA MANEJO DEL MODULO CONDICION DE INGRESO QUE CONSUMEN LOS ENDPOINTS DEL SERVER*/

export function getEntryConditionExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/entrycondition-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'entrycondition.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postEntryConditionImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/entrycondition-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}


export function addEntryConditionApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-entrycondition`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getEntryConditionsApi(token) {
    const url = `${switchPath()}/${apiVersion}/entryconditions`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getEntryConditionsActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/entryconditions-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateEntryConditionApi(token, entryConditionData, entryConditionId) {
    const url = `${switchPath()}/${apiVersion}/update-entrycondition/${entryConditionId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(entryConditionData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateEntryConditionApi(token, entryConditionId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-entrycondition/${entryConditionId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteEntryConditionApi(token, entryConditionId) {
    const url = `${switchPath()}/${apiVersion}/delete-entrycondition/${entryConditionId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}
