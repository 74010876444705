import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  //EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined
} from "@ant-design/icons";
import { updateTeacherApi } from "../../../../api/teacher";
import { getAccessToken } from "../../../../api/auth";
import {
  emailValidation,
  numberValidation,
  phoneValidation,
  removeClassErrorSuccess,
} from "../../../../utils/formValidation";

import "./EditTeacherForm.scss";

export default function EditTeacherForm(props) {
  const { teacher, setIsVisibleModal, setReloadTeachers } = props;
  const [teacherData, setTeacherData] = useState({});

  //trae los datos de tipo de proveedor
  useEffect(() => {
    setTeacherData({
      teacher_code: teacher.teacher_code,
      description: teacher.description,
      email: teacher.email,
      phone: teacher.phone,
    });
  }, [teacher]);

  const [formValid, setFormValid] = useState({});

  const inputValidation = (e) => {
    const { name } = e.target;

    removeClassErrorSuccess(e.target);

    setTeacherData({
      ...teacherData,
      [name]: e.target.value,
    });
    if(e.target.value !== ""){
      if (name === "email") {
        setFormValid({
          ...formValid,
          [name]: emailValidation(e.target),
        });
      }

      if (name === "phone") {
        setFormValid({
          ...formValid,
          [name]: phoneValidation(e.target),
        });
      }
      if (name === "documentNumber")  {
        setFormValid({
          ...formValid,
          [name]: numberValidation(e.target),
        });
      }
    }
  };

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!teacherData.description) {
      notification["error"]({
        message: "Obligatorio: *",
      });
      errorExists = true;
    } 
    else if (
      formValid.phone === false) {
      //BUG
      notification["error"]({
        message: "Telefóno inválido",
      });
      errorExists = true;
    }
    else if (
      formValid.email === false && teacherData.email !=="") {
      //BUG
      notification["error"]({
        message: "Email inválido",
      });
      errorExists = true;
    }else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateTeacher = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let teacherUpdate = teacherData; //es let porque se va actualizando

      updateTeacherApi(token, teacherUpdate, teacher._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadTeachers(true);
          }
        }
      );
    }
  };

  return (
    <div className="edit-teacher-form">
      <EditForm
        teacherData={teacherData}
        setTeacherData={setTeacherData}
        updateTeacher={updateTeacher}
        inputValidation={inputValidation}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    teacherData,
    setTeacherData,
    updateTeacher,
    inputValidation
  } = props;

  return (
    <Form className="form-edit" onSubmit={updateTeacher}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={teacherData.teacher_code}
              onChange={(e) => setTeacherData({ ...teacherData, teacher_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
            //   placeholder="descripción"
              value={teacherData.description}
              onChange={(e) =>
                setTeacherData({ ...teacherData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Teléfono
          </label>
          <Form.Item>
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Teléfono"
              type="phone"
              name="phone"
              value={teacherData.phone}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            Email
          </label>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              value={teacherData.email}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateTeacher}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
