export function minLengthValidation(inputData, minLength) {
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    if (value.length >= minLength) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}


export function passwordValidation(inputData) {
    // eslint-disable-next-line no-useless-escape
    const passwordValid= /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    const resultValidation = passwordValid.test(value);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}


export function emailValidation (inputData) {
    // eslint-disable-next-line no-useless-escape
    const emailValid = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    const resultValidation = emailValid.test(value);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function numberValidation (inputData) {
    // eslint-disable-next-line no-useless-escape
    //const numberValid = /^(?=.*?[0-9]).{0,11}$/;
    const numberValid = /^([0-9])*$/;
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    const resultValidation = numberValid.test(value);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function stringValidation (inputData) {
    // eslint-disable-next-line no-useless-escape
    const charsValid = /^([a-zA-Z0-9ÑñÁáÉéÍíÓóÚúÜü_\u00E0\u00FC\.\,\(\)\|\&\- ])*$/;
    const { value } = inputData;

    removeClassErrorSuccess(inputData);

    const resultValidation = charsValid.test(value);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function phoneValidation(inputData){
    //eliminamos todo lo que no es dígito
    const { value } = inputData;
    const num = value.replace( /\D+/, '');
    // //devolver si coincidió con el regex/^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/
    const numberValid =  /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;

    removeClassErrorSuccess(inputData);

    const resultValidation = numberValid.test(num);
    if(resultValidation) {
        inputData.classList.add("success");
        return true;
    } else {
        inputData.classList.add("error");
        return false;
    }
}

export function removeClassErrorSuccess(inputData) {
    inputData.classList.remove('success');
    inputData.classList.remove('error');
}
