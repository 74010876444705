import React, { useState, useEffect } from "react"; //useCallback
import {
  InputNumber,
  Form,
  //Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  Spin,
  Modal,
  Alert,
  // Tag,
  // Collapse
} from "antd";
import {
  // UserOutlined,
  // TagOutlined,
  // EnvironmentOutlined,
  // DollarCircleOutlined,
  // IdcardOutlined,
  // ScheduleOutlined,
  // TeamOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";
//import { useDropzone } from "react-dropzone";
//import NoAvatar from "../../../../assets/img/png/no-avatar.png";
import {
  //getSchoolYearAvatarApi,
  uploadSchoolYearAvatarApi,
  updateSchoolYearApi,
} from "../../../../api/schoolYear";
import { getAccessToken } from "../../../../api/auth";
import { getStateSchoolYearsActiveApi, getStateSchoolYearByIdApi } from "../../../../api/stateSchoolYear";
import { getStateCoursesActiveApi } from "../../../../api/stateCourse";
import { updateCoursesBySchoolYearEndApi} from "../../../../api/course";
// import { getAbsencesByCourseApi} from "../../../../api/absence";
import {updateStateStudentSchoolYear} from "../../../../utils/stateStudent";

import "./EditSchoolYearForm.scss";
// import { getInscriptionByCourseApi } from "../../../../api/inscription";

export default function EditSchoolYearForm(props) {
  const { schoolYear, setIsVisibleModal, setReloadSchoolYears } = props;
  //const [avatar, setAvatar] = useState(null);
  const [schoolYearData, setSchoolYearData] = useState({});
  const [listStateSchoolYearsActive, setListStateSchoolYearsActive] = useState([]);
  const [listStateCoursesActive, setListStateCoursesActive] = useState([]);
  const [stateCourseFinalizado, setStateCourseFinalizado] = useState([]);
  const [loading, setLoading]=useState(false);
  const [stateSchoolYearActual, setStateSchoolYearActual] = useState(null);

  //estado relacionado a si el usuario realizó modificación en los inputs que deben ser validados
  const [modifDataToValid, setModifDataToValid] = useState({
  });
  const accessToken = getAccessToken();
  //let stateSchoolYearfinalizado = "";
  let lastSchoolYearId = 0;

  //trae los datos del schoolYear
  useEffect(() => {
    setSchoolYearData({
      //schoolyear_code: schoolYear.schoolyear_code,
      year: schoolYear.year,
      stateSchoolYear: schoolYear.stateSchoolYear? schoolYear.stateSchoolYear._id : "sin datos",
    });
  }, [schoolYear]);

  useEffect(() => {
    setStateSchoolYearActual(schoolYear.stateSchoolYear? schoolYear.stateSchoolYear._id : null);
  }, [schoolYear]);

  useEffect(() => {
    getStateSchoolYearsActiveApi(accessToken, true).then((response) => {
      setListStateSchoolYearsActive(response.stateSchoolYears);
    });
  }, []);

  useEffect(() => {
    getStateCoursesActiveApi(accessToken, true).then((response) => {
      setListStateCoursesActive(response.stateCourses);
      response.stateCourses.map((state) => {
        if((state.description === "finalizado") || (state.description === "Finalizado")){
          //console.log(state._id);
          setStateCourseFinalizado(state);
        }
        return true;
      })
    });
  }, []);

  /*
  function getStateCourseFinalizado() {
    listStateCoursesActive.map((state) => {
      if((state.description === "finalizado") || (state.description === "Finalizado")){
        //console.log(state._id);
        return state;
      }
    });
  }*/

  //getStateCourseFinalizado();

  // //si tiene avatar lo trae, sino lo deja vacio
  // useEffect(() => {
  //   if (schoolYear.avatar) {
  //     getSchoolYearAvatarApi(schoolYear.avatar).then((response) => {
  //       setAvatar(response);
  //     });
  //   } else {
  //     setAvatar(null);
  //   }
  // }, [schoolYear]);

  // //si se carga un avatar lo prepara para enviar a la db
  // useEffect(() => {
  //   if (avatar) {
  //     setSchoolYearData({ ...schoolYearData, avatar: avatar.file });
  //   }
  // }, [avatar]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (
      !schoolYearData.stateSchoolYear ||
      !schoolYearData.year
    ) {
      notification["error"]({
        message: "Campos obligatorios: año y estado",
      });
      errorExists = true;
    }
    return errorExists;
  };

  const updateSchoolYear = async (e) => {
    e.preventDefault();
    const error = isFormValid();
    const token = getAccessToken();
    // stateLoading(true);
    let stateSchoolYearfinalizado = "";
    //let finalizado = "";
    if (!error) {
      let schoolYearUpdate = schoolYearData; //es let porque se va actualizando

      if (typeof schoolYearUpdate.avatar === "object") {
        //actualiza el avatar del schoolYear
        uploadSchoolYearAvatarApi(
          token,
          schoolYearUpdate.avatar,
          schoolYear._id
        ).then(async (response) => {
          schoolYearUpdate.avatar = response.avatarName;
          await updateSchoolYearApi(token, schoolYearUpdate, schoolYear._id).then(
            (result) => {
              notification["success"]({
                message: result.message, //el mensaje que viene del server
              });
              // setIsVisibleModal(false);
              setReloadSchoolYears(true);
            }
          );
        });
      } else {
        //actualiza el schoolYear menos el avatar
        await updateSchoolYearApi(token, schoolYearUpdate, schoolYear._id).then(
          async (result) => {
            if (
              result.message === "ERR_CONNECTION_REFUSED" ||
              result.message === "Failed to fetch" ||
              result.message === undefined
            ) {
              notification["error"]({
                message: "Servidor caido",
              });
              setIsVisibleModal(true);
            } else {
              notification["success"]({
                message: result.message, //el mensaje que viene del server
              });
              //setIsVisibleModal(false);
              //setReloadSchoolYears(true);
              await getStateSchoolYearByIdApi(token, schoolYearUpdate.stateSchoolYear).then(async(response) => {
                //console.log("response: " + response.stateSchoolYear.description);
                if ((response.stateSchoolYear.description === "finalizado") ||
                    (response.stateSchoolYear.description === "Finalizado") ) {
                  stateSchoolYearfinalizado = response.stateSchoolYear;
                  lastSchoolYearId = schoolYear._id;
                  //TO DO: VERIFICAR FUNCIONAMIENTO DE ESTA FUNCION
                  //VERIFICO QUE EL AÑO LECTIVO NO ESTA YO FINALIZADO ANTERIORMENTE
                  //Primero se actualizan los estados de los estudiantes y luego se setea el curso a estado finalizado
                  if(schoolYear.stateSchoolYear._id !== response.stateSchoolYear._id && schoolYear.year > 2021){
                    //VERIFICO QUE SEA MAYOR A 2021 YA QUE NO HAY INASISTENCIAS CARGADAS
                    setLoading(true);
                    await updateStateStudentSchoolYear(token, schoolYear).then(async result=>{
                      if(result && result.state){
                        infoResult(result.err, result.count, result.message);
                        await updateCoursesBySchoolYearEnd(lastSchoolYearId);
                      }
                      setLoading(false);
                      setIsVisibleModal(false);
                    });
                    // setIsVisibleModal(false);
                  }else{
                    setIsVisibleModal(false);
                    await updateCoursesBySchoolYearEnd(lastSchoolYearId);
                  } 
                } else {
                  lastSchoolYearId = 0;
                  // console.log(lastSchoolYearId);
                  // await updateCoursesBySchoolYearEnd(lastSchoolYearId);
                }
                setReloadSchoolYears(true);
                setIsVisibleModal(false);
                //getCoursesByStateCourse(token, stateCourseFinalizado._id)
                //getAbsencesByCourseApi(token, courseId)
                //setIsVisibleModal(false);
              });
             // setReloadSchoolYears(true);
              // setIsVisibleModal(false);
            }
          }
        
          );
      }
    }
    //console.log(lastSchoolYearId);
    //updateCoursesBySchoolYearEnd(lastSchoolYearId);
    // setIsVisibleModal(false);
    // stateLoading(false);;
  };

  const infoResult=(err, count, message)=>{
    Modal.info({
      title:'Ciclo Lectivo Finalizado',
      content:(
        <div>
          <p> {message}</p>
          <p> Cantidad de cursos actualizados: {count}</p>
          <p> Cantidad de errores: {err} </p>
        </div>
      ),
    })
  }

  //modificar todos los cursos cuyo schoolYear sea finalizado -> stateCourse finalizado
  const updateCoursesBySchoolYearEnd = async (lastSchoolYearId) => {
    //e.preventDefault();
    const token = getAccessToken();
    if (lastSchoolYearId !== 0) {
    await updateCoursesBySchoolYearEndApi(token, schoolYear._id, stateCourseFinalizado).then(
      (result) => {
        if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          //setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //setIsVisibleModal(false);
          //setReloadSchoolYears(true);
        }
      }
    );
    } else {
      // console.log("lastSchoolYearId === 0");
    }
  };

  return (
    <div className="edit-schoolyear-form">
      {/* <UploadSchoolYearAvatar avatar={avatar} setAvatar={setAvatar} /> */}
      {/* <Spin spinning={loading} size="large"/> */}
      {loading? 
      <div style={{"textAlign":"center"}}> 
      <Alert 
        message="Ciclo Lectivo Finalizado"
        description="Actualización del estado de los estudiantes de cada curso del ciclo lectivo"
        type="info"
      />
      <div style={{"margin":"auto", "paddingTop":'5%'}}><Spin style={{height:'50%'}} tip="en proceso"/> </div></div>
        : null}
      <EditForm
        schoolYearData={schoolYearData}
        setSchoolYearData={setSchoolYearData}
        updateSchoolYear={updateSchoolYear}
        setModifDataToValid={setModifDataToValid}
        modifDataToValid={modifDataToValid}
        listStateSchoolYearsActive={listStateSchoolYearsActive}
        listStateCoursesActive={listStateCoursesActive}
        stateSchoolYearActual={stateSchoolYearActual}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    schoolYearData,
    setSchoolYearData,
    //setModifDataToValid, modifDataToValid,
    updateSchoolYear,
    listStateSchoolYearsActive,
    stateSchoolYearActual
    //listStateCoursesActive, getStateCourseFinalizado
  } = props;

  // const [stateDisabled, setStateDisabled]=useState(false);
  const { Option } = Select;
  
  // useEffect(() => {
  //   if(listStateSchoolYearsActive && listStateSchoolYearsActive.length > 0 && stateSchoolYearActual !==null){
  //     let state=listStateSchoolYearsActive.filter(i=>i._id===stateSchoolYearActual);
  //     if(state&& state.length > 0 &&
  //       state[0].description.toLowerCase() === "finalizado"){
  //       setStateDisabled(true);
  //     } else{
  //       setStateDisabled(false);
  //     }
  //   }
  // }, [stateSchoolYearActual, listStateSchoolYearsActive]);

  return (
    <Form
      className="form-edit"
      onSubmit={updateSchoolYear} //onFinish={updateSchoolYear}
    >
      {/*
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={schoolYearData.statecourse_code}
              onChange={(e) => setSchoolYearData({ ...schoolYearData, statecourse_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
      </Row>
      */}

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <Form.Item>
            <label className="control-label">Año</label>
            <InputNumber
              // type="text"
              placeholder="Año"
              value={schoolYearData.year}
              onChange={(e) =>
                setSchoolYearData({
                  ...schoolYearData,
                  year: e,
                })
              }
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        {/* <Col span={6}>
          <Form.Item>
            <label className="control-label">Tipo</label>
            <Select
              prefix={<TeamOutlined />}
              className="select-form"
              placeholder="Selecciona el tipo de proveedor"
              onChange={(e) => setSchoolYearData({ ...schoolYearData, type: e })}
              value={schoolYearData.type}
            > */}
        {/*
              <Option value="products">Productos</Option>
              <Option value="services">Servicios</Option>
              <Option value="supplies">Insumos</Option>
              <Option value="fixed-assets">Bienes de uso</Option>
              <Option value="various">Varios</Option>
              <Option value="others">Otros</Option>
              */}
        {/* {listSchoolYearTypesActive
                ? listSchoolYearTypesActive.map((schoolYearType) => {
                    return (
                      <Option key={schoolYearType._id} value={schoolYearType.description}>
                        {schoolYearType.description}
                      </Option>
                    );
                  })
                : null} */}
        {/* </Select>
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">
            <span className="control-required">*</span>Estado
          </label>
          <Form.Item>
            <Select
              showSearch
              // disabled={stateDisabled}
              placeholder="Selecciona un estado"
              value={schoolYearData.stateSchoolYear}
              onChange={(e) => setSchoolYearData({ ...schoolYearData, stateSchoolYear: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              
            >
              {listStateSchoolYearsActive
                ? listStateSchoolYearsActive.map((state) => {
                    return (
                      <Option key={state._id}>
                        {state.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateSchoolYear}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
