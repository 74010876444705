import { 
    //formatDateView, formatDateHour, formatDateHour2,
    formatDateView2
 } from "../utils/formatDate";
import Logo from '../assets/img/png/icba.png';
import moment from "moment";

const pdfmake=require ('pdfmake/build/pdfmake');
const pdfFonts=require('pdfmake/build/vfs_fonts');


export function downloadPdf(docDefinition,title){
    try{
    
    pdfmake.vfs= pdfFonts.pdfMake.vfs;
    
    pdfmake.createPdf(docDefinition).download(title);

    }catch(err){
        throw(err);
    }
}

export function openPdf(docDefinition){
    try{
    
    pdfmake.vfs= pdfFonts.pdfMake.vfs;
   
    pdfmake.createPdf(docDefinition).open();

    }catch(err){
        throw(err);
    }
}

//ENCABEZADO fecha
export function getHeader(){
    return (
        [
            {text: `Fecha de consulta: ${formatDateView2(moment())}`, style:'header'},
            '\n',
        ]
    )
}

//LINEA DEL ENCABEZADO PARA PAGINAS VERTICALES
export function lineVerticalPage(){
    return (
        {
            table : { 
                headerRows : 1,
                alignment: "center",
                widths: [510],
                body : [
                        [''],
                        ['']
                        ]
            },
            layout : 'headerLineOnly'
          }
    )
}

//LINEA DEL ENCABEZADO PARA PAGINAS HORIZONTALES
export function lineLandscapePage(){
    return (
        {
            table : { 
                headerRows : 1,
                alignment: "center",
                widths: [760],
                body : [
                        [''],
                        ['']
                        ]
            },
            layout : 'headerLineOnly'
          }
    )
}

/*
const line =  {
    table : { 
        headerRows : 1,
        alignment: "center",
        widths: [500],
        body : [
                [''],
                ['']
                ]
    },
    layout : 'headerLineOnly'
}
*/

//PIE DE PAGINA
/*
export function getFooter(currentPage, pageCount){
    //function(currentPage, pageCount) { return currentPage.toString() + ' de ' + pageCount, alignment: 'center'; }
    return (
        [
            {text: `${currentPage} de ${pageCount}`, style:'footer', alignment: 'center'},
        ]
    )
}
*/

//FOOTER PERSONALIZADO HOJA VERTICAL
export var verticalFooter =
function(currentPage, pageCount) { 
  return(
    [
      { canvas: [ { type: 'line', x1: 35, y1: 0, x2: 550, y2: 0, lineWidth: 2, alignment: "center" } ]},
      "\n",
    //   {
    //     text: `Instituto Clínico de Buenos Aires - Callao 1033 5° Piso - C.P(1023) - Tel: 4816-7292`,
    //     style: "footer",
    //     alignment: "center",
    //     // opacity: 0.5,
    //     fontSize: 9,
    //     margin: [0, 5, 0, 0]
    //   },
    //   {
    //     text: `e-mail: icba@eol.org.ar`,
    //     style: "footer",
    //     alignment: "center",
    //     // opacity: 0.5,
    //     fontSize: 9,
    //   },
      { 
        text: `${currentPage} de ${pageCount}`, 
        style:'footer', 
        alignment: 'right', 
        opacity: 0.5, fontSize: 9, 
        margin: [0, 0, 30, 10] 
      },
    ]
    //currentPage.toString() + ' de ' + pageCount 
  ) 
}

//FOOTER PERSONALIZADO HOJA HORIZONTAL
export var landscapeFooter =
function(currentPage, pageCount) { 
  return(
    [
      { canvas: [ { type: 'line', x1: 35, y1: 0, x2: 800, y2: 0, lineWidth: 2, alignment: "center" } ]},
      "\n",
    //   {
    //     text: `Instituto Clínico de Buenos Aires - Callao 1033 5° Piso - C.P(1023) - Tel: 4816-7292`,
    //     style: "footer",
    //     alignment: "center",
    //     // opacity: 0.5,
    //     fontSize: 9,
    //     margin: [0, 5, 0, 0]
    //   },
    //   {
    //     text: `e-mail: icba@eol.org.ar`,
    //     style: "footer",
    //     alignment: "center",
    //     // opacity: 0.5,
    //     fontSize: 9,
    //   },
      { 
        text: `${currentPage} de ${pageCount}`, 
        style:'footer', 
        alignment: 'right', 
        opacity: 0.5, fontSize: 9, 
        margin: [0, 0, 30, 10] 
      },
    ]
    //currentPage.toString() + ' de ' + pageCount 
  ) 
}


//logo chico
export function getLogo(){
    return (
        [
            {
                image: Logo,
                width: 25,
                alignment: 'left',
                //[left, top, right, bottom]
                margin: [ 50, 10, 10, 10 ],
                //opacity: 0.4
            },
            /*
            '\t',
            { 
                text:'Control de asistencia', 
                style:'title', 
                alignment:'center'
            },
            */
        ]

    )
}

//logo grande
export function getLogoHeader(){
    return (
        [
            {
                image: Logo,
                width: 50,
                alignment: 'left',
                //[left, top, right, bottom]
                margin: [ 0, 0, 0, 0 ],
                //opacity: 0.4
            },
            /*
            '\t',
            { 
                text:'Control de asistencia', 
                style:'title', 
                alignment:'center'
            },
            */
        ]

    )
}

export function getTitle(){
    return (
        [
            { 
                text:'Control de asistencia', 
                style:'title', 
                alignment:'center'
            },
        ]
    )
}

export function getStyles(){
    const styles={
        header:{
            fontSize: 10,
            alignment:'right',
            bold: true,
            italics:true,
            margin: [10, 20, 40, 20]
        },
        title:{
            fontSize: 18,
            bold: true,
            alignment: 'center',
        },
        titleLeft:{
            fontSize: 12,
            bold: true,
            alignment: 'Left',
        },
        quote:{
            italics: true
        },
        small:{
            fontSize:8
        },
        tableHeader: {
			bold: true,
			fontSize: 12,
            color: 'black',
            fillColor: '#cccccc',
            margin:[5,5,5,5]
        },
        tableHeaderWithoutColor: {
			bold: true,
			fontSize: 12,
            color: 'black',
            fillColor: null,
            margin:[0,0,0,0]
        },
        table:{
            fontSize:10,
            margin:[5,5,5,5]
        },
        list:{
            fontSize: 10,
            margin:[5,5,5,5]
        },
        footer:{
            heigh: 40
            //margin: [0, 15, 0, 15]
        }
    }
    return styles;
}

export function docDefinitionTable(title, dataHeaderTable, dataTable){
    const docDefinition =
    {
        pageSize:'A4',
        pageOrientation: 'vertical',
        header:getHeader(),
        content:[
            getLogo(),
            '\n',
            '\n',
            { text: `${title}` , style:'title'},
            '\n',
            '\n',
            {
                table: {
                    headerRow:1,
                    dontBreakRows:true,
                    widths:['*','*','auto','auto','auto'],
                    body:
                    [
                        ...dataHeaderTable,
                        ...dataTable,
                    ]
                }
            },
        ],
        styles:getStyles()
    };

    return docDefinition;
}