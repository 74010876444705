import React, { useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { emailValidation } from '../../../utils/formValidation';
import { verifyEmailApi, sendEmailApi } from '../../../api/user';
import './RecoverForm.scss';

export default function RecoverForm() {
    const [inputs, setInputs] = useState({
        email: ""
    });

    const [formValid, setFormValid] = useState ({
        email: false
    });

    const changeForm = e => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        });
    };

    const inputValidation = e => {
        const { type, name } = e.target;

        if (type === "email") {
            setFormValid({
                ...formValid,
                [name]: emailValidation(e.target)
            });
        }
    };

    const isFormValid = () =>{
        const {email} = formValid;
        const emailVal = inputs.email;

        let errorExists = false;
        if (!emailVal) {
            notification['error']({
                message: "El email es obligatorio"
            });
            errorExists = true;
        } else {
            if (!email) {
                notification["error"]({
                    message: "El email es inválido"
                });
                errorExists = true;
            }
        }
        return errorExists;
    };

    function goToRoute(props, routeName) { //para redirigir cuando se necesite
        props.navigation.navigate(routeName);
    }

    // en desuso
    const findMail = async e => { //async se usa con await
        e.preventDefault(); //esto evita que se refresque la pantalla al presionar el boton de submit
        //console.log(inputs);

        if(!isFormValid()){
            //le dice a la funcion que pare en esta ejec hasta que termine y siga con las prox lineas
            const result = await verifyEmailApi(inputs);
            //console.log(result);
            if(!result.ok) {
                notification["error"]({
                    message: result.message
                });
            } else if (result.ok === true) {
                //TO DO: ejecutar enviarMail en lugar de la notification
                notification["success"]({
                    message: result.message
                });
            //window.location.href = "/admin";
            }
        console.log(result);
        console.log(result.message);
        }
    };

    //response.code !== 200 , 404,
    const enviarMail = async (e) => {
        e.preventDefault();

        if (!isFormValid()) {
            //const accessToken = getAccessToken();
            const result = await sendEmailApi(inputs)
            //.then((result) => {
            console.log(result);
            console.log(result.message);
            if (
            result === "ERR_CONNECTION_REFUSED" ||
            result === "Failed to fetch" ||
            result === undefined
            ) {
            notification["error"]({
                message: "Servidor caido"
            });
            } else if (result.ok === true) { //response.code !== 200 -> undefined
            notification["success"]({
                //message: result.message,
                message: "Email enviado, revise su casilla de correo"
            });
            }
            else {
            notification["error"]({
                message: result.message
            });
            }
            /*})
            .catch((err) => {
              notification["error"]({
                message: err,
              });
            });*/
        }
    };

    return (
        <Form className="recover-form" onChange={changeForm} onSubmit={enviarMail}>
            <Form.Item>
                <label className="control-label">
                    Ingrese su correo para enviarle un e-mail con un enlace para restablecer su contraseña
                </label>
            </Form.Item>
            <Form.Item>
                <Input
                    prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25" }} />}
                    type="email"
                    name="email"
                    placeholder="Correo electrónico"
                    className="login-form__input"
                    onChange={inputValidation}
                    value={inputs.email}
                />
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit" className="recover-form__button" onClick={enviarMail}>
                    Enviar
                </Button>
            </Form.Item>
        </Form>
    );
}