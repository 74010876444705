import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  notification,
} from "antd";
import { TagOutlined, MailOutlined } from "@ant-design/icons";
import { updateAdviserApi } from "../../../../api/adviser";
import { getAccessToken } from "../../../../api/auth";
import { emailValidation, removeClassErrorSuccess } from "../../../../utils/formValidation";

import "./EditAdviserForm.scss";

export default function EditAdviserForm(props) {
  const { adviser, setIsVisibleModal, setReloadAdvisers } = props;
  const [ adviserData, setAdviserData ] = useState({});

  const [modifDataToValid, setModifDataToValid] = useState({
    phone: false
  });

  //trae los datos de tipo de consejero
  useEffect(() => {
    setAdviserData({
      adviser_code: adviser.adviser_code,
      name: adviser.name,
      lastname: adviser.lastname,
      email: adviser.email
    });
  }, [adviser]);

  const [formValid, setFormValid] = useState({
  });

  const inputValidation = (e) => {
    const { name } = e.target;

    removeClassErrorSuccess(e.target);

    setAdviserData({
      ...adviserData,
      [name]: e.target.value,
    });
    if (e.target.value !== "") {
      if (name === "email") {
          setFormValid({
            ...formValid,
            [name]: emailValidation(e.target),
          });
        }
    }
  };

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!adviserData.adviser_code || !adviserData.name) {
      notification["error"]({
        message: "Obligatorios: Código y Nombre.",
      });
      errorExists = true;
    } else if (
      formValid.email===false && adviserData.email !=="") {
      //BUG
      notification["error"]({
        message: "Email inválido",
      });
      errorExists = true;
    }
    else {
      errorExists = false;
    }
    return errorExists;
  };

  const updateAdviser = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let adviserUpdate = adviserData; //es let porque se va actualizando

      updateAdviserApi(token, adviserUpdate, adviser._id).then((result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
          setIsVisibleModal(true);
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
          setIsVisibleModal(true);
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          setIsVisibleModal(false);
          setReloadAdvisers(true);
        }
      });
    }
  };

  return (
    <div className="edit-adviser-form">
      <EditForm
        adviserData={adviserData}
        setAdviserData={setAdviserData}
        updateAdviser={updateAdviser}
        modifDataToValid={modifDataToValid}
        setModifDataToValid={setModifDataToValid}
        inputValidation={inputValidation}
      />
    </div>
  );
}

function EditForm(props) {
  const { adviserData, setAdviserData, updateAdviser, inputValidation } = props;

  return (
    <Form className="form-edit" onSubmit={updateAdviser}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Código</label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Código"
              value={adviserData.adviser_code}
              onChange={(e) =>
                setAdviserData({ ...adviserData, adviser_code: e })
              }
              min={1}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label"><span className="control-required">* </span>Nombre</label>
          <Form.Item>
            <Input
              placeholder="Nombre"
              value={adviserData.name}
              onChange={(e) =>
                setAdviserData({ ...adviserData, name: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">Apellido</label>
          <Form.Item>
            <Input
              placeholder="Apellido"
              name="lastname"
              value={adviserData.lastname}
              onChange={(e) =>
                setAdviserData({ ...adviserData, lastname: e.target.value })
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">E-mail</label>
          <Form.Item>
            <Input
              prefix={<MailOutlined />}
              placeholder="E-mail"
              name="email"
              value={adviserData.email}
              onChange={inputValidation}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateAdviser}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
