import React, { useState, useEffect } from "react";
import {
  //BrowserRouter as Router,
  //Route,
  //useParams,
  //Link,
  //useLocation,
  Redirect,
  //withRouter,
  //useHistory
} from "react-router-dom";
//import { Button } from 'antd';

//mport { ArrowLeftOutlined } from "@ant-design/icons";

import { getAccessToken } from "../../../api/auth";
import { getInscriptionsByStudentApi } from "../../../api/inscription";
import { getStudentApi } from "../../../api/student";
import ListCoursesStudent from "../../../components/Admin/Inscriptions/ListCoursesStudent";
//import Error404 from "../../Error404.js";

export default function CoursesStudent(props) {
  const [studentData, setStudentData] = useState({});
  const [inscriptionCourses, setInscriptionCourses] = useState([]);
  const [reloadInscriptionCourses, setReloadInscriptionCourses] = useState(false);
  //const history = useHistory();
  const token = getAccessToken();
  const { id } = props.location.state ? props.location.state : false;

  useEffect(() => {
    if (id !== false && id !== undefined) {
      getInscriptionsByStudentApi(token, id).then((response) => {
        setInscriptionCourses(response.inscriptions);
      });
    }
    setReloadInscriptionCourses(false);
  }, [token, reloadInscriptionCourses]);


  useEffect(() => {
    if (id !== false && id !== undefined) {
      getStudentApi(token, id).then((response) => {
        setStudentData(response.student);
      });
    }
  }, [id]);

  return (
    <div className="inscriptions">
      {/*VOLVER HACIA ATRAS*/}
      {/*<Button type="primary" icon={<ArrowLeftOutlined/>} onClick={() => history.goBack()}>Atrás</Button>*/}
      <div> </div>
      {id ? (
        <>
          <h3>
            {" "}
            Listado de Cursos del Estudiante "
            {
              studentData.name +
              " " +
              studentData.lastname
            }
            "
          </h3>
          <ListCoursesStudent
            studentData={studentData}
            inscriptions={inscriptionCourses}
            setReloadInscriptionCourses={setReloadInscriptionCourses}
          />
        </>
      ) : (
        <>
          <Redirect to="/admin/students" />
        </>
      )}
    </div>
  );
}
