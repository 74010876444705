import { getCoursesBySchoolYearApi } from "../api/course";
import { getAbsencesByCourseApi } from "../api/absence";
import { updateOwnHoursByStudentIdApi } from "../api/student";
import {
  getInscriptionByCourseApi,
  updateStateStudentByInscriptionIdApi,
  updateStateStudentsByInscriptionIdApi,
  updateOwnHoursStudentsByInscriptionIdApi,
} from "../api/inscription";
import { getStateStudentCoursesActiveApi } from "../api/stateStudentCourse";

//TODO: hay que modificar todos los estados de las inscripciones en listStudentCourses
//primero: modificar todos los cursos cuyo schoolYear sea finalizado -> stateCourse finalizado
/* tambien debe llamarse a una funcion que cuente las inasistencias de cada estudiante
q tenga curso en este año finalizado y si ese conteo es mayor a la cantidad de inasistencias aceptadas
por el curso, el estado_estudiante_curso debe setearse en 'Desaprobado' */
//lo ideal seria poner un progressBar mientras se actualizan todos los estado_estudiante_curso
//estado_estudiante_curso: se setea en inscription -> students -> student -> stateStudentCourse

async function getListStateCourse(token) {
  let list = await getStateStudentCoursesActiveApi(token, true);
  if (list.stateStudentCourses && list.stateStudentCourses.length > 0) {
    return list.stateStudentCourses;
  } else {
    return [];
  }
}

function getStateResult(
  token,
  listStateStudentCoursesActive,
  result,
  absencesAllowed
) {
  // let listStateStudentCoursesActive = await getListStateCourse(token);
  let stateResult = null;
  if (listStateStudentCoursesActive.length > 0) {
    if (result <= absencesAllowed) {
      //console.log("aprobado");
      stateResult = listStateStudentCoursesActive.filter(
        (i) => i.description.toLowerCase() === "aprobado"
      );
    } else if (result > absencesAllowed) {
      stateResult = listStateStudentCoursesActive.filter(
        (i) => i.description.toLowerCase() === "no aprobado"
      );
      //console.log("no aprobado");
    }
  }
  return stateResult;
}

export async function updateStateStudentSchoolYear(token, schoolYear) {
  let countErr = 0;
  let countCourses = 0;
  // let info=[];
  let listStates = await getListStateCourse(token);
  let approved = listStates.filter(
    (i) => i.description.toLowerCase() === "aprobado"
  );
  let notApproved = listStates.filter(
    (i) => i.description.toLowerCase() === "no aprobado"
  );
  let enrolled = listStates.filter(
    (i) => i.description.toLowerCase() === "inscripto"
  );
  //busco los cursos relacionados al año lectivo finalizado
  let response = await getCoursesBySchoolYearApi(token, schoolYear._id);
  if (response && response.courses && response.courses.length > 0) {
    for await (let course of response.courses) {
      //verifico que el curso no este ya finalizado y que este activo
      if(course.active && (!course.stateCourse ||
        (course.stateCourse && course.stateCourse.description && 
          course.stateCourse.description.toLowerCase() !== "finalizado"))){
        countCourses++;
        let absencesAllowed = course.workload
          ? course.workload.absencesAllowed
          : undefined;
        //busco las inasistencias del curso
        let responseAbsences = await getAbsencesByCourseApi(token, course._id);
        let absences = responseAbsences.absences;
        //busco la inscripcion y los estudiantes del curso
        let responseInscription = await getInscriptionByCourseApi(
          token,
          course._id
        );
        if (responseInscription.inscription) {
          let arrayResult = responseInscription.inscription.students.map(
            (student) => {
              //verifico que El estudiante no tenga stateStudentCourse distinto a undefined,sino sea inscripto, aprobado y no aprobado
              if (
                !student.stateStudentCourse ||
                (approved.length > 0 && student.stateStudentCourse &&
                  student.stateStudentCourse._id === approved[0]._id) ||
                (notApproved.length > 0 && student.stateStudentCourse &&
                  student.stateStudentCourse._id === notApproved[0]._id) ||
                (enrolled.length > 0 && student.stateStudentCourse &&
                    student.stateStudentCourse._id === enrolled[0]._id)
              ) {
                //Conteo de inasistencias por cada estudiante en el curso
                let result = 0;
                absences.map((list) =>
                  list.students.map((item) => {
                    if (item.student._id === student.student._id) {
                      if (item.justify !== undefined && item.justify === false) {
                        result++;
                      }
                    }
                    // return true;
                  })
                );
                if (absencesAllowed) {
                  //comparo el resultado del conteo de inasistencias con la cantidad de faltas permitidas
                  let stateResult = getStateResult(
                    token,
                    listStates,
                    result,
                    absencesAllowed
                  );
                  if (
                    stateResult &&
                    stateResult !== null &&
                    stateResult.length > 0
                  ) {
                    let hours =
                      stateResult[0]._id === approved[0]._id
                        ? course.workload.hours
                        : 0;
                    let updateStateStudent = {
                      student: student.student._id,
                      stateStudentCourse: stateResult[0]._id,
                      ownHours: hours,
                    };

                    return updateStateStudent;
                  }
                }
              }
              return null;
            }
          );
          //Filtro para eliminar todos los valor null
          let arrayUpdate = arrayResult.filter((i) => i !== null);
          let resultUpdate = await updateStateStudentsByInscriptionIdApi(
            token,
            arrayUpdate,
            responseInscription.inscription._id
          );
          // console.log(resultUpdate);
          if (resultUpdate.code === 200) {
            //Si fue exitoso, entonces actualizo las horas de los estudiantes.
            let resultUpdate2 = await updateOwnHoursStudentByInscription(
              token,
              responseInscription.inscription._id
            );
            // console.log(resultUpdate2)
            if (resultUpdate2 === false) {
              countErr++;
            }else{
            }
          } else {
            countErr++;
          }
        }
      }
    }
    return {
      state: true,
      err: countErr,
      count: countCourses,
      message: "Se han actualizado el estado de los estudiantes de cada curso.",
    };
  } else {
    return { state: false, message: "No se pudo realizar la acción" };
  }
  //return count;
}

export async function updateStateStudentCourse(token, course, newStudents) {
  let countErr = 0;
  let countStudents=0;
  let listStates = await getListStateCourse(token);
  let approved = listStates.filter(
    (i) => i.description.toLowerCase() === "aprobado"
  );
  let notApproved = listStates.filter(
    (i) => i.description.toLowerCase() === "no aprobado"
  );
  let enrolled = listStates.filter(
    (i) => i.description.toLowerCase() === "inscripto"
  );
  //Curso array (necesario info workload para obtener la cantidad de inasistencias permitidas)
  let absencesAllowed = course.workload
    ? course.workload.absencesAllowed
    : undefined;
  //busco las inasistencias del curso
  let responseAbsences = await getAbsencesByCourseApi(token, course._id);
  if (responseAbsences && responseAbsences.absences) {
    let absences = responseAbsences.absences;
    //busco la inscripcion y los estudiantes del curso
    let responseInscription = await getInscriptionByCourseApi(
      token,
      course._id
    );
    if (responseInscription.inscription) {
      let students=newStudents?
       responseInscription.inscription.students
       .filter(i=>newStudents.filter((x=> x.student===i.student._id)).length > 0) : 
       responseInscription.inscription.students;
      // if (responseInscription.inscription.students.length > 0) {
        if(students.length > 0){
        for await (let student of students) {
          countStudents++;
          //verifico que El estudiante no tenga stateStudentCourse distinto a undefined, o sea inscripto, aprobado y no aprobado
          if (
            !student.stateStudentCourse ||
            (approved.length > 0 && student.stateStudentCourse &&
              student.stateStudentCourse._id === approved[0]._id) ||
            (notApproved.length > 0 && student.stateStudentCourse &&
              student.stateStudentCourse._id === notApproved[0]._id) ||
              (enrolled.length > 0 && student.stateStudentCourse &&
                student.stateStudentCourse._id === enrolled[0]._id) 
          ) {
            //Conteo de inasistencias del estudiante en el curso
            let result = 0;
            absences.map((list) =>
              list.students.map((item) => {
                if (item.student._id === student.student._id) {
                  if (item.justify !== undefined && item.justify === false) {
                    result++;
                  }
                }
                return true;
              })
            );
            if (absencesAllowed) {
              //comparo el resultado del conteo de inasistencias con la cantidad de faltas permitidas
              let stateResult = await getStateResult(
                token,
                listStates,
                result,
                absencesAllowed
              );

              if (
                stateResult &&
                stateResult !== null &&
                stateResult.length > 0
              ) {
                let hours =
                  stateResult[0]._id === approved[0]._id
                    ? course.workload.hours
                    : 0;
                let updateStateStudent = {
                  student: student.student._id,
                  stateStudentCourse: stateResult[0]._id,
                  ownHours: hours,
                };
                //Actualizo los estados de los estudiantes de la inscripcion
                let resultUpdate = await updateStateStudentByInscriptionIdApi(
                  token,
                  updateStateStudent,
                  responseInscription.inscription._id
                );
                // console.log(result.message);
                if (resultUpdate.code !== 200) {
                  //Si no fue exitoso
                  countErr++;
                }else if(resultUpdate.code===200 && newStudents){
                   //actualizo las horas de los estudiantes nuevos agregados
                   let resultUpdate2 = await updateOwnHoursByStudentIdApi(
                    token,
                    student.student._id
                  );
                  if (resultUpdate2 === false) {
                    countErr++;
                  }
                }
              }
            }
          }
        }
        //actualizo las horas de los estudiantes del curso/inscripcion completo
        //si no son nuevos estudiantes
        if(!newStudents){
          let resultUpdate2 = await updateOwnHoursStudentByInscription(
            token,
            responseInscription.inscription._id
          );
          if (resultUpdate2 === false) {
            countErr++;
          }
        }
      } else {
        if(newStudents){
          return {
            state: false,
            err: countErr,
            message: "Hubo un error al consultar la inscripcion de los nuevos estudiantes.",
          };
        }else{
          return {
            state: true,
            err: countErr,
            count:countStudents,
            message: "El curso no tiene estudiantes inscriptos.",
          };
        }
      }
    }
    return {
      state: true,
      err: countErr,
      count:countStudents,
      message: "Se han actualizado el estado de los estudiantes del curso.",
    };
  } else {
    return { state: false, message: "No se pudo realizar la acción" };
  }
}

export async function updateOwnHoursStudentByInscription(token, inscriptionId) {
  let result = await updateOwnHoursStudentsByInscriptionIdApi(
    token,
    inscriptionId
  );
  if (result.code === 200) {
    return true;
  }
  return false;
}
