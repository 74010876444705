import React, { useState, useEffect } from "react";
import { getAccessToken } from "../../../api/auth";
import { getStateSchoolYearsActiveApi } from "../../../api/stateSchoolYear";
import ListStateSchoolYears from "../../../components/Admin/StateSchoolYear/ListStateSchoolYears";


export default function StateSchoolYears() {
    const [stateSchoolYearsActive, setStateSchoolYearsActive] = useState([]);
    const [stateSchoolYearsInactive, setStateSchoolYearsInactive] = useState([]);
    const [reloadStateSchoolYears, setReloadStateSchoolYears ] = useState(false);
    const token = getAccessToken();

    useEffect(() => {
        getStateSchoolYearsActiveApi(token, true).then(response => {
            setStateSchoolYearsActive(response.stateSchoolYears);
        });
        getStateSchoolYearsActiveApi(token, false).then(response => {
            setStateSchoolYearsInactive(response.stateSchoolYears);
        });
        setReloadStateSchoolYears(false);
    }, [token, reloadStateSchoolYears]);

    return (
        <div className="stateschoolyears">
            <h3>Listado de Estados de Año lectivo</h3>
            <ListStateSchoolYears stateSchoolYearsActive={stateSchoolYearsActive} stateSchoolYearsInactive={stateSchoolYearsInactive} setReloadStateSchoolYears={setReloadStateSchoolYears}
                />

        </div>
    );
}