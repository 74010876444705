
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { getAccessToken } from "../../../api/auth";
import { getAbsencesByCourseApi } from "../../../api/absence";
import { getCourseApi } from "../../../api/course";
import ListCourseAbsences from "../../../components/Admin/CourseAbsences/ListCourseAbsences";

export default function CourseAbsences(props) {
  const [courseAbsences, setCourseAbsences] = useState([]);
  const [course, setCourse] = useState([]);
  const [reloadCourseAbsences, setReloadCourseAbsences] = useState(false);
  const token = getAccessToken();

  useEffect(() => {
    if (props.location.state) {
      getCourseApi(token, props.location.state.id).then(
          (response) => {
              setCourse(response.course);
          }
      )
      getAbsencesByCourseApi(token, props.location.state.id).then(
        (response) => {
          setCourseAbsences(response.absences);
        }
      );
    }
    setReloadCourseAbsences(false);
  }, [token, reloadCourseAbsences]);

  return (
    <div className="courseabsences">
      {props.location.state ? (
        <>
          <h3>
            Listado de Inasistencias del Curso "
            {course? course.name : null}" 
          </h3>
          <ListCourseAbsences
            course={course}
            absences={courseAbsences}
            setReloadCourseAbsences={setReloadCourseAbsences}
          />
        </>
      ) : (
        <>
          <Redirect to="/admin/courses" />
        </>
      )}
    </div>
  );
}
