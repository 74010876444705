import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Button, Row, Col, notification } from "antd";
import {
  TagOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { updateStateCourseApi } from "../../../../api/stateCourse";
import { getAccessToken } from "../../../../api/auth";
import { numberValidation } from "../../../../utils/formValidation";

import "./EditStateCourseForm.scss";

export default function EditStateCourseForm(props) {
  const { stateCourse, setIsVisibleModal, setReloadStateCourses } = props;
  const [stateCourseData, setStateCourseData] = useState({});

  const [modifDataToValid, setModifDataToValid] = useState({
    phone: false,
  });
  const [validData, setValidData] = useState({
    email: false,
  });

  //trae los datos de tipo de proveedor
  useEffect(() => {
    setStateCourseData({
      statecourse_code: stateCourse.statecourse_code,
      description: stateCourse.description,
    });
  }, [stateCourse]);

  //Verifica que no exista errores en los datos que se deben validar
  const isFormValid = (e) => {
    let errorExists = false;
    if (!stateCourseData.description) {
      notification["error"]({
        message: "Obligatorio: Descripción.",
      });
      errorExists = true;
    } else {
    /*
    else if (modifDataToValid.phone) {
      if (!validData.phone) {
        //solo si el email está vacio, falta la validación en el return de EditForm
        notification["error"]({
          message: "Ingreso de teléfono inválido"
        });
        errorExists = true;
      }
    }*/
      errorExists = false;
    }
    return errorExists;
  };

  const updateStateCourse = (e) => {
    e.preventDefault();
    const error = isFormValid();
    if (!error) {
      const token = getAccessToken();
      let stateCourseUpdate = stateCourseData; //es let porque se va actualizando

      updateStateCourseApi(token, stateCourseUpdate, stateCourse._id).then(
        (result) => {
          if (
            result.message === "ERR_CONNECTION_REFUSED" ||
            result.message === "Failed to fetch" ||
            result.message === undefined
          ) {
            notification["error"]({
              message: "Servidor caido",
            });
            setIsVisibleModal(true);
          } else if (result.code !== 200) {
            notification["error"]({
              message: result.message,
            });
            setIsVisibleModal(true);
          } else {
            notification["success"]({
              message: result.message, //el mensaje que viene del server
            });
            //console.log("respuesta: " + result.message);
            setIsVisibleModal(false);
            setReloadStateCourses(true);
          }
        }
      );
    }
  };

  return (
    <div className="edit-statecourse-form">
      <EditForm
        stateCourseData={stateCourseData}
        setStateCourseData={setStateCourseData}
        updateStateCourse={updateStateCourse}
        modifDataToValid={modifDataToValid}
        setModifDataToValid={setModifDataToValid}
        validData={validData}
        setValidData={setValidData}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    stateCourseData,
    setStateCourseData,
    updateStateCourse,
    setModifDataToValid,
    modifDataToValid,
    validData,
    setValidData,
  } = props;

  //validaciones de inputs
  const inputValidation = (e) => {
    const { type, description } = e.target;

    setStateCourseData({
      ...stateCourseData,
      [description]: e.target.value,
    });
    setModifDataToValid({
      ...modifDataToValid,
      [description]: true,
    });
    if (description === "phone") {
      setValidData({
        ...validData,
        [description]: numberValidation(e.target),
      });
    }
  };

  return (
    <Form className="form-edit" onSubmit={updateStateCourse}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={12}>
          <label className="control-label">
            Código
          </label>
          <Form.Item>
            <InputNumber
              prefix={<TagOutlined />}
              placeholder="Automático"
              value={stateCourseData.statecourse_code}
              onChange={(e) => setStateCourseData({ ...stateCourseData, statecourse_code: e })}
              style={{ width: "100%" }}
              disabled={true}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label className="control-label">
            <span className="control-required">* </span>Descripción
          </label>
          <Form.Item>
            <Input
              placeholder="Descripción"
              value={stateCourseData.description}
              onChange={(e) =>
                setStateCourseData({ ...stateCourseData, description: e.target.value })
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateStateCourse}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
