import React, { useState, useEffect } from "react";
import {
  Form,
  Select,
  Button,
  Row,
  Col,
  notification,
  Divider
} from "antd";
// import {
//   UserOutlined,
//   TagOutlined
// } from "@ant-design/icons";
import { updateStateStudentByInscriptionIdApi } from "../../../../api/inscription";
import { getStateStudentCoursesActiveApi } from "../../../../api/stateStudentCourse";
// import { updateOwnHoursStudentByInscription } from "../../../../utils/stateStudent";
import { getAccessToken } from "../../../../api/auth";

import "./EditStateStudentCourseForm.scss";
import { updateOwnHoursByStudentIdApi } from "../../../../api/student";
import { getCourseApi } from "../../../../api/course";

export default function EditStateStudentCourseForm(props) {
  const { inscription, student, setIsVisibleModal, setReloadInscriptions, stateStudentCourse } = props;
  const [ input, setInput ] = useState({});
  const [ listStateStudentCoursesActive, setListStateStudentCoursesActive ] = useState([]);
  const accessToken = getAccessToken();

  //trae los datos de la inscripcion
  useEffect(() => {
    setInput({
      student: student._id
    });
  }, [student]);

  useEffect(() => {
    getStateStudentCoursesActiveApi(accessToken, true).then((response) => {
      setListStateStudentCoursesActive(response.stateStudentCourses);
    });
  }, []);

  const updateInscription = async (e) => {
    e.preventDefault();
    // const error = isFormValid();
    if (input.stateStudentCourse && input.stateStudentCourse !== null) {
      const token = getAccessToken();

      let inscriptionUpdate = input; 

      //verifico el estado nuevo seleccionado para suma o resta de horas
      let stateStudent=listStateStudentCoursesActive.filter(i=>i._id===input.stateStudentCourse);
      if(stateStudent && stateStudent.length > 0){
        if (stateStudent[0].description.toLowerCase() ==="aprobado" ){
          let hours=inscription.course.workload? inscription.course.workload.hours? inscription.course.workload.hours : 0 : 0;
          if(hours===0){
            //solo en el caso de que las horas son 0 verifica igualmente el curso 
            let data= await getCourseApi(token, inscription.course._id);
            if(data.course){
              hours=data.course.workload? data.course.workload.hours? data.course.workload.hours : 0 : 0;
            }
          }
          inscriptionUpdate.ownHours=hours;
        }
        // else if( stateStudent[0].description.toLowerCase() ==="inscripto" || stateStudent[0].description.toLowerCase() ==="no aprobado" || stateStudent[0].description.toLowerCase() ==="abandono"
        //  || stateStudent[0].description.toLowerCase() ==="abandonó" || stateStudent[0].description.toLowerCase() ==="no suma hs"){
        //estados "inscripto" "no aprobado" "abandono" "recupera hs" "no suma hs" "horas conferencia"
        else{
          inscriptionUpdate.ownHours=0;
        }
      }

      //actualizo el estado del estudiante en la inscripcion
      updateStateStudentByInscriptionIdApi(token, inscriptionUpdate, inscription._id).then(async(result) => {
        if (
          result.message === "ERR_CONNECTION_REFUSED" ||
          result.message === "Failed to fetch" ||
          result.message === undefined
        ) {
          notification["error"]({
            message: "Servidor caido",
          });
        } else if (result.code !== 200) {
          notification["error"]({
            message: result.message,
          });
        } else {
          notification["success"]({
            message: result.message, //el mensaje que viene del server
          });
          //console.log("respuesta: " + result.message);
          //Actualizo las horas del estudiante
          // if(stateStudent && stateStudent.length > 0 && 
          //   (stateStudent[0].description.toLowerCase() ==="inscripto" || stateStudent[0].description.toLowerCase() ==="aprobado" || stateStudent[0].description.toLowerCase() ==="no aprobado" || stateStudent[0].description.toLowerCase() ==="abandono"
          //    || stateStudent[0].description.toLowerCase() ==="abandonó"
          //    || stateStudent[0].description.toLowerCase() ==="no suma hs")){
            let resultUpdateHours= await updateOwnHoursByStudentIdApi(token, input.student);
            if(resultUpdateHours.code===200){
              notification["success"]({
                message: "Actualización de las horas del estudiante correcta"
              });
            }else{
              notification["err"]({
                message: "Error al actualizar las horas del estudiante"
              });
            }
          //}
        }
        setIsVisibleModal(false);
        setReloadInscriptions(true);
      });
    }else{
      notification["error"]({
        message: "Debe seleccionar alguna opción",
      });
    }
  };

  return (
    <div className="edit-inscription-form">
      <EditForm
        input={input}
        setInput={setInput}
        updateInscription={updateInscription}
        listStateStudentCoursesActive={listStateStudentCoursesActive}
        stateStudentCourse={stateStudentCourse}
      />
    </div>
  );
}

function EditForm(props) {
  const {
    input,
    setInput,
    updateInscription,
    listStateStudentCoursesActive,
    stateStudentCourse
  } = props;
  const { Option } = Select;
  //console.log(listStateStudentCoursesActive);
  // console.log(input);

  return (
    <Form className="form-edit" onSubmit={updateInscription}>
      {/* //onFinish={updateUser} */}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Estado actual : </label>{" "} {stateStudentCourse!==null? stateStudentCourse : "-"}
          </Col>
      </Row>
      <Divider/>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <label className="control-label">Nuevo estado del curso</label>
          <Form.Item>
            <Select
              //prefix={<DollarCircleOutlined />}
              showSearch
              placeholder="Selecciona el estado del curso"
              value={input.stateStudentCourse}
              onChange={(e) => setInput({ ...input, stateStudentCourse: e })}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listStateStudentCoursesActive
                ? listStateStudentCoursesActive.map((state) => {
                    return (
                      <Option key={state._id}>
                        {state.description}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-submit"
          onClick={updateInscription}
        >
          Actualizar
        </Button>
      </Form.Item>
    </Form>
  );
}
