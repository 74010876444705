import { switchPath, apiVersion } from './config';

// CONSEJERO

export function getAdviserExportApi(token) {
    const url = `${switchPath()}/${apiVersion}/adviser-export-csv`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "text/csv",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.text();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'adviser.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch(err => {
            return err.message;
        });
}

export function postAdviserImportApi(token, file) {
    const url = `${switchPath()}/${apiVersion}/adviser-import-csv`;
    const formData = new FormData();
    formData.append("file", file, file.name);

    const params = {
        method: "POST",
        body: formData,
        headers: {
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(err => {
            return err.message;
        });
}

export function addAdviserApi(token, data){
    const url = `${switchPath()}/${apiVersion}/add-adviser`;
    const params = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
    };

    return fetch(url, params)
        .then(response => {
            //console.log(response);
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return {
                ok: false,
                message: err.message
            };
        });
}

export function getAdvisersApi(token) {
    const url = `${switchPath()}/${apiVersion}/advisers`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getAdvisersPaginateApi(token,state, limit, page, filter,filters,schoolYear) {
    let url = "";
    //permite armar los parametros en base a los filtros de la busqueda avanzados para enviar a la consulta
    let options= filters? (Object.keys(filters)
        .map(k => `${k}=${encodeURI(filters[k])}`)
        .join('&')): null;
    // console.log(options);
    //TODO: VERIFICAR LAS SIGUIENTES CONDICIONES EN BASE A LOS FILTROS
    if(filter!==undefined || filters !==null){
        url=`${switchPath()}/${apiVersion}/advisers-paginate?state=${state}&limit=${limit}&page=${page}&filter=${filter}&${options}&schoolYear=${schoolYear}`;
    }
    else{
        url=`${switchPath()}/${apiVersion}/advisers-paginate?state=${state}&limit=${limit}&page=${page}&schoolYear=${schoolYear}`;
    }
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


export function getAdvisersActiveApi(token, status) {
    const url = `${switchPath()}/${apiVersion}/advisers-active?active=${status}`;
    //console.log(url);
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log(result);
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function updateAdviserApi(token, adviserData, adviserId) {
    const url = `${switchPath()}/${apiVersion}/update-adviser/${adviserId}`;
    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify(adviserData)
    }

    return fetch(url, params)
    .then(response => {
        return response.json();
    })
    .then(result => {
        return result;
    })
    .catch(err => {
        return err.message;
    });
}

export function activateAdviserApi(token, adviserId, status) {
    const url = `${switchPath()}/${apiVersion}/activate-adviser/${adviserId}`;

    const params = {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        },
        body: JSON.stringify({
            active: status
        })
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function deleteAdviserApi(token, adviserId) {
    const url = `${switchPath()}/${apiVersion}/delete-adviser/${adviserId}`;

    const params = {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            Authorization: token
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err;
        });
}
