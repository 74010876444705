import { 
  downloadPdf, 
  openPdf , 
  getStyles, 
  getHeader, 
  //getFooter, 
  getTitle,
  getLogo
} from "../../../../utils/pdfManager"; //docDefinitionTable
// import { convertTypeIva } from "../../../../utils/convert";
//import { formatDateView } from "../../../../utils/formatDate";
//import Logo from '../../../../assets/img/png/icba.png';
//import moment from "moment";

//Si yo le mando al docente un listado quieren mail y celulares y nbre y apellido y cantidad

export function attendanceDetailPdf(dataCourse, datainscription, isDownload) {
  //console.log(dataCourse);
  //console.log(datainscription);

  const _formatCourse = (data) => {
    return [
      //{ text: `${data.name ? data.name : ""}`, style:'list', bold: true },
      //'\n',
      { text: (`Docentes: ${data.teachers? data.teachers.map(teacher=> teacher.description): ""}    Días: ${data.days ? data.days.map(day=>day.description) : ""}    Lugar : ${data.place ? data.place.description : ""}    Duración: ${data.duration ? data.duration.description : ""} `), fontSize:10 ,
       style:'list'},
      /*
      { text: `Nombre: ${data.name ? data.name : ""}`, style:'list' },
      { text: `Docentes: ${data.teachers? data.teachers.map(teacher=> teacher.description) : "sin datos"}` , style:'list'},
      { text: `Dias: ${data.days ? data.days.map(day=>day.description) : ""}` , style:'list'},
      { text: `Lugar : ${data.place ? data.place.description : ""}`, style:'list' },
      { text: `Duración: ${data.duration ? data.duration.description : ""}` , style:'list'},
      */
    ];
  };

  //celdas
  const _formatStudent = (data) => {
    //margin: [left, top, right, bottom]
    return data.sort((a,b)=>{
      return a.student.lastname.localeCompare(b.student.lastname) ||
      a.student.name.localeCompare(b.student.name);
    }).map((item) => {
      return [
        { text: `${item.student.lastname ? item.student.lastname : ""}, ${item.student.name ? item.student.name : ""}`, margin: [ 0, 3, 0, 3 ], alignment: 'center', fontSize: 9 },
        { text: `${item.student.email ? item.student.email : ""}`, margin: [ 0, 3, 0, 3 ], alignment: 'center', fontSize: 9 },
        { text: `${item.student.mobile ? item.student.mobile : item.student.phone? item.student.phone : ""} `, margin: [ 0, 3, 0, 3 ], alignment: 'center', fontSize: 9 },
        { text: ` `, margin: [ 0, 3, 0, 3 ], alignment: 'center', fontSize: 9},
      ];
    });
  };

  const formattedDataCourse = _formatCourse(dataCourse);

  const formattedDataStudents = _formatStudent(datainscription.students);

  const docDefinition = {
    pageSize: "A4",
    //pageOrientation: "landscape",
    pageOrientation: "vertical",
    //watermark: { text: 'ICdeBA', color: 'grey', opacity: 0.1, bold: true, italics: false },
    //watermark: { image: Logo, opacity: 0.1 },
    background: getLogo(),
    header: getHeader(),
    footer: function(currentPage, pageCount) { 
      return(
        [
          {text: `${currentPage} de ${pageCount}`, style:'footer', alignment: 'center', opacity: 0.5, fontSize: 9},
        ]
        //currentPage.toString() + ' de ' + pageCount 
      ) 
    },
    //footer: getFooter(),
    content: [
      //getLogo(),
      //'\n',
      getTitle(),
      //'\n',
      //[ { text:`Fecha: ${formatDateView(new Date())}`} ],
      '\n',
      [
        { text:`Curso "${dataCourse.name ? dataCourse.name : ""}" con ${datainscription.students.length} estudiantes`, bold: true}
      ],
      //'\n',
      {
        type: "none",
        //ol: [...formattedDataCourse],
        text: [...formattedDataCourse],
        margin: [ 0, 0, 0, 8 ],
      },
      //'\n',
      //[ { text:`Cantidad de estudiantes: ${datainscription.students.length}`} ],
      //'\n',
      {
        style: 'table',
        table: {
          headerRow: 1,
          widths: [145,145,80,90],
          //widths: ['*','*','*','*'],
          //[left, top, right, bottom]
          //margin: [ 5, 2, 10, 20 ],
          //heights: 30,
          heights: 10,
          alignment: 'center',
          dontBreakRows: true,
          body: [
            [
              { text: "Apellido y Nombres", style: "tableHeader", margin: [ 0, 3, 0, 3 ], alignment: 'center', fontSize: 10 },
              { text: "Email", style: "tableHeader", margin: [ 0, 3, 0, 3 ], alignment: 'center', fontSize: 10 },
              { text: "Celular", style: "tableHeader", margin: [ 0, 3, 0, 3 ], alignment: 'center', fontSize: 10 },
              { text: "Firma", style: "tableHeader", margin: [ 0, 3, 0, 3 ], alignment: 'center', fontSize: 10 },
            ],
            ...formattedDataStudents,
          ],
        },
      },
    ],
    styles:getStyles()
  };
  let title = `${dataCourse.name}`;
  if (isDownload) {
    downloadPdf(docDefinition, title);
  } else {
    openPdf(docDefinition);
  }
}
